import React, { Fragment } from "react";
import Divider from "@mui/material/Divider";
import { Dialog, Transition } from "@headlessui/react";

function LogoutModal({ showModal, setShowModal }) {
  const toggle = () => {
    setShowModal(!showModal);
  };

  const logout = () => {
    localStorage.clear();
    // window.location.reload(false);
    // Get the current URL
    let currentUrl = window.location.href;

    // Remove query parameters
    let newUrl = currentUrl.split("?")[0];
    window.location.replace(newUrl);
  };
  return (
    <Transition appear show={showModal} as={Fragment}>
      <Dialog as="div" className="relative z-[101]" onClose={toggle}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto bg-black bg-opacity-75">
          <div className="bg-black-900 flex min-h-full w-full items-center justify-center bg-opacity-75 p-4 text-center ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`${"max-w-lg"}   overflow-initial z-10 h-full   w-full transform
                 rounded-lg  bg-white p-6 text-left align-middle shadow-xl transition-all md:h-auto`}
              >
                <Dialog.Title
                  as="h3"
                  className="mb-4 flex flex-row text-base leading-6"
                >
                  <span className="width-auto flex-1 flex-col text-base font-normal text-[#1d4655]">
                    Confirm logout
                  </span>
                </Dialog.Title>
                <div className="mb-[16px]">
                  <div className="mb-[16px] text-[14px] text-[#2e2e2f]">
                    Are You Sure you want to Logout ?
                  </div>
                </div>
                <Divider />

                <footer className="mt-4">
                  {
                    <div className="mt-4 flex justify-end gap-2">
                      <button
                        onClick={() => toggle()}
                        className="rounded-3xl border border-solid border-[#444444] px-5 py-3 text-center text-sm font-normal text-[#444444]"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => logout()}
                        className="rounded-3xl border border-solid border-[#444444] bg-[#444444] px-5 py-3 text-center text-sm font-bold text-[#FFF]"
                      >
                        Yes
                      </button>
                    </div>
                  }
                </footer>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default LogoutModal;
