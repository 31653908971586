import React, { useState } from "react";
import CustomToggleSwitch from "../../shared-components/CustomToggleSwitch";
import CustomCheckBox from "@/components/shared-components/CustomCheckBox";
import { useCustomTranslation } from "@/hooks/useTranslation";

// import TeethControls, {
//   redoAllComparingMode,
// } from "../teethControls/TeethControls";

const ComparePlans = ({ tp_data, showModifications, applyRedo }) => {
  const [customToggle, setCustomToggle] = useState(false);

  const onChange = () => {
    setCustomToggle(!customToggle);
    applyRedo();
  };
  const { content } = useCustomTranslation();

  const getContent = (group, key) => {
    return content(`controls.${group}.${key}`);
  };
  return (
    <div className="compare_container ">
      <div className="level_1_title header_grid compare_card ">
        <div> {getContent("labels", "compare_plans")}</div>
        <CustomToggleSwitch
          title={""}
          checked={customToggle}
          onChange={onChange}
        />
      </div>
      {/* {toggle && (
        <>
          <div className="version compare_card header_grid">
            <div>{`Version ${tp_data.version}`}</div>
            <CustomCheckBox id="original_plan" />
          </div>
          {showModifications && (
            <div className="version compare_card header_grid">
              <div>Doctor Modifications</div>
              <CustomCheckBox id="doctor_modifcation" />
            </div>
          )}
        </>
      )} */}
    </div>
  );
};

export default ComparePlans;
