import { MessageIcon } from "@/assets";
import React from "react";
const ModificationCard = ({ onClick, data }) => {
  // counter = number of not applied modification
  let movement_counter = 0;
  let ipr_counter = 0;
  const movement = data?.movement || {};
  const ipr = data?.ipr || {};
  for (const key in movement) {
    const entry = movement[key];

    for (const subKey in entry) {
      if (entry[subKey].applied === false) {
        movement_counter += 1;
      }
    }
  }
  for (const key in ipr) {
    const entry = ipr[key];
    if (entry.applied === false) {
      ipr_counter += 1;
    }
  }
  const counter = movement_counter + ipr_counter;
  return (
    <div
      className="doctor_modification_card absolute bottom-[80px] right-[10px] z-10 flex gap-2 pr-2  pt-3"
      onClick={onClick}
    >
      <div className="relative ">
        <MessageIcon />
        {counter > 0 && (
          <div className="modification_numbers absolute">{counter}</div>
        )}
      </div>
      <div>Doctor modifications</div>
    </div>
  );
};

export default ModificationCard;
