import { RejectIcon } from "@/assets";
import { useModal } from "@/hooks/useModal";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { CopyToClipboard } from "../shared-components/CopyToClipboard";

import CustomCheckBox from "../shared-components/CustomCheckBox";
import { findRanges } from "@/helpers/findRanges";
import { groupIssuesByIdentifier } from "../../helpers/groupBy";
import clsx from "clsx";
import { capitalizeFirstLetter } from "@/helpers/capitalizeFirstLetter";
import { useGlobalStore } from "@/store";

export const Reject = ({ detectedIssues, issueType, data }) => {
  const { ModalContainer: SubmitModal, toggleModal: toggleSubmit } = useModal();
  const { ModalContainer: SkipModal, toggleModal: toggleSkip } = useModal();

  const groupedIssues = groupIssuesByIdentifier(detectedIssues);
  const [issue, setIssue] = useState(groupedIssues);

  const { setToggle, canvas } = useGlobalStore(state => ({
    setToggle: state.setToggle,
    canvas: state.refs.canvas,
  }));

  const onChange = (key, index) => {
    let updatedGroupedIssues = { ...issue };

    updatedGroupedIssues[key] = updatedGroupedIssues[key].map((item, idx) => {
      if (idx === index) {
        return { ...item, applied: !item.applied };
      }
      return item;
    });

    setIssue(updatedGroupedIssues);
  };

  // Function to filter and extract ids
  const extractIdsWithAppliedTrue = obj => {
    let result = [];
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        let items = obj[key];
        items.forEach(item => {
          if (item.applied === true) {
            result.push(item.id);
          }
        });
      }
    }
    return result;
  };

  const checkIssueLength = () => {
    return extractIdsWithAppliedTrue(issue)?.length > 0;
  };

  const handleShowReject = () => {
    if (checkIssueLength()) {
      toggleSubmit();
    }
  };
  return (
    <>
      <SubmitModal
        ConfirmApprove
        title="Confirm Selection"
        primaryAction={() => {
          if (window.parent) {
            window.parent.postMessage(
              {
                data: extractIdsWithAppliedTrue(issue),
                tsqcReportId :data?.tsqcReportId ,
                hasTsqcReportedIssues: data?.hasTsqcReportedIssues,
              },
              "*",
            );
            toggleSubmit();
            setToggle("showReject");
          }
        }}
        primaryActionText="Confirm Selection"
        primaryActionColor="border-[#00C7B1] bg-[#00C7B1] text-base"
        footerChildren={
          <div className="grid px-7 py-3 text-center  text-[14px]  text-[#8B8B8B] ">
            You can find the issues selected for fixing in the TDMS once
            confirmed.
          </div>
        }
      >
        <div className="text-[16px] text-[#3D3935]">
          {" "}
          Are you sure you want to confirm your selection?
        </div>
      </SubmitModal>
      <SkipModal
        ConfirmSkip
        title="Skip TSQC"
        primaryAction={() => {
          if (window.parent) {
            window.parent.postMessage(
              {
                data: "SKIP_TSQC",
                hasTsqcReportedIssues: data?.hasTsqcReportedIssues,
                tsqcReportId :data?.tsqcReportId 
              },
              "*",
            );

            toggleSkip();
            setToggle("showReject");
          }
        }}
        primaryActionText="Skip TSQC"
        primaryActionColor="border-[#DA0606] bg-[#DA0606] text-base"
      >
        <div className="text-[16px] text-[#3D3935]">
          {" "}
          Skipping TSQC will approve the conversion and publish the treatment
          setup to the QC
        </div>
      </SkipModal>
      <div>
        <div className="rounded-xl bg-[#FFE1DF] px-2 py-3">
          <div className="grid  grid-cols-[auto,1fr]">
            <div className="p-4">
              <RejectIcon />
            </div>
            <div className="grid grid-rows-2">
              <div className="font-semibold">Automated QC Results</div>
              <div className="font-semibold text-[#FF0000]">Rejected</div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div className="text-#3D3935 mt-1 py-2 text-[14px] font-[400]">
              Select the issues that you want to fix:
            </div>

            <div
              className={clsx(
                "h-[calc(50vh-200px)] max-h-[calc(70vh-250px)] min-h-[calc(300px-200px)] overflow-auto",
              )}
            >
              {Object.entries(issue || {}).map(([key, value]) => (
                <ul key={key}>
                  <li className="text-[14px] font-medium text-black">
                    {" "}
                    <span className="text-center">*</span>
                    <span className="px-3 pt-2">
                      {capitalizeFirstLetter(issueType[key])}
                    </span>
                  </li>
                  <div className="grid grid-cols-[1fr,auto,7fr] gap-7  py-1">
                    <div className=""></div>
                    <div className="grid ">
                      <div className="text-[12px] text-[#878787]">Tooth</div>{" "}
                    </div>
                    <div className="grid ">
                      <div className="text-[12px] text-[#878787]">Steps</div>{" "}
                    </div>
                  </div>
                  {value.map((val, index) => {
                    return (
                      <div
                        className="grid grid-cols-[1fr,1fr,7fr] gap-7 "
                        key={index}
                      >
                        <div className="grid items-end justify-items-end text-end ">
                          <CustomCheckBox
                            key={index}
                            onChange={() => onChange(key, index)}
                            checked={value[val]?.applied}
                            id={val.id}
                          />
                        </div>
                        <div className="grid ">
                          <div>{val.detectedAtTooth}</div>{" "}
                        </div>
                        <div className="grid ">
                          <div>{findRanges(val.detectedAtStages)}</div>{" "}
                        </div>
                      </div>
                    );
                  })}
                </ul>
              ))}
            </div>
          </div>
          <div className="absolute bottom-0  m-auto mt-2 w-[95%]  pb-3">
            {checkIssueLength() && (
              <CopyToClipboard text={issue} issueType={issueType} />
            )}

            <div className="mt-2 ">
              <button
                onClick={() => handleShowReject()}
                className={clsx(
                  "float-end  rounded px-6  py-2  text-[14px] text-base font-[400] text-white  ",
                  {
                    " cursor-pointer  bg-[#00C7B1]   ": checkIssueLength(),
                    " cursor-default bg-[#D2D2D2] ": !checkIssueLength(),
                  },
                )}
              >
                Confirm Selection
              </button>
              <button
                onClick={toggleSkip}
                className=" float-end mr-1 cursor-pointer rounded border border-[#FF0000]  px-6  py-2 text-[14px] text-base font-[400] text-[#FF0000]"
              >
                Skip TSQC
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
