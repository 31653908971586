import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  MesialDistal,
  BuccalLingual,
  ExtrusionIntrusion,
  Rotation,
  MesialHingeRotation,
  DistalHingeRotation,
  CrownAngulation,
  Inclination,
} from "../Icons";
import clsx from "clsx";
import { numberingFdi, numberingUniversal } from "@/helpers/teethNumbering";
import { getParams } from "@/helpers/getParams";
import { useGlobalStore } from "../../../store";
import {
  TranslationComponent,
  useCustomTranslation,
} from "@/hooks/useTranslation";
import "./movementTable.scss";

export const MovementTable = ({
  movementTable,
  parsedMovementTables,
  maxStepNumber,
}) => {
  const { content } = useCustomTranslation();

  const getContent = (group, key) => {
    return content(`movement_table.${group}.${key}`);
  };
  const { dentalNotation } = getParams();
  const { setToggle, currentStep, storedStep } = useGlobalStore(state => ({
    setToggle: state.setToggle,
    currentStep: state.stepperControls.currentStep,
    storedStep: state.stepperControls.storedStep,
  }));

  const [toggleArch, setToggleArch] = useState(0);
  const [toggleValue, setToggleValue] = useState(0);

  const [movementTableUpperLower, setMovementTableUpperLower] = useState({});

  const handleArchChange = event => {
    setToggleArch(Number(event.target.value));
  };
  const handleValueChange = event => {
    setToggleValue(Number(event.target.value));
  };

  const TotalArray = {
    Rotation_fifty: [
      11, 12, 13, 14, 15, 21, 22, 23, 24, 25, 31, 32, 33, 34, 35, 41, 42, 43,
      44, 45,
    ],
    Rotation_thirty: [16, 17, 18, 26, 27, 28, 36, 37, 38, 46, 47, 48],
    Torque_fifteen: [11, 12, 13, 21, 22, 23, 31, 32, 33, 41, 42, 43],
    Torque_ten: [
      14, 15, 16, 17, 24, 25, 26, 27, 34, 35, 36, 37, 38, 44, 45, 46, 47,
    ],
    Extrusion_rand: [
      14, 15, 16, 17, 18, 24, 25, 26, 27, 28, 34, 35, 36, 37, 38, 44, 45, 46,
      47, 48,
    ],
    Extrusion_two: [11, 12, 13, 21, 22, 23, 31, 32, 33, 41, 42, 43],
  };

  const TotalvalueToComp = {
    Rotation_fifty: [50, -50],
    Rotation_thirty: [30, -30],
    Angulation_twenty: [20, -20],
    Torque_fifteen: [15, -15],
    Torque_ten: [10, -10],
    Mesial_four: [4, -4],
    Extrusion_rand: [1, -0.5],
    Extrusion_two: [2, -2],
    in_three: [3, -3],
  };

  const Checked = (ToothNumber, array, Rotation, value) => {
    if (!Rotation) return false;
    let newRotation = Number(Rotation.split(" ")[0]);
    for (var i = 0; i < array.length; i++) {
      if (Number(ToothNumber) === array[i]) {
        if (newRotation > value[0] || newRotation < value[1]) return true;
      }
    }
    return false;
  };

  const Allteeth = (ToothNumber, value, AllValueteeth) => {
    if (!AllValueteeth) return false;
    let newAllValueteeth = Number(AllValueteeth.split(" ")[0]);
    if (newAllValueteeth > value[0] || newAllValueteeth < value[1]) {
      return true;
    }
    return false;
  };

  if (!movementTable || movementTable?.length === 0) return null;

  const getMovementPerStep = (data, storedStep, tooth, key) => {
    let value = 0,
      prevValue = 0;
    if (data?.[storedStep]?.[tooth]?.tooth?.[key]) {
      value = Number(data?.[storedStep]?.[tooth]?.tooth?.[key].split(" ")[0]);
    } else {
      value = 0;
    }

    if (storedStep - 1 === 0 || storedStep === 0) {
      prevValue = 0;
    } else {
      if (data?.[storedStep - 1]?.[tooth]) {
        prevValue = Number(
          data?.[storedStep - 1]?.[tooth]?.tooth?.[key].split(" ")[0],
        );
      } else {
        prevValue = 0;
      }
    }
    return (value - prevValue).toFixed(2);
  };

  const ToothData = (data, maxStepNumber) => {
    const mt = {
      0: {},
      1: {},
    };
    for (let i = 0; i <= maxStepNumber; i++) {
      const upperTooth = new Array(16).fill(undefined);
      const lowerTooth = new Array(16).fill(undefined);

      data[i] &&
        data?.[i].forEach(tooth => {
          const universalNumber =
            numberingFdi[tooth["Tooth number"]]?.["universal"];
          const index = universalNumber - 1; // Adjust index to start from 0

          if (index >= 0 && index < 16) {
            upperTooth[index] = { tooth };
          } else if (index >= 16 && index < 32) {
            lowerTooth[index - 16] = { tooth };
          }
        });
      // console.log(mt);
      mt[0][i] = upperTooth;
      mt[1][i] = lowerTooth;
    }

    setMovementTableUpperLower(mt);
  };

  useLayoutEffect(() => {
    ToothData(parsedMovementTables, maxStepNumber);
  }, []);

  return (
    <div className="fixed bottom-0 z-[999] h-[310px] w-full bg-white px-[20px] text-[13px]">
      <div className="mb-4 mt-2 flex items-center justify-between font-normal">
        <TranslationComponent group={"movement_table"} />

        <div className="flex items-center gap-[10px]">
          <h2 className="pr-2 text-lg font-bold">
            {getContent("labels", "teeth_movement_table")}
          </h2>
          <label className="flex items-center gap-[10px]">
            <input
              type="radio"
              value={0}
              checked={toggleValue == 0}
              onChange={handleValueChange}
            />
            {getContent("labels", "cumulative")}
          </label>
          <label className="flex items-center gap-[10px]">
            <input
              type="radio"
              value={1}
              checked={toggleValue == 1}
              onChange={handleValueChange}
            />
            {getContent("labels", "per_step")}
          </label>
        </div>
        <div
          className="z-[2] cursor-pointer text-2xl text-[#898A8D]"
          onClick={() => setToggle("showMovement")}
        >
          &#x2715;
        </div>
      </div>
      <div className="bounty-scrollbar h-auto w-full overflow-auto">
        <table className="test w-full text-center">
          <tbody className="table w-full table-auto ">
            <tr className="p-y-[4px] h-[24px] w-full whitespace-nowrap border-x-[1px]">
              <td className="bg-[#E2E8ED] "></td>
              {new Array(16).fill(0).map((elem, i) => {
                return (
                  <td
                    key={i}
                    className={`${movementTableUpperLower?.[!toggleArch]?.[storedStep][i] ? "text-black" : "text-[#737D85]"} tsst w-[100px] bg-[#E2E8ED] text-xs font-normal`}
                  >
                    {
                      numberingUniversal[i + 1 + (!toggleArch ? 0 : 16)]?.[
                        dentalNotation
                      ]
                    }
                  </td>
                );
              })}
            </tr>
            <tr className=" w-[100px] whitespace-nowrap border border-[#E5E7EB]">
              <td className="flex items-center gap-[8px] pl-[4px] pr-[8px]">
                <Rotation />
                <div className="text-xs font-normal ">
                  {getContent("labels", "rotation")}
                </div>
              </td>
              {movementTableUpperLower?.[toggleArch]?.[storedStep].map(
                (elem, i) => (
                  <td
                    key={i}
                    className={clsx(
                      {
                        "border-x-[1px] border-[#F2042C] bg-[#FFE3E8] text-[#F2042C]":
                          Checked(
                            elem?.tooth["Tooth number"],
                            TotalArray.Rotation_fifty,
                            toggleValue
                              ? getMovementPerStep(
                                  movementTableUpperLower?.[toggleArch],
                                  storedStep,
                                  i,
                                  "Rotation",
                                )
                              : elem?.tooth.Rotation,
                            TotalvalueToComp.Rotation_fifty,
                          ) ||
                          Checked(
                            elem?.tooth["Tooth number"],
                            TotalArray.Rotation_thirty,
                            toggleValue
                              ? getMovementPerStep(
                                  movementTableUpperLower?.[toggleArch],
                                  storedStep,
                                  i,
                                  "Rotation",
                                )
                              : elem?.tooth.Rotation,
                            TotalvalueToComp.Rotation_thirty,
                          ),
                      },
                      {
                        "bg-[#EDEDED] text-[#3D3935]": !elem?.tooth,
                      },
                      "text-xs font-normal hover:border hover:border-[#009ACE] hover:bg-[#F1FCFF]",
                    )}
                  >
                    {elem?.tooth
                      ? elem?.tooth?.["Rotation"]
                        ? toggleValue
                          ? getMovementPerStep(
                              movementTableUpperLower?.[toggleArch],
                              storedStep,
                              i,
                              "Rotation",
                            )
                          : Number(elem?.tooth?.["Rotation"].split(" ")[0])
                        : 0
                      : "-"}
                  </td>
                ),
              )}
            </tr>
            <tr className=" w-[100px] whitespace-nowrap border border-[#E5E7EB]">
              <td className="flex items-center gap-[8px] pl-[4px] pr-[8px]">
                <CrownAngulation />
                <div className="text-xs font-normal ">
                  {getContent("labels", "angulation")}
                </div>
              </td>
              {movementTableUpperLower?.[toggleArch]?.[storedStep].map(
                (elem, i) => (
                  <td
                    key={i}
                    className={clsx(
                      {
                        "border-x-[1px] border-[#F2042C] bg-[#FFE3E8] text-[#F2042C]":
                          Allteeth(
                            elem?.tooth["Tooth number"],
                            TotalvalueToComp.Angulation_twenty,
                            toggleValue
                              ? getMovementPerStep(
                                  movementTableUpperLower?.[toggleArch],
                                  storedStep,
                                  i,
                                  "Angulation",
                                )
                              : elem?.tooth.Angulation,
                          ),
                      },
                      {
                        "bg-[#EDEDED] text-[#3D3935]": !elem?.tooth,
                      },
                      "text-xs font-normal hover:border hover:border-[#009ACE] hover:bg-[#F1FCFF]",
                    )}
                  >
                    {elem?.tooth
                      ? elem?.tooth?.["Angulation"]
                        ? toggleValue
                          ? getMovementPerStep(
                              movementTableUpperLower?.[toggleArch],
                              storedStep,
                              i,
                              "Angulation",
                            )
                          : Number(elem?.tooth?.["Angulation"].split(" ")[0])
                        : 0
                      : "-"}
                  </td>
                ),
              )}
            </tr>
            <tr className=" w-[100px] whitespace-nowrap border border-[#E5E7EB]">
              <td className="flex items-center gap-[8px] pl-[4px] pr-[8px]">
                <Inclination />
                <div className="text-xs font-normal ">
                  {getContent("labels", "torque")}
                </div>
              </td>
              {movementTableUpperLower?.[toggleArch]?.[storedStep].map(
                (elem, i) => (
                  <td
                    key={i}
                    className={clsx(
                      {
                        "border-x-[1px] border-[#F2042C] bg-[#FFE3E8] text-[#F2042C]":
                          Checked(
                            elem?.tooth["Tooth number"],
                            TotalArray.Torque_fifteen,
                            toggleValue
                              ? getMovementPerStep(
                                  movementTableUpperLower?.[toggleArch],
                                  storedStep,
                                  i,
                                  "Inclination",
                                )
                              : elem?.tooth.Inclination,
                            TotalvalueToComp.Torque_fifteen,
                          ) ||
                          Checked(
                            elem?.tooth["Tooth number"],
                            TotalArray.Torque_ten,
                            toggleValue
                              ? getMovementPerStep(
                                  movementTableUpperLower?.[toggleArch],
                                  storedStep,
                                  i,
                                  "Inclination",
                                )
                              : elem?.tooth.Inclination,
                            TotalvalueToComp.Torque_ten,
                          ),
                      },
                      {
                        "bg-[#EDEDED] text-[#3D3935]": !elem?.tooth,
                      },
                      "text-xs font-normal hover:border hover:border-[#009ACE] hover:bg-[#F1FCFF]",
                    )}
                  >
                    {elem?.tooth
                      ? elem?.tooth?.["Inclination"]
                        ? toggleValue
                          ? getMovementPerStep(
                              movementTableUpperLower?.[toggleArch],
                              storedStep,
                              i,
                              "Inclination",
                            )
                          : Number(elem?.tooth?.["Inclination"].split(" ")[0])
                        : 0
                      : "-"}
                  </td>
                ),
              )}
            </tr>
            <tr className=" w-[100px] whitespace-nowrap border border-[#E5E7EB]">
              <td className="flex items-center gap-[8px] pl-[4px] pr-[8px]">
                <MesialDistal />
                <div className="text-xs font-normal ">
                  {`${getContent("labels", "translation_m_d")}, mm`}
                </div>
              </td>
              {movementTableUpperLower?.[toggleArch]?.[storedStep].map(
                (elem, i) => (
                  <td
                    key={i}
                    className={clsx(
                      {
                        "border-x-[1px] border-[#F2042C] bg-[#FFE3E8] text-[#F2042C]":
                          Allteeth(
                            elem?.tooth["Tooth number"],
                            TotalvalueToComp.Mesial_four,
                            toggleValue
                              ? getMovementPerStep(
                                  movementTableUpperLower?.[toggleArch],
                                  storedStep,
                                  i,
                                  "Left/ Right",
                                )
                              : elem?.tooth["Left/ Right"],
                          ),
                      },
                      {
                        "bg-[#EDEDED] text-[#3D3935]": !elem?.tooth,
                      },
                      "text-xs font-normal hover:border hover:border-[#009ACE] hover:bg-[#F1FCFF]",
                    )}
                  >
                    {elem?.tooth
                      ? elem?.tooth?.["Left/ Right"]
                        ? toggleValue
                          ? getMovementPerStep(
                              movementTableUpperLower?.[toggleArch],
                              storedStep,
                              i,
                              "Left/ Right",
                            )
                          : Number(elem?.tooth?.["Left/ Right"].split(" ")[0])
                        : 0
                      : "-"}
                  </td>
                ),
              )}
            </tr>
            <tr className=" w-[100px] whitespace-nowrap border border-[#E5E7EB] ">
              <td className="flex items-center gap-[8px] pl-[4px] pr-[8px]">
                <ExtrusionIntrusion />
                <div className="text-xs font-normal ">
                  {`${getContent("labels", "extrusion_intrusion")}, mm`}
                </div>
              </td>
              {movementTableUpperLower?.[toggleArch]?.[storedStep].map(
                (elem, i) => (
                  <td
                    key={i}
                    className={clsx(
                      {
                        "border-x-[1px] border-[#F2042C] bg-[#FFE3E8] text-[#F2042C]":
                          Checked(
                            elem?.tooth["Tooth number"],
                            TotalArray.Extrusion_rand,
                            toggleValue
                              ? getMovementPerStep(
                                  movementTableUpperLower?.[toggleArch],
                                  storedStep,
                                  i,
                                  "Extrusion/ Intrusion",
                                )
                              : elem?.tooth["Extrusion/ Intrusion"],
                            TotalvalueToComp.Extrusion_rand,
                          ) ||
                          Checked(
                            elem?.tooth["Tooth number"],
                            TotalArray.Extrusion_two,
                            toggleValue
                              ? getMovementPerStep(
                                  movementTableUpperLower?.[toggleArch],
                                  storedStep,
                                  i,
                                  "Extrusion/ Intrusion",
                                )
                              : elem?.tooth["Extrusion/ Intrusion"],
                            TotalvalueToComp.Extrusion_two,
                          ),
                      },
                      {
                        "bg-[#EDEDED] text-[#3D3935]": !elem?.tooth,
                      },
                      "text-xs font-normal hover:border hover:border-[#009ACE] hover:bg-[#F1FCFF]",
                    )}
                  >
                    {elem?.tooth
                      ? elem?.tooth?.["Extrusion/ Intrusion"]
                        ? toggleValue
                          ? getMovementPerStep(
                              movementTableUpperLower?.[toggleArch],
                              storedStep,
                              i,
                              "Extrusion/ Intrusion",
                            )
                          : Number(
                              elem?.tooth?.["Extrusion/ Intrusion"].split(
                                " ",
                              )[0],
                            )
                        : 0
                      : "-"}
                  </td>
                ),
              )}
            </tr>

            <tr className=" w-[100px] whitespace-nowrap border border-[#E5E7EB]">
              <td className="flex items-center gap-[8px] pl-[4px] pr-[8px]">
                <BuccalLingual />
                <div className="text-xs font-normal ">
                  {`${getContent("labels", "translation_b_l")}, mm`}
                </div>
              </td>
              {movementTableUpperLower?.[toggleArch]?.[storedStep].map(
                (elem, i) => (
                  <td
                    key={i}
                    className={clsx(
                      {
                        "border-x-[1px] border-[#F2042C] bg-[#FFE3E8] text-[#F2042C]":
                          Allteeth(
                            elem?.tooth["Tooth number"],
                            TotalvalueToComp.in_three,
                            toggleValue
                              ? getMovementPerStep(
                                  movementTableUpperLower?.[toggleArch],
                                  storedStep,
                                  i,
                                  "Forward/ Backward",
                                )
                              : elem?.tooth["Forward/ Backward"],
                          ),
                      },
                      {
                        "bg-[#EDEDED] text-[#3D3935]": !elem?.tooth,
                      },
                      "text-xs font-normal hover:border hover:border-[#009ACE] hover:bg-[#F1FCFF]",
                    )}
                  >
                    {elem?.tooth?.["Forward/ Backward"]
                      ? toggleValue
                        ? getMovementPerStep(
                            movementTableUpperLower?.[toggleArch],
                            storedStep,
                            i,
                            "Forward/ Backward",
                          )
                        : Number(
                            elem?.tooth?.["Forward/ Backward"].split(" ")[0],
                          )
                      : "-"}
                  </td>
                ),
              )}
            </tr>
            <tr className=" w-[100px] text-nowrap  border border-[#E5E7EB]">
              <td className=" flex items-center gap-[8px] pl-[4px] pr-[8px]">
                <DistalHingeRotation />
                <div className="text-xs font-normal text-[#3D3935] ">
                  {`${getContent("labels", "distal_hinge_rotation")}, °`}
                </div>
              </td>
              {movementTableUpperLower?.[toggleArch]?.[storedStep].map(
                (elem, i) => (
                  <td
                    key={i}
                    className={clsx(
                      {
                        "bg-[#EDEDED] text-[#3D3935]": !elem?.tooth,
                      },
                      "text-xs font-normal hover:border hover:border-[#009ACE] hover:bg-[#F1FCFF]",
                    )}
                  >
                    -
                  </td>
                ),
              )}
            </tr>
            <tr className="  w-[100px] whitespace-nowrap border border-[#E5E7EB]">
              <td className="flex  items-center gap-[8px] pl-[4px] pr-[8px]">
                <MesialHingeRotation />
                <div className="text-xs font-normal text-[#3D3935]">
                  {`${getContent("labels", "mesial_hinge_rotation")}, °`}
                </div>
              </td>
              {movementTableUpperLower?.[toggleArch]?.[storedStep].map(
                (elem, i) => (
                  <td
                    key={i}
                    className={clsx(
                      {
                        "bg-[#EDEDED] text-[#3D3935]": !elem?.tooth,
                      },
                      "text-xs font-normal hover:border hover:border-[#009ACE] hover:bg-[#F1FCFF]",
                    )}
                  >
                    -
                  </td>
                ),
              )}
            </tr>
          </tbody>
        </table>
      </div>
      <div className=" flex items-center gap-[10px] pt-[10px]">
        <label className="flex items-center gap-[10px]">
          <input
            type="radio"
            value={0}
            checked={toggleArch == 0}
            onChange={handleArchChange}
          />
          {getContent("labels", "upper_arch")}
        </label>
        <label className="flex items-center gap-[10px]">
          <input
            type="radio"
            value={1}
            checked={toggleArch == 1}
            onChange={handleArchChange}
          />
          {getContent("labels", "lower_arch")}
        </label>
      </div>
    </div>
  );
};
