import React, { useState } from "react";
import Input from "../shared-components/Input";
import { changeLanguage } from "i18next";

function SignUp({ setUserData }) {
  const [userDetails, setUserDetails] = useState({
    username: "",
    password: "",
  });

  const inputs = [
    {
      id: 1,
      name: "username",
      label: "Email",
      type: "email",
      placeholder: "Email",
      required: true,
      pattern:
        "^[a-zA-Z0-9]+(?:.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:.[a-zA-Z0-9]+)*$",
      extra: "",
      error: "invalid email address!",
    },
    {
      id: 2,
      name: "password",
      label: "Password",
      type: "password",
      placeholder: "Password",
      required: true,
      extra: "",
      error: "password should at least 8 character!",
      pattern:
        "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*\\-]).{8,}$",
    },
  ];

  const onchanage = e => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };
  const submitForm = e => {
    e.preventDefault();
    Login();
  };
  const Login = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL_MFG}auth/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userDetails),
        },
      );
      const result = await response.json();
      if (response.status === 200) {
        const translator =
          JSON.parse(process.env.REACT_APP_TRANSLATORS)?.filter(
            elem => elem.email === result.email,
          )?.[0] || null;

        localStorage.setItem("user_data", JSON.stringify(result));
        // localStorage.setItem("language", result);
        setUserData(result);

        if (translator) {
          localStorage.setItem("language", translator.language || "en");

          changeLanguage(translator.language || "en");
          // Get the current URL
          let currentUrl = window.location.href;

          // Add query parameters
          let queryParams = new URLSearchParams(window.location.search);
          queryParams.set("demoTS", "true");

          // Construct the new URL with query parameters
          let newUrl = `${currentUrl.split("?")[0]}?${queryParams.toString()}`;
          window.location.replace(newUrl);
        }
      } else {
        alert(result?.extra[0]?.msg);
      }
    } catch (error) {
      alert(error);
    }
  };
  return (
    <div className="mt-[100px]   flex w-full   flex-col justify-center sm:flex-row ">
      <form className="flex flex-1 flex-col   gap-4 rounded-md border-2 border-[#d3d3d3] px-[1em] py-[1em]	lg:max-w-[600px]">
        <h2 className="text-3xl font-bold">Sign In </h2>

        {inputs.map(input => (
          <Input
            key={input.id}
            {...input}
            onchange={onchanage}
            value={userDetails[input.name]}
          />
        ))}
        <div className="flex flex-col justify-center gap-2 ">
          <button
            className="max-w-max rounded-md bg-black px-[20px] py-[10px] text-white disabled:bg-gray-600"
            onClick={submitForm}
            disabled={
              userDetails.password === "" || userDetails.username === ""
            }
          >
            Login
          </button>
        </div>
      </form>
    </div>
  );
}

export default SignUp;
