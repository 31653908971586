import nav from "./components/nav_de.json";
import movement_table from "./components/movement_table_de.json";
import staging_timeline from "./components/staging_timeline_de.json";
import bolton from "./components/bolton_de.json";
import overjet_overbite from "./components/overjet_overbite_de.json";
import controls from "./components/controls_de.json";
import others from "./components/others_de.json";

export const de = {
  nav: { ...nav },
  movement_table: { ...movement_table },
  staging_timeline: { ...staging_timeline },
  bolton: { ...bolton },
  overjet_overbite: { ...overjet_overbite },
  controls: { ...controls },
  other: { ...others },

};
