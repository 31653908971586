import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { de } from "./de/index_de";
// import LanguageDetector from 'i18next-browser-languagedetector';
import { en } from "./en/index_en";
import { fr } from "./fr/index_fr";

const is_translator = JSON.parse(process.env.REACT_APP_TRANSLATOR) || false;
const translation = JSON.parse(localStorage.getItem("translation"));
const lang = localStorage.getItem("language") || "en";

const en_strings = { ...en };
const fr_strings = { ...fr };
const de_strings = { ...de };
localStorage.setItem(
  "translation",
  translation && is_translator
    ? JSON.stringify({
        en: { ...en_strings, ...translation.en },
        fr: { ...fr_strings, ...translation.fr },
        de: { ...de_strings, ...translation.de },
      })
    : JSON.stringify({ en: en_strings, fr: fr_strings, de: de_strings }),
);

console.log(JSON.parse(localStorage.getItem("translation")));
i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  //   .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: process.env.NODE_ENV !== "production",
    lng: lang, // default language
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    resources: {
      en: {
        translation:
          translation && Object.keys(translation).length > 0 && is_translator
            ? { ...translation["en"] }
            : { ...en },
      },
      fr: {
        translation:
          translation && Object.keys(translation).length > 0 && is_translator
            ? { ...translation["fr"] }
            : { ...fr },
      },
      de: {
        translation:
          translation && Object.keys(translation).length > 0 && is_translator
            ? { ...translation["de"] }
            : { ...de },
      },
    },
  });

export default i18n;
