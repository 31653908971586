import { CheckIcon, CopyCheckIcon } from "@/assets";
import { useModal } from "@/hooks/useModal";
import { useGlobalStore } from "@/store";
import React from "react";
import toast, { Toaster } from "react-hot-toast";

export const Approve = ({ data }) => {
  const { ModalContainer: SubmitModal, toggleModal: toggleSubmit } = useModal();
  const notify = () => {
    toast("sent", {
      icon: <CopyCheckIcon />,
      style: {
        borderRadius: "10px",
        background: "#333",
        color: "#fff",
      },
    });
  };

  const { setToggle } = useGlobalStore(state => ({
    setToggle: state.setToggle,
  }));

  return (
    <>
      <SubmitModal
        ConfirmApprove
        title="Confirm Selection"
        primaryAction={() => {
          if (window.parent) {
            window.parent.postMessage(
              {
                data: "Approved_TSQC",
                hasTsqcReportedIssues: data?.hasTsqcReportedIssues,
                tsqcReportId :data?.tsqcReportId 
              },
              "*",
            );
            notify();
            setToggle("showApprove");
          }
        }}
        primaryActionText="Confirm Selection"
        primaryActionColor="border-[#00C7B1] bg-[#00C7B1] text-base"
        footerChildren={
          <div className="grid px-7 py-3 text-center  text-[14px]  text-[#8B8B8B] ">
            You can find the issues selected for fixing in the TDMS once
            confirmed.
          </div>
        }
      >
        <div className="text-[16px] text-[#3D3935]">
          {" "}
          Are you sure you want to confirm your selection?
        </div>
      </SubmitModal>
      <Toaster position="bottom-center" reverseOrder={false} />

      <div className="grid grid-rows-2 gap-2">
        <div className="rounded-xl bg-[#CEEAE3] px-2 py-3">
          <div className="grid  grid-cols-[auto,1fr]">
            <div className="p-4">
              <CheckIcon />
            </div>
            <div className="grid  grid-rows-2">
              <div className="font-semibold">Automated QC Results</div>
              <div className="font-semibold text-[#00B189]">Approved</div>
            </div>
          </div>
        </div>
        <div>
          {" "}
          <button
            onClick={toggleSubmit}
            className=" float-end cursor-pointer rounded bg-[#00B189] px-6  py-3 text-base font-semibold text-white"
          >
            Send to QC
          </button>
        </div>
      </div>
    </>
  );
};
