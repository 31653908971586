import React, { useRef, version } from "react";
import { MeshBasicMaterial, Vector3 } from "three";
import { render, useFrame, useThree } from "@react-three/fiber";
import { LinearToSRGB } from "three/src/math/ColorManagement";
import * as THREE from "three";

const upAndDownLightIntensity = 500;
const frontLightIntensity = 350;
const positionOffset = new Vector3(1.0814523696899414, 17.764172673225403, 0);

function Lights() {
  const camera = useThree().camera;
  const lightRef = useRef(null);
  const pointLightRef = useRef(null);
  const upPointLightRef = useRef(null);
  const downPointLightRef = useRef(null);

  // const renderer = useThree();
  // renderer.gl.toneMapping = THREE.NoToneMapping;
  useFrame(() => {
    if (lightRef?.current !== null) {
      lightRef?.current?.rotation.copy(camera.rotation);
      var worldPosition = new THREE.Vector3();
      // pointLightRef.current.getWorldPosition(worldPosition);
      // pointLightRef.current.decay = lerp(
      //   2.15,
      //   2.4,
      //   Math.min(-Math.abs(worldPosition.y), worldPosition.z) / -60
      // );
      upPointLightRef.current.decay = lerp(3.3, 1.95, worldPosition.y / 60);
      downPointLightRef.current.decay = lerp(3.3, 1.95, -worldPosition.y / 60);
    }
  });

  function lerp(a, b, alpha) {
    return a + alpha * (b - a);
  }
  return (
    <mesh>
      <mesh ref={lightRef}>
        <mesh position={positionOffset}>
          <ambientLight color={0xfffcf5} intensity={0.6} />
          <pointLight
            ref={pointLightRef}
            position={[25, -20, 60]}
            decay={2.1}
            intensity={2500}
            distance={150}
            color={0xfffcf5}
          />
          <pointLight
            ref={pointLightRef}
            position={[-25, -20, 60]}
            decay={2.1}
            intensity={2700}
            distance={150}
            color={0xfffcf5}
          />
          <pointLight
            ref={pointLightRef}
            position={[-15, -20, -60]}
            decay={2.1}
            intensity={2700}
            distance={150}
            color={0xfffcf5}
          />
        </mesh>
      </mesh>
      <spotLight
        position={[0, 0, -60]}
        decay={1.9}
        intensity={2500}
        distance={150}
        angle={0.5}
        penumbra={0.5}
      />
      {/* <pointLight
        position={[0, 0, -60]}
        decay={2}
        intensity={2500}
        distance={150}
      /> */}
      {/* <pointLight
        position={[0, 0, 0]}
        decay={2}
        intensity={1500}
        distance={150}
        color={0xc5c5c5}
      /> */}
      <pointLight
        ref={downPointLightRef}
        position={[0, -70, 0]}
        decay={3.3}
        intensity={2500}
        distance={150}
      />
      <pointLight
        ref={upPointLightRef}
        position={[0, 50, 0]}
        decay={3.3}
        intensity={2500}
        distance={150}
      />
    </mesh>
  );
}

export default Lights;
