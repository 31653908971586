import { useGlobalStore } from "@/store";
import React, { useEffect, useState } from "react";

const ColorSlider = () => {
  const { setToggle, setLayout, backGroundColor } = useGlobalStore(state => ({
    ...state.computed,
    setLayout: state.setLayout,
    backGroundColor: state.layoutConfig.backGroundColor,
    setToggle: state.setToggle,
  }));
  const startColor = { r: 228, g: 228, b: 228 }; // #E4E4E4
  const endColor = { r: 115, g: 114, b: 114 }; // #737272
  const [sliderValue, setSliderValue] = useState(50);

  function hexToRgb(hex) {
    const bigint = parseInt(hex.substring(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return { r, g, b };
  }

  function rgbToHex(r, g, b) {
    return (
      "#" +
      ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()
    );
  }

  function getColorPosition(startColor, endColor, givenColorHex) {
    const givenColor = hexToRgb(givenColorHex);

    const tR = (givenColor.r - startColor.r) / (endColor.r - startColor.r);
    const tG = (givenColor.g - startColor.g) / (endColor.g - startColor.g);
    const tB = (givenColor.b - startColor.b) / (endColor.b - startColor.b);

    const t = (tR + tG + tB) / 3; // Average interpolation factor

    return t * 100; // Position out of 100
  }

  function getComplementaryColor(hex) {
    const { r, g, b } = hexToRgb(hex);
    const compR = 255 - r;
    const compG = 255 - g;
    const compB = 255 - b;
    return rgbToHex(compR, compG, compB);
  }

  useEffect(() => {
    const position = getColorPosition(startColor, endColor, backGroundColor);
    const originalColor = backGroundColor;
    const complementaryColor = getComplementaryColor(originalColor);
    setSliderValue(position);
    setLayout("complementaryColor", complementaryColor);
  }, [backGroundColor]);

  const handleSliderChange = event => {
    const value = event.target.value;
    setSliderValue(value);
    const newColor = calculateColor(value);
    setLayout("backGroundColor", newColor);
    localStorage.setItem("ViewerBackGroundColor", newColor);
  };

  const calculateColor = value => {
    const ratio = value / 100;

    const r = Math.round(startColor.r * (1 - ratio) + endColor.r * ratio);
    const g = Math.round(startColor.g * (1 - ratio) + endColor.g * ratio);
    const b = Math.round(startColor.b * (1 - ratio) + endColor.b * ratio);

    const toHex = value => {
      const hex = value.toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    };

    const hexColor = `#${toHex(r)}${toHex(g)}${toHex(b)}`;
    return hexColor;
  };
  const trackStyle = {
    background: `linear-gradient(to right, #2c2c2c ${sliderValue}%, #e0e0e0 ${sliderValue}%)`,
  };
  return (
    <div className="bounty_color_slider">
      <input
        type="range"
        className="range"
        min="0"
        max="100"
        value={sliderValue}
        style={trackStyle}
        onPointerUp={() => setToggle("showBackGroundColorSlider", false)}
        onChange={handleSliderChange}
      />
    </div>
  );
};

export default ColorSlider;
