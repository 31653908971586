import React, { useRef } from "react";
import { Grid } from "@react-three/drei";
import { useThree, useFrame } from "@react-three/fiber";
function GridView(props) {
  const { grid } = props;
  const gridRef = useRef(null);
  const { camera } = useThree();
  const gridConfig = {
    rotation: [1.5, 0, 0],
    position: [0, 0, -10],
    gridSize: [10, 10],
    infiniteGrid: true,
    cellColor: "#858585",
    cellSize: 1,
    cellThickness: 0.9,
    sectionSize: 10,
    sectionThickness: 1.35,
    sectionColor: "#829595",
    fadeDistance: 10000,
    fadeStrength: 0,
  };

  useFrame(() => {
    if (camera.zoom > 6 && camera.zoom <= 100) {
      gridRef.current.material.cellSize = 1;
      gridRef.current.material.sectionSize = 10;
    }
    window.addEventListener("wheel", event => {
      if (grid) {
        if (camera.zoom > 100) {
          gridRef.current.material.cellSize = 0.1;
          gridRef.current.material.sectionSize = 1;
        } else if (camera.zoom > 6 && camera.zoom <= 100) {
          gridRef.current.material.cellSize = 1;
          gridRef.current.material.sectionSize = 10;
        } else if (camera.zoom <= 6) {
          gridRef.current.material.cellSize = 10;
          gridRef.current.material.sectionSize = 10;
        }
      }
    });
  });

  return <Grid ref={gridRef} {...gridConfig} />;
}

export default GridView;
