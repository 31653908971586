import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Nav from "./controls/Nav";
import Stepper from "./stepper/Stepper";
import Loader from "./Loader";
import {
  apiResolver,
  getJSON,
  getTSUrlsForEonMfg,
  getIssueTypes,
} from "@/helpers/apiResolver";
import { getParams } from "@/helpers/getParams";
import "./viewer.scss";
import { ThreeJsViews } from "./ThreeJsViews";
import ViewerNotFound from "./ViewerNotFound";
import { useQuery } from "@tanstack/react-query";
import { StagingTimeLine } from "./overlays/StagingTimeLine";
import { BoltonAnalysis } from "./overlays/BoltonAnalysis";
import { MovementTable } from "./overlays/MovementTable";
import { OverlayWrapper } from "./overlays/OverlayWrapper";
import { isEmpty } from "@/helpers/functions";
import TeethControls from "./teethControls/TeethControls";
import Beta from "../Beta";
import { useGlobalStore } from "../../store";
import { mapStagingTimeline } from "@/helpers/mapStagingTimeline";
import { useWindowOrientation } from "@/hooks/useWindowOrientation";
import { OverbiteAnalysis } from "./overlays/OverbiteAnalysis";
import DoctorModifications from "./modifications";

//added by hamzeh
import { numberingUniversal } from "@/helpers/teethNumbering";
import poppins from "@/static/poppins_regular.json";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader.js";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry.js";
import ApproveTPModal from "../demo-tp/ApproveTPModal";
import Header from "./Header";
import { themeConfig } from "@/helpers/themeConfigrasions";

import { demoMovementTable } from "../demo-tp/demoMovementTable";
import { demoBoltonAnalysisInfo } from "../demo-tp/demoBoltonAnalysisInfo";
import {
  SetCutsAndButtonsElasticsData,
  TDMSTeethNumbersToUniversal,
  TurnElastics,
} from "./Elastics";
import { Saving, ChangeSaved } from "../shared-components/AutoSave";
import RevisedTreatment from "./modifications/RevisedTreatment";
import Records from "./overlays/Records";
import { Approve } from "./Approve";
import { Reject } from "./Reject";
import { setSuperProps } from "@/mixpanel/Mixpanel";
import { useCustomTranslation } from "@/hooks/useTranslation";
import {
  ponticsData,
  preparePonticsData,
  turnPontics,
} from "./controls/Pontics";
import GhostCursorComponent from "@/helpers/GhostCursorComponent";
import { getSingedURL } from "@/helpers/getSingedURL";
// end
const GLBData = [{}];
export let isBJ;
export let globalBiteJump;
export let globalBiteJumpUpperLower = { u: false, l: false };
let teethTransformationData;
let myOTP;
let globalDiff = 0; // if negative l > u : u > l```
let fakeAttachmentReplacer;
let once = false;
function isNegative(num) {
  if (Math.sign(num) === -1) {
    return true;
  }
  return false;
}
let bad = false;
//caching
let c1, c2;
function TeethSort(a, b) {
  c1 = parseInt(a.name.split("_")[2].substring(1, 5));
  c2 = parseInt(b.name.split("_")[2].substring(1, 5));
  if (c1 < c2) {
    return -1;
  } else if (c1 > c2) {
    return 1;
  }
  return 0;
}
function MeshOrganiser(model) {
  const steps2 = model;
  let upper = { gum: {}, teeth: [] };
  let lower = { gum: {}, teeth: [] };
  let longerPart;
  let longer;
  if (!model.children[2].userData.start_together) {
    if (model.children[0].children.length > model.children[1].children.length) {
      let diff =
        model.children[0].children.length - model.children[1].children.length;
      globalDiff = diff;
      for (let i = 0; i < diff; i++) {
        steps2.children[1].children.unshift(steps2.children[1].children[0]);
      }
    } else if (
      model.children[0].children.length < model.children[1].children.length
    ) {
      let diff =
        model.children[1].children.length - model.children[0].children.length;
      globalDiff = -diff;
      for (let i = 0; i < diff; i++) {
        steps2.children[0].children.unshift(steps2.children[0].children[0]);
      }
    }
  }
  if (steps2.children[0].children.length > steps2.children[1].children.length) {
    longer = steps2.children[0].children.length;
    longerPart = steps2.children[0];
  } else {
    longer = steps2.children[1].children.length;
    if (globalDiff <= 0) {
      longerPart = steps2.children[1];
    } else {
      longerPart = steps2.children[0];
    }
  }
  //
  let isOneArch = false;
  if (
    steps2.children[0].children.length <= 1 ||
    steps2.children[1].children.length <= 1
  ) {
    console.log("this is one arch");
    isOneArch = true;
  }
  if (isOneArch) {
    // old end together solution
    for (let t = 0; t < longer; t++) {
      for (let i = 0; i < longer; i++) {
        // steps
        if (longerPart.children[i].name.includes("st".concat(t))) {
          if (steps2.children[0].children.length > i) {
            //24 , 25
            upper = { gum: {}, teeth: [] };
            steps2.children[0].children[i].children.map(child => {
              if (child.name.includes("gum")) {
                upper.gum = child;
              } else {
                let ref = new THREE.Mesh(child.geometry, child.material);
                ref.name = child.name;
                let attachmentGroup = new THREE.Group(),
                  numberingGroup = new THREE.Group(),
                  iprGroup = new THREE.Group(),
                  rootAnchorsGroup = new THREE.Group(),
                  elasticsGroup = new THREE.Group();
                attachmentGroup.name = "attachments";
                numberingGroup.name = "numbering";
                iprGroup.name = "IPRs";
                rootAnchorsGroup.name = "rootAnchorsGroup";
                elasticsGroup.name = "elasticsGroup";
                for (let x = 0; x < child.children.length; x++) {
                  let ref1 = new THREE.Mesh(
                    child.children[x].geometry,
                    child.children[x].material,
                  );
                  attachmentGroup.add(ref1);
                }
                ref.add(attachmentGroup);
                numberingGroup.visible = false;
                ref.add(numberingGroup);
                ref.add(iprGroup);
                ref.add(rootAnchorsGroup);
                ref.add(elasticsGroup);
                upper.teeth.push(ref);
              }
            });
          }
          if (steps2.children[1].children.length > i) {
            // 62
            lower = { gum: {}, teeth: [] };
            steps2.children[1].children[i].children.map(child => {
              if (child.name.includes("gum")) {
                lower.gum = child;
              } else {
                let ref = new THREE.Mesh(child.geometry, child.material);
                ref.name = child.name;
                let attachmentGroup = new THREE.Group(),
                  numberingGroup = new THREE.Group(),
                  iprGroup = new THREE.Group(),
                  rootAnchorsGroup = new THREE.Group(),
                  elasticsGroup = new THREE.Group();
                attachmentGroup.name = "attachments";
                numberingGroup.name = "numbering";
                iprGroup.name = "IPRs";
                rootAnchorsGroup.name = "rootAnchorsGroup";
                elasticsGroup.name = "elasticsGroup";
                for (let x = 0; x < child.children.length; x++) {
                  let ref1 = new THREE.Mesh(
                    child.children[x].geometry,
                    child.children[x].material,
                  );
                  attachmentGroup.add(ref1);
                }
                ref.add(attachmentGroup);
                numberingGroup.visible = false;
                ref.add(numberingGroup);
                ref.add(iprGroup);
                ref.add(rootAnchorsGroup);
                ref.add(elasticsGroup);
                lower.teeth.push(ref);
              }
            });
          }
          lower.teeth.sort(TeethSort);
          upper.teeth.sort(TeethSort);
          GLBData[t] = { upper, lower };
          break;
        }
      }
    }
  } // new end together solution
  else {
    //start together + end together + equal steps + one scan
    for (let i = 0; i < longer; i++) {
      let r = i;
      if (steps2.children[0].children.length <= i + (isBJ ? 1 : 0)) {
        r =
          steps2.children[0].children.length -
          1 -
          (isBJ && i + 1 < longer ? 1 : 0);
      }
      upper = { gum: {}, teeth: [] };
      if (r >= 0) {
        steps2.children[0].children[r].children.map(child => {
          if (child.name.includes("gum")) {
            upper.gum = new THREE.Mesh(child.geometry, child.material);
            upper.gum.name = "u_st" + i + "_gum";
          } else {
            let ref = new THREE.Mesh(child.geometry, child.material);
            ref.name =
              "u_st" + i + "_t" + child.name.split("_")[2].substring(1, 4);
            let attachmentGroup = new THREE.Group(),
              numberingGroup = new THREE.Group(),
              iprGroup = new THREE.Group(),
              rootAnchorsGroup = new THREE.Group(),
              elasticsGroup = new THREE.Group();
            attachmentGroup.name = "attachments";
            numberingGroup.name = "numbering";
            iprGroup.name = "IPRs";
            rootAnchorsGroup.name = "rootAnchorsGroup";
            elasticsGroup.name = "elasticsGroup";
            for (let x = 0; x < child.children.length; x++) {
              let ref1 = new THREE.Mesh(
                child.children[x].geometry,
                child.children[x].material,
              );
              attachmentGroup.add(ref1);
            }
            ref.add(attachmentGroup);
            numberingGroup.visible = false;
            ref.add(numberingGroup);
            ref.add(iprGroup);
            ref.add(rootAnchorsGroup);
            ref.add(elasticsGroup);
            upper.teeth.push(ref);
          }
        });
      }
      r = i;
      if (steps2.children[1].children.length <= i + (isBJ ? 1 : 0)) {
        r =
          steps2.children[1].children.length -
          1 -
          (isBJ && i + 1 < longer ? 1 : 0);
      }
      lower = { gum: {}, teeth: [] };
      if (r >= 0) {
        steps2.children[1].children[r].children.map(child => {
          if (child.name.includes("gum")) {
            lower.gum = new THREE.Mesh(child.geometry, child.material);
            lower.gum.name = "l_st" + i + "_gum";
          } else {
            let ref = new THREE.Mesh(child.geometry, child.material);
            ref.name =
              "l_st" + i + "_t" + child.name.split("_")[2].substring(1, 4);
            let attachmentGroup = new THREE.Group(),
              numberingGroup = new THREE.Group(),
              iprGroup = new THREE.Group(),
              rootAnchorsGroup = new THREE.Group(),
              elasticsGroup = new THREE.Group();
            attachmentGroup.name = "attachments";
            numberingGroup.name = "numbering";
            iprGroup.name = "IPRs";
            rootAnchorsGroup.name = "rootAnchorsGroup";
            elasticsGroup.name = "elasticsGroup";
            for (let x = 0; x < child.children.length; x++) {
              let ref1 = new THREE.Mesh(
                child.children[x].geometry,
                child.children[x].material,
              );
              attachmentGroup.add(ref1);
            }
            ref.add(attachmentGroup);
            numberingGroup.visible = false;
            ref.add(numberingGroup);
            ref.add(iprGroup);
            ref.add(rootAnchorsGroup);
            ref.add(elasticsGroup);
            lower.teeth.push(ref);
          }
        });
        lower.teeth.sort(TeethSort);
        upper.teeth.sort(TeethSort);
        GLBData[i] = { upper, lower };
      }
    }
  }
  // attachmentMaterial.opacity = options.numbering ? 0.5 : 1;
}

function Scene() {
  const demoTS = getParams().demoTS === "true" ? true : false;
  const tdms = getParams().tdms === "true" ? true : false;
  const isPatient = getParams().isPatient === "true" ? true : false;
  const isC = getParams().isC === "true" ? true : false;
  const { isLoading, error, data } = demoTS
    ? false
    : useQuery({
        queryKey: ["treatmentSetupInfo"],
        queryFn: apiResolver,
        staleTime: 600000, // Cache data for 60 seconds (1 minute)
      });

  async function fetchData() {
    try {
      const result = await getTSUrlsForEonMfg();

      setResponse(result);
    } catch (error) {
      console.error("Error:", error);
    }
  }
  async function fetchIssueType() {
    try {
      const result = await getIssueTypes();

      const convertedObject = result.reduce((acc, item) => {
        acc[item.value] = item.title;
        return acc;
      }, {});

      setIssueType(convertedObject);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const isViewerControlsEnabled = data?.isViewerControlsEnabled;
  const localStorageSpace = function () {
    let allStrings = "";
    for (let key in window.localStorage) {
      if (window.localStorage.hasOwnProperty(key)) {
        allStrings += window.localStorage[key];
      }
    }
    return allStrings
      ? 3 + (allStrings.length * 16) / (8 * 1024) + " KB"
      : "Empty (0 KB)";
  };
  function parseTable(tableHtml) {
    const tableObject = [];

    const tableRows = tableHtml.querySelectorAll("tr");

    // Check if the first row contains the 'Tooth number' header
    const firstRow = tableRows[0];
    let headers = Array.from(firstRow.querySelectorAll("td")).map(td =>
      td.textContent.trim(),
    );
    if (!headers.includes("Tooth number")) {
      console.log("Table doesn't contain 'Tooth number'. Skipping...");
      return tableObject; // Return empty object
    }

    // Get column headers
    headers = headers.filter(header => header !== ""); // Remove empty headers if any
    // Iterate over rows (skip first row as it contains headers)
    for (let i = 1; i < tableRows.length; i++) {
      const rowData = {};
      const rowCells = tableRows[i].querySelectorAll("td");

      // Iterate over cells in row
      for (let j = 0; j < rowCells.length; j++) {
        rowData[headers[j]] = rowCells[j].textContent.trim();
      }

      // Push row data to table object
      tableObject.push(rowData);
    }
    return tableObject;
  }
  function fetchHTML() {
    // Fetch HTML content from the URL
    fetch(
      data?.movementTableUrl ? data?.movementTableUrl : "/DemoOrthoReport.html",
    )
      .then(response => response.text())
      .then(html => {
        // Parse the HTML string into a DOM object
        const parsedTables = [undefined];
        const domParser = new DOMParser();
        const doc = domParser.parseFromString(html, "text/html");
        // Get the table from the DOM object
        const tables = doc.querySelectorAll("table.OrthoAutoTable");
        // Parse the table into a JavaScript object
        tables.forEach(table => {
          if (parseTable(table).length) parsedTables.push(parseTable(table));
        });
        setParsedMovementTables(parsedTables);
        // console.log(parsedTables);
      })
      .catch(error => {
        console.error("Error fetching or parsing data:", error);
      });
  }
  function deleteStorage() {
    // delete autosave for tp when last modification was more than one month old
    let keys = Object.keys(localStorage);

    keys.forEach(key => {
      if (key.includes("_TP")) {
        const storedData = JSON.parse(localStorage.getItem(key));
        const date = new Date(storedData.date);
        const oneMonthAgo = new Date();
        const oneHourAgo = new Date();
        oneHourAgo.setHours(oneHourAgo.getHours() - 1);
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
        if (date <= oneMonthAgo) {
          localStorage.removeItem(key);
        }
      }
    });
  }
  useLayoutEffect(() => {
    // console.log(localStorageSpace());
    deleteStorage();
  }, []);
  const {
    isMobile,
    setToggle,
    split,
    showOverbite,
    showBolton,
    showMovement,
    showRecords,
    showStaging,
    showReject,
    showApprove,
    compareModifications,
    currentStep,
    setStepperControls,
    stepperControls,
    saving,
    saved,
    autoSaveDate,
    hasElastics,
    elastics,
    startRevision,
    setControls3D,
    show3DControls,
    pontics,
    setLayout,
    occ,
    complementaryColor,
    backgroundColor,
  } = useGlobalStore(state => ({
    hasElastics: state.toggle.hasElastics,
    elastics: state.toggle.elastics,
    split: state.toggle.split,
    showMovement: state.toggle.showMovement,
    showRecords: state.toggle.showRecords,
    showStaging: state.toggle.showStaging,
    showOverbite: state.toggle.showOverbite,
    showReject: state.toggle.showReject,
    showApprove: state.toggle.showApprove,
    showBolton: state.toggle.showBolton,
    compareModifications: state.toggle.compareModifications,
    setToggle: state.setToggle,
    isMobile: state.layoutConfig.isMobile,
    currentStep: state.stepperControls.currentStep,
    setStepperControls: state.setStepperControls,
    stepperControls: state.stepperControls,
    saving: state.controls3D.saving,
    saved: state.controls3D.saved,
    autoSaveDate: state.controls3D.autoSaveDate,
    startRevision: state.toggle.startRevision,
    show3DControls: state.toggle.show3DControls,
    pontics: state.toggle.pontics,
    setLayout: state.setLayout,
    occ: state.toggle.occ,
    setControls3D: state.setControls3D,
    complementaryColor: state.layoutConfig.complementaryColor,
    backgroundColor: state.layoutConfig.backGroundColor,
  }));

  const [view, setView] = useState("front");
  const [model, setModel] = useState(null);
  const [hasIpr, setHasIpr] = useState(false);
  const [toggleModifications, setToggleModifications] = useState(false);
  const [parsedMovementTables, setParsedMovementTables] = useState([]);

  const [response, setResponse] = useState("");
  const [issueType, setIssueType] = useState("");

  const handle = useFullScreenHandle();

  useEffect(() => {
    if (localStorage.getItem("ViewerBackGroundColor")) {
      setLayout(
        "backGroundColor",
        localStorage.getItem("ViewerBackGroundColor"),
      );
    }
  }, []);

  useWindowOrientation();
  // this utility function allows you to use any three.js
  // loader with promises and async/await
  const loadGLTF = url => {
    //console.log(url);
    const loader = new GLTFLoader();

    loader.load(
      url,
      gltf => {
        // gltf.scene.traverse(function (child) {
        //   })
        setModel(gltf.scene.children[0]);
        isBJ = gltf.scene.children[0].children[2].userData.bitejump;
        MeshOrganiser(gltf.scene.children[0]);
        preparePonticsData(gltf.scene.children[0].children[2].userData.pontics);
        prepareIpr(gltf.scene.children[0], setIprOverlays);
        prepareIpr(gltf.scene.children[0], setIprOverlays);
        prepareAttachmentonLastStep(gltf.scene.children[0]);
        if (isBJ) {
          fixBiteJumpNumberingKeys(gltf.scene.children[0]);
        }
        prepareNumbering();
        SetCutsAndButtonsElasticsData(
          gltf.scene.children[0].children[2].userData.elastics,
        );
        teethTransformationData = prepareTeethORIandCenter(myOTP);
        globalBiteJump = gltf.scene.children[0].children[2].userData.bitejump;

        setTpLoaded(true);
        setNotFound(false);
      },
      // called while loading is progressing
      undefined,
      // called when loading has errors
      error => {
        console.error("Error loading GLTF model:", error);
      },
    );
  };

  // TODO: move data preperation to react query level modification (already cached too), and any other dependencies to custom hooks
  const [iprOverLays, setIprOverlays] = useState([]);
  const [numberingOverLays, setNumberingOverlays] = useState([]);
  const [stagingTimeline, setStagingTimeline] = useState({
    loading: true,
    data: null,
  });

  /**
   * gets the Orientation points and center from the OTP for each tooth
   */
  function prepareTeethORIandCenter(OTP) {
    console.log("roots are paused");
    return;
    if (!OTP.Segmentation) {
      console.log("old otp format");
      return;
    }
    let teethTransformationData = [];
    for (let i = 0; i < OTP.Segmentation.Value.Teeth.length; i++) {
      teethTransformationData[OTP.Segmentation.Value.Teeth[i].Unn - 1] = {
        ...OTP.Segmentation.Value.Teeth[i].ToothOrientation,
        ToothRotationCenter:
          OTP.Segmentation.Value.Teeth[i].ToothRotationCenter,
      };
    }
    return teethTransformationData;
  }

  function prepareFirstMovementValues() {
    let result = [];
    result.fill(0, 0, 31);
    let lastMovement = OTP.Stages[OTP.Stages.length - 1];
    for (let i = 0; i < lastMovement.Teeth.length; i++) {
      let movement = lastMovement.Teeth[i].Movement.Value.MovementValues.Value;
      result[lastMovement.Teeth[i].Unn] = {
        "Mesial/Distal": movement.LeftRight,
        "Buccal/Lingual": movement.ForwardBackward,
        "Extrusion/Intrusion": movement.IntrusionExtrusion,
        RightHinge: 0,
        LeftHinge: 0,
        Inclination: movement.Inclination,
        Angulation: movement.Angulation,
        Rotation: movement.Rotation,
      };
    }
    setControls3D("teethMovements", result);
  }

  useEffect(() => {
    isC && fetchData();
    isC && fetchIssueType();
  }, []);

  useEffect(() => {
    if (show3DControls || occ) {
      TurnElastics(false);
      setToggle("elastics", false);
    }
  }, [show3DControls, occ]);
  useEffect(() => {
    TurnElastics(elastics);
  }, [elastics]);

  useEffect(() => {
    if (show3DControls) {
      setToggle("split", false);
    }
  }, [show3DControls]);

  function prepareIpr(modal, setIprOverlays) {
    // return;
    let model = modal;
    modal = modal.children[2].userData;
    let upperOrLower = 0;
    let splitHolder;
    const newArray = JSON.parse(JSON.stringify(iprOverLays)); //create deep copy of ipr array
    newArray[0] = {};
    newArray[1] = {};
    let maxUpper = -1;
    let maxLower = -1;
    let iprKeys = Object.keys(modal.teeth_info);
    iprKeys.forEach(key => {
      splitHolder = key.split("_");
      let step = splitHolder[1].substring(
        splitHolder[1].indexOf("t") + 1,
        splitHolder[1].length,
      );
      let teethNum = splitHolder[2].substring(
        splitHolder[2].indexOf("t") + 1,
        splitHolder[2].length,
      );
      if (splitHolder[0] === "u") {
        upperOrLower = 0;
        if (globalDiff < 0) {
          step = parseInt(step) - globalDiff;
        }
        if (maxUpper < parseInt(step)) {
          maxUpper = step;
        }
      } else {
        upperOrLower = 1;
        if (globalDiff > 0) {
          step = parseInt(step) + globalDiff;
        }
        if (maxLower < parseInt(step)) {
          maxLower = step;
        }
      }
      if (numberingOverLays[step] === undefined) {
        numberingOverLays[step] = [];
      }
      if (numberingOverLays[step][upperOrLower] === undefined) {
        numberingOverLays[step][upperOrLower] = [];
      }
      numberingOverLays[step][upperOrLower][teethNum] = {
        label_position_center: modal.teeth_info[key].label_position_center,
        label_position_end: modal.teeth_info[key].label_position_end,
      };
      // delete modal.teeth_info[key]["label_position_center"];
      // delete modal.teeth_info[key]["label_position_end"];
      if (newArray[upperOrLower][splitHolder[2]] == undefined) {
        newArray[upperOrLower][splitHolder[2]] = [];
        numberingOverLays;
      }
      modal.teeth_info[key]["step"] = step;
      newArray[upperOrLower][splitHolder[2]].push(modal.teeth_info[key]);
    });
    if (maxLower != -1 && maxUpper != -1) {
      if (modal.start_together) {
        if (maxLower != maxUpper) {
          if (maxLower > maxUpper) {
            for (let x = parseInt(maxUpper) + 1; x <= parseInt(maxLower); x++) {
              numberingOverLays[x][0] = numberingOverLays[maxUpper][0];
            }
          } else {
            for (let x = parseInt(maxLower) + 1; x <= parseInt(maxUpper); x++) {
              numberingOverLays[x][1] = numberingOverLays[maxLower][1];
            }
          }
        }
      } else {
        if (bad)
          if (globalDiff > 0) {
            for (let i = 0; i < globalDiff; i++) {
              numberingOverLays[i][1] = numberingOverLays[globalDiff][1];
              let teethKeys = Object.keys(newArray[1]);
              teethKeys.forEach(item => {
                let r = structuredClone(newArray[1][item][0]);
                newArray[1][item][0].amount = null;
                newArray[1][item].unshift(r);
              });
            }
          } else if (globalDiff < 0) {
            for (let i = 0; i < -globalDiff; i++) {
              numberingOverLays[i][0] = numberingOverLays[-globalDiff][0];
              let teethKeys = Object.keys(newArray[0]);
              teethKeys.forEach(item => {
                let r = structuredClone(newArray[0][item][0]);
                newArray[0][item][0].amount = null;
                newArray[0][item].unshift(r);
              });
            }
          }
      }
    }
    bad = true;
    let globalDiff1 = 0;
    if (model.children[0].children.length > model.children[1].children.length) {
      globalDiff1 =
        model.children[0].children.length - model.children[1].children.length;
    } else if (
      model.children[0].children.length < model.children[1].children.length
    ) {
      globalDiff1 =
        -1 *
        (model.children[1].children.length - model.children[0].children.length);
    }
    if (globalDiff1 > 0) {
      let keys = Object.keys(newArray[1]);
      keys.forEach(item => {
        let lastIndex = newArray[1][item].length - 1;
        for (let i = 0; i < globalDiff1; i++) {
          let copy = structuredClone(newArray[1][item][lastIndex]);
          copy.amount = null;
          newArray[1][item].push(copy);
        }
      });
    } else if (globalDiff1 < 0) {
      let keys = Object.keys(newArray[0]);
      keys.forEach(item => {
        let lastIndex = newArray[0][item].length - 1;
        for (let i = 0; i >= globalDiff1; i--) {
          let copy = structuredClone(newArray[0][item][lastIndex]);
          newArray[0][item].push(copy);
        }
      });
    }
    removePonticsIPRKey(newArray);
    if (
      isBJ &&
      model.children[0].children.length != model.children[1].children.length
    ) {
      fixBiteJumpIPRKeys(model, newArray);
      //fixBiteJumpNumberingKeys(model, newArray);
    }
    setIprOverlays(newArray);
  }
  function removePonticsIPRKey(newArray) {
    for (let toothNumber = 1; toothNumber <= 32; toothNumber++) {
      if (ponticsData[toothNumber]) {
        delete newArray[toothNumber < 16 ? 0 : 1]["t".concat(toothNumber)];
      }
    }
  }
  function fixBiteJumpIPRKeys(model, newArray) {
    // console.log(numberingOverLays, iprOverLays);
    let indexOfLowestStepJaw =
      model.children[0].children.length < model.children[1].children.length
        ? 0
        : 1;
    let maxStepNumber;
    let lowestStepNumberJaw;
    if (model.children[0].children.length < model.children[1].children.length) {
      maxStepNumber = model.children[1].children.length;
      lowestStepNumberJaw = model.children[0].children.length;
    } else {
      maxStepNumber = model.children[0].children.length;
      lowestStepNumberJaw = model.children[1].children.length;
    }
    Object.values(newArray[indexOfLowestStepJaw]).forEach(ipr => {
      for (
        let step = lowestStepNumberJaw - 1;
        step < maxStepNumber - (indexOfLowestStepJaw === 0 ? 0 : 1);
        step++
      ) {
        if (!ipr[step]) {
          break;
        }
        ipr[step] = ipr[step - 1];
      }
    });
  }
  function fixBiteJumpNumberingKeys(model) {
    let newArray = numberingOverLays;
    let indexOfLowestStepJaw =
      model.children[0].children.length < model.children[1].children.length
        ? 0
        : 1;
    let maxStepNumber;
    let lowestStepNumberJaw;
    if (model.children[0].children.length < model.children[1].children.length) {
      maxStepNumber = model.children[1].children.length;
      lowestStepNumberJaw = model.children[0].children.length;
    } else {
      maxStepNumber = model.children[0].children.length;
      lowestStepNumberJaw = model.children[1].children.length;
    }
    for (let step = lowestStepNumberJaw - 1; step < maxStepNumber - 1; step++) {
      newArray[step + ""][indexOfLowestStepJaw] =
        newArray[(step - 1).toString()][indexOfLowestStepJaw];
    }
  }
  function prepareNumbering() {
    let isLower;
    let t = Date.now();
    const font = new FontLoader().parse(poppins);
    const textMaterial = new THREE.MeshPhongMaterial({ color: "black" });
    for (let stepNumber = 0; stepNumber < GLBData.length; stepNumber++) {
      let toothIndex = -1;
      for (let toothNumber = 1; toothNumber <= 32; toothNumber++) {
        if (toothNumber < 17) {
          isLower = 0;
        } else {
          if (isLower === 0) {
            toothIndex = -1;
          }
          isLower = 1;
        }
        if (
          numberingOverLays?.[stepNumber]?.[isLower] &&
          numberingOverLays?.[stepNumber]?.[isLower]?.[toothNumber]
        ) {
          toothIndex++;
          const label = numberingOverLays[stepNumber][isLower][toothNumber];
          // let font = new FontLoader().parse(poppins);
          let text = (
            numberingUniversal[toothNumber][dentalNotation] || ""
          ).toString();
          const tGeo = new TextGeometry(text, {
            font: font,
            size: 2,
            height: 0.05,
          });
          const textMesh = new THREE.Mesh(
            tGeo,
            textMaterial,
            // new THREE.MeshPhongMaterial({ color: "black" }),
          );
          // const label_distance = 0.01;
          const labelpos = new THREE.Vector3(
            label.label_position_center?.[0] - label.label_position_end?.[0],
            label.label_position_center?.[1] - label.label_position_end?.[1],
            label.label_position_center?.[2] - label.label_position_end?.[2],
          ).multiplyScalar(1.1);
          tGeo.computeBoundingBox();
          tGeo.center();
          textMesh.geometry.dispose();
          textMesh.geometry = tGeo;
          textMesh.position.set(
            label.label_position_center?.[0] - labelpos.x,
            label.label_position_center?.[1] - labelpos.y,
            label.label_position_center?.[2] - labelpos.z,
          );
          // let y_rotation = Math.atan2(
          //   label.label_position_end?.[0],
          //   label.label_position_end?.[2],
          // );
          textMesh.lookAt(textMesh.position.clone().sub(labelpos));
          textMesh.visible = true;
          textMesh.name = "teethNum_" + stepNumber + "_" + toothNumber;
          // console.log(GLBData, stepNumber, isLower, toothIndex);
          GLBData[stepNumber][isLower === 0 ? "upper" : "lower"].teeth[
            toothIndex % 16
          ].children[1].attach(textMesh.clone());
        }
      }
    }
    return;
  }

  useEffect(() => {
    const checkForIpr = overlays => {
      for (const iprs of Object.values(overlays || {})) {
        for (const ipr of iprs) {
          if (ipr?.amount !== null) {
            setToggle("ipr", true);
            setToggle("hasIpr", true);
            return;
          }
        }
      }
    };
    const newArray = JSON.parse(JSON.stringify(iprOverLays));
    checkForIpr(newArray[0]); // 0 is upper arch
    checkForIpr(newArray[1]); // 1 is lower arch
  }, [iprOverLays]);
  function prepareAttachmentonLastStep(model) {
    let attachmentMapper = [];
    let upperLength = model.children[0].children.length;
    let lowerLength = model.children[1].children.length;
    if (upperLength !== 0) {
      model.children[0].children[upperLength - 1].children.forEach(tooth => {
        if (tooth.children.length != 0) {
          attachmentMapper[tooth.name.split("_")[2].substring(1, 4)] =
            tooth.children;
        }
      });
    }
    if (lowerLength !== 0) {
      model.children[1].children[lowerLength - 1].children.forEach(tooth => {
        if (tooth.children.length != 0) {
          attachmentMapper[tooth.name.split("_")[2].substring(1, 4)] =
            tooth.children;
        }
      });
    }
    setControls3D("attachmentArray", attachmentMapper);
  }

  const [tpLoaded, setTpLoaded] = useState(false);
  const [tpNotFound, setNotFound] = useState(false);

  const [maxStepNumber, setMaxStepNumber] = useState(0);
  // TODO: remove states and move to react query
  const [boltonInfo, setBoltonInfo] = useState({});
  const [steps, setSteps] = useState([]);
  const [biteJumpsSteps, setBiteJumpsSteps] = useState([]);
  const [photosXrays, serPhotosXrays] = useState([]);
  const [overbiteOverjet, setOverbiteOverjet] = useState("");
  // user Guiding
  const [ipAddress, setIPAddress] = useState("");
  const order = [
    "right_buccal",
    "frontal",
    "left_buccal",
    "upper_occlusal",
    "lower_occlusal",
    "front_smiling",
    "front_pose",
    "profile",
    "panoramic",
    "cephalometric",
  ];
  const triggerUserGuiding = () => {
    fetch("https://api.ipify.org?format=json")
      .then(response => response.json())
      .then(data => {
        setIPAddress(data.ip);
        window.userGuiding.identify(data.ip);
      })
      .catch(error => console.log(error));

    if (window.parent) {
      window.addEventListener("message", function (event) {
        const photos_xrays = event?.data?.photos_xrays || {};
        if (Object.keys(photos_xrays).length > 0) {
          // Create a new sorted object
          const sortedC = {};

          //Iterate over the order array to sort the object
          order.forEach(key => {
            if (photos_xrays.hasOwnProperty(key)) {
              sortedC[key] = photos_xrays[key];
            }
          });
          //console.log(sortedC);
          serPhotosXrays(sortedC);
        }

        if (event?.data?.user_data) {
          window.userGuiding.identify(event.data.user_data.uuid);
          setSuperProps({
            clientID: event?.data?.user_data?.companyUuid || "",
            doctorID: event?.data?.user_data?.uuid || "",
          });
        }
      });
    }
  };
  useEffect(() => {
    if (Object.keys(photosXrays).length > 0) {
      setToggle("hasPhotosXrays", true);
    } else {
      setToggle("hasPhotosXrays", false);
    }
  }, [photosXrays]);
  useEffect(() => {
    if (demoTS) {
      getJSON("DemoTreatmentPlan.otp", (status, response) => {
        myOTP = response;
        setStagingTimeline({
          loading: false,
          data: mapStagingTimeline(response),
        });
      });
      fetchHTML();
      const boltonData = demoBoltonAnalysisInfo;
      boltonData &&
      !isEmpty(boltonData) &&
      (!isEmpty(boltonData?.toothWidthTable) ||
        !isEmpty(boltonData?.anteriorRatio) ||
        !isEmpty(boltonData?.overAllRatio) ||
        !isEmpty(boltonData?.upperArchLength) ||
        !isEmpty(boltonData?.lowerArchLength))
        ? setBoltonInfo(boltonData)
        : setBoltonInfo(null);
      getSingedURL()
        .then(result => {
          loadGLTF(result);
        })
        .catch(error => {
          console.error(error); // Logs any errors that may occur during the operation
        });
    } else {
      if (!isLoading) {
        if (error) {
          console.log(error, "Treatment plan not found");
          setTpLoaded(false);
          setNotFound(true);
        } else {
          fetchHTML();
          triggerUserGuiding();

          const boltonData = data?.boltonAnalysisInfo;
          boltonData &&
          !isEmpty(boltonData) &&
          (!isEmpty(boltonData?.toothWidthTable) ||
            !isEmpty(boltonData?.anteriorRatio) ||
            !isEmpty(boltonData?.overAllRatio) ||
            !isEmpty(boltonData?.upperArchLength) ||
            !isEmpty(boltonData?.lowerArchLength))
            ? setBoltonInfo(boltonData)
            : setBoltonInfo(null);

          if (data?.otpFileUrl) {
            setStagingTimeline({ loading: true, data: null });
            try {
              getJSON(data?.otpFileUrl, (status, response) => {
                myOTP = response;
                setStagingTimeline({
                  loading: false,
                  data: mapStagingTimeline(response),
                });
              });
            } catch (e) {
              setStagingTimeline({ loading: false, data: null });
            }
          } else {
            setStagingTimeline({ loading: false, data: null });
          }

          if (
            process.env.REACT_APP_DEV_MODE === "true" &&
            process.env.REACT_APP_GLB_LOCAL === "true"
          ) {
            loadGLTF("24031228TY.OMAR_7b72b (4).glb");
          } else {
            data?.convertedFilesUrl
              ? data?.convertedFilesUrl !== undefined
                ? loadGLTF(data?.convertedFilesUrl)
                : (setTpLoaded(false), setNotFound(true))
              : data?.new_converted_files_urls?.[0] !== undefined
                ? loadGLTF(data?.new_converted_files_urls?.[0])
                : (setTpLoaded(false), setNotFound(true));
          }
        }
      }
    }
  }, [isLoading, error]);
  useEffect(() => {
    if (tpLoaded) {
      // prepareIpr(model.children[2].userData, setIprOverlays);
      // if (!once && maxStepNumber !== 0) {
      //   prepareNumbering();
      //   once = true;
      // }

      //console.log(model.children[2].userData.elastics, "elastics form the other side");
      if (model.children[2].userData.elastics) {
        setToggle("hasElastics", true);
        setToggle("elastics", true);
      } else {
        setToggle("hasElastics", false);
        setToggle("elastics", false);
        TurnElastics(false);
      }

      //prepareFirstMovementValues();
      //console.log(model.children[2].userData);
      setToggle(
        "hasPontics",
        model.children[2].userData.pontics ? true : false,
      );
      setToggle(
        "hasBiteJump",
        model.children[2].userData.bitejump ? true : false,
      );
      console.log(model.children[2].userData.bitejump_steps, "userData");
      if (model.children[2].userData.bitejump_steps) {
        globalBiteJumpUpperLower.u =
          !!model.children[2].userData?.bitejump_steps[0];
        globalBiteJumpUpperLower.l =
          !!model.children[2].userData?.bitejump_steps[1];

        setBiteJumpsSteps([
          model.children[2].userData?.bitejump_steps[0]?.split("st")[1],
          model.children[2].userData?.bitejump_steps[1]?.split("st")[1],
        ]);
      }
      setMaxStepNumber(
        Math.max(
          model.children[2].userData.upper_steps_number,
          model.children[2].userData.lower_steps_number,
        ),
      );
      setOverbiteOverjet(model.children[2].userData.bite_type);
      handlingTeethPerStep(model);
      if (window.parent) {
        window.parent.postMessage(
          {
            isLoading: false,
            hasTsqcReportedIssues: data?.hasTsqcReportedIssues,
            tsqcReportId: data?.tsqcReportId,
          },
          "*",
        );
      }
    }
  }, [tpLoaded, model, maxStepNumber, hasElastics]);

  const handlingTeethPerStep = model => {
    const upperSteps = model.children[2].userData.upper_steps_number;
    const lowerSteps = model.children[2].userData.lower_steps_number;

    let steps;
    if (model.children[2].userData.start_together) {
      steps = Array.from({ length: maxStepNumber + 1 }, (v, i) => ({
        u: i <= upperSteps ? true : false,
        l: i <= lowerSteps ? true : false,
      }));
    } else if (!model.children[2].userData.start_together) {
      // debugger;
      steps = Array.from({ length: maxStepNumber + 1 }, (v, i) =>
        isNegative(globalDiff)
          ? {
              u: i <= Math.abs(globalDiff) ? false : true,
              l: i <= lowerSteps ? true : false,
            }
          : {
              u: i <= upperSteps ? true : false,
              l: i <= Math.abs(globalDiff) ? false : true,
            },
      );
    }

    setSteps(prev => steps);
  };
  useEffect(() => {
    if (
      tpLoaded &&
      (startRevision || compareModifications) &&
      maxStepNumber > 0
    ) {
      setStepperControls("currentStep", maxStepNumber);
    }
  }, [startRevision, tpLoaded, maxStepNumber, compareModifications]);
  useEffect(() => {
    //console.log("legacy 300-11", handle.node.current.offsetWidth);
    if (!handle.active) {
      setToggle("expand", false);
    } else if (!handle.active) {
      setToggle("expand", true);
    }
  }, [handle.active]);
  useEffect(() => {
    window.addEventListener("message", receiveMessage, false);
    return () => {
      window.removeEventListener("message", receiveMessage, false);
    };
  }, []);

  function receiveMessage(event) {
    // Display the received message
    if (event.data.reviseTreatment) {
      setToggle("startRevision", event.data.reviseTreatment);
    }
  }

  const { dentalNotation } = getParams();

  const [canModify, setCanModify] = useState(false);

  const toggleModificationsCard = () => {
    setToggleModifications(!toggleModifications);

    window.parent.postMessage(
      { startTrigger: true },
      process.env.REACT_APP_TDMS_URL,
    );
    window.addEventListener("message", function (event) {
      const photos_xrays = event?.data?.photos_xrays || {};
      console.log({ photos_xrays });
      if (Object.keys(photos_xrays).length > 0) {
        // Create a new sorted object
        const sortedC = {};

        //Iterate over the order array to sort the object
        order.forEach(key => {
          if (photos_xrays.hasOwnProperty(key)) {
            sortedC[key] = photos_xrays[key];
          }
        });
        //console.log(sortedC);
        serPhotosXrays(sortedC);
      }
      const designer =
        event?.data?.current_job?.type === "DESIGN" ||
        event?.data?.current_job?.type === "PENDING_CONVERSION" ||
        event?.data?.current_job?.assigneeType === "DESIGN_SUPERVISOR";

      const next_tp = event?.data?.next_tp || null;

      const modify =
        data.status === "REJECTED" &&
        (next_tp === null ||
          next_tp.status === "NEW" ||
          next_tp.status === "CONVERTED");

      const canModifyKey = next_tp === null ? modify : designer && modify;

      setCanModify(next_tp === null ? modify : designer && modify);
    });
  };
  useEffect(() => {
    if (tpLoaded && show3DControls) {
      turnPontics(false);
      setToggle("pontics", false);
    }
  }, [show3DControls, tpLoaded]);
  useEffect(() => {
    tpLoaded && turnPontics(pontics);
  }, [tpLoaded, pontics]);
  const ref = useRef();
  useEffect(() => {
    // console.log(demoTS);
    if (demoTS) {
      themeConfig("444444");
    }
  }, [demoTS]);

  const { content } = useCustomTranslation();

  const getContent = (group, key) => {
    return content(`other.${group}.${key}`);
  };
  return (
    <FullScreen handle={handle} className="relative">
      {saving && <Saving />}
      {/* {tpLoaded && <GhostCursorComponent />} */}
      {saved && <ChangeSaved autoSaveDate={autoSaveDate} />}
      {tpLoaded && (
        <>
          <ApproveTPModal />
          {/* {startRevision && <Beta />} */}
          {
            <TeethControls
              startRevision={startRevision}
              ref={ref}
              dentalNotation={dentalNotation}
              maxStepNumber={maxStepNumber}
              currentStep={stepperControls.currentStep}
              tpLoaded={tpLoaded}
              GLBData={GLBData}
              iprOverLays={iprOverLays}
              setIprOverlays={setIprOverlays}
              model={model}
              movementTable={demoTS ? demoMovementTable : data?.movementTable}
              parsedMovementTables={parsedMovementTables}
            />
          }
          {/* Doctor modifications */}
          {!demoTS && (
            <DoctorModifications
              redoAllComparingMode={ref.current}
              toggleModificationsCard={toggleModificationsCard}
              toggleModifications={toggleModifications}
              tp_data={data}
              canModify={canModify}
            />
          )}

          {!demoTS && data?.status === "REJECTED" && !tdms && !isPatient && (
            <RevisedTreatment />
          )}
          {/*end Doctor modification */}
          {isC &&
            response?.detectedIssues &&
            response?.detectedIssues.length === 0 && (
              <OverlayWrapper
                showOverlay={!showApprove}
                overlayKey="showApprove"
                minWidth={418}
                defaultHeight={150}
                lessHeight={150}
              >
                <Approve data={data} />
              </OverlayWrapper>
            )}

          {isC &&
            response?.detectedIssues &&
            response?.detectedIssues.length > 0 && (
              <OverlayWrapper
                showOverlay={!showReject}
                overlayKey="showReject"
                minWidth={418}
                defaultHeight={"50vh"}
                rejectHightWrapper
              >
                <Reject
                  detectedIssues={response?.detectedIssues}
                  issueType={issueType}
                  data={data}
                />
              </OverlayWrapper>
            )}

          <OverlayWrapper
            showOverlay={showOverbite}
            overlayKey="showOverbite"
            minWidth={340}
            defaultHeight={410}
          >
            <OverbiteAnalysis overbiteOverjet={overbiteOverjet} />
          </OverlayWrapper>
          <OverlayWrapper
            showOverlay={showBolton}
            overlayKey="showBolton"
            minWidth={340}
            defaultHeight={620}
          >
            <BoltonAnalysis boltonInfo={boltonInfo} />
          </OverlayWrapper>
          <OverlayWrapper
            showOverlay={showStaging}
            overlayKey="showStaging"
            minWidth={510}
            defaultHeight={710}
          >
            <StagingTimeLine
              maxStepNumber={maxStepNumber}
              steps={steps}
              stagingTimeline={stagingTimeline}
            />
          </OverlayWrapper>

          {/* <OverlayWrapper
            showOverlay={showMovement}
            overlayKey="showMovement"
            minWidth={610}
            defaultHeight={1000}
          > */}

          {/* </OverlayWrapper> */}

          <OverlayWrapper
            showOverlay={showRecords}
            overlayKey="showRecords"
            minWidth={400}
            defaultHeight={1000}
          >
            <Records photosXrays={photosXrays} />
          </OverlayWrapper>
        </>
      )}
      {!tpNotFound && <Loader tpLoaded={tpLoaded} />}
      {tpNotFound && (
        <ViewerNotFound
          tpNotFound={tpNotFound}
          complementaryColor={complementaryColor}
        />
      )}
      <div
        style={{
          gridTemplateColumns: "1fr",
          backgroundColor: backgroundColor,
          display: "grid",
          gridTemplateRows: !tpLoaded
            ? "1fr"
            : isMobile
              ? "1fr 55px 60px"
              : split || compareModifications
                ? "60px 1fr"
                : "60px 1fr 55px",
        }}
      >
        {tpLoaded && demoTS && <Header />}
        {tpLoaded && (
          <Nav
            view={view}
            setView={setView}
            handle={handle}
            GLBData={GLBData}
            hasIpr={hasIpr}
            boltonInfo={boltonInfo}
            disableMovement={demoTS ? false : isEmpty(data?.movementTable)}
            disableStaging={demoTS ? false : !data?.otpFileUrl}
          />
        )}
        <ThreeJsViews
          redoAllComparingMode={ref.current}
          setAutoSave={ref.current}
          view={view}
          tpLoaded={tpLoaded}
          iprOverLays={iprOverLays}
          setIprOverlays={setIprOverlays}
          numberingOverLays={numberingOverLays}
          setTpLoaded={setTpLoaded}
          maxStepNumber={maxStepNumber}
          model={model}
          setModel={setModel}
          GLBData={GLBData}
          fakeAttachmentReplacer={fakeAttachmentReplacer}
          isViewerControlsEnabled={isViewerControlsEnabled}
          teethTransformationData={teethTransformationData}
          hasIpr={hasIpr}
          biteJumpsSteps={biteJumpsSteps}
          ponticsData={ponticsData}
        />
        {showMovement && (
          <MovementTable
            movementTable={demoTS ? demoMovementTable : data?.movementTable}
            parsedMovementTables={parsedMovementTables}
            maxStepNumber={maxStepNumber}
          />
        )}
        {tpLoaded && (
          <div
            className={
              split || compareModifications
                ? "bounty_footer_right"
                : isMobile
                  ? "bounty_footer_landscape_mobile bounty_footer"
                  : "bounty_footer"
            }
            style={{
              color: complementaryColor,
              fontSize: isMobile ? "10px" : "14px",
              textAlign:
                split || isMobile || compareModifications ? "center" : "left",
              bottom:
                split || compareModifications
                  ? "20px"
                  : isMobile
                    ? "130px"
                    : "70px",
            }}
          >
            {getContent(
              "labels",
              "simulated_gingiva_and_tooth_movements_actual_clinical_results_may_vary",
            )}
          </div>
        )}
        {tpLoaded && (split || compareModifications) && (
          <div
            className="bounty_footer_left"
            style={{
              color: complementaryColor,
              fontSize: isMobile ? "10px" : "14px",
              textAlign:
                split || isMobile || compareModifications ? "center" : "left",
              bottom:
                split || compareModifications
                  ? "20px"
                  : isMobile
                    ? "130px"
                    : "70px",
            }}
          >
            {getContent(
              "labels",
              "simulated_gingiva_and_tooth_movements_actual_clinical_results_may_vary",
            )}
          </div>
        )}
        {tpLoaded && !startRevision && !toggleModifications && (
          <Stepper
            maxStepNumber={maxStepNumber}
            steps={steps}
            tpLoaded={tpLoaded}
            model={model}
          />
        )}
      </div>
    </FullScreen>
  );
}

export default Scene;
