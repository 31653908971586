import React, { useEffect } from "react";
import "./controls.scss";
import Movement from "./Movement";
import Views from "./Views";
import Render from "./Render";
import { Close, Expand, Shrink } from "../Icons";
import { useGlobalStore } from "../../../store";
import { useQuery } from "@tanstack/react-query";
import { getParams } from "@/helpers/getParams";
import { apiResolver } from "@/helpers/apiResolver";
import {
  TranslationComponent,
  useCustomTranslation,
} from "@/hooks/useTranslation";
function Nav({
  view,
  setView,
  handle,
  GLBData,
  hasIpr,
  boltonInfo,
  disableMovement,
  disableStaging,
}) {
  const { content } = useCustomTranslation();

  const getContent = (group, key) => {
    return content(`nav.${group}.${key}`);
  };
  const withPatientInfo = getParams().withPatientInfo === "true" ? true : false;
  const demoTS = getParams().demoTS === "true" ? true : false;
  const {
    isMobile,
    expand,
    split,
    setToggle,
    compareModifications,
    complementaryColor,
  } = useGlobalStore(state => ({
    isMobile: state.layoutConfig.isMobile,
    expand: state.toggle.expand,
    split: state.toggle.split,
    setToggle: state.setToggle,
    compareModifications: state.toggle.compareModifications,
    complementaryColor: state.layoutConfig.complementaryColor,
  }));

  const { data } = demoTS
    ? ""
    : useQuery({
        queryKey: ["treatmentSetupInfo"],
        queryFn: apiResolver,
        staleTime: 600000, // Cache data for 60 seconds (1 minute)
      });
  const handleExpand = () => {
    setToggle("expand");
    const elem = document.documentElement;
    // console.log(document.documentElement);
    // if (expand === true) {
    //   if (document.exitFullscreen) {
    //     document.exitFullscreen();
    //   } else if (document.webkitExitFullscreen) {
    //     /* Safari */
    //     document.webkitExitFullscreen();
    //   } else if (document.msExitFullscreen) {
    //     /* IE11 */
    //     document.msExitFullscreen();
    //   }
    // } else {
    //   if (document.requestFullscreen) {
    //     document.requestFullscreen();
    //   } else if (document.webkitRequestFullscreen) {
    //     /* Safari */
    //     document.webkitRequestFullscreen();
    //   } else if (document.msRequestFullscreen) {
    //     /* IE11 */
    //     document.msRequestFullscreen();
    //   }
    // }

    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      // If no element is in fullscreen, request fullscreen
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        // Firefox
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        // Chrome, Safari and Opera
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        // IE/Edge
        elem.msRequestFullscreen();
      }
    } else {
      // If an element is in fullscreen, exit fullscreen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        // Firefox
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        // Chrome, Safari and Opera
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        // IE/Edge
        document.msExitFullscreen();
      }
    }
  };

  return (
    <>
      <div className="bounty_canvas_container">
        <TranslationComponent group={"nav"} color={"secondary"} />
        {split && (
          <div
            className="bounty_initial_plan"
            style={{ color: complementaryColor }}
          >
            {compareModifications
              ? getContent("labels", "initial_setup")
              : getContent("labels", "initial_plan")}
          </div>
        )}
        {split && (
          <div
            className="bounty_final_result"
            style={{ color: complementaryColor }}
          >
            {compareModifications
              ? getContent("labels", "doctor_modification")
              : getContent("labels", "final_result")}
          </div>
        )}
        {split && (
          <div
            className="bounty_close_split"
            style={{ right: compareModifications ? "2%" : "290px" }}
            onClick={() => setToggle("split")}
          >
            <Close />
          </div>
        )}
      </div>
      {withPatientInfo && (
        <div
          className={`${isMobile && "absolute"}  z-10 flex ${
            isMobile ? "h-[42px]" : "h-[32px]"
          } w-full ${isMobile ? "flex-nowarp" : "flex-wrap"} content-center
          items-center ${
            isMobile ? "justify-between" : "justify-center"
          } bg-white text-center text-sm font-normal`}
        >
          {`${data?.patientInfo?.firstName} ${data?.patientInfo?.lastName} / ${data?.title} / ${getContent("labels", "case_id")}:  ${data?.clientReferenceId ? data?.clientReferenceId : data?.identifier}`}
          {isMobile && (!expand ? <Expand /> : <Shrink />)}
        </div>
      )}
      <div
        className={withPatientInfo ? "top-8" : demoTS ? "top-[60px]" : "top-0"}
        id={
          isMobile
            ? "bounty_main_controls_container_mobile"
            : "bounty_main_controls_container"
        }
      >
        {!isMobile && <Movement />}
        <div
          className={
            isMobile
              ? "bounty_views_render_container_mobile"
              : "bounty_views_render_container"
          }
        >
          <Views GLBData={GLBData} view={view} setView={setView} />
          {!isMobile && <div id="bounty_divider"></div>}
          <Render
            view={view}
            setView={setView}
            GLBData={GLBData}
            hasIpr={hasIpr}
            boltonInfo={boltonInfo}
            disableMovement={disableMovement}
            disableStaging={disableStaging}
          />
        </div>

        <div>
          <span
            onPointerUp={() => handleExpand()}
            className={
              isMobile
                ? "bounty_nav_icon_full_screen_mobile"
                : "bounty_nav_icon_full_screen"
            }
          >
            {!isMobile && (!expand ? <Expand /> : <Shrink />)}
          </span>
        </div>
        {/* <div>
          <span
            onClick={() => setModal(prev => !prev)}
            className={ "bounty_nav_icon_full_screen"}
          >
            Click Me!
          </span>
        </div> */}
      </div>
    </>
  );
}

export default Nav;
