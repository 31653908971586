import React from "react";
import { numberingFdi } from "@/helpers/teethNumbering";
import { getParams } from "@/helpers/getParams";
import { useGlobalStore } from "../../../store";
import {
  TranslationComponent,
  useCustomTranslation,
} from "@/hooks/useTranslation";

export const BoltonAnalysis = ({ boltonInfo }) => {
  const { content } = useCustomTranslation();

  const getContent = (group, key) => {
    return content(`bolton.${group}.${key}`);
  };
  const { dentalNotation } = getParams();
  const { setToggle } = useGlobalStore(state => ({
    setToggle: state.setToggle,
  }));

  const upperRight = [];
  const upperLeft = [];
  const lowerRight = [];
  const lowerLeft = [];

  Object.keys(boltonInfo?.toothWidthTable).map(index => {
    index = parseInt(index);
    if (index >= 11 && index <= 18)
      upperRight.push({
        value: boltonInfo?.toothWidthTable[index],
        index,
      });
    if (index >= 21 && index <= 28)
      upperLeft.push({ value: boltonInfo?.toothWidthTable[index], index });
    if (index >= 31 && index <= 38)
      lowerLeft.push({ value: boltonInfo?.toothWidthTable[index], index });
    if (index >= 41 && index <= 48)
      lowerRight.push({
        value: boltonInfo?.toothWidthTable[index],
        index,
      });
  });

  return (
    <div className="flex h-full flex-col gap-2 text-xs text-[#242426]">
      <TranslationComponent group={"bolton"} />
      <div className="flex items-center justify-between font-semibold">
        <div className="text-lg">{getContent("labels", "bolton_analysis")}</div>
        <div
          className="z-[2] cursor-pointer text-2xl text-[#898A8D]"
          onClick={() => setToggle("showBolton")}
        >
          &#x2715;
        </div>
      </div>
      <div className="flex gap-2">
        <div className="flex flex-1 flex-col gap-2 rounded border border-solid border-[#d1d1d1] p-2">
          <div className="text-sm font-bold">
            {boltonInfo?.anteriorRatio ? boltonInfo?.anteriorRatio : "-----"}
          </div>
          <div> {getContent("labels", "anterior_bolton_ratio")}</div>
        </div>
        <div className="flex flex-1 flex-col gap-2 rounded border border-solid border-[#d1d1d1] p-2">
          <div className="text-sm font-bold">
            {boltonInfo?.overAllRatio ? boltonInfo?.overAllRatio : "-----"}
          </div>
          <div>{getContent("labels", "overall_bolton_ratio")} </div>
        </div>
      </div>
      <div className="flex flex-col gap-2 rounded border border-solid border-[#d1d1d1] p-2">
        <div className="flex justify-between">
          <div>{getContent("labels", "anterior_normal_value")} </div>
          <div className="font-bold"> .772 ± .0165</div>
        </div>
        <div className="flex justify-between">
          <div>{getContent("labels", "overall_normal_value")}</div>
          <div className="font-bold"> .913 ± .0191</div>
        </div>
      </div>
      <div className="bounty-scrollbar h-full max-h-[calc(100dvh-350px)] overflow-auto ">
        <div className="flex gap-3">
          <div className="flex flex-col flex-1 gap-2 text-center">
            <div className="font-bold">
              {getContent("labels", "upper_right")}
            </div>
            <div>
              {upperRight?.map(({ value, index }, i) => (
                <div
                  key={i}
                  className="flex justify-center px-3 py-[2px] odd:bg-[#F5F5F5]"
                >
                  <div className="flex-1 font-bold">
                    {numberingFdi[index][dentalNotation]}
                  </div>
                  <div className="flex-1 whitespace-nowrap">
                    {value || "-----"}
                  </div>
                </div>
              )) || "-----"}
            </div>
          </div>
          <div className="flex flex-col flex-1 gap-2 text-center">
            <div className="font-bold">
              {getContent("labels", "upper_left")}
            </div>
            <div>
              {upperLeft?.map(({ value, index }, i) => (
                <div
                  key={i}
                  className="flex justify-center px-3 py-[2px] odd:bg-[#F5F5F5]"
                >
                  <div className="flex-1 font-bold">
                    {numberingFdi[index][dentalNotation]}
                  </div>
                  <div className="flex-1 whitespace-nowrap">
                    {value || "-----"}
                  </div>
                </div>
              )) || "-----"}
            </div>
          </div>
        </div>
        <div className="mt-1 flex justify-between bg-[#F5F5F5] px-6">
          <div className="font-medium">
            {getContent("labels", "upper_total_value")}
          </div>
          <div className="font-semibold">
            {boltonInfo?.upperArchLength
              ? boltonInfo?.upperArchLength.split("Actual arc length: ")[1]
              : "-----"}
          </div>
        </div>
        <div className="flex gap-3 mt-2">
          <div className="flex flex-col flex-1 gap-2 text-center">
            <div className="font-bold">
              {getContent("labels", "lower_right")}
            </div>
            <div className="flex flex-col-reverse">
              {lowerRight?.map(({ value, index }, i) => (
                <div
                  key={i}
                  className="flex justify-center px-3 py-[2px] even:bg-[#F5F5F5]"
                >
                  <div className="flex-1 font-bold">
                    {numberingFdi[index][dentalNotation]}
                  </div>
                  <div className="flex-1 whitespace-nowrap">
                    {value || "-----"}
                  </div>
                </div>
              )) || "-----"}
            </div>
          </div>
          <div className="flex flex-col flex-1 gap-2 text-center">
            <div className="font-bold">
              {getContent("labels", "lower_left")}
            </div>
            <div className="flex flex-col-reverse">
              {lowerLeft?.map(({ value, index }, i) => (
                <div
                  key={i}
                  className="flex justify-center px-3 py-[2px] even:bg-[#F5F5F5]"
                >
                  <div className="flex-1 font-bold">
                    {numberingFdi[index][dentalNotation]}
                  </div>
                  <div className="flex-1 whitespace-nowrap">
                    {value || "-----"}
                  </div>
                </div>
              )) || "-----"}
            </div>
          </div>
        </div>
        <div className="mt-1 flex justify-between bg-[#F5F5F5] px-6">
          <div className="font-medium">
            {getContent("labels", "lower_total_value")}
          </div>
          <div className="font-semibold">
            {boltonInfo?.lowerArchLength
              ? boltonInfo?.lowerArchLength.split("Actual arc length: ")[1]
              : "-----"}
          </div>
        </div>
      </div>
    </div>
  );
};
