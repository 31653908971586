import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";
import { Fragment } from "react";


export default function Modal({
  isOpen = false,
  toggle = () => {},
  title = "Modal Title",
  className,
  children,
  footerChildren,
  customActions = null,
  primaryAction = toggle,
  primaryActionText = "Save",
  secondaryAction = toggle,
  secondaryActionText = "Cancel",
  ConfirmApprove,
  primaryActionColor,
  ConfirmSkip
}) {

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[101]" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto bg-black bg-opacity-75">
            <div className="bg-black-900 flex min-h-full w-full items-center justify-center bg-opacity-75 p-4 text-center ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={`${
                    className === "tp"
                      ? "max-w-4xl"
                      : ConfirmApprove ||ConfirmSkip 
                        ? "max-w-md"
                        : "max-w-lg"
                  }   overflow-initial z-10 h-full   w-full transform
                 rounded-lg  bg-white p-6 text-left align-middle shadow-xl transition-all md:h-auto`}
                >
                  <Dialog.Title
                    as="h3"
                    className="mb-4 flex flex-row text-base leading-6"
                  >
                    <span className="width-auto flex-1 flex-col  font-semibold text-[#242426]">
                      {title}
                    </span>
                    <span
                      className="group ml-auto inline-flex cursor-pointer text-xl text-[#898A8D]"
                      onClick={toggle}
                    >
                      &#x2715;
                    </span>
                  </Dialog.Title>
                  {children}
                  <footer className="mt-4">
                    {customActions ? (
                      customActions
                    ) : (
                      <div
                        className={clsx({
                          "grid grid-cols-2 gap-2": ConfirmApprove,
                          "mt-4 flex justify-end gap-2": !ConfirmApprove,
                        })}
                      >
                        <button
                          className="rounded-[3px] border border-solid border-[#BCBCBC] px-5 py-3 text-center text-sm font-normal text-[#666666]"
                          onClick={secondaryAction}
                        >
                          {secondaryActionText}
                        </button>
                        <button
                          className={clsx({
                            "rounded-[3px] border border-solid border-[#009ACE] bg-[#009ACE] px-5 py-3 text-center text-sm font-bold text-[#FFF]":
                              !primaryActionColor,
                            [`rounded-[3px] border border-solid ${primaryActionColor} px-5 py-3 text-center  font-bold text-[#FFF]`]:
                              primaryActionColor,
                          })}
                          onClick={primaryAction}
                        >
                          {primaryActionText}
                        </button>
                      </div>
                    )}

                    {footerChildren}
                  </footer>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
