import React from "react";
import { ZoomIn, ZoomOut, Pan, Rotate, BrightnessIcon } from "../Icons";
import { ToolTip } from "../../shared-components/ToolTip";
import { useGlobalStore } from "../../../store";
import { useCustomTranslation } from "@/hooks/useTranslation";
import ColorSlider from "../../../helpers/ColorPicker";
import "../viewer.scss";

function Movement() {
  const { content } = useCustomTranslation();

  const getContent = (group, key) => {
    return content(`nav.${group}.${key}`);
  };
  const {
    controlsRef,
    controlsRef2,
    camera,
    leftMouse,
    setLayout,
    layoutConfig,
    setToggle,
    showBackGroundColorSlider,
  } = useGlobalStore(state => ({
    controlsRef: state.refs.controlsRef,
    controlsRef2: state.refs.controlsRef2,
    camera: state.computed.camera,
    leftMouse: state.layoutConfig.leftMouse,
    setLayout: state.setLayout,
    layoutConfig: state.layoutConfig,
    setToggle: state.setToggle,
    showBackGroundColorSlider: state.toggle.showBackGroundColorSlider,
  }));

  const isiPad =
    (/iPad|iPad Pro|iPad Air|iPad Mini/.test(navigator.userAgent) &&
      navigator.maxTouchPoints > 1) ||
    (navigator.userAgent.match(/Mac/) &&
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2);

  const handleColorChange = color => {
    setLayout(color.hex);
  };

  return (
    <div id={"bounty_movement_controls"}>
      {!isiPad && (
        <div
          className={`bounty_nav_icon ${
            leftMouse === "pan" ? "views_active" : ""
          }`}
          onClick={() => setLayout("leftMouse", "pan")}
        >
          <ToolTip text={getContent("tooltips", "pan")} />
          <Pan />
        </div>
      )}
      {!isiPad && (
        <div
          className={`bounty_nav_icon ${
            leftMouse === "rotate" ? "views_active" : ""
          }`}
          onClick={() => setLayout("leftMouse", "rotate")}
        >
          <ToolTip text={getContent("tooltips", "rotate")} />
          <Rotate />
        </div>
      )}
      <div
        className="bounty_nav_icon zoomIn"
        onClick={() => {
          controlsRef.current.zoom((camera.zoom = +0.25), true),
            controlsRef2.current.zoom((camera.zoom += 0.25), true);
        }}
      >
        <ToolTip text={getContent("tooltips", "zoom_in")} />
        <ZoomIn />
      </div>
      <div
        className="bounty_nav_icon zoomOut"
        onClick={() => {
          controlsRef.current.zoom((camera.zoom = -0.25), true),
            controlsRef2.current.zoom((camera.zoom -= 0.25), true);
        }}
      >
        <ToolTip text={getContent("tooltips", "zoom_out")} />
        <ZoomOut />
      </div>
      <div
        onClick={() => setToggle("showBackGroundColorSlider")}
        className={`bounty_nav_icon ${showBackGroundColorSlider ? "views_active" : ""}`}
      >
        <ToolTip text={getContent("tooltips", "show_brightness")} />
        <BrightnessIcon />
      </div>
      {showBackGroundColorSlider && (
        <ColorSlider
          hex={layoutConfig.backGroundColor}
          onChange={handleColorChange}
        />
      )}
    </div>
  );
}

export default Movement;
