import React, {
  createRef,
  memo,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

import { numberingUniversal } from "@/helpers/teethNumbering";
import { ArrowDownIcon, VectorIcon } from "../../../assets";
import { cn, isEmpty } from "@/helpers/functions";
import { getParams } from "@/helpers/getParams";
import { useGlobalStore } from "../../../store";
import { Loading } from "@/components/shared-components/Loading";
import { TableStepper } from "./TableStepper";
import { TranslationComponent, useCustomTranslation } from "@/hooks/useTranslation";

export const StagingTimeLine = memo(
  ({ maxStepNumber, steps, stagingTimeline }) => {
    const { content } = useCustomTranslation();

    const getContent = (group, key) => {
      return content(`staging_timeline.${group}.${key}`);
    };
    const { dentalNotation } = getParams();
    const { setToggle, currentStep } = useGlobalStore(state => ({
      setToggle: state.setToggle,
      currentStep: state.stepperControls.currentStep,
    }));

    const elementsRef = useRef(
      Array(maxStepNumber + 1)
        .fill(0)
        .map(() => createRef()),
    );
    const firstRowRef = useRef(null);
    const lastRowRef = useRef(null);

    // show border max 5 times distributed evenly on the timeline
    const showInterval = (index, maxStepNumber) => {
      const step = Math.floor(maxStepNumber / 4);
      return index % step === 0 || index === 0 || index === maxStepNumber;
    };

    const showBorder = (index, maxStepNumber) => {
      const step = Math.floor(maxStepNumber / 7);
      return index % step === 0 && index !== 0 && index !== maxStepNumber;
    };

    const MissingTooth = ({ className }) => (
      <span
        className={cn(
          "mr-2 cursor-pointer text-[15px] font-medium leading-[4px] text-[#898A8D]",
          className,
        )}
      >
        &#x2715;
      </span>
    );

    // Vertical line height setter
    useLayoutEffect(() => {
      if (!elementsRef.current[currentStep]?.current) return;

      const { top: firstRowTop, bottom: firstRowBottom } =
        firstRowRef.current?.getBoundingClientRect() || {};
      const { top: lastRowTop, bottom: lastRowBottom } =
        lastRowRef.current?.getBoundingClientRect() || {};

      const height = lastRowBottom - firstRowTop;

      elementsRef.current[currentStep].current.style.height = `${height}px`;
    }, [currentStep]);

    if (stagingTimeline.loading) return <Loading />;
    if (!stagingTimeline.data)
      return (
        <div className="text-lg font-bold">
          {getContent("labels", "no_data_available")}
        </div>
      );

    return (
      <div className="flex flex-col h-full gap-5">

        <TranslationComponent group={"staging_timeline"}/>
        <div className="flex flex-col gap-3">
          <div className="flex items-center justify-between font-semibold">
            <div className="text-lg font-bold">
              {getContent("labels", "set_up_staging_movement_timeline")}
            </div>
            <div
              className="z-[2] cursor-pointer text-2xl text-[#898A8D]"
              onClick={() => setToggle("showStaging")}
            >
              &#x2715;
            </div>
          </div>
          <div className="grid grid-cols-2 text-[11px] text-[#636363]">
            <div>
              <span className="mr-2 inline-block aspect-square w-[9px] rounded-full bg-[#00B189]" />
              <span> {getContent("labels", "movement")}</span>
            </div>
            <div>
              <MissingTooth />
              <span> {getContent("labels", "missing_tooth")}</span>
            </div>
            <div>
              <span className="mr-2 inline-block aspect-square w-[9px] rounded-full bg-[#F1F1F1]" />
              <span> {getContent("labels", "no_movement")}</span>
            </div>
            <div>
              <ArrowDownIcon className="inline-block w-3 mr-2" />
              <span> {getContent("labels", "attachment_on")}</span>
            </div>
          </div>
        </div>
        <table className="h-full w-full overflow-hidden text-center font-roboto text-[10px] font-normal">
          <thead className="relative w-[calc(100%-5px)]">
            <TableStepper maxStepNumber={maxStepNumber} />
            <tr className="table w-full table-fixed text-[#515151]">
              <th className="w-6" />
              {Object.keys(steps)?.map((step, i) => (
                <th
                  key={i}
                  className={cn("opacity-0", {
                    "opacity-100":
                      maxStepNumber < 15 ||
                      showInterval(i, maxStepNumber) ||
                      i === currentStep,

                    "w-fit": [0, maxStepNumber].includes(i), // helps to align the vertical line
                  })}
                >
                  <div
                    className={cn("relative m-auto w-fit", {
                      "mr-0": i === maxStepNumber,
                      "ml-0": i === 0,
                    })}
                  >
                    <span
                      className={cn("relative z-40 flex justify-center", {
                        "top-[2px] z-40 text-white": i === currentStep,
                      })}
                    >
                      {step}
                    </span>
                    <VectorIcon
                      className={cn(
                        "absolute left-[-4.5px] top-0 z-30 hidden w-[calc(100%+8px)]",
                        {
                          "inline-block": i === currentStep,
                          "left-[-6.5px] w-[calc(100%+12px)]": i <= 9,
                        },
                      )}
                    />
                    {/* vertical line */}
                    <div
                      ref={elementsRef.current[i]}
                      className={cn(
                        "absolute left-[calc(50%-2.5px)] top-[26px] z-50 hidden w-1 bg-black opacity-40",
                        {
                          "inline-block": i === currentStep,
                        },
                      )}
                    ></div>
                  </div>
                </th>
              ))}
              <th className="w-6" />
            </tr>
          </thead>
          <tbody className="bounty-scrollbar relative mt-1 block h-[calc(100%-10px)] max-h-fit w-full overflow-auto">
            {Object.values(numberingUniversal).map((number, toothIndex) => {
              return (
                <tr
                  key={toothIndex}
                  ref={
                    toothIndex === 0
                      ? firstRowRef
                      : toothIndex ===
                          Object.values(numberingUniversal).length - 1
                        ? lastRowRef
                        : null
                  }
                  className={cn("table w-full table-fixed overflow-hidden", {
                    "mb-3": toothIndex === 15,
                  })}
                >
                  <td className={cn("w-6 pr-2 text-center text-[#4E4E4E]")}>
                    {number[dentalNotation]}
                  </td>
                  {Object.keys(steps)?.map((step, stepIndex) => (
                    <td
                      key={stepIndex}
                      className={cn(
                        "relative h-full bg-[linear-gradient(to_bottom,white_0%,white_35%,#F1F1F1_35%,#F1F1F1_65%,white_65%,white_100%)]",
                        {
                          "bg-[linear-gradient(to_bottom,white_0%,white_35%,#00B189_35%,#00B189_65%,white_65%,white_100%)]":
                            stagingTimeline.data[toothIndex]?.[stepIndex]
                              ?.movement,
                          "border-r border-solid border-[#E6E6E6]": showBorder(
                            stepIndex,
                            maxStepNumber,
                          ),
                        },
                      )}
                    >
                      <div className="flex content-center justify-center w-full h-full">
                        {stepIndex === 0 &&
                        isEmpty(
                          stagingTimeline.data[toothIndex]?.[stepIndex],
                        ) ? (
                          <MissingTooth className="absolute -left-[4.5px] top-[calc(50%-14px)] z-20 text-lg" />
                        ) : null}
                        {stagingTimeline.data[toothIndex]?.[stepIndex]
                          ?.attachment && (
                          <div
                            className={cn("relative m-auto w-fit", {
                              "ml-0 justify-start": stepIndex === 0,
                              "mr-0 justify-end": stepIndex === maxStepNumber,
                            })}
                          >
                            <ArrowDownIcon
                              className={cn("z-20 block w-[13px]", {
                                "relative -left-[3px]": stepIndex === 0,
                                "justify-end": stepIndex === maxStepNumber,
                              })}
                            />
                          </div>
                        )}
                      </div>
                    </td>
                  ))}
                  <td
                    className={cn("w-6 pl-1 pr-2 text-center text-[#4E4E4E]")}
                  >
                    {number[dentalNotation]}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  },
);
