import { useState } from "react";

function Input({
  value,
  onchange,
  name,
  label,
  type,
  required,
  extra,
  align,
  error,
  pattern,
}) {
  const [focused, setFocusted] = useState(false);

  const handleFocus = e => {
    setFocusted(true);
  };

  const handleInput = e => {
    setFocusted(false);
  };

  return (
    <div className={`flex flex-col gap-2 ${align}`}>
      <label htmlFor="username" className="text-sm">
        {label}
        <span className="text-gray-400">{`${extra}`}</span>
      </label>
      <input
        type={type}
        className="block w-full rounded border border-[#7676767] px-[15px] py-[10px] outline-none"
        name={name}
        value={value}
        onChange={onchange}
        required={required}
        pattern={pattern}
        onBlur={handleFocus}
        focused={focused.toString()}
        onFocus={handleInput}
      />
      <span className="custom-rule hidden text-red-600">{error}</span>
    </div>
  );
}

export default Input;
