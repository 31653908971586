import React, { useState, useEffect, useRef } from "react";
import { numberingUniversal } from "@/helpers/teethNumbering";
import { toothMovementTypes } from "./TeethPositionHelper";
import { Lock, Unlock } from "../Icons";
import TeethPositionHelper from "./TeethPositionHelper";
import { useGlobalStore } from "../../../store";
import * as THREE from "three";
import { lockTooth } from "./TeethPositionHelper";
import { useCustomTranslation } from "@/hooks/useTranslation";

function TeethPosition(props) {
  const {
    dentalNotation,
    iprOverLays,
    setIprOverlays,
    setAutoSave,
    movementTable,
    parsedMovementTables,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { content } = useCustomTranslation();

  const getContent = (group, key) => {
    return content(`controls.${group}.${key}`);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const { maxStepNumber, currentStep, tpLoaded, GLBData } = props;

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const { selectedTooth, isLocked, setControls3D, setToggle, toggle } =
    useGlobalStore(state => ({
      selectedTooth: state.controls3D.selectedTooth,
      isUpper: state.controls3D.isUpper,
      isLocked: state.controls3D.isLocked,
      setControls3D: state.setControls3D,
      setToggle: state.setToggle,
      toggle: state.toggle,
    }));

  const renderOcc = () => {
    if (toggle.occ) {
      setToggle("occ");
      // setTimeout(() => {
      //   setToggle("occ");
      // }, 100);
    }
  };
  return (
    <div className="bounty_tooth_position">
      <div className="bounty_tooth_position_div_1">
        <div id="tooth_position" className="text-sm font-medium">
          {getContent("labels", "tooth_position")}
        </div>
        <div
          onClick={toggleModal}
          style={{ cursor: "pointer", zIndex: "99" }}
          className={isModalOpen ? "arrow arrow_active" : "arrow"}
        />
      </div>
      {!isModalOpen && (
        <div>
          <div style={{ display: "grid", justifyItems: "center" }}>
            <div className="bounty_divider" />
          </div>
          <div>
            <div className="bounty_tooth_position_div_2">
              <div className="bounty_tooth_position_div_2_1">
                <div className="text-xs font-bold">
                  {" "}
                  {getContent("labels", "selected_tooth")}
                </div>
                <div className="custom-dropdown">
                  <div
                    className={`dropdown-button ${isOpen ? "open" : ""}`}
                    //onClick={toggleDropdown}
                  >
                    {/* {console.log(
                      selectedTooth?.name.split("_")[2].split("")[1],
                      isUpper
                        ? Number(selectedTooth?.name.split("_")[2].split("")[1])
                        : Number(
                            selectedTooth?.name.split("_")[2].split("")[1],
                          ) + 16,
                    )} */}
                    {selectedTooth
                      ? numberingUniversal[
                          Number(selectedTooth.name.split("_")[2].split("t")[1])
                        ][dentalNotation]
                      : "--"}
                    {/* <span className={isOpen ? "arrow arrow_active" : "arrow"} /> */}
                  </div>
                  {isOpen && (
                    <ul className="dropdown-options">
                      {Object.values(numberingUniversal).map((number, i) => (
                        <li
                          key={i}
                          // onClick={() =>
                          //   handleOptionClick(number[dentalNotation])
                          // }
                        >
                          {number[dentalNotation]}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
              {/* <div className="bounty_tooth_position_div_2_1">
                <div className="text-xs font-bold">Lock Tooth</div>
                <div
                  id="lock_tooth"
                  className={`${
                    !selectedTooth ? "bounty_controls_disabled" : ""
                  }`}
                  style={{
                    cursor: "pointer",
                    backgroundColor:
                      isLocked && selectedTooth?.userData["isLocked"]
                        ? "black"
                        : "#F2F2F2",
                    padding: "6px",
                  }}
                  onClick={() => {
                    selectedTooth
                      ? (lockTooth(false, selectedTooth),
                        setControls3D("isLocked", !isLocked))
                      : null;
                  }}
                >
                  {isLocked && selectedTooth?.userData["isLocked"] ? (
                    <Lock />
                  ) : (
                    <Unlock />
                  )}
                </div>
              </div> */}
            </div>
            <div style={{ display: "grid", justifyItems: "center" }}>
              <div className="bounty_divider" />
            </div>
            <div>
              <div>
                {toothMovementTypes.map((elem, i) => {
                  return (
                    <TeethPositionHelper
                      key={i}
                      movementTable={movementTable}
                      callback1={elem.callback}
                      callback2={elem.callback2}
                      value={"--"}
                      type={elem.type}
                      text={elem.text}
                      svg={elem.svg}
                      maxStepNumber={maxStepNumber}
                      currentStep={currentStep}
                      tpLoaded={tpLoaded}
                      GLBData={GLBData}
                      iprOverLays={iprOverLays}
                      setIprOverlays={setIprOverlays}
                      renderOcc={renderOcc}
                      setAutoSave={setAutoSave}
                      parsedMovementTables={parsedMovementTables}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default TeethPosition;
