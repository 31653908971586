import React from "react";
import { numberingUniversal } from "@/helpers/teethNumbering";
import { getParams } from "@/helpers/getParams";
import { useGlobalStore } from "../../../store";
import Beta from "@/components/Beta";
import {
  TranslationComponent,
  useCustomTranslation,
} from "@/hooks/useTranslation";

export const OverbiteAnalysis = ({ overbiteOverjet }) => {
  const { content } = useCustomTranslation();

  const getContent = (group, key) => {
    return content(`overjet_overbite.${group}.${key}`);
  };

  const { dentalNotation } = getParams();
  const { setToggle } = useGlobalStore(state => ({
    setToggle: state.setToggle,
  }));

  return (
    <div className="flex h-full flex-col gap-2 text-xs text-[#242426]">
      <TranslationComponent group={"overjet_overbite"} />
      <div className="flex items-end justify-between font-semibold">
        <div>
          <Beta className="relative bottom-0 left-0" />
          <div className="mt-1 text-lg">
            {`${getContent("labels", "overjet_and_overbite")} (mm)`}{" "}
          </div>
        </div>
        <div
          className="z-[2] cursor-pointer text-2xl leading-none text-[#898A8D]"
          onClick={() => setToggle("showOverbite")}
        >
          &#x2715;
        </div>
      </div>
      <p className="border-b border-[#DBDBDB]  pb-2 text-xs leading-[18px]">
        {getContent(
          "labels",
          "overjet_overbite_data_is_measured_at_the_most_prominent_point_of_each_tooth",
        )}
      </p>
      <div className="bounty-scrollbar flex max-h-[calc(100%-48px)] flex-col gap-2 overflow-y-auto">
        <div className="flex flex-col gap-2 border-b border-[#DBDBDB] pb-2">
          <div className="text-sm font-semibold">
            {" "}
            {getContent("labels", "overjet")}
          </div>
          <table className="text-xs text-center">
            <thead>
              <tr className="font-bold">
                <th></th>
                <th>{numberingUniversal[8][dentalNotation]}</th>
                <th>{numberingUniversal[9][dentalNotation]}</th>
              </tr>
            </thead>
            <tbody>
              <tr className="px-4 py-1 odd:bg-[#F5F5F5]">
                <td className="font-semibold">
                  {getContent("labels", "initial")}
                </td>
                <td>{overbiteOverjet?.overjet?.initial?.R1?.toFixed(1)}</td>
                <td>{overbiteOverjet?.overjet?.initial?.L1?.toFixed(1)}</td>
              </tr>
              <tr className="px-4 py-1 odd:bg-[#F5F5F5]">
                <td className="font-semibold">
                  {getContent("labels", "final")}
                </td>
                <td>{overbiteOverjet?.overjet?.final?.R1?.toFixed(1)}</td>
                <td>{overbiteOverjet?.overjet?.final?.L1?.toFixed(1)}</td>
              </tr>
              <tr className="px-4 py-1 odd:bg-[#F5F5F5]">
                <td className="font-semibold">
                  {getContent("labels", "delta")}
                </td>
                <td>{overbiteOverjet?.overjet?.delta?.R1?.toFixed(1)}</td>
                <td>{overbiteOverjet?.overjet?.delta?.L1?.toFixed(1)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex flex-col gap-2 pb-2">
          <div className="text-sm font-semibold">
            {getContent("labels", "overbite")}
          </div>
          <table className="text-xs text-center">
            <thead>
              <tr className="font-bold">
                <th></th>
                <th>{numberingUniversal[8][dentalNotation]}</th>
                <th>{numberingUniversal[9][dentalNotation]}</th>
              </tr>
            </thead>
            <tbody>
              <tr className="px-4 py-1 odd:bg-[#F5F5F5]">
                <td className="font-semibold">
                  {getContent("labels", "initial")}
                </td>
                <td>{overbiteOverjet?.overbite?.initial?.R1?.toFixed(1)}</td>
                <td>{overbiteOverjet?.overbite?.initial?.L1?.toFixed(1)}</td>
              </tr>
              <tr className="px-4 py-1 odd:bg-[#F5F5F5]">
                <td className="font-semibold">
                  {getContent("labels", "final")}
                </td>
                <td>{overbiteOverjet?.overbite?.final?.R1?.toFixed(1)}</td>
                <td>{overbiteOverjet?.overbite?.final?.L1?.toFixed(1)}</td>
              </tr>
              <tr className="px-4 py-1 odd:bg-[#F5F5F5]">
                <td className="font-semibold">
                  {getContent("labels", "delta")}
                </td>
                <td>{overbiteOverjet?.overbite?.delta?.R1?.toFixed(1)}</td>
                <td>{overbiteOverjet?.overbite?.delta?.L1?.toFixed(1)}</td>
              </tr>
            </tbody>
          </table>
          <p>
            {getContent(
              "labels",
              "oj_values_for_teeth_with_pre_bonded_attachments_may_be_inaccurate",
            )}
          </p>
        </div>
      </div>
    </div>
  );
};
