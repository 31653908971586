import React from "react";
import { Left, Lower, Right, Upper, Bottom, Top, Front } from "../Icons";
import { ToolTip } from "../../shared-components/ToolTip";
import { controller } from "./controller";
import { useGlobalStore } from "../../../store";
import { useCustomTranslation } from "@/hooks/useTranslation";

function Views({ view, setView, GLBData, boltonInfo }) {
  const { content } = useCustomTranslation();

  const getContent = (group, key) => {
    return content(`nav.${group}.${key}`);
  };
  const { controlsRef, controlsRef2, isMobile, setToggle, toggle } =
    useGlobalStore(state => ({
      controlsRef: state.refs.controlsRef,
      controlsRef2: state.refs.controlsRef2,
      isMobile: state.layoutConfig.isMobile,
      setToggle: state.setToggle,
      toggle: state.toggle,
    }));

  return (
    <div
      id={isMobile ? "bounty_views_controls_mobile" : "bounty_views_controls"}
    >
      {!isMobile && (
        <div
          onClick={() =>
            Object.values(GLBData[0].upper?.gum).length === 0
              ? null
              : controller(
                  "upper",
                  controlsRef.current,
                  controlsRef2.current,
                  view,
                  setView,
                  setToggle,
                  toggle,
                  boltonInfo,
                )
          }
          className={`bounty_nav_icon ${
            toggle.showUpper
              ? "views_active"
              : Object.values(GLBData[0].upper?.gum).length === 0
                ? "bounty_icon_disabled"
                : ""
          }`}
        >
          {!isMobile && <ToolTip text={getContent("tooltips", "show_upper")} />}
          <Upper />
          <div>{getContent("labels", "upper")}</div>
        </div>
      )}
      <div
        onClick={() =>
          Object.values(GLBData[0].upper?.gum).length === 0
            ? null
            : controller(
                "top",
                controlsRef.current,
                controlsRef2.current,
                view,
                setView,
                setToggle,
                toggle,
                boltonInfo,
              )
        }
        className={`bounty_nav_icon ${
          view === "top"
            ? "views_active"
            : Object.values(GLBData[0].upper?.gum).length === 0
              ? "bounty_icon_disabled"
              : ""
        }`}
        style={{
          padding: isMobile ? "0px 10px" : "0px",
          width: isMobile ? "60px" : "100%",
        }}
      >
        {!isMobile && <ToolTip text={getContent("tooltips", "top_view")} />}
        <Top />
        <div>{getContent("labels", "top")}</div>
      </div>
      {!isMobile && (
        <div
          onClick={() =>
            controller(
              "right",
              controlsRef.current,
              controlsRef2.current,
              view,
              setView,
              setToggle,
              toggle,
              boltonInfo,
            )
          }
          className={`bounty_nav_icon ${
            view === "right" ? "views_active" : ""
          }`}
        >
          {!isMobile && <ToolTip text={getContent("tooltips", "right_view")} />}
          <Right />
          <div>{getContent("labels", "right")}</div>
        </div>
      )}
      <div
        onClick={() =>
          controller(
            "front",
            controlsRef.current,
            controlsRef2.current,
            view,
            setView,
            setToggle,
            toggle,
            boltonInfo,
          )
        }
        className={`bounty_nav_icon ${view === "front" ? "views_active" : ""}`}
        style={{
          padding: isMobile ? "0px 10px" : "0px",
          width: isMobile ? "60px" : "100%",
        }}
      >
        {!isMobile && <ToolTip text={getContent("tooltips", "front_view")} />}
        <Front />
        <div>{getContent("labels", "front")}</div>
      </div>
      {!isMobile && (
        <div
          onClick={() =>
            controller(
              "left",
              controlsRef.current,
              controlsRef2.current,
              view,
              setView,
              setToggle,
              toggle,
              boltonInfo,
            )
          }
          className={`bounty_nav_icon ${view === "left" ? "views_active" : ""}`}
        >
          {!isMobile && <ToolTip text={getContent("tooltips", "left_view")} />}
          <Left />
          <div>{getContent("labels", "left")}</div>
        </div>
      )}
      <div
        onClick={() =>
          Object.values(GLBData[0].lower?.gum).length === 0
            ? null
            : controller(
                "bottom",
                controlsRef.current,
                controlsRef2.current,
                view,
                setView,
                setToggle,
                toggle,
                boltonInfo,
              )
        }
        className={`bounty_nav_icon ${
          view === "bottom"
            ? "views_active"
            : Object.values(GLBData[0].lower?.gum).length === 0
              ? "bounty_icon_disabled"
              : ""
        }`}
        style={{
          padding: isMobile ? "0px 10px" : "0px",
          width: isMobile ? "60px" : "100%",
        }}
      >
        {!isMobile && <ToolTip text={getContent("tooltips", "bottom_view")} />}
        <Bottom />
        <div>{getContent("labels", "bottom")}</div>
      </div>
      {!isMobile && (
        <div
          onClick={() =>
            Object.values(GLBData[0].lower?.gum).length === 0
              ? null
              : controller(
                  "lower",
                  controlsRef.current,
                  controlsRef2.current,
                  view,
                  setView,
                  setToggle,
                  toggle,
                  boltonInfo,
                )
          }
          className={`bounty_nav_icon ${
            toggle.showLower
              ? "views_active"
              : Object.values(GLBData[0].lower?.gum).length === 0
                ? "bounty_icon_disabled"
                : ""
          }`}
        >
          {!isMobile && <ToolTip text={getContent("tooltips", "show_lower")} />}
          <Lower />
          <div>{getContent("labels", "lower")}</div>
        </div>
      )}
    </div>
  );
}

export default Views;
