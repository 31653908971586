export const demoMovementTable = [
  {
    "Tooth number": "14",
    Rotation: "6.00 deg",
    Angulation: "",
    Inclination: "6.00 deg",
    "Left/ Right": "0.60 mm",
    "Extrusion/ Intrusion": "-0.20 mm",
    "Forward/ Backward": "1.85 mm",
    "IPR Distal": "",
    "IPR Mesial": "",
    "Long Axis": "75.3 deg",
    Circumference: "26.7 mm",
  },
  {
    "Tooth number": "13",
    Rotation: "21.98 deg",
    Angulation: "10.00 deg",
    Inclination: "",
    "Left/ Right": "-0.98 mm",
    "Extrusion/ Intrusion": "-0.43 mm",
    "Forward/ Backward": "1.11 mm",
    "IPR Distal": "",
    "IPR Mesial": "",
    "Long Axis": "67.8 deg",
    Circumference: "24.6 mm",
  },
  {
    "Tooth number": "12",
    Rotation: "-23.43 deg",
    Angulation: "1.00 deg",
    Inclination: "2.00 deg",
    "Left/ Right": "0.92 mm",
    "Extrusion/ Intrusion": "-0.31 mm",
    "Forward/ Backward": "0.60 mm",
    "IPR Distal": "",
    "IPR Mesial": "",
    "Long Axis": "67.2 deg",
    Circumference: "20.4 mm",
  },
  {
    "Tooth number": "11",
    Rotation: "-14.86 deg",
    Angulation: "2.00 deg",
    Inclination: "-2.00 deg",
    "Left/ Right": "0.06 mm",
    "Extrusion/ Intrusion": "-0.54 mm",
    "Forward/ Backward": "1.43 mm",
    "IPR Distal": "",
    "IPR Mesial": "",
    "Long Axis": "65.1 deg",
    Circumference: "24.0 mm",
  },
  {
    "Tooth number": "21",
    Rotation: "-5.48 deg",
    Angulation: "",
    Inclination: "5.00 deg",
    "Left/ Right": "0.49 mm",
    "Extrusion/ Intrusion": "-0.61 mm",
    "Forward/ Backward": "1.17 mm",
    "IPR Distal": "",
    "IPR Mesial": "",
    "Long Axis": "67.9 deg",
    Circumference: "24.5 mm",
  },
  {
    "Tooth number": "22",
    Rotation: "-46.74 deg",
    Angulation: "-8.35 deg",
    Inclination: "15.26 deg",
    "Left/ Right": "1.30 mm",
    "Extrusion/ Intrusion": "-0.13 mm",
    "Forward/ Backward": "-0.70 mm",
    "IPR Distal": "",
    "IPR Mesial": "",
    "Long Axis": "65.2 deg",
    Circumference: "20.4 mm",
  },
  {
    "Tooth number": "23",
    Rotation: "",
    Angulation: "",
    Inclination: "",
    "Left/ Right": "-0.16 mm",
    "Extrusion/ Intrusion": "-0.01 mm",
    "Forward/ Backward": "0.61 mm",
    "IPR Distal": "",
    "IPR Mesial": "",
    "Long Axis": "72.2 deg",
    Circumference: "24.2 mm",
  },
];
