export const demoBoltonAnalysisInfo = {
  toothWidthTable: {
    11: "9.08 mm",
    12: "6.84 mm",
    13: "8.16 mm",
    14: "7.67 mm",
    15: "6.93 mm",
    16: "10.94 mm",
    17: "10.54 mm",
    18: "",
    21: "9.62 mm",
    22: "7.05 mm",
    23: "8.06 mm",
    24: "7.36 mm",
    25: "6.21 mm",
    26: "9.50 mm",
    27: "9.88 mm",
    28: "8.64 mm",
    31: "5.80 mm",
    32: "6.41 mm",
    33: "7.09 mm",
    34: "7.82 mm",
    35: "7.76 mm",
    36: "11.38 mm",
    37: "10.12 mm",
    38: "12.87 mm",
    41: "5.95 mm",
    42: "6.40 mm",
    43: "6.93 mm",
    44: "7.93 mm",
    45: "7.87 mm",
    46: "9.93 mm",
    47: "11.38 mm",
    48: "",
  },
  anteriorRatio: "0.79",
  overAllRatio: "0.94",
  upperArchLength: "Actual arc length: 126.49 mm",
  lowerArchLength: "Actual arc length: 125.65 mm",
};
