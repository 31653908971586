import { useEffect } from "react";

/**
 * @param {Function?} callback
 * @param {Object?} limits
 * @param {Number} limits.min
 * @param {Number} limits.max
 * @returns {void}
 *
 * */
export const useWindowResize = (callback, limits) => {
  const onResize = () => {
    if (limits) {
      if (window.innerWidth >= limits.min && window.innerWidth <= limits.max) {
        callback();
      }
    } else {
      callback();
    }
  };

  useEffect(() => {
    const debouncedResize = debounce(onResize, 50);
    window.addEventListener("resize", debouncedResize);
    return () => window.removeEventListener("resize", debouncedResize);
  }, [callback, limits]);
};

function debounce(fn, ms) {
  let timer;
  return _ => {
    clearTimeout(timer);
    timer = setTimeout(_ => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}