import * as THREE from "three";
import { Vector3, Euler, Box3 } from "three";
import { DecalGeometry } from "three/addons/geometries/DecalGeometry.js";
import { isBJ } from "./Scene";

const decalTextureLoader = new THREE.TextureLoader();
const decalDiffuseCut = decalTextureLoader.load("./decal-diffuse220.png");
const decalDiffuseCutNormal = decalTextureLoader.load(
  "./Rubber_Normal_Map.png",
);
const decalDiffuseButtonNormal = decalTextureLoader.load(
  "./Long_Normal_Map3.png",
);

const decalDiffuseCutDisplacement = decalTextureLoader.load(
  "./Displacement_map.jpg",
);
const decalDiffuseButtonDisplacement = decalTextureLoader.load(
  "./Long_DisplacementMap2.jpg",
);

const Mode = {
  NONE: "NONE",
  ADDCUTS: "ADDCUTS",
  ADDATTACHMENTS: "ADDATTACHMENTS",
  TEETHMOVEMENT: "TEETHMOVEMENT",
};
let currentMode = Mode.NONE;
const CutPositionModes = {
  MESIALLY: "MESIALLY",
  DISTALLY: "DISTALLY",
  CENTER: "CENTER",
};
const CutDirectionModes = {
  MESIALLY: "MESIALLY",
  DISTALLY: "DISTALLY",
  CENTER: "CENTER",
};
let CutDirection = CutDirectionModes.DISTALLY;

const decalDiffuseButton = decalTextureLoader.load("./Long.png");
decalDiffuseButton.magFilter = THREE.NearestFilter;
decalDiffuseCut.colorSpace = THREE.SRGBColorSpace;
decalDiffuseButton.colorSpace = THREE.SRGBColorSpace;

const decalCutMaterial = new THREE.MeshPhongMaterial({
  // specular: conf1.color,
  color: 0x00d4ff,
  map: decalDiffuseCut,
  // bumpMap: decalDiffuseCutHeight,
  // bumpScale: 10,
  displacementMap: decalDiffuseCutDisplacement,
  displacementScale: 0.05,
  normalMap: decalDiffuseCutNormal,
  normalScale: new THREE.Vector2(0.9, 0.9),
  shininess: 30,
  transparent: true,
  depthTest: true,
  // depthWrite: false,
  polygonOffset: true,
  polygonOffsetFactor: -2,
  // wireframe: false,
  side: THREE.FrontSide,
  // displacementMap: decalDiffuseCutDisplacement,
});
const decalButtonMaterial = new THREE.MeshPhongMaterial({
  color: 0x00d4ff,
  map: decalDiffuseButton,
  normalMap: decalDiffuseButtonNormal,
  normalScale: new THREE.Vector2(1, 1),
  displacementMap: decalDiffuseButtonDisplacement,
  displacementScale: 0.05,
  // normalScale: new THREE.Vector2(1, 2),
  shininess: 30,
  transparent: true,
  // depthTest: true,
  // depthWrite: false,
  polygonOffset: true,
  polygonOffsetFactor: -2,
  // wireframe: false,
  side: THREE.FrontSide,
});
/**
 * Converting the ToothIndex to Universal tooth Number
 */
export const TDMSTeethNumbersToUniversal = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 32, 31, 30, 29, 28, 27,
  26, 25, 24, 23, 22, 21, 20, 19, 18, 17,
];
const elasticsSide = {
  center: 0,
  left: -1,
  distal: -1,
  mesial: 1,
  right: 1,
};
const elasticsSurface = {
  buccal: -1,
  lingual: 1,
  palatal: 1,
};
const elasticsType = {
  cut: 0,
  button: 1,
};
let GLBData, group, splitGroup, elasticsDataArray, elasticsData;
let isCutOrButton = false,
  intervalTime = 50,
  intervalHolder;

const commandsInterval = [];
const cutANDButtonsList = [];
const cutsAndButtons = [];
const decals = [];
let leftOrRight = 0; // 0 means none, 1 right, -1 left
let pauseInterval = false;
let globalCurrentStep = 0;

export function TurnElastics(isOn = true) {
  decalButtonMaterial.visible = isOn;
  decalCutMaterial.visible = isOn;
}
export function SetCutsAndButtonsElasticsData(elasticsData1) {
  elasticsData = elasticsData1;
}
/**
 *
 * @param {group} _group 3D group ref that contains all steps
 * @param {group} _splitGroup 3D group ref that contains last step
 */
export function SetGroup(_group, _splitGroup) {
  group = _group;
  splitGroup = _splitGroup;
}
export function SetElasticsGLBData(parameter) {
  GLBData = parameter;
  globalCurrentStep = parameter.length;
  for (let i = 0; i < parameter.length; i++) {
    cutsAndButtons[i] = [];
  }
  //console.log("GLBData is set ", parameter);
}

function ConvertDirectionToSide(tooth, direction) {
  if (!tooth) {
    console.log("no tooth was sent");
    return undefined;
  }
  let side;
  const box3 = new Box3().setFromObject(tooth);
  const vector = new Vector3();
  box3.getCenter(vector);
  if (vector.x > 0) {
    if (direction === elasticsSide.left) {
      side = elasticsSide.mesial;
    } else if (direction === elasticsSide.right) {
      side = elasticsSide.distal;
    } else {
      side = elasticsSide.center;
    }
  } else {
    if (direction === elasticsSide.left) {
      side = elasticsSide.distal;
    } else if (direction === elasticsSide.right) {
      side = elasticsSide.mesial;
    } else {
      side = elasticsSide.center;
    }
  }
  //console.log(side, direction);
  return side;
}
function GetToothByNumber(toothNumber, stepNumber) {
  const isLower = toothNumber > 16 ? 1 : 0;
  //console.log(toothNumber, stepNumber);
  for (
    let i = 0;
    i < group.current.children[stepNumber * 2 + isLower].children.length;
    i++
  ) {
    if (
      group.current.children[stepNumber * 2 + isLower].children[i].name.split(
        "_",
      )[2] ===
      "t" + toothNumber
    ) {
      return group.current.children[stepNumber * 2 + isLower].children[i];
    }
  }
}

/**
 *
 * @param {object[object][]} elastics
 * @returns {Array<Array><Object>} elastics data in the drawing formate
 */
export function PrepareCutsAndButtonsData() {
  if (!elasticsData) {
    console.log("this case has no elastics");
    return;
  }

  //console.log(
  //JSON.parse(JSON.stringify(elasticsData)),
  // "prepare",
  //  elasticsData,
  //);
  const TeethKeys = Object.keys(elasticsData.teeth);
  const elasticsArray = [];
  for (let i = 0; i < TeethKeys.length; i++) {
    if (elasticsData.teeth[TeethKeys[i]]) {
      elasticsArray[TDMSTeethNumbersToUniversal[TeethKeys[i]]] = [];
      for (let r = 0; r < elasticsData.teeth[TeethKeys[i]].length; r++) {
        elasticsData.teeth[TeethKeys[i]][r].surface =
          elasticsSurface[elasticsData.teeth[TeethKeys[i]][r].surface];

        elasticsData.teeth[TeethKeys[i]][r].elastic =
          elasticsType[elasticsData.teeth[TeethKeys[i]][r].elastic];

        if (elasticsData.teeth[TeethKeys[i]][r].elastic === 1) {
          elasticsData.teeth[TeethKeys[i]][r].side =
            elasticsSide[elasticsData.teeth[TeethKeys[i]][r].side];
        } else {
          elasticsData.teeth[TeethKeys[i]][r].side = ConvertDirectionToSide(
            GetToothByNumber(
              TDMSTeethNumbersToUniversal[Number(TeethKeys[i])],
              elasticsData.teeth[TeethKeys[i]][r].from,
            ),
            elasticsSide[elasticsData.teeth[TeethKeys[i]][r].direction],
          );
        }

        elasticsArray[TDMSTeethNumbersToUniversal[TeethKeys[i]]].push(
          elasticsData.teeth[TeethKeys[i]][r],
        );
      }
    }
  }
  elasticsDataArray = elasticsArray;
}

function AdjustCutDirection(direction) {
  switch (direction) {
    case elasticsSide.center:
      CutDirection = CutDirectionModes.CENTER;
      break;
    case elasticsSide.mesial:
      CutDirection = CutDirectionModes.DISTALLY;
      break;
    case elasticsSide.distal:
      CutDirection = CutDirectionModes.MESIALLY;
      break;
  }
}

export function InjectDrawCutsAndButtonsData() {
  console.log("inject");
  if (!elasticsDataArray) {
    return;
  }
  for (let i = 0; i < elasticsDataArray.length; i++) {
    for (let o = 0; o < elasticsDataArray[i]?.length; o++) {
      if (elasticsDataArray[i][o].side != undefined) {
        if (i < 16) {
          AdjustCutDirection(elasticsDataArray[i][o].side);
        } else {
          AdjustCutDirection(elasticsDataArray[i][o].side * -1);
        }
        DrawElastic(
          i,
          Number(elasticsDataArray[i][o].from),
          Number(elasticsDataArray[i][o].to) + (isBJ ? 1 : 0),
          elasticsDataArray[i][o].elastic,
          elasticsDataArray[i][o].surface,
          elasticsDataArray[i][o].side,
        );
      } else {
        console.log("fail", elasticsDataArray[i][o], i, o);
      }
    }
  }
}

/**
 *
 * @param {int} teethNumber Universal Tooth Number
 * @param {int} startStep the step to start drawing from (including)
 * @param {int} endStep the step to stop drawing at (including) *if 0 then to the last step
 * @param {int} cutOrButton 0 = cut, 1 = button
 * @param {int} isInner  -1 = not inner, 1 = inner
 * @param {int} direction 0 = center, -1 = Distally, 1 = Mesially
 */
function DrawElastic(
  teethNumber, // normal formula numbering (not fdi)
  startStep,
  endStep, // 0 = to the final step,
  cutOrButton, // 0 = cut, 1 = button
  isInner, // -1 = not inner, 1 = inner
  direction = 0, // 0 = center, -1 = Distally, 1 = Mesially
) {
  let stepNum;
  const t = Date.now();
  const zero = new THREE.Vector3();
  const one = new THREE.Vector3();
  const size = new THREE.Vector3();
  let two = new THREE.Vector3();
  let center = new THREE.Vector3();
  let rayDir;

  let upperOrLower = 0;

  let leftRightPercentageOffset = 4; // cut = 4, button = 3.5
  if (isCutOrButton === 1) {
    leftRightPercentageOffset = 3.5;
  }

  if (cutOrButton === 1) {
    isCutOrButton = true;
  } else {
    isCutOrButton = false;
  }

  if (teethNumber > 16) {
    upperOrLower = 1;
  }
  for (let i1 = startStep; i1 < GLBData.length; i1++) {
    if (endStep !== 0 && i1 > endStep) {
      break;
    }
    let skip = false;
    let cutType = 1;
    group.current.children[i1 * 2 + upperOrLower].children.forEach(item => {
      if (skip) {
        return;
      }
      stepNum = item.name.split("_")[1].substring(2, 5);
      if (commandsInterval[stepNum] === undefined) {
        commandsInterval[stepNum] = [];
      }
      if (item.name.includes("_t" + teethNumber)) {
        skip = true;

        let box3 = new THREE.Box3().setFromObject(item);
        box3.getCenter(one);
        center = one.clone();
        two = one.clone();

        let angle = THREE.MathUtils.clamp(
          (Math.atan2(zero.x, zero.z) - Math.atan2(one.x, one.z)) *
            (180 / Math.PI),
          -70,
          70,
        );

        box3.getSize(size);
        let forX = angle / -90;
        let forZ = 0;
        if (angle > 0) {
          forZ = forX + 1;
        } else {
          forZ = -(forX - 1);
        }

        two.setX(one.x + (size.x / 2) * forX);
        two.setZ(one.z + (size.z / 2) * forZ);
        rayDir = center.clone().sub(two);

        let shootSurface;
        if (isInner === -1) {
          shootSurface = center
            .clone()
            .sub(rayDir.clone().multiplyScalar(1.75));
        } else {
          shootSurface = center
            .clone()
            .add(rayDir.clone().multiplyScalar(1.75));
        }
        if (isInner === 1) {
          rayDir.multiplyScalar(-1);
        }
        if (direction === 0) {
          commandsInterval[stepNum].push({
            item,
            shootSurface: shootSurface.clone(),
            rayDir,
            size: new Vector3(
              Math.abs(size.x * forX) + Math.abs(size.z * forZ),
              2.5,
              5,
            ),
            angle: Math.abs(angle),
            forX,
            forZ,
            isInner,
            isCutOrButton: isCutOrButton,
            CutDirection: CutDirection,
            leftOrRight: leftOrRight,
          });
          return;
        }

        two.setY(one.y - size.y / 4);

        if (direction === -1) {
          // left

          if (one.x > 0) {
            if (Math.abs(angle) < 65) {
              one.set(shootSurface.x, shootSurface.y, shootSurface.z);
              leftOrRight = 1;
              cutType = 0;
            } else {
              one.set(
                shootSurface.x + (size.x / leftRightPercentageOffset) * forZ,
                shootSurface.y,
                shootSurface.z - (size.z / leftRightPercentageOffset) * forX,
              );
              cutType = 1;
            }
            commandsInterval[stepNum].push({
              item,
              shootSurface: one.clone(),
              rayDir,
              size: new Vector3(
                Math.abs(size.x * forX) + Math.abs(size.z * forZ),
                2.5,
                5,
              ),
              angle: Math.abs(angle),
              forX,
              forZ,
              cutType,
              isInner,
              isCutOrButton: isCutOrButton,
              CutDirection: CutDirection,
              leftOrRight: leftOrRight,
            });
          } else {
            // right

            if (Math.abs(angle) < 65) {
              one.set(shootSurface.x, shootSurface.y, shootSurface.z);
              leftOrRight = -1;
              cutType = 0;
            } else {
              one.set(
                shootSurface.x - (size.x / leftRightPercentageOffset) * forZ,
                shootSurface.y,
                shootSurface.z + (size.z / leftRightPercentageOffset) * forX,
              );
              cutType = 1;
            }

            commandsInterval[stepNum].push({
              item,
              shootSurface: one.clone(),
              rayDir,
              size: new Vector3(
                Math.abs(size.x * forX) + Math.abs(size.z * forZ),
                2.5,
                5,
              ),
              angle: Math.abs(angle),
              forX,
              forZ,
              cutType,
              isInner,
              isCutOrButton: isCutOrButton,
              CutDirection: CutDirection,
              leftOrRight: leftOrRight,
            });
          }
        } else if (direction === 1) {
          //not Distally => Misally
          // right
          if (one.x > 0) {
            if (Math.abs(angle) < 65) {
              leftOrRight = -1;
              one.set(shootSurface.x, shootSurface.y, shootSurface.z);
              cutType = 0;
            } else {
              one.set(
                shootSurface.x - (size.x / leftRightPercentageOffset) * forZ,
                shootSurface.y,
                shootSurface.z + (size.z / leftRightPercentageOffset) * forX,
              );
              cutType = 1;
            }

            commandsInterval[stepNum].push({
              item,
              shootSurface: one.clone(),
              rayDir,
              size: new Vector3(
                Math.abs(size.x * forX) + Math.abs(size.z * forZ),
                2.5,
                5,
              ),
              angle: Math.abs(angle),
              forX,
              forZ,
              cutType,
              isInner,
              isCutOrButton: isCutOrButton,
              CutDirection: CutDirection,
              leftOrRight: leftOrRight,
            });
          } else {
            if (Math.abs(angle) < 65) {
              one.set(shootSurface.x, shootSurface.y, shootSurface.z);
              leftOrRight = 1;
              cutType = 0;
            } else {
              one.set(
                shootSurface.x + (size.x / leftRightPercentageOffset) * forZ,
                shootSurface.y,
                shootSurface.z - (size.z / leftRightPercentageOffset) * forX,
              );
              cutType = 1;
            }

            commandsInterval[stepNum].push({
              item,
              shootSurface: one.clone(),
              rayDir,
              size: new Vector3(
                Math.abs(size.x * forX) + Math.abs(size.z * forZ),
                2.5,
                5,
              ),
              angle: Math.abs(angle),
              forX,
              forZ,
              cutType,
              isInner,
              isCutOrButton: isCutOrButton,
              CutDirection: CutDirection,
              leftOrRight: leftOrRight,
            });
          }
        }
      }
    });
  }
}

export function StartExecution() {
  if (!group || !GLBData) {
    console.log("undefined group or GLBData");
    return;
  }
  intervalHolder = setInterval(shoot, intervalTime);
}

function isFront(castPosition, targetPosition, collisionList) {
  const raycaster2 = new THREE.Raycaster(
    castPosition,
    targetPosition.clone().normalize(),
  );
  const t = raycaster2.intersectObjects(collisionList, false);

  for (let i = 0; i < t.length; i++) {
    if (t[i].point.distanceTo(targetPosition) < 0.01) {
      if (i === 0) {
        return -1; // back face
      } else if (i === 1) {
        return 1; //front face
      }
    }
  }
  return 1; // means fail
}

function isIt(element, index, array) {
  return element === faceId;
}

let faceId;
let faceIndex;
function shoot(
  mesh,
  position,
  orientation,
  size,
  angle,
  forX,
  forZ,
  cutType,
  isInner = -1,
) {
  let t1 = Date.now();
  for (let i = 0; i < commandsInterval.length; i++) {
    if (commandsInterval[i] === undefined || commandsInterval[i].length === 0) {
      continue;
      clearInterval(intervalHolder);
    }

    mesh = commandsInterval[i][0].item;
    position = commandsInterval[i][0].shootSurface;
    orientation = commandsInterval[i][0].rayDir;
    size = commandsInterval[i][0].size;
    angle = commandsInterval[i][0].angle;
    forX = commandsInterval[i][0].forX;
    forZ = commandsInterval[i][0].forZ;
    cutType = commandsInterval[i][0].cutType;
    isInner = commandsInterval[i][0].isInner;
    CutDirection = commandsInterval[i][0].CutDirection;
    leftOrRight = commandsInterval[i][0].leftOrRight;

    isCutOrButton = commandsInterval[i][0].isCutOrButton;
    commandsInterval[i].shift();
    if (commandsInterval[i].length === 0) {
      for (let t = 0; t < i + 1; t++) {
        commandsInterval.shift();
      }
    }
    break;
  }
  if (mesh === undefined) {
    clearInterval(intervalHolder);
    copyElasticsToSplit();
    return;
  }

  if (isInner === 1) {
    cutType = 1;
  }

  const geometry = new THREE.SphereGeometry(0.4, 15, 32);
  const geometry1 = new THREE.BoxGeometry(1, 1, 1);

  const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
  const material1 = new THREE.MeshPhongMaterial({
    color: 0xf0ff00,
    displacementMap: decalDiffuseCutDisplacement,
    displacementScale: 0.1,
  });

  const cube = new THREE.Mesh(geometry, material); // raycast cube
  cube.scale.set(1.5, 1.5, 1.5);
  const cube2 = new THREE.Mesh(geometry, material); // tooth center Cube
  const cube22 = new THREE.Mesh(geometry, material); // tooth center Cube

  const cube3 = new THREE.Mesh(geometry, material1); // raycast cube
  const cube4 = new THREE.Mesh(geometry1, material); // raycast cube

  // scene.add(cube);
  // scene.add(cube22);

  // cube4.scale.set(15, 15, 15);

  // scene.add(cube2);
  // scene.add(cube3);
  // scene.add(cube4);
  cube3.position.set(position.x, position.y, position.z);

  const center = new Vector3();
  const box3 = new THREE.Box3();
  box3.setFromObject(mesh);
  box3.getCenter(center); // teeth Center
  const size2 = new Vector3();
  box3.getSize(size2);
  const box3Helper = new THREE.Box3Helper(box3);
  // scene.add(box3Helper);
  const object = new THREE.Object3D();
  const hitSide = isFront(new Vector3(), position, [mesh]);
  if (hitSide === 0) {
    return;
  }
  let offsetPos = 0;
  if (mesh.parent.name.includes("l")) {
    offsetPos -= size2.y / 4;
  } else {
    offsetPos += size2.y / 4;
  }
  object.position.set(position.x, position.y + offsetPos, position.z);

  cube2.position.set(center.x, center.y, center.z);

  const material2 = new THREE.LineBasicMaterial({ color: 0x0000ff });
  const points = [];
  points.push(new THREE.Vector3(0, 0, 0));
  points.push(new THREE.Vector3(0, 0, 10));

  const geometry2 = new THREE.BufferGeometry().setFromPoints(points);
  const line = new THREE.Line(geometry2, material2);
  const line2 = new THREE.Line(geometry2, material2);

  cube.add(line);
  cube22.add(line2);
  let loop = false;
  let raycaster2 = new THREE.Raycaster();
  cube.position.set(position.x, position.y, position.z);
  cube.lookAt(position.clone().add(orientation));
  let collisionPoint;
  let t = 0;
  let iteration = 0;
  let correctionLimit = 50;
  let hitGum = false;
  let cutMovement = 3;
  let cutVar = 0.25;
  let multiplier = 1;
  let isSwitch = -1;
  if (isCutOrButton) {
    cutVar *= 2;
  }

  let oldDistance = 0;

  if (cutType === 0) {
    do {
      const timeT = Date.now();

      loop = false;
      t++;
      if (t > correctionLimit || iteration > correctionLimit * 2) {
        // line.material = new THREE.LineBasicMaterial({ color: 0xff0000 });
        break;
      }
      cube.position.setX(object.position.x);
      cube.position.setY(object.position.y);
      cube.position.setZ(object.position.z);

      raycaster2.set(cube.position, orientation.normalize());
      collisionPoint = raycaster2.intersectObjects([mesh, mesh.parent], false);

      let skip = false;
      const line = new THREE.Line(geometry2, material2);
      const objectTemp = new THREE.Mesh(geometry, material);
      objectTemp.position.set(
        cube.position.x,
        cube.position.y,
        cube.position.z + iteration,
      );
      objectTemp.lookAt(
        cube.position.clone().add(orientation.clone().normalize()),
      );
      objectTemp.add(line);

      if (
        isInner === 1 &&
        collisionPoint.length != 0 &&
        (collisionPoint.length % 2 === 1 ||
          collisionPoint[0].object.name.includes("gum"))
      ) {
        // if it is inside the gum or tooth
        object.position.sub(orientation.multiplyScalar(0.5));
        cube.position.setX(object.position.x);
        cube.position.setZ(object.position.z);
        t--;
        loop = true;
      }

      ///////////////////////////////////////// new 2

      if (collisionPoint.length != 0) {
        // hit teeth or gum
        if (collisionPoint[0].object === mesh.parent) {
          // if hit gum
          // shoot there
          if (leftOrRight === 1) {
            object.position.setX(object.position.x - cutVar * forZ * 4);
            object.position.setZ(object.position.z + cutVar * forX * 4);
          } else {
            object.position.setX(object.position.x + cutVar * forZ * 4);
            object.position.setZ(object.position.z - cutVar * forX * 4);
          }

          cube.position.set(
            object.position.x,
            object.position.y,
            object.position.z,
          );
          objectTemp.position.set(
            object.position.x,
            object.position.y,
            object.position.z,
          );
          // scene.add(objectTemp);

          raycaster2.set(cube.position, orientation.normalize());
          collisionPoint = raycaster2.intersectObjects(
            [mesh, mesh.parent],
            false,
          );
          // loop = false;
          break;
        } else {
          if (leftOrRight === 1) {
            object.position.setX(object.position.x + cutVar * forZ);
            object.position.setZ(object.position.z - cutVar * forX);
          } else {
            object.position.setX(object.position.x - cutVar * forZ);
            object.position.setZ(object.position.z + cutVar * forX);
          }
          loop = true;
        }
      } else {
        // if there is nothing hit by the ray
        // object.position.setX(object.position.x - cutVar * forZ);
        // object.position.setZ(object.position.z - cutVar * forX);
        line.material = new THREE.LineBasicMaterial({ color: 0x0000ff });
        // loop = false;
        // scene.add(objectTemp);
        // return;
      }
      ///////////////////////////////////////// New
      // scene.add(objectTemp);

      if (!loop) {
        // scene.add(objectTemp);
        loop = true;
        t = 0;
        iteration += 2;
        if (mesh.parent.name.includes("l")) {
          object.position.set(
            position.x,
            position.y - cutVar * iteration,
            position.z,
          );
        } else {
          object.position.set(
            position.x,
            position.y + cutVar * iteration,
            position.z,
          );
        }
      }
    } while (loop);
    leftOrRight = 0;
  } else {
    do {
      const timeT = Date.now();

      loop = false;
      t++;
      if (t > correctionLimit) {
        line.material = new THREE.LineBasicMaterial({ color: 0xff0000 });
        break;
      }
      if (angle === 70 || isInner === 1) {
        cube.position.setY(object.position.y);
        raycaster2.set(cube.position, orientation.normalize());
      } else {
        cube.position.setY(object.position.y);
        raycaster2.set(cube.position, orientation.normalize());
      }

      collisionPoint = raycaster2.intersectObjects([mesh, mesh.parent], false);

      const line = new THREE.Line(geometry2, material2);
      const objectTemp = new THREE.Mesh(geometry, material);
      objectTemp.position.set(
        cube.position.x,
        cube.position.y,
        cube.position.z,
      );
      objectTemp.lookAt(
        cube.position.clone().add(orientation.clone().normalize()),
      );
      objectTemp.add(line);
      if (
        isInner === 1 &&
        collisionPoint.length != 0 &&
        (collisionPoint.length % 2 === 1 ||
          collisionPoint[0].object.name.includes("gum"))
      ) {
        // if it is inside the gum or tooth
        object.position.sub(orientation.multiplyScalar(0.5));
        cube.position.setX(object.position.x);
        cube.position.setZ(object.position.z);
        t--;
        loop = true;
      }

      // if (collisionPoint.length != 0) {
      //   if (collisionPoint[0].object === mesh.parent) {
      //     if (cutMovement <= cutVar) {
      //       hitGum = true;
      //       break;
      //     }
      //     if (collisionPoint[0].object.name.includes("l")) {
      //       object.position.setY(object.position.y + cutMovement);
      //     } else {
      //       object.position.setY(object.position.y - cutMovement);
      //     }
      //   } else {
      //     if (cutMovement <= cutVar) {
      //       break;
      //     }
      //     if (collisionPoint[0].object.name.includes("l")) {
      //       object.position.setY(object.position.y - cutMovement);
      //     } else {
      //       object.position.setY(object.position.y + cutMovement);
      //     }
      //     hitGum = false;
      //   }

      //   cutMovement /= 2;
      //   loop = true;
      ////////////////////////////////////////////////
      // if (collisionPoint.length != 0) {
      //   if (collisionPoint[0].object === mesh.parent) {
      //     if (cutMovement <= cutVar / 2) {
      //       hitGum = true;
      //       if (collisionPoint[0].object.name.includes("l")) {
      //         object.position.setY(object.position.y + cutMovement + cutVar);
      //       } else {
      //         object.position.setY(object.position.y - cutMovement - cutVar);
      //       }
      //     }
      //     if (collisionPoint[0].object.name.includes("l")) {
      //       object.position.setY(object.position.y + cutMovement);
      //     } else {
      //       object.position.setY(object.position.y - cutMovement);
      //     }
      //   } else {
      //     if (cutMovement <= cutVar / 2 && hitGum) {
      //       break;
      //     }
      //     if (collisionPoint[0].object.name.includes("l")) {
      //       object.position.setY(object.position.y - cutMovement);
      //     } else {
      //       object.position.setY(object.position.y + cutMovement);
      //     }
      //     hitGum = false;
      //   }

      //   cutMovement /= 2;
      //   loop = true;
      //////////////////////////////////
      if (collisionPoint.length != 0) {
        if (collisionPoint[0].object === mesh.parent) {
          hitGum = true;
          if (collisionPoint[0].object.name.includes("l")) {
            object.position.setY(object.position.y + cutVar * 2);
          } else {
            object.position.setY(object.position.y - cutVar * 2);
          }
          loop = true;
        } else {
          if (collisionPoint[0].object.name.includes("l")) {
            object.position.setY(object.position.y - cutVar);
          } else {
            object.position.setY(object.position.y + cutVar);
          }
          if (hitGum) {
            break;
          }
          loop = true;
        }
      } else {
        // if there is nothing hit by the ray
        line.material = new THREE.LineBasicMaterial({ color: 0x000000 });

        // scene.add(objectTemp);
        return;
      }
    } while (loop);
  }

  if (t < correctionLimit) {
    line.material = new THREE.LineBasicMaterial({ color: 0x0000ff });
    // size.x = 3;
  } else if (t === correctionLimit) {
  }
  object.position.setY(object.position.y - cutVar * 10);

  if (collisionPoint.length === 0) {
    console.log("fail to hit", mesh.name);
    return;
  }
  if (false) {
    console.log(
      collisionPoint[0],
      "this is hit",
      collisionPoint[0].object.geometry.attributes,
      collisionPoint[0].object.geometry.index,
      collisionPoint[0].object.name,
    );
    const offset = new THREE.Vector3();
    mesh.getWorldPosition(offset);
    cube22.position.set(
      mesh.geometry.attributes.position.array[collisionPoint[0].face.c * 3],
      mesh.geometry.attributes.position.array[collisionPoint[0].face.c * 3 + 1],
      mesh.geometry.attributes.position.array[collisionPoint[0].face.c * 3 + 2],
    );
    // cube22.position.set(collisionPoint[0].point.x,collisionPoint[0].point.y,collisionPoint[0].point.z)
    cube22.position.add(offset);
    cube22.lookAt(collisionPoint[0].point);

    let max = 0;
    collisionPoint[0].object.geometry.index.array.forEach(item => {
      if (item > max) max = item;
    });
    console.log("this is hit max", max);
    if (mesh.name.includes("_t29")) {
      console.log(mesh.geometry.index.array, "indexy 2");
      if (faceIndex === undefined) {
        faceId = collisionPoint[0].face.c;
        console.log(faceId, "not Hi");
        faceIndex = mesh.geometry.index.array.findIndex(isIt);
        console.log(faceIndex, "not Hi");
      } else {
        cube22.position.set(
          mesh.geometry.attributes.position.array[faceId * 3],
          mesh.geometry.attributes.position.array[faceId * 3 + 1],
          mesh.geometry.attributes.position.array[faceId * 3 + 2],
        );
        console.log(mesh.geometry.index.array[faceId], "not Hi");
      }
    }
  }

  if (!isCutOrButton) {
    cube.position.sub(
      cube.position.clone().sub(collisionPoint[0].point).multiplyScalar(0.9),
    );
    cube.lookAt(collisionPoint[0].point);
    object.lookAt(collisionPoint[0].point);
    orientation.set(cube.rotation.x, cube.rotation.y, cube.rotation.z);
    if (CutDirection === CutDirectionModes.MESIALLY) {
      if (center.x < 0) {
        orientation.z = orientation.z + 0.96 * -isInner;
      } else {
        orientation.z = orientation.z - 0.96 * -isInner;
      }
    } else if (CutDirection === CutDirectionModes.DISTALLY) {
      if (center.x < 0) {
        orientation.z = orientation.z - 0.96 * -isInner;
      } else {
        orientation.z = orientation.z + 0.96 * -isInner;
      }
    } else {
      orientation.z = orientation.z + 1.57 * -isInner;
    }

    size.x = 2.5;
    size.z = 5;
    const m = new THREE.Mesh(
      new DecalGeometry(
        mesh,
        cube.position,
        new Euler(orientation.x, orientation.y, orientation.z),
        size,
      ),
      decalCutMaterial,
    );
    m.name = "cut";
    decals.push(m);
    const t = new Vector3();
    mesh.children[4].add(m);
    // console.log(cutsAndButtons);
    cutsAndButtons[mesh.name.split("_")[1].substring(2, 5)].push(m);
    m.getWorldPosition(t);
    m.position.set(-t.x, -t.y, -t.z);
    cutANDButtonsList.push(m);
  } else {
    cube.position.sub(
      cube.position.clone().sub(collisionPoint[0].point).multiplyScalar(0.8),
    );
    cube.lookAt(collisionPoint[0].point);
    object.lookAt(collisionPoint[0].point);
    orientation.set(cube.rotation.x, cube.rotation.y, cube.rotation.z);

    if (CutDirection === CutDirectionModes.MESIALLY) {
      if (
        mesh.parent === group.current.children[globalCurrentStep * 2] ||
        mesh.parent.name.includes("u_")
      ) {
        // if top tooth

        if (isInner === 1) {
          if (position.x < 0) {
            orientation.z = orientation.z - 3.14 * -isInner;
          } else {
            orientation.z = orientation.z - 3.14 * -isInner;
          }
        } else {
          if (position.x < 0) {
            orientation.z = orientation.z - 3.14 * -isInner;
          } else {
            orientation.z = orientation.z - 3.14 * -isInner;
          }
        }
      } else {
        // if lower tooth

        if (isInner === 1) {
          if (position.x < 0) {
            orientation.z = orientation.z;
          } else {
            orientation.z = orientation.z;
          }
        } else {
          if (position.x < 0) {
            orientation.z = orientation.z;
          } else {
            orientation.z = orientation.z;
          }
        }
      }
    } else {
      // Distally
      if (
        mesh.parent.name.includes("u_") ||
        mesh.parent === group.current.children[globalCurrentStep * 2]
      ) {
        // if top tooth
        if (isInner === 1) {
          if (position.x < 0) {
            orientation.z = orientation.z - 3.14 * -isInner;
          } else {
            orientation.z = orientation.z - 3.14 * -isInner;
          }
        } else {
          if (position.x < 0) {
            orientation.z = orientation.z - 3.14 * -isInner;
          } else {
            orientation.z = orientation.z + 3.14 * -isInner;
          }
        }
      } else {
        // if lower tooth

        if (isInner === 1) {
          if (position.x < 0) {
            orientation.z = orientation.z * -isInner;
          } else {
            orientation.z = orientation.z * -isInner;
          }
        } else {
          if (position.x < 0) {
            orientation.z = orientation.z * -isInner;
          } else {
            orientation.z = orientation.z * -isInner;
          }
        }
      }
    }

    const r = collisionPoint[0].point
      .clone()
      .add(collisionPoint[0].face.normal.multiplyScalar(0.9));
    size.setY(size.y * 2);
    let first;
    let countery = 0;
    collisionPoint.forEach(item => {
      if (!item.object.name.includes("gum")) {
        if (!first) {
          first = item.point;
        } else {
          if (isInner === -1) size.z = first.distanceTo(item.point) + 2;
          else {
            size.z = first.distanceTo(item.point) - 4;
          }
          countery++;
        }
      }
    });
    // size.x = size.x / 4;
    size.x = THREE.MathUtils.clamp(size.y, 2.3, 3.75);
    // console.log("new size", size);

    // console.log(size, "size", mesh.name);

    const m = new THREE.Mesh(
      new DecalGeometry( // Button
        mesh,
        cube.position,
        new Euler(orientation.x, orientation.y, orientation.z),
        size,
      ),
      decalButtonMaterial,
    );
    m.name = "button";
    decals.push(m);
    const t = new Vector3();

    mesh.children[4].add(m);
    cutsAndButtons[mesh.name.split("_")[1].substring(2, 5)].push(m);
    m.getWorldPosition(t);
    m.position.set(-t.x, -t.y, -t.z);
    cutANDButtonsList.push(m);
  }

  //   const material3 = new THREE.MeshPhysicalMaterial({
  //     color: 0x00d4ff,
  //     metalness: 0.2,
  //     reflectivity: 25,
  //   });
  //   const cubet = new THREE.Mesh(geometry1, material3);
  //   cubet.position.set(
  //     collisionPoint[0].point.x,
  //     collisionPoint[0].point.y,
  //     collisionPoint[0].point.z
  //   );

  //   cubet.lookAt(
  //     collisionPoint[0].point.clone().sub(collisionPoint[0].face.normal)
  //   );
  //   scene.add(cubet);
  // }
}

function copyElasticsToSplit() {
  const maxStepNumber = splitGroup.current.children.length - 2;
  for (let isLower = 0; isLower < 2; isLower++) {
    for (
      let i = 0;
      i < group.current.children[maxStepNumber + isLower].children.length;
      i++
    ) {
      if (
        typeof splitGroup.current.children[maxStepNumber + isLower].children[
          i
        ] === "Mesh"
      ) {
        splitGroup.current.children[maxStepNumber + isLower].children[
          i
        ].children[4] =
          group.current.children[maxStepNumber + isLower].children[
            i
          ].children[4].clone();
        splitGroup.current.children[maxStepNumber + isLower].children[
          i
        ].children[4].position.copy(splitGroup.current.position);
      }
    }
  }
}
