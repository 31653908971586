import {
  axiosForMFG,
  fetchTSUrlsForEonAccess,
  fetchTSUrlsForEonMfg,
} from "@/api/TS-API";
import { getParams } from "./getParams";

export const apiResolver = async () => {
  const { treatmentSetupsId, isM, isO } = getParams();
  let ownerType = "doctor";
  if (isO === "c") {
    ownerType = "client";
  } else if (isO === "d") {
    ownerType = "doctor";
  }
  try {
    let response;
    let isMFG = isM === "true";
    if (isMFG) {
      response = await fetchTSUrlsForEonMfg(treatmentSetupsId, ownerType).then(
        res => {
          return res.data;
        },
      );
    } else {
      response = await fetchTSUrlsForEonAccess(treatmentSetupsId).then(res => {
        return res.data;
      });
    }
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log("response error message =>", error.message);
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log("request error message =>", error.message);
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
  }
};

export const getDoctorConfig = async uuid => {
  try {
    const { data } = await axiosForMFG.get(
      `/external/viewer/config/doctor/${uuid}`,
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getJSON = (url, callback) => {
  const xhr = new XMLHttpRequest();
  xhr.open("GET", url, true);
  xhr.responseType = "json";
  xhr.onload = function () {
    if (xhr.status === 200) {
      callback(null, xhr.response);
    } else {
      callback(xhr.status, xhr.response);
    }
  };
  xhr.send();
};

export const getTSUrlsForEonMfg = async () => {
  const { treatmentSetupsId } = getParams();

  try {
    const response = await axiosForMFG.get(
      `/external/viewer/plan/${treatmentSetupsId}/tsqcResult`,
    );

    // Assuming the response data is what you need
    return response.data;
  } catch (error) {
    if (error.response.status === 404) {
      // Handle 404 error
      return "do nothing";
    } else {
      // Handle other errors
      console.log(error);
      throw error; // Rethrow the error for further handling
    }
  }
};

export const getIssueTypes = async () => {
  try {
    const response = await axiosForMFG.get(`/external/viewer/tsqc/issueTypes`);

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
