import mixpanel from "mixpanel-browser";

import { checkRevisionMethod, generateModificationsList } from "./helpers";
import { getParams } from "@/helpers/getParams";

mixpanel.init(process.env.REACT_APP_MIXPANEL_ID, {
  debug: process.env.NODE_ENV !== "production" ? true : false,
  track_pageview: false,
});
/// set super props
export const setSuperProps = (props) => {
  const { clientID, doctorID } = props;
  mixpanel.register({ "Client ID": clientID, "Doctor ID": doctorID });
};
export const StartRevision = (logsData, TPdata, action) => {
  const modifications =
    Object.keys(logsData?.logs)?.length > 0
      ? generateModificationsList(logsData?.logs)
      : [];
  const trackedData = {
    "TS UUID": getParams()?.treatmentSetupsId || "",
    "Revision Method": checkRevisionMethod(logsData),
    Modfications: modifications,
    "No.of steps": Math.max(TPdata?.lowerSteps, TPdata?.upperSteps),
    "Major Version No": TPdata?.version?.toString()?.split(".")?.[0],
    Action: action,
  };
  mixpanel.track("Start Revision", trackedData);
};
