import React, { useEffect } from "react";
import { useGlobalStore } from "@/store";
import { useCustomTranslation } from "@/hooks/useTranslation";

function Records({ photosXrays }) {
  const { setToggle } = useGlobalStore(state => ({
    setToggle: state.setToggle,
  }));
  const { content } = useCustomTranslation();

  const getContent = (group, key) => {
    return content(`other.${group}.${key}`);
  };
  const photos = Object.values(photosXrays).map((image, index) => {
    if (image) {
      return (
        <div key={index} className="px-4 py-2">
          <img src={image} />
        </div>
      );
    }
  });
  return (
    <div className="flex h-full flex-col p-[14px]">
      <div className="flex justify-between">
        <div className="text-lg font-medium text-[#242426]">
          {getContent("labels", "patient_photos_x_rays")}
        </div>
        <div
          className="text-l z-[2] cursor-pointer text-[#898A8D]"
          onClick={() => setToggle("showRecords")}
        >
          &#x2715;
        </div>
      </div>
      <div className="bounty-scrollbar relative mt-1 block h-[calc(100%-10px)] max-h-fit w-full overflow-auto">
        {photos}
      </div>
    </div>
  );
}

export default Records;
