import React from "react";
import "./checkbox.scss";

const CustomCheckBox = ({ id, title, checked, onChange, disabled  }) => {

  return (
    <div className="form-group">
      <input
        type="checkbox"
        id={id}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      />
      <label htmlFor={id} className="movement_text">
        {title}
      </label>
    </div>
  );
};

export default CustomCheckBox;
