import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import * as THREE from "three";
import { useThree, useFrame } from "@react-three/fiber";
import {
  InjectDrawCutsAndButtonsData,
  SetElasticsGLBData,
  SetGroup,
  StartExecution,
  PrepareCutsAndButtonsData,
} from "./Elastics";
import {
  computeBoundsTree,
  disposeBoundsTree,
  acceleratedRaycast,
} from "three-mesh-bvh";
import { placeIPRBetweenTeeth } from "@/helpers/ipr";
import { GUI } from "dat.gui";
import { getParams } from "@/helpers/getParams";
import { useGlobalStore } from "../../store";
import {
  PrepareTeethMovementPath,
  calculateTeethPositions,
} from "./teethControls/TeethPositionHelper";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import {
  addRemoveAttachment,
  onPointerMove,
  selectTooth,
  attachmentMaterial as newAttachmentMaterial,
} from "./teethControls/addRemoveAttachment";
import {
  assignPonticsMaterial,
  assignPonticsToGroup,
  ponticsTeethMaterial,
} from "./controls/Pontics";
import { globalBiteJump } from "./Scene";
import { useCustomTranslation } from "@/hooks/useTranslation";

THREE.BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
THREE.BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
THREE.Mesh.prototype.raycast = acceleratedRaycast;

let BJGroup;
let optionCounter = 0;
let isElasticsGroupAssigned = false,
  isPonticsGroupAssigned = false;
let counter = 0;
let once = 0;
let guiCounter = 0;
let conf = { color: "#fff9f9" }; //teeth color
let conf1 = { color: "#2e2e2e" }; // specular color
let conf5 = { color: "#303030" }; // emissive gum color
let conf2 = { color: "#ff9090" }; // gum color
let conf3 = { color: "#ff93ab" }; // emissive color
let conf4 = { color: "#ffe2e6" }; // Spec gum color
const turnGUIOn = false;
let rootsAreCreated = false;
const turnCutsOn = false;

let oldOptions = {};
let orbitControlSwitch = true;
let attachmentsList = [];
let globalCurrentStep;
let selectedAttachment;
// let selectedAttachmentOffset;
const tempParent = new THREE.Group();
const geometry = new THREE.BufferGeometry();
const line = new THREE.Line(geometry, new THREE.LineBasicMaterial());
export let attachmentMaterial = new THREE.MeshPhysicalMaterial({
  color: 0xef8e9a,
  roughness: 0,
  transparent: false,
  side: THREE.DoubleSide,
});
let gumMaterial;

let meshGroup;

export let teethMaterial, selectedTeethMaterial, lockedTeethMaterial;

function Mesh({
  tploaded,
  view,
  maxStepNumber,
  GLBData: GLBData,
  perspectiveCameraRef,
  controlsRef,
  iprOverLays,
  setIprOverlays,
  numberingOverLays,
  model,
  setModel,
  isViewerControlsEnabled,
  teethTransformationData,
  ponticsData,
  setAutoSave,
  biteJumpsSteps,
}) {
  const { dentalNotation } = getParams();
  const groupRef = React.useRef(null);

  const {
    canvas,
    setRefs,
    setControls3D,
    groupRef2,
    selectedTooth,
    selectedAttachmentType,
    toggle,
    currentStep,
    logs,
    setLogs,
    setScene,
    attachmentArray,
    setToggle,
  } = useGlobalStore(state => ({
    selectedAttachmentType: state.controls3D.selectedAttachmentType,
    selectedTooth: state.controls3D.selectedTooth,
    setRefs: state.setRefs,
    groupRef2: state.refs.groupRef2,
    canvas: state.refs.canvas,
    controlsRef: state.refs.controlsRef,
    setControls3D: state.setControls3D,
    toggle: state.toggle,
    currentStep: state.stepperControls.currentStep,
    logs: state.logs,
    setLogs: state.setLogs,
    setScene: state.setScene,
    attachmentArray: state.controls3D.attachmentArray,
    setToggle: state.setToggle,
  }));

  if (!teethMaterial) {
    teethMaterial = new THREE.MeshPhysicalMaterial({
      roughness: 0.4,
      reflectivity: 8,
      specularIntensity: 3,
      specularColor: 0x2e2e2e,
      emissiveIntensity: 1,

      color: conf.color,
      emissive: conf5.color,
      side: THREE.DoubleSide,
      flatShading: false,
      clearcoat: 0.8,
      name: "default",
    });
    assignPonticsMaterial(
      new THREE.MeshPhysicalMaterial({
        roughness: 0.4,
        reflectivity: 4,
        specularIntensity: 1.5,
        specularColor: 0x2e2e2e,
        emissiveIntensity: 1,

        color: 0xbdbdbd,
        emissive: conf5.color,
        side: THREE.DoubleSide,
        flatShading: false,
        clearcoat: 0.8,
      }),
    );
  }
  if (!selectedTeethMaterial) {
    selectedTeethMaterial = new THREE.MeshPhysicalMaterial({
      roughness: 0.4,
      reflectivity: 12,
      specularIntensity: 2,
      specularColor: "#00008B",
      emissiveIntensity: 5,
      //   emissive: conf5.color,
      color: 0xc2e2ff,
      side: THREE.DoubleSide,
      flatShading: false,
      clearcoat: 0.8,
      name: "selected",
    });
  }
  if (!lockedTeethMaterial) {
    lockedTeethMaterial = new THREE.MeshPhysicalMaterial({
      roughness: 0.4,
      reflectivity: 4,
      specularIntensity: 3,
      specularColor: 0x2e2e2e,
      emissiveIntensity: 1,
      // emissive: conf5.color,
      color: "darkgray",
      side: THREE.DoubleSide,
      flatShading: false,
      clearcoat: 0.8,
      name: "locked",
    });
  }
  attachmentMaterial.color.setHex(0xef8e9a);

  React.useEffect(() => {
    setRefs({
      groupRef,
    });
  }, []);

  if (!optionCounter) {
    oldOptions = toggle;
    optionCounter++;
  }

  if (groupRef.current) {
    if (!isElasticsGroupAssigned) {
      isElasticsGroupAssigned = true;
      SetGroup(groupRef, groupRef2);
      SetElasticsGLBData(GLBData);
      PrepareCutsAndButtonsData();
      InjectDrawCutsAndButtonsData();
      StartExecution();
    }
    if (!isPonticsGroupAssigned) {
      isPonticsGroupAssigned = true;
      assignPonticsToGroup(groupRef);
    }
  }
  if (toggle.occ) {
    teethMaterial.transparent = true;
    teethMaterial.opacity = 0.7;
    teethMaterial.side = THREE.FrontSide;
    teethMaterial.needsUpdate = true;

    ponticsTeethMaterial.transparent = true;
    ponticsTeethMaterial.opacity = 0.7;
    ponticsTeethMaterial.side = THREE.FrontSide;
    ponticsTeethMaterial.needsUpdate = true;
  } else {
    teethMaterial.transparent = false;
    teethMaterial.side = THREE.DoubleSide;
    teethMaterial.opacity = 1;
    teethMaterial.needsUpdate = true;

    ponticsTeethMaterial.transparent = false;
    ponticsTeethMaterial.side = THREE.DoubleSide;
    ponticsTeethMaterial.opacity = 1;
    ponticsTeethMaterial.needsUpdate = true;
  }
  if (toggle.numbering) {
    attachmentMaterial.transparent = true;
    attachmentMaterial.opacity = 0.5;
    newAttachmentMaterial.opacity = 0.5;
    newAttachmentMaterial.needsUpdate = true;
    attachmentMaterial.side = THREE.DoubleSide;
    attachmentMaterial.needsUpdate = true;
    newAttachmentMaterial.opacity = 0.5;
    newAttachmentMaterial.transparent = true;
    newAttachmentMaterial.opacity = 0.5;
    newAttachmentMaterial.side = THREE.DoubleSide;
    newAttachmentMaterial.needsUpdate = true;
  } else {
    attachmentMaterial.transparent = false;
    attachmentMaterial.side = THREE.DoubleSide;
    attachmentMaterial.opacity = 1;
    newAttachmentMaterial.needsUpdate = true;
    attachmentMaterial.needsUpdate = true;
    newAttachmentMaterial.transparent = false;
    newAttachmentMaterial.side = THREE.DoubleSide;
    newAttachmentMaterial.opacity = 1;
    newAttachmentMaterial.needsUpdate = true;
  }

  if (toggle.attachment) {
    attachmentMaterial.visible = true;
    newAttachmentMaterial.visible = true;
  } else {
    attachmentMaterial.visible = false;
    newAttachmentMaterial.visible = false;
  }
  if (!gumMaterial) {
    gumMaterial = new THREE.MeshPhysicalMaterial({
      roughness: 0.35,
      reflectivity: 0.65,
      specularIntensity: 0.35,
      specularColor: conf4.color,
      color: conf2.color,
      emissive: conf3.color,
      emissiveIntensity: 0.15,
      side: THREE.DoubleSide,
      opacity: toggle.show3DControls ? 0 : 1,
      depthWrite: toggle.show3DControls ? false : true,
      transparent: true,
    });
  } else {
    gumMaterial.opacity = toggle.show3DControls ? 0 : 1;
    gumMaterial.depthWrite = toggle.show3DControls ? false : true;
  }

  const centering_group = new THREE.Group();
  const scene = useThree().scene;
  const camera = useThree().camera;
  const pointer = new THREE.Vector2();
  let raycaster = new THREE.Raycaster();
  const lineGroup = new THREE.Group();
  lineGroup.name = "occ_group";
  const lineGroup2 = new THREE.Group();
  lineGroup2.name = "occ_group2";
  const lineGeometry = new THREE.BufferGeometry();
  const lineOcc = new THREE.LineSegments(
    lineGeometry,
    new THREE.LineBasicMaterial({ color: 0xe91e63 }),
  );
  function myCallbackFunction() {
    reflectIPRVision();
  }
  if (controlsRef?.current) {
    controlsRef.current.addEventListener("control", myCallbackFunction);
  }
  useEffect(() => {
    //console.log("wat?");
    setTimeout(() => {
      myCallbackFunction();
    }, 500);
  }, [toggle.showUpper, toggle.showLower, view]);

  // let c = [];
  // if (groupRef.current != null) {
  //   c = [];
  //   groupRef.current.children.forEach(child => {
  //     if (child.visible) {
  //       c.push(child);
  //     }
  //   });
  // }
  globalCurrentStep = currentStep;
  if (!meshGroup) {
    meshGroup = PrepareMesh(GLBData, ponticsData);
  }
  // if (!counter && turnCutsOn) {
  //   line.material.color = "black";

  //   geometry.setFromPoints([new THREE.Vector3(), new THREE.Vector3()]);
  //   scene.add(line);
  //   line.visible = false;
  //   // window.addEventListener(
  //   //   "pointerdown",
  //   //   function (e) {
  //   //     if (selectedAttachment !== null) {
  //   //       selectedAttachment = null;
  //   //       return;
  //   //     }
  //   //     if (!orbitControlSwitch) {
  //   //       let activeAttachmentsList = [];
  //   //       for (let index2 = 0; index2 < 2; index2++) {
  //   //         for (
  //   //           let index = 0;
  //   //           index <
  //   //           groupRef.current.children[globalCurrentStep * 2 + index2].children
  //   //             .length;
  //   //           index++
  //   //         ) {
  //   //           if (
  //   //             groupRef.current.children[globalCurrentStep * 2 + index2]
  //   //               .children[index].children.length > 0
  //   //           ) {
  //   //             groupRef.current.children[
  //   //               globalCurrentStep * 2 + index2
  //   //             ].children[index].children.forEach(attachment => {
  //   //               activeAttachmentsList.push(attachment);
  //   //             });
  //   //           }
  //   //         }
  //   //       }

  //   //       if (activeAttachmentsList.length > 0) {
  //   //         checkIntersection(
  //   //           e.clientX,
  //   //           e.clientY,
  //   //           perspectiveCameraRef.current,
  //   //           activeAttachmentsList,
  //   //         );
  //   //       }
  //   //     }

  //   //     if (!orbitControlSwitch) {
  //   //       let activeTeethList = [];

  //   //       for (let index2 = 0; index2 < 2; index2++) {
  //   //         groupRef.current.children[
  //   //           globalCurrentStep * 2 + index2
  //   //         ].children.forEach(tooth => {
  //   //           activeTeethList.push(tooth);
  //   //         });
  //   //       }

  //   //       if (activeTeethList.length > 0) {
  //   //         checkIntersection2(
  //   //           e.clientX,
  //   //           e.clientY,
  //   //           perspectiveCameraRef.current,
  //   //           activeTeethList,
  //   //         );
  //   //       }
  //   //     }
  //   //   },
  //   //   false,
  //   // );

  //   window.addEventListener(
  //     "keydown",
  //     function (e) {
  //       if (e.key === "t" || e.key === "T") {
  //         orbitControlSwitch = !orbitControlSwitch;
  //         selectedAttachment = null;
  //         controlsRef.current._enabled = orbitControlSwitch;
  //       }
  //       return;
  //       if (e.key === "6" && !orbitControlSwitch) {
  //         selectedAttachment.object.position.x += 0.1;
  //       }
  //       if (e.key === "4" && !orbitControlSwitch) {
  //         selectedAttachment.object.position.x -= 0.1;
  //       }
  //       if (e.key === "8" && !orbitControlSwitch) {
  //         selectedAttachment.object.position.y += 0.1;
  //       }
  //       if (e.key === "2" && !orbitControlSwitch) {
  //         selectedAttachment.object.position.y -= 0.1;
  //       }
  //     },
  //     false,
  //   );

  //   window.addEventListener(
  //     "mousemove",
  //     function (e) {
  //       if (selectedAttachment != null) {
  //         // work here
  //         getRayCollisionPoint(
  //           e.clientX,
  //           e.clientY,
  //           perspectiveCameraRef.current,
  //           [
  //             ...groupRef.current.children[globalCurrentStep * 2].children,
  //             ...groupRef.current.children[globalCurrentStep * 2 + 1].children,
  //           ],
  //         );
  //       }
  //     },
  //     false,
  //   );
  //   counter++;
  // }

  /**
   * Hides and unhide IPRs based on angle between the cam and the IPR
   */
  function reflectIPRVision() {
    const angleAllowed = 90;
    let inc = 1;
    let gumCenter = new THREE.Vector3(),
      tempCasher = new THREE.Vector3();
    let state = false;
    for (
      let i = (maxStepNumber + 1) * 2;
      i < groupRef.current.children.length;
      i += 1
    ) {
      if (groupRef.current.children[i].name.includes("line")) {
        if (inc === 1) {
          inc = 2;
          const box3 = new THREE.Box3().setFromObject(
            groupRef.current.children[0],
          );
          box3.getCenter(gumCenter);
        }
        const box3 = new THREE.Box3().setFromObject(
          groupRef.current.children[i],
        );
        box3.getCenter(tempCasher);

        const result = Math.abs(
          (Math.atan2(camera.position.x, camera.position.z) -
            Math.atan2(gumCenter.x, gumCenter.z)) *
            (180 / Math.PI) -
            (Math.atan2(tempCasher.x, tempCasher.z) -
              Math.atan2(gumCenter.x, gumCenter.z)) *
              (180 / Math.PI),
        );
        if (toggle.showUpper !== toggle.showLower) {
          if (toggle.showUpper === false) {
            if (groupRef.current.children[i].name.charAt(0) === "u") {
              groupRef.current.children[i].visible = false;
              state = false;
              continue;
            }
          } else {
            if (groupRef.current.children[i].name.charAt(0) === "l") {
              groupRef.current.children[i].visible = false;
              state = false;
              continue;
            }
          }
        }
        if (result > angleAllowed) {
          groupRef.current.children[i].visible = false;
          state = false;
        } else {
          groupRef.current.children[i].visible = true;
          state = true;
        }
      } else {
        if (groupRef.current.children[i].name.includes("ipr")) {
          groupRef.current.children[i].visible = state;
        }
      }
    }
  }

  function createRootAnchors(teethTransformationData) {
    const loader = new GLTFLoader();
    loader.load("RootAnchors.glb", function (rootAnchor) {
      const maxStepNumber = GLBData.length - 1;
      const parentUpper = groupRef.current.children[maxStepNumber * 2];
      const parentLower = groupRef.current.children[maxStepNumber * 2 + 1];
      let cloneHolder;

      parentUpper.children.forEach(item => {
        let teethNumberMinusOne = item.name.split("_")[2].substring(1) - 1;
        if (teethTransformationData[teethNumberMinusOne]) {
          cloneHolder = rootAnchor.scene.children[0].clone();
          cloneHolder.scale.set(100, 100, 100);
          cloneHolder.name = "rootAnchor";
          cloneHolder.updateMatrixWorld();
          const geometry = new THREE.BoxGeometry(1, 1, 1);
          const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
          const cube = new THREE.Mesh(geometry, material);
          const cube1 = new THREE.Mesh(geometry, material);
          cube.position.set(
            teethTransformationData[teethNumberMinusOne]
              .ToothRotationCenter[0] + parentUpper.parent.position.x,
            teethTransformationData[teethNumberMinusOne]
              .ToothRotationCenter[1] + parentUpper.parent.position.y,
            teethTransformationData[teethNumberMinusOne]
              .ToothRotationCenter[2] + parentUpper.parent.position.z,
          );
          cube1.position.set(
            cube.position.x -
              teethTransformationData[teethNumberMinusOne].Up[0] * 10,
            cube.position.y -
              teethTransformationData[teethNumberMinusOne].Up[1] * 10,
            cube.position.z -
              teethTransformationData[teethNumberMinusOne].Up[2] * 10,
          );

          cloneHolder.position.set(
            cube.position.x,
            cube.position.y,
            cube.position.z,
          );
          cloneHolder.lookAt(cube1.position);
          item.children[3].attach(cloneHolder);

          cloneHolder.updateMatrixWorld();
          cloneHolder.visible = true;
        }
      });
      parentLower.children.forEach(item => {
        let teethNumberMinusOne = item.name.split("_")[2].substring(1) - 1;
        if (teethTransformationData[teethNumberMinusOne]) {
          cloneHolder = rootAnchor.scene.children[0].clone();
          cloneHolder.scale.set(100, 100, 100);
          cloneHolder.name = "rootAnchor";
          cloneHolder.updateMatrixWorld();
          const geometry = new THREE.BoxGeometry(1, 1, 1);
          const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
          const cube = new THREE.Mesh(geometry, material);
          const cube1 = new THREE.Mesh(geometry, material);
          cube.position.set(
            teethTransformationData[teethNumberMinusOne]
              .ToothRotationCenter[0] + parentLower.parent.position.x,
            teethTransformationData[teethNumberMinusOne]
              .ToothRotationCenter[1] + parentLower.parent.position.y,
            teethTransformationData[teethNumberMinusOne]
              .ToothRotationCenter[2] + parentLower.parent.position.z,
          );
          cube1.position.set(
            cube.position.x -
              teethTransformationData[teethNumberMinusOne].Up[0] * 10,
            cube.position.y -
              teethTransformationData[teethNumberMinusOne].Up[1] * 10,
            cube.position.z -
              teethTransformationData[teethNumberMinusOne].Up[2] * 10,
          );

          cloneHolder.position.set(
            cube.position.x,
            cube.position.y,
            cube.position.z,
          );
          cloneHolder.lookAt(cube1.position);
          item.children[3].attach(cloneHolder);

          cloneHolder.rotateZ(3.14);
          cloneHolder.visible = true;
        }
      });
    });
  }
  function getRayCollisionPoint(x, y, camera, collisionList) {
    const intersection = {
      intersects: false,
      point: new THREE.Vector3(),
      normal: new THREE.Vector3(),
    };
    let mouseHelper = new THREE.Mesh(
      new THREE.BoxGeometry(1, 1, 10),
      new THREE.MeshNormalMaterial(),
    );
    const mouse = new THREE.Vector2();
    let intersects = [];
    let raycaster = new THREE.Raycaster();
    mouse.x = (x / window.innerWidth) * 2 - 1;
    mouse.y = -(y / window.innerHeight) * 2 + 1;
    raycaster.setFromCamera(mouse, camera);

    raycaster.intersectObjects(collisionList, false, intersects);
    let teethHolder;
    if (intersects.length > 0) {
      teethHolder = intersects[0];
      if (teethHolder.object != selectedAttachment.object.parent) {
        return;
      }
      const p = teethHolder.point;
      mouseHelper.position.copy(p);
      intersection.point.copy(p);
      const n = teethHolder.face.normal.clone();
      n.transformDirection(teethHolder.object.matrixWorld);
      n.multiplyScalar(10);
      n.add(teethHolder.point);

      intersection.normal.copy(teethHolder.face.normal);
      mouseHelper.lookAt(n);
      line.visible = true;
      setPositionToWorld(
        selectedAttachment.object,
        new THREE.Vector3(p.x, p.y, p.z),
        n,
      );
      // selectedAttachment.object.position.set(p.x, p.y, p.z);
      //positions.setXYZ(1, n.x, n.y, n.z);
      //selectedAttachment.needsUpdate = true;

      intersection.intersects = true;

      intersects.length = 0;
    } else {
      intersection.intersects = false;
    }
  }

  //const normalContactThreshold = -10;
  const heavyContactThreshold = 0;
  if (!rootsAreCreated && teethTransformationData) {
    setTimeout(() => {
      createRootAnchors(teethTransformationData);
    }, 1000);
    rootsAreCreated = true;
  }
  if (turnGUIOn) {
    if (guiCounter == 0) {
      guiCounter++;
      const gui = new GUI();
      gui.addColor(conf, "color").onChange(function (values) {
        teethMaterial.color = conf;
        updateState({});

        // heavyContactThreshold = conf.number;
      });
      gui.addColor(conf1, "color").onChange(function (values) {
        attachmentMaterial.specularColor = conf1;
        updateState({});

        // heavyContactThreshold = conf.number;
      });
      gui.addColor(conf5, "color").onChange(function (values) {
        teethMaterial.emissive = conf5;
        updateState({});
        teethMaterial.needsUpdate = true;

        // heavyContactThreshold = conf.number;
      });
      gui.addColor(conf2, "color").onChange(function (values) {
        gumMaterial.color = conf2;

        updateState({});
        // heavyContactThreshold = conf.number;
      });
      gui.addColor(conf3, "color").onChange(function (values) {
        gumMaterial.emissive = conf3;
        updateState({});

        // heavyContactThreshold = conf.number;
      });

      gui.addColor(conf4, "color").onChange(function (values) {
        gumMaterial.specularColor = conf4;
        updateState({});

        // heavyContactThreshold = conf.number;
      });
    }
  }

  const bgLineHeavyContactMaterial = new THREE.LineBasicMaterial({
    color: "red",
    side: THREE.DoubleSide,
    polygonOffset: true,
    polygonOffsetFactor: 1,
    polygonOffsetUnits: 1,
    blending: THREE.CustomBlending,
  });

  const bgLineNormalContactMaterial = new THREE.LineBasicMaterial({
    color: "blue",
    side: THREE.DoubleSide,
    polygonOffset: true,
    polygonOffsetFactor: 1,
    polygonOffsetUnits: 1,
    blending: THREE.CustomBlending,
    linewidth: 1,
  });

  let center = { x: 0, y: 0, z: 0 };

  function setPositionToWorld(object, position, lookAtPosition) {
    if (true) {
      // lookAtPosition.add(selectedAttachmentOffset);
      let box3 = new THREE.Box3().setFromObject(object);
      const point = new THREE.Vector3(); // attachment center
      box3.getCenter(point);

      const movementAmount = position.sub(point); // amount needed to move to reach the target position

      let objectPos = object.position.clone(); // local attachment pos
      let objectWorldPos = new THREE.Vector3(); // world attachment pos
      object.getWorldPosition(objectWorldPos);
      point.sub(objectWorldPos);

      object.position.set(0, 0, 0);
      object.lookAt(lookAtPosition);

      object.position.set(objectPos.x, objectPos.y, objectPos.z);
      let toWorldOffset = objectPos.sub(objectWorldPos); //

      let box31 = new THREE.Box3().setFromObject(object);
      const point2 = new THREE.Vector3(); // attachment center
      box31.getCenter(point2);

      const newObjectWorldPosition = point.add(objectWorldPos); // distance btw attachment and pivot point
      newObjectWorldPosition.add(toWorldOffset);

      point2.sub(objectWorldPos);
      newObjectWorldPosition.sub(point2);
      newObjectWorldPosition.add(movementAmount);

      // objectWorldPos = point2;
      // point2.add(toWorldOffset);

      // objectWorldPos.sub(t); // move the world pos by attachment distance
      // objectWorldPos.add(toWorldOffset); // make the world position to local pos

      //objectWorldPos.add(selectedAttachmentOffset);
      //objectWorldPos.add(t.x, t.y, t.z);
      object.position.set(
        newObjectWorldPosition.x,
        newObjectWorldPosition.y,
        newObjectWorldPosition.z,
      );
      once++;
    }
  }

  function checkIntersection(x, y, camera, collisionList) {
    const intersection = {
      intersects: false,
      point: new THREE.Vector3(),
      normal: new THREE.Vector3(),
    };
    let mouseHelper = new THREE.Mesh(
      new THREE.BoxGeometry(1, 1, 10),
      new THREE.MeshNormalMaterial(),
    );
    const mouse = new THREE.Vector2();
    let intersects = [];
    let raycaster = new THREE.Raycaster();
    mouse.x = (x / window.innerWidth) * 2 - 1;
    mouse.y = -(y / window.innerHeight) * 2 + 1;
    raycaster.setFromCamera(mouse, camera);

    raycaster.intersectObjects(collisionList, false, intersects);
    let attachmentHolder;
    if (intersects.length > 0) {
      attachmentHolder = intersects[0];
      selectedAttachment = attachmentHolder;
      const p = attachmentHolder.point;
      mouseHelper.position.copy(p);
      intersection.point.copy(p);
      const n = attachmentHolder.face.normal.clone();
      n.transformDirection(attachmentHolder.object.matrixWorld);
      n.multiplyScalar(10);
      n.add(attachmentHolder.point);

      intersection.normal.copy(attachmentHolder.face.normal);
      mouseHelper.lookAt(n);

      intersection.intersects = true;

      intersects.length = 0;
      selectedAttachmentOffset = selectedAttachment.object.position
        .clone()
        .sub(p)
        .clone();
    } else {
      intersection.intersects = false;
    }
  }

  function checkIntersection2(x, y, camera, collisionList) {
    const intersection = {
      intersects: false,
      point: new THREE.Vector3(),
      normal: new THREE.Vector3(),
    };
    let mouseHelper = new THREE.Mesh(
      new THREE.BoxGeometry(1, 1, 10),
      new THREE.MeshNormalMaterial(),
    );
    const mouse = new THREE.Vector2();
    let intersects = [];
    let raycaster = new THREE.Raycaster();
    mouse.x = (x / window.innerWidth) * 2 - 1;
    mouse.y = -(y / window.innerHeight) * 2 + 1;
    raycaster.setFromCamera(mouse, camera);

    raycaster.intersectObjects(collisionList, false, intersects);
    let attachmentHolder;
    if (intersects.length > 0) {
      attachmentHolder = intersects[0];
      selectedAttachment = attachmentHolder;
      const p = attachmentHolder.point;
      mouseHelper.position.copy(p);
      intersection.point.copy(p);
      const n = attachmentHolder.face.normal.clone();
      var ngeometry = new THREE.SphereGeometry();

      n.transformDirection(attachmentHolder.object.matrixWorld);
      n.multiplyScalar(10);
      n.add(attachmentHolder.point);

      intersection.normal.copy(attachmentHolder.face.normal);
      mouseHelper.lookAt(n);

      intersection.intersects = true;

      intersects.length = 0;
      selectedAttachmentOffset = selectedAttachment.object.position
        .clone()
        .sub(p)
        .clone();
    } else {
      intersection.intersects = false;
    }
  }
  useEffect(() => {
    console.log("tp load call");
    if (!tploaded) {
      groupRef.current.children.forEach((child, i) => {
        if (child.name) {
          if (child.name.includes("st")) {
            centering_group.add(child.clone());
          }
          if (child.children.length > 0) {
            child.children.forEach(teeth => {
              // teeth.geometry = mergeVertices(teeth.geometry);
              teeth.geometry.computeVertexNormals();
              teeth?.children[0]?.children.forEach(attachment => {
                attachmentsList.push(attachment);
                // attachment.geometry = mergeVertices(attachment.geometry);
                attachment.geometry.computeVertexNormals();
              });
            });
          }
          //child.geometry = mergeVertices(child.geometry);
          child.geometry?.computeVertexNormals();
        }
      });

      const aabb = new THREE.Box3();
      aabb.setFromObject(centering_group);
      center = new THREE.Vector3(0, 0, 0);
      aabb.getCenter(center);

      setCenterVec({ x: center.x, y: center.y, z: center.z });
      groupRef.current.position.set(-center.x, -center.y, -center.z);
      BJGroup.position.set(-center.x, -center.y, -center.z);
      groupRef.current.updateMatrixWorld();
      // if (
      //   true &&
      //   groupRef.current
      //   // Object.values(GLBData[0].lower?.gum).length > 0 &&
      //   // Object.values(GLBData[0].upper?.gum).length > 0
      // ) {
      //   PrepareTeethMovementPath(groupRef, GLBData);
      //   calculateTeethPositions(GLBData.length - 1, groupRef);
      // }
      addRemoveAttachment(scene, perspectiveCameraRef, groupRef.current, {
        x: center.x,
        y: center.y,
        z: center.z,
      });
    }
  }, [tploaded]);
  const [centerVec, setCenterVec] = useState({ x: 0, y: 0, z: 0 });
  function setLinePosition() {
    let tempLineGroup, tempLineGroup2;
    groupRef.current.children.forEach(child => {
      if (child.name == "occ_group") tempLineGroup = child;
    });
    groupRef2.current?.children.forEach(child => {
      if (child.name == "occ_group2") tempLineGroup2 = child;
    });
    if (tempLineGroup != undefined) {
      if (toggle.showLower && !toggle.showUpper) {
        tempLineGroup.position.set(0, 0.025, 0);
        if (toggle.split || toggle.compareModifications) {
          tempLineGroup2.position.set(0, 0.025, 0);
        }
      } else if (!toggle.showLower && toggle.showUpper) {
        tempLineGroup.position.set(0, -0.025, 0);
        if (toggle.split || toggle.compareModifications) {
          tempLineGroup2.position.set(0, -0.025, 0);
        }
      } else {
        if (tempLineGroup === undefined) return;
        tempLineGroup.position.set(0, 0, 0);
        if (
          (toggle.split || toggle.compareModifications) &&
          tempLineGroup2 === undefined
        ) {
          tempLineGroup2.position.set(0, 0, 0);
        }
      }
    }
    oldOptions = toggle;
  }

  useEffect(() => {
    // placeNumbersOnTeeth(
    //   groupRef,
    //   groupRef2,
    //   toggle,
    //   currentStep,
    //   numberingOverLays,
    //   dentalNotation,
    //   maxStepNumber,
    // );
    ReflectNumberingToggle();
  }, [
    currentStep,
    toggle.numbering,
    toggle.showUpper,
    toggle.showLower,
    dentalNotation,
  ]);
  useEffect(() => {
    return;
    console.log("Double check");
    groupRef.current.children.forEach(childGum => {
      if (childGum.name.includes("gum")) {
        childGum.children.forEach(child => {
          if (child.userData["isPontics"] === true) {
            if (toggle.pontics || true) {
              console.log(child, "horray");

              // child.material = ponticsTeethMaterial;
            } else {
              child.material.color.set(0xfffff5);
            }
          }
        });
      }
    });
    if (toggle.split || toggle.compareModifications) {
      groupRef2.current?.children.forEach(child => {
        if (child.userData["isPontics"]) {
          if (toggle.pontics || true) {
            console.log("horray2");
            child.material.color.set(0xd7d7d7);
          } else {
            child.material.color.set(0xfffff5);
          }
        }
      });
    }
  }, [toggle.pontics, toggle.split, toggle.compareModifications, currentStep]);
  const { content } = useCustomTranslation();

  const getContent = (group, key) => {
    return content(`other.${group}.${key}`);
  };
  useEffect(() => {
    //let t = Date.now();
    placeIPRBetweenTeeth(
      groupRef,
      toggle,
      currentStep,
      iprOverLays,
      toggle.startRevision,
      isViewerControlsEnabled,
      getContent,
    );
    //console.log("teeth IPR is ready, process time:", Date.now() - t);
  }, [
    currentStep,
    toggle.ipr,
    toggle.showUpper,
    toggle.showLower,
    iprOverLays,
    toggle.startRevision,
    toggle.compareModifications,
  ]);

  useLayoutEffect(() => {
    const maxStepNumber = GLBData.length - 1 - (globalBiteJump ? 1 : 0);
    if (groupRef.current != null) {
      if (!toggle.showLower && !toggle.showUpper) {
        groupRef.current.children[currentStep * 2].visible = true;
        groupRef.current.children[currentStep * 2 + 1].visible = true;
        if (
          groupRef2.current != null &&
          groupRef2.current.children != undefined
        ) {
          groupRef2.current.children[maxStepNumber * 2].visible = true;
          groupRef2.current.children[maxStepNumber * 2 + 1].visible = true;
        }
      } else if (toggle.showUpper) {
        groupRef.current.children[currentStep * 2].visible = true;
        groupRef.current.children[currentStep * 2 + 1].visible = false;
        if (
          groupRef2.current != null &&
          groupRef2.current.children != undefined
        ) {
          groupRef2.current.children[maxStepNumber * 2].visible = true;
          groupRef2.current.children[maxStepNumber * 2 + 1].visible = false;
        }
      } else {
        groupRef.current.children[currentStep * 2].visible = false;
        groupRef.current.children[currentStep * 2 + 1].visible = true;
        if (
          groupRef2.current != null &&
          groupRef2.current.children != undefined
        ) {
          groupRef2.current.children[maxStepNumber * 2].visible = false;
          groupRef2.current.children[maxStepNumber * 2 + 1].visible = true;
        }
      }
    }
  }, [
    currentStep,
    view,
    toggle.superImpose,
    toggle.showUpper,
    toggle.showLower,
    toggle.split,
    toggle.compareModifications,
  ]);

  function RenderCurrentStep() {
    if (!meshGroup) {
      console.log("empty mesh group");
      return;
    }
    for (let index = 0; index < (maxStepNumber + 1) * 2; index++) {
      if (!toggle.showLower && !toggle.showUpper) {
        meshGroup.children[index].visible =
          index === currentStep * 2 || index === currentStep * 2 + 1
            ? true
            : false;
      } else if (toggle.showLower) {
        meshGroup.children[index].visible =
          index === currentStep * 2 + 1 ? true : false;
      } else {
        meshGroup.children[index].visible =
          index === currentStep * 2 ? true : false;
      }
    }
    //super Impose
    // groupRef.current.children.forEach(elem => {
    //   console.log(elem.name);
    // });
    groupRef.current.children.forEach(elem => {
      if (elem.name.includes("Super")) {
        if (!toggle.showLower && !toggle.showUpper) {
          // Super_Impose_st0_lower
          // Super_Impose_st0_upper
          elem.visible = toggle.superImpose;
        } else if (toggle.showLower) {
          if (elem.name === "Super_Impose_st0_lower") {
            elem.visible = toggle.superImpose;
          } else {
            elem.visible = false;
          }
          groupRef.current.children[
            groupRef.current.children.length - 1
          ].visible = toggle.superImpose;
          groupRef.current.children[
            groupRef.current.children.length - 2
          ].visible = false;
        } else {
          if (elem.name === "Super_Impose_st0_upper") {
            elem.visible = toggle.superImpose;
          } else {
            elem.visible = false;
          }
        }
      }
    });
  }
  function ReflectNumberingToggle() {
    if (!meshGroup) {
      console.log("mesh group is empty");
      return;
    }
    //upper
    meshGroup.children[currentStep * 2].children.forEach(tooth => {
      tooth.children[1].visible = toggle.numbering;
    });
    //lower
    meshGroup.children[currentStep * 2 + 1].children.forEach(tooth => {
      tooth.children[1].visible = toggle.numbering;
    });
    if (!groupRef2?.current) {
      return;
    }

    groupRef2.current.children.forEach(gum => {
      if (gum.name.includes("gum")) {
        gum.children.forEach(tooth => {
          tooth.children[1].visible = toggle.numbering;
        });
      }
    });
  }
  function ApplyTeethMaterial() {
    if (!meshGroup) {
      console.log("mesh group is empty");
      return;
    }
    //upper
    meshGroup.children[currentStep * 2].children.forEach(tooth => {
      tooth.material =
        selectedTooth?.name === tooth.name
          ? selectedTooth?.userData["isLocked"]
            ? lockedTeethMaterial
            : selectedTeethMaterial
          : teethMaterial;
    });
    //lower
    meshGroup.children[currentStep * 2 + 1].children.forEach(tooth => {
      tooth.material =
        selectedTooth?.name === tooth.name
          ? selectedTooth?.userData["isLocked"]
            ? lockedTeethMaterial
            : selectedTeethMaterial
          : teethMaterial;
    });
  }
  function DrawContact(mesh1, mesh2, material, isSplit) {
    ////////////////////////////////////////////////////////
    let geometry;
    let matrix2to1 = new THREE.Matrix4()
      .copy(mesh1.matrixWorld)
      .invert()
      .multiply(mesh2.matrixWorld);
    const edge = new THREE.Line3();
    const results = [];
    mesh1.geometry.boundsTree.bvhcast(mesh2.geometry.boundsTree, matrix2to1, {
      intersectsTriangles(triangle1, triangle2) {
        if (triangle1.intersectsTriangle(triangle2, edge)) {
          const { start, end } = edge;
          results.push(start.x, start.y, start.z, end.x, end.y, end.z);
        }
      },
    });
    if (results.length) {
      geometry = lineOcc.geometry.clone();
      const posArray = geometry.attributes.position.array;
      if (posArray.length < results.length) {
        geometry.dispose();
        geometry.setAttribute(
          "position",
          new THREE.BufferAttribute(new Float32Array(results), 3, false),
        );
      } else {
        posArray.set(results);
      }

      geometry.setDrawRange(0, results.length / 3);
      geometry.attributes.position.needsUpdate = true;

      lineOcc.geometry = geometry.clone();
      const bgLine = lineOcc.clone();
      bgLine.material = material;

      material.renderOrder = 3;
      if (isSplit) {
        lineGroup2.add(bgLine);
      } else {
        lineGroup.add(bgLine);
      }
    }
  }
  function PrepareMesh(GLBData, ponticsData) {
    if (
      (GLBData[0].upper.teeth.length !== 0 &&
        GLBData[0].upper.teeth[0].children.length === 0) ||
      (GLBData[0].lower.teeth.length !== 0 &&
        GLBData[0].lower.teeth[0].children.length === 0)
    ) {
      console.log("GLB not ready");
      return;
    }
    let modelGroup = new THREE.Group();
    let gumHolder, toothHolder;
    GLBData.forEach((gingiva, stepNumber) => {
      gumHolder = new THREE.Group();
      if (gingiva.upper.gum?.name) {
        gumHolder = gingiva.upper.gum.clone();
        gumHolder.castShadow = true;
        gumHolder.receiveShadow = true;
        gumHolder.material = gumMaterial;
        gumHolder.name = gingiva.upper.gum.name;
        gumHolder.visible = stepNumber == currentStep ? true : false;
        let toothNumber;
        gingiva.upper.teeth.forEach((tooth, toothIndex) => {
          toothHolder = tooth;
          toothHolder.castShadow = true;
          toothHolder.receiveShadow = true;
          toothHolder.name = tooth.name;

          toothNumber = parseInt(
            toothHolder.name.split("_")[2].substring(1, 4),
          );

          if (ponticsData[toothNumber]) {
            toothHolder.material = ponticsTeethMaterial;
            toothHolder.userData["isPontics"] = true;
          } else {
            toothHolder.material = teethMaterial;
          }

          tooth.children[0].children.forEach(attachment => {
            attachment.castShadow = true;
            attachment.receiveShadow = true;
            attachment.name = attachment.name + "_att";
            attachment.visible = true;
            attachment.material = attachmentMaterial;
          });
          gumHolder.add(toothHolder.clone());
        });
      }
      modelGroup.add(gumHolder);
      gumHolder = new THREE.Group();

      if (gingiva.lower.gum?.name) {
        gumHolder = gingiva.lower.gum.clone();
        gumHolder.castShadow = true;
        gumHolder.receiveShadow = true;
        gumHolder.material = gumMaterial;
        gumHolder.name = gingiva.lower.gum.name;
        gumHolder.visible = stepNumber == currentStep ? true : false;
        let toothNumber;
        gingiva.lower.teeth.forEach((tooth, toothIndex) => {
          toothHolder = tooth;
          toothHolder.castShadow = true;
          toothHolder.receiveShadow = true;
          toothHolder.name = tooth.name;

          toothNumber = parseInt(
            toothHolder.name.split("_")[2].substring(1, 4),
          );

          if (ponticsData[toothNumber]) {
            toothHolder.material = ponticsTeethMaterial;
          } else {
            toothHolder.material = teethMaterial;
          }
          tooth.children[0].children.forEach(attachment => {
            attachment.castShadow = true;
            attachment.receiveShadow = true;
            attachment.name = attachment.name + "_att";
            attachment.visible = true;
            attachment.material = attachmentMaterial;
          });
          gumHolder.add(toothHolder.clone());
        });
      }
      modelGroup.add(gumHolder);
    });
    const superImposeMaterial = new THREE.MeshStandardMaterial();
    superImposeMaterial.transparent = true;
    superImposeMaterial.color.set(0x0000e1);
    superImposeMaterial.opacity = 0.4;
    superImposeMaterial.flatShading = false;
    gumHolder = new THREE.Group();
    gumHolder.visible = false;
    gumHolder.name = "Super_Impose_st0_upper";
    GLBData[0].upper.teeth.forEach(tooth => {
      if (tooth.userData["isPontics"]) {
        return;
      }
      toothHolder = tooth.clone();
      toothHolder.material = superImposeMaterial;
      toothHolder.name = "super_" + tooth.name;
      gumHolder.add(toothHolder);
    });
    modelGroup.add(gumHolder);
    gumHolder = new THREE.Group();
    gumHolder.name = "Super_Impose_st0_lower";
    GLBData[0].lower.teeth.forEach(tooth => {
      if (tooth.userData["isPontics"]) {
        return;
      }
      toothHolder = tooth.clone();
      toothHolder.material = superImposeMaterial;
      toothHolder.name = "super_" + tooth.name;
      gumHolder.add(toothHolder);
    });
    gumHolder.visible = false;
    modelGroup.add(gumHolder);

    return modelGroup;
  }

  const handleSelectTooth = event => {
    if (!toggle.show3DControls) return;
    selectTooth(
      event,
      perspectiveCameraRef,
      raycaster,
      pointer,
      [
        ...groupRef.current.children[globalCurrentStep * 2].children,
        ...groupRef.current.children[globalCurrentStep * 2 + 1].children,
      ],
      teethMaterial,
      scene,
      centerVec,
      setControls3D,
      selectedTooth,
      selectedAttachmentType,
      selectedAttachment,
      numberingOverLays,
      GLBData,
      model,
      setModel,
      groupRef,
      toggle.showUpper,
      toggle.showLower,
      logs,
      setLogs,
      setScene,
      attachmentArray,
      setAutoSave.setAutoSave,
    );
  };

  useEffect(() => {
    canvas.current?.addEventListener("pointerdown", handleSelectTooth, false);
    return () => {
      canvas.current.removeEventListener(
        "pointerdown",
        handleSelectTooth,
        false,
      );
    };
  }, [
    selectedAttachmentType,
    centerVec,
    toggle.show3DControls,
    toggle.showUpper,
    toggle.showLower,
    logs,
  ]);
  const [tpBiteJump, setTpBiteJump] = useState([]);
  const [tpWithoutBiteJump, setTpWithoutBiteJump] = useState([]);
  const [iprOverLaysWithBiteJump, setIprOverLaysWithBiteJump] = useState(
    JSON.parse(JSON.stringify(iprOverLays)),
  );
  const [iprOverLaysWithOutBiteJump, setIprOverLaysWithOutBiteJump] = useState(
    JSON.parse(JSON.stringify(iprOverLays)),
  );
  useLayoutEffect(() => {
    console.log({ iprOverLays });
    Object.values(iprOverLaysWithOutBiteJump[0] || {}).forEach(iprs => {
      iprs.pop(); // Removes the last element from each array
    });
    Object.values(iprOverLaysWithOutBiteJump[1] || {}).forEach(iprs => {
      iprs.pop(); // Removes the last element from each array
    });
    Object.values(iprOverLaysWithBiteJump[0] || {}).forEach(iprs => {
      iprs.splice(-2, 1); // Removes the last element from each array
    });
    Object.values(iprOverLaysWithBiteJump[1] || {}).forEach(iprs => {
      iprs.splice(-2, 1); // Removes the last element from each array
    });
  }, []);
  useEffect(() => {
    console.log({ iprOverLaysWithBiteJump, iprOverLaysWithOutBiteJump });
  }, [iprOverLaysWithBiteJump, iprOverLaysWithOutBiteJump]);
  useLayoutEffect(() => {
    if (!BJGroup) {
      BJGroup = new THREE.Group();
      BJGroup.visible = false;
      BJGroup.name = "BiteJumpGroup";
    }
    // return;
    const mesh2 = groupRef.current;
    const mesh3 = groupRef2.current;
    // Helper function to compute normals for a named mesh
    const computeNormals = (mesh, name) => {
      const object = mesh.getObjectByName(name);
      if (!object) return;

      object.geometry.computeVertexNormals();
      object.children.forEach(child => {
        child.geometry.computeVertexNormals();
        child.children[0]?.children.forEach(attachment => {
          attachment.geometry.computeVertexNormals();
        });
      });
      return object;
    };

    const biteJumpsStep = Math.max(
      Number(biteJumpsSteps[0]),
      Number(biteJumpsSteps[1]),
    );
    // Setting the BiteJump objects if they exist
    const upperGum = computeNormals(mesh2, `u_st${biteJumpsStep}_gum`);
    const lowerGum = computeNormals(mesh2, `l_st${biteJumpsStep}_gum`);

    if (upperGum && lowerGum) {
      setTpBiteJump([upperGum, lowerGum]);
      BJGroup.attach(upperGum);
      BJGroup.attach(lowerGum);
      setTpBiteJump([upperGum, lowerGum]);
      // Remove these objects from mesh2
      mesh2.remove(upperGum);
      mesh2.remove(lowerGum);

      if (mesh3) {
        // Similarly remove from mesh3

        mesh3.remove(mesh3.getObjectByName(`u_st${Number(biteJumpsStep)}_gum`));
        mesh3.remove(mesh3.getObjectByName(`l_st${Number(biteJumpsStep)}_gum`));
      }
    }

    if (tpWithoutBiteJump.length < 2 && maxStepNumber > 0) {
      setTpWithoutBiteJump([
        mesh2.children[maxStepNumber * 2],
        mesh2.children[maxStepNumber * 2 + 1],
      ]);
    }
    if (maxStepNumber > 0) {
      PrepareTeethMovementPath(groupRef, GLBData);
      calculateTeethPositions(
        GLBData.length - 1 - (globalBiteJump ? 1 : 0),
        groupRef,
      );

      scene.updateMatrixWorld(true);
    }
    if (!BJGroup.parent) {
      scene.attach(BJGroup);
    }
  }, [biteJumpsSteps, maxStepNumber, tpWithoutBiteJump]);
  useEffect(() => {
    if (toggle.startRevision) {
      setToggle("biteJump", false);
    }
  }, [toggle.startRevision]);
  useEffect(() => {
    if (toggle.hasBiteJump && maxStepNumber > 0) {
      if (toggle.biteJump) {
        console.log({ iprOverLaysWithBiteJump }, "from toggle");
        //setIprOverlays(iprOverLaysWithBiteJump);
        if (tpBiteJump[0]) {
          tpBiteJump[0].parent = groupRef.current;

          groupRef.current.children[maxStepNumber * 2] = tpBiteJump[0];
          if (groupRef2.current) {
            const tpBiteJumpClone0 = tpBiteJump[0].clone();
            tpBiteJumpClone0.parent = groupRef2.current;
            tpBiteJumpClone0.visible = true;
            groupRef2.current.children[maxStepNumber * 2] = tpBiteJumpClone0;
          }
        }
        // --------------------- //
        if (tpBiteJump[1]) {
          tpBiteJump[1].parent = groupRef.current;

          groupRef.current.children[maxStepNumber * 2 + 1] = tpBiteJump[1];
          if (groupRef2.current) {
            const tpBiteJumpClone1 = tpBiteJump[1].clone();
            tpBiteJumpClone1.parent = groupRef2.current;
            groupRef2.current.children[0].visible = false;
            groupRef2.current.children[1].visible = false;
            tpBiteJumpClone1.visible = true;
            groupRef2.current.children[maxStepNumber * 2 + 1] =
              tpBiteJumpClone1;
          }
        }
      } else {
        //setIprOverlays(iprOverLaysWithOutBiteJump);
        if (tpWithoutBiteJump.length > 0) {
          //console.log({ currentStep, maxStepNumber });
          // tpWithoutBiteJump[0].visible =
          //   currentStep === maxStepNumber ? true : false;
          if (tpWithoutBiteJump[0]) {
            groupRef.current.children[maxStepNumber * 2] = tpWithoutBiteJump[0];
            if (groupRef2.current) {
              const tpWithoutBiteJumpClone0 = tpWithoutBiteJump[0].clone();
              tpWithoutBiteJumpClone0.visible = true;
              groupRef2.current.children[maxStepNumber * 2] =
                tpWithoutBiteJumpClone0;
            }
          }
          // ---------------- //
          // tpWithoutBiteJump[1].visible =
          //   currentStep === maxStepNumber ? true : false;
          if (tpWithoutBiteJump[1]) {
            groupRef.current.children[maxStepNumber * 2 + 1] =
              tpWithoutBiteJump[1];
            if (groupRef2.current) {
              const tpWithoutBiteJumpClone1 = tpWithoutBiteJump[1].clone();
              tpWithoutBiteJumpClone1.visible = true;
              groupRef2.current.children[0].visible = false;
              groupRef2.current.children[1].visible = false;
              groupRef2.current.children[maxStepNumber * 2 + 1] =
                tpWithoutBiteJumpClone1;
            }
          }
        }
      }
      groupRef2.current.children.forEach(gum => {
        if (gum.name.includes("gum")) {
          gum.children.forEach(tooth => {
            tooth.children[1].visible = toggle.numbering;
          });
        }
      });
    }
    // console.log(groupRef.current);
  }, [
    toggle.biteJump,
    toggle.hasBiteJump,
    maxStepNumber,
    tpBiteJump,
    tpWithoutBiteJump,
    toggle.split,
    currentStep,
    toggle.show3DControls,
  ]);
  useEffect(() => {
    RenderCurrentStep();
    if (!toggle.occ) {
      oldOptions = toggle;

      const childrenToRemove = [];
      groupRef.current.children.forEach(child => {
        if (child.name === "occ_group") {
          childrenToRemove.push(child);
        }
      });
      groupRef2.current?.children.forEach(child => {
        if (child.name === "occ_group2") {
          childrenToRemove.push(child);
        }
      });
      childrenToRemove.forEach(
        child => (
          groupRef.current.remove(child), groupRef2.current.remove(child)
        ),
      );
      return;
    }
    if (
      oldOptions.showLower != toggle.showLower ||
      oldOptions.showUpper != toggle.showUpper
    ) {
      setLinePosition();
      return;
    }
    lineGeometry.setFromPoints([
      new THREE.Vector3(0, 1, 0),
      new THREE.Vector3(0, -1, 0),
    ]);
    const childrenToRemove = [];
    groupRef.current.children.forEach(child => {
      if (child.name === "occ_group") {
        childrenToRemove.push(child);
      }
    });
    groupRef2.current?.children.forEach(child => {
      if (child.name === "occ_group2") {
        childrenToRemove.push(child);
      }
    });
    childrenToRemove.forEach(
      child => (
        groupRef.current.remove(child), groupRef2.current.remove(child)
      ),
    );
    if (toggle.occ) {
      for (
        let t = 0;
        t < groupRef.current.children[currentStep * 2 + 1].children.length;
        t++
      ) {
        let lowerTooth =
          groupRef.current.children[currentStep * 2 + 1].children[t];
        lowerTooth.geometry.computeBoundsTree();
        for (
          let i = 0;
          i < groupRef.current.children[currentStep * 2].children.length;
          i++
        ) {
          let upperTooth =
            groupRef.current.children[currentStep * 2].children[i];
          if (t === 0) {
            upperTooth.geometry.computeBoundsTree();
          }
          //lowerTooth.position.y += heavyContactThreshold;
          scene.updateMatrixWorld(true);
          DrawContact(
            lowerTooth,
            upperTooth,
            bgLineHeavyContactMaterial,
            false,
          );
        }
      }
      if (toggle.split || toggle.compareModifications) {
        for (
          let t = 0;
          t <
          groupRef2.current.children[(maxStepNumber - 1) * 2 + 1].children
            .length;
          t++
        ) {
          let lowerTooth =
            groupRef2.current.children[(maxStepNumber - 1) * 2 + 1].children[t];
          lowerTooth.geometry.computeBoundsTree();
          for (
            let i = 0;
            i <
            groupRef2.current.children[(maxStepNumber - 1) * 2].children.length;
            i++
          ) {
            let upperTooth =
              groupRef2.current.children[(maxStepNumber - 1) * 2].children[i];
            if (t === 0) {
              upperTooth.geometry.computeBoundsTree();
            }
            scene.updateMatrixWorld(true);
            DrawContact(
              lowerTooth,
              upperTooth,
              bgLineHeavyContactMaterial,
              true,
            );
          }
        }
      }
      setLinePosition();
      groupRef.current.add(lineGroup);
      groupRef2.current.add(lineGroup2);
    }
  }, [
    currentStep,
    toggle.occ,
    toggle.split,
    toggle.compareModifications,
    toggle.showLower,
    toggle.showUpper,
    toggle.superImpose,
    toggle.biteJump,
  ]);
  const names = [
    "att_hbra",
    "att_vbra",
    "att_oblique",
    "att_extrusion",
    "att_biolane",
    "att_root_control",
    "att_rotational",
  ];
  if (meshGroup) {
    return <primitive ref={groupRef} object={meshGroup} />;
  } else return <></>;
}

export default Mesh;
