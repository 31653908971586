import { IprIndicatorIcon } from "@/assets";
import { cn } from "@/helpers/functions";
import React from "react";
import "./stepper.scss";

export const IprIndicator = ({ stepsWithIpr, stepNumber, currentStep }) => {
  return (
    <span
      className={cn("absolute -top-6 z-10 hidden text-[#555555]", {
        block: stepsWithIpr?.[stepNumber],
      })}
    >
      <IprIndicatorIcon
        className={`fill-[#555555]
          ${currentStep === stepNumber ? "bounty_active_ipr" : ""}`}
      />
    </span>
  );
};
