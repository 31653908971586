import * as THREE from "three";
import { MeshLine, MeshLineMaterial } from "meshline";

function makeLabelCanvas(
  text,
  innerBgColor,
  bgColor,
  innerTextColor,
  outerTextColor,
  step,
  startRevision,
  isSpacing,
  isViewerControlsEnabled,
) {
  text = " " + text.toFixed(2) + " ";

  step = " " + step + " ";
  const radius = 20;
  const font = `bold 125px 'Arial'`;
  const outerCanvas = document.createElement("canvas");
  const outerContext = outerCanvas.getContext("2d");

  outerCanvas.width = startRevision ? 500 : 550;
  outerCanvas.height = startRevision ? 375 : 275;

  const innerCanvas = document.createElement("canvas");
  innerCanvas.width = startRevision ? 275 : 200;
  innerCanvas.height = 175;
  innerCanvas.style.marginRight = "10px"; // Set the right margin

  const innerContext = innerCanvas.getContext("2d");
  const innerRadius = 20;

  innerContext.beginPath();
  innerContext.moveTo(innerRadius, 0);
  innerContext.lineTo(innerCanvas.width - innerRadius, 0);
  innerContext.quadraticCurveTo(
    innerCanvas.width,
    0,
    innerCanvas.width,
    innerRadius,
  );
  innerContext.lineTo(innerCanvas.width, innerCanvas.height - innerRadius);
  innerContext.quadraticCurveTo(
    innerCanvas.width,
    innerCanvas.height,
    innerCanvas.width - innerRadius,
    innerCanvas.height,
  );
  innerContext.lineTo(innerRadius, innerCanvas.height);
  innerContext.quadraticCurveTo(
    0,
    innerCanvas.height,
    0,
    innerCanvas.height - innerRadius,
  );
  innerContext.lineTo(0, innerRadius);
  innerContext.quadraticCurveTo(0, 0, innerRadius, 0);
  innerContext.closePath();
  innerContext.fillStyle = startRevision
    ? isSpacing
      ? "#FFF1A8"
      : "#ffffff"
    : innerBgColor;
  innerContext.fill();
  innerContext.font = startRevision ? `500 100px 'Arial'` : font;
  innerContext.fillStyle = startRevision ? "#616060" : innerTextColor;
  innerContext.textAlign = "center";
  innerContext.textBaseline = "middle";
  innerContext.fillText(step, innerCanvas.width / 2, innerCanvas.height / 2);

  outerContext.shadowColor = "rgba(0, 0, 0, 0.25)";
  outerContext.shadowOffsetX = 0;
  outerContext.shadowOffsetY = 0;
  outerContext.shadowBlur = 50;

  outerContext.beginPath();
  outerContext.moveTo(radius, 0);
  outerContext.lineTo(outerCanvas.width - radius, 0);
  outerContext.quadraticCurveTo(
    outerCanvas.width,
    0,
    outerCanvas.width,
    radius,
  );
  outerContext.lineTo(outerCanvas.width, outerCanvas.height - radius - 15); // Subtract 10 here
  outerContext.quadraticCurveTo(
    outerCanvas.width,
    outerCanvas.height - 15, // Subtract 10 here
    outerCanvas.width - radius,
    outerCanvas.height - 15, // Subtract 10 here
  );
  outerContext.lineTo(radius, outerCanvas.height - 15); // Subtract 10 here
  outerContext.quadraticCurveTo(
    0,
    outerCanvas.height - 15, // Subtract 10 here
    0,
    outerCanvas.height - radius - 15, // Subtract 10 here
  );
  outerContext.lineTo(0, radius);
  outerContext.quadraticCurveTo(0, 0, radius, 0);
  outerContext.closePath();
  outerContext.fillStyle = startRevision
    ? isSpacing
      ? "#FFF1A8"
      : bgColor
    : bgColor;
  outerContext.fill();
  outerContext.font = font;

  // Reset the shadow values
  outerContext.shadowColor = "rgba(0,0,0,0)";
  outerContext.shadowOffsetX = 0;
  outerContext.shadowOffsetY = 0;
  outerContext.fillStyle = outerTextColor;
  outerContext.textAlign = "center";
  outerContext.textBaseline = "middle";
  outerContext.fillText(
    text,
    outerCanvas.width / (startRevision ? 2 : 4),
    outerCanvas.height / (startRevision ? 4 : 2),
  );
  // calculate the position of the innerCanvas
  const innerCanvasX =
    (outerCanvas.width - innerCanvas.width) / (startRevision ? 2 : 1.2);
  const innerCanvasY =
    (outerCanvas.width - innerCanvas.width) / (startRevision ? 1.5 : 8.5);

  outerContext.drawImage(innerCanvas, innerCanvasX, innerCanvasY);

  return outerContext.canvas;
}

function makeSpriteLabel(
  position,
  text,
  colors,
  step,
  i,
  startRevision,
  isSpacing,
  isViewerControlsEnabled,
) {
  let canvas = makeLabelCanvas(
    text,
    colors.innerBgColor,
    colors.bgColor,
    colors.innerTextColor,
    colors.outerTextColor,
    step,
    startRevision,
    isSpacing,
    isViewerControlsEnabled,
  );
  const texture = new THREE.CanvasTexture(canvas);
  texture.minFilter = THREE.LinearFilter;
  texture.wrapS = THREE.ClampToEdgeWrapping;
  texture.wrapT = THREE.ClampToEdgeWrapping;

  const labelMaterial = new THREE.SpriteMaterial({
    map: texture,
    side: THREE.DoubleSide,
    transparent: true,
    toneMapped: false,
  });

  const sprite = new THREE.Object3D();
  sprite.position.copy(position);
  sprite.position.z += 0.1;
  sprite.position.y += i * 3;
  const label = new THREE.Sprite(labelMaterial);

  label.renderOrder = 1;

  sprite.add(label);

  const labelBaseScale = 0.01;
  label.scale.x = canvas.width * labelBaseScale;
  label.scale.y = canvas.height * labelBaseScale;

  return sprite;
}
let createLine = function (p0, p1, color, upper) {
  const samples = [p0, p1];

  const geometry = new THREE.BufferGeometry().setFromPoints(samples);
  const line = new MeshLine();
  line.setGeometry(geometry);
  const material = new MeshLineMaterial({
    useMap: false, //tells the material to use map (0 - solid color, 1 use texture)
    color: "white",
    opacity: 0.38,
    resolution: new THREE.Vector2(window.innerWidth, window.innerHeight), //THREE.Vector2 specifying the canvas size (REQUIRED)
    sizeAttenuation: false, // makes the line width constant regardless distance (1 unit is 1px on screen) (0 - attenuate, 1 - don't attenuate)
    transparent: true,
    lineWidth: 5,
    depthTest: true,
    alphaTest: 0, //cutoff value from 0 to 1
  });

  const mesh = new THREE.Mesh(line.geometry, material);
  mesh.name = upper ? "u_line" : "l_line";
  return mesh;
};
const tolerance = 1e-16;
function ignoreSmallNumber(number) {
  return Math.abs(number) < tolerance;
}
export const placeIPRBetweenTeeth = (
  group,
  options,
  currentStep,
  iprOverLays,
  startRevision,
  isViewerControlsEnabled,
  getContent,
) => {
  let one = new THREE.Vector3();
  let box3 = new THREE.Box3().setFromObject(
    group.current.children[currentStep],
  );
  box3.getCenter(one);
  let gumOffset = group.current.position.z;
  const childrenToRemove = [];
  group.current.children.forEach(child => {
    if (
      child.name.split("_")[1] === "ipr" ||
      child.name.split("_")[1] === "line"
    ) {
      childrenToRemove.push(child);
    }
  });
  childrenToRemove.forEach(child => group.current.remove(child));

  if (options.ipr) {
    Object.values(iprOverLays[0] || {}).forEach((iprs, i) => {
      if (iprs[currentStep] === undefined) return;
      let step = 0;

      let linestart = new THREE.Vector3(
        iprs[currentStep].ipr_position[0],
        iprs[currentStep].ipr_position[1],
        iprs[currentStep].ipr_position[2],
      );

      let lineEnd = getIPRPos(linestart.clone(), 1, 12, gumOffset);

      const line = createLine(linestart, lineEnd, "#2b2f2e", true);
      line.visible = options.showLower ? false : true;
      let totalAmount = 0;

      iprs.forEach((ipr, i) => {
        if (isNaN(ipr.amount)) {
          return;
        }
        if (ipr.amount === null) {
          return;
        }

        totalAmount += Number(ipr?.amount);
      });
      iprs.forEach((ipr, i) => {
        if (
          ipr.amount === null ||
          isNaN(ipr.amount) ||
          Math.abs(Number(Number(totalAmount).toFixed(2))) <= 0 ||
          Math.abs(Number(Number(ipr.amount).toFixed(2))) <= 0
        ) {
          return;
        }

        const amount = Math.abs(Number(ipr.amount).toFixed(2));
        step = Number(ipr?.step);
        if (startRevision) {
          if (Number(Number(totalAmount).toFixed(2)) < 0) {
            step = getContent("labels", "space");
          } else {
            step = getContent("labels", "ipr");
          }
        }
        let label = new THREE.Object3D();
        const isSpacing =
          Number(Number(totalAmount).toFixed(2)) < 0 ? true : false;
        label = makeSpriteLabel(
          lineEnd,
          /*numberingData.displayName*/ startRevision
            ? Math.abs(totalAmount)
            : amount,
          {
            innerBgColor: step === currentStep ? "#ffffff" : "#484848",
            bgColor: step === currentStep ? "#295DCD" : "#ffffff",
            innerTextColor: step === currentStep ? "#295DCD" : "#ffffff",
            outerTextColor: step === currentStep ? "#ffffff" : "#000000",
          },
          step,
          startRevision ? 0 : i,
          startRevision,
          isSpacing,
          isViewerControlsEnabled,
        );

        /************************************************************ */
        label.name = "u_ipr";
        label.visible = options.showLower ? false : true;
        line.visible = options.showLower ? false : true;
        line.name = "u_line";
        // i > 0 && label.scale.set(label.scale.x * 0.85, label.scale.y * 0.85, label.scale.z * 0.85)

        if (line.parent === null && (totalAmount || amount > 0)) {
          group.current.add(line);
        }
        // if (startRevision && i > 0) {
        //   return;
        // }
        (totalAmount || amount > 0) && group.current.add(label);
      });
    });

    Object.values(iprOverLays[1] || {}).forEach((iprs, i) => {
      if (iprs[currentStep] === undefined) return;
      let step = 0;
      let linestart = new THREE.Vector3(
        iprs[currentStep].ipr_position[0],
        iprs[currentStep].ipr_position[1],
        iprs[currentStep].ipr_position[2],
      );
      let lineEnd = getIPRPos(linestart.clone(), -1, 12, gumOffset);

      const line = createLine(linestart, lineEnd, "#2b2f2e", true);
      line.visible = options.showLower ? false : true;
      let totalAmount = 0;
      iprs.forEach((ipr, i) => {
        if (isNaN(ipr.amount)) {
          return;
        }
        if (ipr.amount === null) {
          return;
        }
        totalAmount += Number(ipr?.amount);
      });
      iprs.forEach((ipr, i) => {
        if (
          ipr.amount === null ||
          isNaN(ipr.amount) ||
          Math.abs(Number(Number(totalAmount).toFixed(2))) <= 0 ||
          Math.abs(Number(Number(ipr.amount).toFixed(2))) <= 0
        ) {
          return;
        }

        const amount = Math.abs(Number(ipr.amount).toFixed(2));
        step = Number(ipr?.step);
        if (startRevision) {
          if (Number(Number(totalAmount).toFixed(2)) < 0) {
            step = getContent("labels", "space");
          } else {
            step = getContent("labels", "ipr");
          }
        }
        let label = new THREE.Object3D();

        const isSpacing =
          Number(Number(totalAmount).toFixed(2)) < 0 ? true : false;
        label = makeSpriteLabel(
          lineEnd,
          /*numberingData.displayName*/ startRevision
            ? Math.abs(totalAmount)
            : amount,
          {
            innerBgColor: step === currentStep ? "#ffffff" : "#484848",
            bgColor: step === currentStep ? "#295DCD" : "#ffffff",
            innerTextColor: step === currentStep ? "#295DCD" : "#ffffff",
            outerTextColor: step === currentStep ? "#ffffff" : "#000000",
          },
          step,
          startRevision ? 0 : -i,
          startRevision,
          isSpacing,
          isViewerControlsEnabled,
        );
        /************************************************************ */
        label.name = "l_ipr";
        label.visible = options.showUpper ? false : true;
        line.visible = options.showUpper ? false : true;
        line.name = "l_line";
        if (line.parent === null && (totalAmount || amount > 0)) {
          group.current.add(line);
        }
        // if (startRevision && i > 0) {
        //   return;
        // }
        (totalAmount || amount > 0) && group.current.add(label);
      });
    });
  }
};
const getIPRPos = (
  iprStartPoint,
  upOrDown = 1,
  amount = 12,
  gumOffset = 0,
  theAngle = 45,
) => {
  // theAngle value between 0-45
  const zero = new THREE.Vector3();
  let one = iprStartPoint.clone();
  let rayDir = new THREE.Vector3();
  one.setZ(one.z + gumOffset);
  let angle = THREE.MathUtils.clamp(
    (Math.atan2(zero.x, zero.z) - Math.atan2(one.x, one.z)) * (180 / Math.PI),
    -70,
    70,
  );
  let forX = angle / -90;
  let forZ = 0;
  if (angle > 0) {
    forZ = forX + 1;
  } else {
    forZ = -(forX - 1);
  }
  rayDir = new THREE.Vector3(
    (forX * theAngle) / 45,
    upOrDown,
    (forZ * theAngle) / 45,
  );
  let t = iprStartPoint.add(rayDir.multiplyScalar(amount));
  return t;
};
