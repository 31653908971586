import { useCustomTranslation } from "@/hooks/useTranslation";
import React from "react";

export const Loading = () => {
  const { content } = useCustomTranslation();

  const getContent = (group, key) => {
    return content(`other.${group}.${key}`);
  };
  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-2">
      <div className="relative h-10 w-10">
        <div className="bg-ts_primary absolute h-full w-full animate-bounce rounded-full opacity-60"></div>
        <div className="bg-ts_primary absolute h-full w-full animate-bounce-reverse rounded-full opacity-60"></div>
      </div>
      <div>{getContent("labels", "loading")}</div>
    </div>
  );
};
