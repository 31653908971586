import axios from "axios";
export const config = {
  headers: {
    "x-api-key": process.env.REACT_APP_TOKEN,
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json; charset=utf-8",
  },
};

export const axiosForMFG = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_MFG,
  ...config,
});
const axiosForAccess = axios.create({
  baseURL: process.env.REACT_APP_URL_ACCESS_BASE,
  headers: {
    Authorization: `Token ${process.env.REACT_APP_ACCESS_TOKEN}`,
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json; charset=utf-8",
  },
});

const fetchTSUrlsForEonAccess = treatmentSetupsId => {
  return axiosForAccess.get(`/treatment_setups/${treatmentSetupsId}`);
};

const fetchTSUrlsForEonMfg = (treatmentSetupsId, ownerType) => {
  return axiosForMFG.get(`external/viewer/plan/${treatmentSetupsId}`, config);
};

const fetchTsqcResult = (treatmentSetupsId) => {
  return axiosForMFG.get(`external/viewer/plan/${treatmentSetupsId}/tsqcResult`, config);
};
const fetchIssueTypes = () => {
  return axiosForMFG.get(`external/viewer/tsqc/issueTypes`,config );
};

export { fetchTSUrlsForEonAccess, fetchTSUrlsForEonMfg  , fetchTsqcResult , fetchIssueTypes};
