import { twMerge } from "tailwind-merge";
import { clsx } from "clsx";

export const cn = (...ClassNames) => twMerge(clsx(ClassNames));

// check if input is empty object or array, if its not obj or array return if its null or undefined or empty string
export const isEmpty = (input) => {
    if (typeof input === "object" && input !== null) {
        if (Array.isArray(input)) {
            return input.length === 0;
        }
        return Object.keys(input).length === 0;
    }
    return input === null || input === undefined || input === "";
}