import { globalBiteJumpUpperLower } from "@/components/viewer/Scene";

export const stepsStyleValidation = (
  stepNumber,
  allStepsForUpper,
  allStepsForLower,
  startTogether,
  passiveAligners,
  upperOverCorrectionStepsNumber,
  lowerOverCorrectionStepsNumber,
  isUpper,
) => {
  let styleArray = [];
  if (startTogether) {
    if (isUpper) {
      if (
        stepNumber <
        allStepsForUpper -
          (globalBiteJumpUpperLower.u ? 1 : 0) -
          upperOverCorrectionStepsNumber
      ) {
        styleArray.push("bounty_step");
      } else {
        upperOverCorrectionStepsNumber === 0
          ? styleArray.push("bounty_step")
          : styleArray.push("bounty_overCorrectionStepsNumber");
      }
    } else {
      if (
        stepNumber <
        allStepsForLower -
          (globalBiteJumpUpperLower.l ? 1 : 0) -
          lowerOverCorrectionStepsNumber
      ) {
        styleArray.push("bounty_step");
      } else {
        lowerOverCorrectionStepsNumber === 0
          ? styleArray.push("bounty_step")
          : styleArray.push("bounty_overCorrectionStepsNumber");
      }
    }
  } else {
    if (isUpper) {
      if (allStepsForUpper - (globalBiteJumpUpperLower.u ? 1 : 0) === 1) {
        passiveAligners
          ? styleArray.push("bounty_passiveAligners")
          : styleArray.push("bounty_step_empty");
      }
      if (
        allStepsForUpper - (globalBiteJumpUpperLower.u ? 1 : 0) ===
        allStepsForLower - (globalBiteJumpUpperLower.l ? 1 : 0)
      ) {
        if (
          stepNumber <
          allStepsForUpper -
            (globalBiteJumpUpperLower.u ? 1 : 0) -
            upperOverCorrectionStepsNumber
        ) {
          styleArray.push("bounty_step");
        } else {
          upperOverCorrectionStepsNumber === 0
            ? styleArray.push("bounty_step")
            : styleArray.push("bounty_overCorrectionStepsNumber");
        }
      }
      if (
        allStepsForUpper - (globalBiteJumpUpperLower.u ? 1 : 0) <
        allStepsForLower - (globalBiteJumpUpperLower.l ? 1 : 0)
      ) {
        if (
          stepNumber <=
          allStepsForLower -
            (globalBiteJumpUpperLower.l ? 1 : 0) -
            allStepsForUpper -
            (globalBiteJumpUpperLower.u ? 1 : 0)
        ) {
          styleArray.push("bounty_step");
        } else if (
          stepNumber >=
            allStepsForLower -
              (globalBiteJumpUpperLower.l ? 1 : 0) -
              allStepsForUpper -
              (globalBiteJumpUpperLower.u ? 1 : 0) &&
          stepNumber <
            allStepsForLower -
              (globalBiteJumpUpperLower.l ? 1 : 0) -
              upperOverCorrectionStepsNumber
        ) {
          styleArray.push("bounty_step");
        } else {
          upperOverCorrectionStepsNumber === 0
            ? styleArray.push("bounty_step")
            : styleArray.push("bounty_overCorrectionStepsNumber");
        }
      } else {
        if (
          stepNumber <=
          allStepsForUpper -
            (globalBiteJumpUpperLower.u ? 1 : 0) -
            allStepsForLower -
            (globalBiteJumpUpperLower.l ? 1 : 0)
        ) {
          styleArray.push("bounty_step");
        } else if (
          stepNumber >=
            allStepsForUpper -
              (globalBiteJumpUpperLower.u ? 1 : 0) -
              allStepsForLower -
              (globalBiteJumpUpperLower.l ? 1 : 0) &&
          stepNumber <
            allStepsForUpper -
              (globalBiteJumpUpperLower.u ? 1 : 0) -
              upperOverCorrectionStepsNumber
        ) {
          styleArray.push("bounty_step");
        } else {
          upperOverCorrectionStepsNumber === 0
            ? styleArray.push("bounty_step")
            : styleArray.push("bounty_overCorrectionStepsNumber");
        }
      }
    } else {
      if (allStepsForLower - (globalBiteJumpUpperLower.l ? 1 : 0) === 1) {
        passiveAligners
          ? styleArray.push("bounty_passiveAligners")
          : styleArray.push("bounty_step_empty");
      }
      if (
        allStepsForLower - (globalBiteJumpUpperLower.l ? 1 : 0) ===
        allStepsForUpper - (globalBiteJumpUpperLower.u ? 1 : 0)
      ) {
        if (
          stepNumber <
          allStepsForLower -
            (globalBiteJumpUpperLower.l ? 1 : 0) -
            lowerOverCorrectionStepsNumber
        ) {
          styleArray.push("bounty_step");
        } else {
          lowerOverCorrectionStepsNumber === 0
            ? styleArray.push("bounty_step")
            : styleArray.push("bounty_overCorrectionStepsNumber");
        }
      }
      if (
        allStepsForUpper - (globalBiteJumpUpperLower.u ? 1 : 0) <
        allStepsForLower - (globalBiteJumpUpperLower.l ? 1 : 0)
      ) {
        if (
          stepNumber <=
          allStepsForLower -
            (globalBiteJumpUpperLower.l ? 1 : 0) -
            allStepsForUpper -
            (globalBiteJumpUpperLower.u ? 1 : 0)
        ) {
          styleArray.push("bounty_step");
        } else if (
          stepNumber >=
            allStepsForLower -
              (globalBiteJumpUpperLower.l ? 1 : 0) -
              allStepsForUpper -
              (globalBiteJumpUpperLower.u ? 1 : 0) &&
          stepNumber <
            allStepsForLower -
              (globalBiteJumpUpperLower.l ? 1 : 0) -
              lowerOverCorrectionStepsNumber
        ) {
          styleArray.push("bounty_step");
        } else {
          lowerOverCorrectionStepsNumber === 0
            ? styleArray.push("bounty_step")
            : styleArray.push("bounty_overCorrectionStepsNumber");
        }
      } else {
        if (
          stepNumber <=
          allStepsForUpper -
            (globalBiteJumpUpperLower.u ? 1 : 0) -
            allStepsForLower -
            (globalBiteJumpUpperLower.l ? 1 : 0)
        ) {
          styleArray.push("bounty_step");
        } else if (
          stepNumber >=
            allStepsForUpper -
              (globalBiteJumpUpperLower.u ? 1 : 0) -
              allStepsForLower -
              (globalBiteJumpUpperLower.l ? 1 : 0) &&
          stepNumber <
            allStepsForUpper -
              (globalBiteJumpUpperLower.u ? 1 : 0) -
              lowerOverCorrectionStepsNumber
        ) {
          styleArray.push("bounty_step");
        } else {
          lowerOverCorrectionStepsNumber === 0
            ? styleArray.push("bounty_step")
            : styleArray.push("bounty_overCorrectionStepsNumber");
        }
      }
    }
  }
  return styleArray.join(" ");
};
