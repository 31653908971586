import { useState } from "react";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";

export const ToolTip = props => {
  return (
    <div
      className={`bounty_tooltip_content 
   
        ${props.isOptions ? "bounty_tooltip_content_options" : ""}`}
    >
      {props.text}
    </div>
  );
};

export const TooltipPopper = ({
  text,
  placement = "auto",
  disabled,
  children,
}) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const [show, setShow] = useState(false);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "auto",
    strategy: "fixed",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 10],
        },
      },
      {
        name: "popperOffsets",
        options: {
          offset: [0, 15],
        },
      },
      { name: "arrow", options: { element: arrowElement } },
    ],
  });

  return (
    <>
      <div
        className="popper-trigger"
        ref={setReferenceElement}
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      >
        {children}
      </div>
      {createPortal(
        <div
          ref={setPopperElement}
          id="tooltip"
          role="tooltip"
          className="z-50 w-60 rounded-sm bg-[#333] p-3 text-sm text-white"
          style={styles.popper}
          data-show={disabled ? null : show}
          {...attributes.popper}
        >
          {text}
          <div
            id="arrow"
            ref={setArrowElement}
            style={styles.arrow}
            data-popper-arrow
          />
        </div>,
        document.querySelector("#root"),
      )}
    </>
  );
};

export const ToolTipToothMovement = ({ text, svg, placement = "left" }) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [show, setShow] = useState(false);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: placement,
    strategy: "fixed",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [-20, 10],
        },
      },
    ],
  });

  return (
    <>
      <div
        className="tooth_movement_icon_div"
        ref={setReferenceElement}
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      >
        {svg}
      </div>
      {createPortal(
        <div
          ref={setPopperElement}
          className="bounty_tooltip_content_tooth_movement z-50"
          style={styles.popper}
          {...attributes.popper}
          data-show={show}
        >
          {text}
        </div>,
        document.querySelector("#root"),
      )}
    </>
  );
};
