import { MathUtils } from "three";
// import { otpFile1 } from "../assets/otpFile";
import { Stage } from "@react-three/drei";
export const mapStagingTimeline = otpFile => {
  const stagingTimeLineArray = new Array(32);
  let validMovementDistance = 0.005;
  for (let i = 0; i < stagingTimeLineArray.length; i++) {
    stagingTimeLineArray[i] = new Array(otpFile.Stages.length);
    for (let r = 0; r < otpFile.Stages.length; r++) {
      stagingTimeLineArray[i][r] = {};
    }
  }
  let cacher, oldcacher;
  let teethNumber;
  let attachmentLength;
  for (
    let i = 0;
    i < otpFile.Stages[0].Teeth.length;
    i++ // i is the     traverser in teeth array
  ) {
    attachmentLength = 0;
    teethNumber = otpFile.Stages[0].Teeth[i].Unn - 1; // tooth number - 1
    for (let r = 0; r < otpFile.Stages.length; r++) {
      if (!otpFile.Stages[r].Teeth[i].hasOwnProperty("Movement")) {
        stagingTimeLineArray[teethNumber] = null;
        break;
      }
      cacher = otpFile.Stages[r].Teeth[i].Movement.Value.MovementValues.Value;
      if (otpFile.Stages[r].Teeth[i].Attachments.length != attachmentLength) {
        attachmentLength = otpFile.Stages[r].Teeth[i].Attachments.length;
        stagingTimeLineArray[teethNumber][r]["attachment"] = true;
      } else {
        stagingTimeLineArray[teethNumber][r]["attachment"] = false;
      }

      if (r === 0) {
        stagingTimeLineArray[teethNumber][r]["movement"] = false;
        oldcacher =
          otpFile.Stages[r].Teeth[i].Movement.Value.MovementValues.Value;
      } else {
        if (
          oldcacher &&
          (cacher.Angulation != oldcacher.Angulation ||
            cacher.Rotation != oldcacher.Rotation ||
            cacher.ForwardBackward != oldcacher.ForwardBackward ||
            cacher.Inclination != oldcacher.Inclination ||
            cacher.IntrusionExtrusion != oldcacher.IntrusionExtrusion ||
            cacher.LeftRight != oldcacher.LeftRight)
        ) {
          const total = Math.abs(
            cacher.Angulation -
              oldcacher.Angulation +
              cacher.Rotation -
              oldcacher.Rotation +
              cacher.ForwardBackward -
              oldcacher.ForwardBackward +
              cacher.Inclination -
              oldcacher.Inclination +
              cacher.IntrusionExtrusion -
              oldcacher.IntrusionExtrusion +
              cacher.LeftRight -
              oldcacher.LeftRight,
          );
          oldcacher =
            otpFile.Stages[r].Teeth[i].Movement.Value.MovementValues.Value;
          if (total >= validMovementDistance) {
            // if (otpFile.Stages[0].Teeth[i].Unn === 2) {
            //   console.log(total, true);
            // }
            stagingTimeLineArray[teethNumber][r]["movement"] = true;
          } else {
            stagingTimeLineArray[teethNumber][r]["movement"] = false;
          }
        } else {
          oldcacher =
            otpFile.Stages[r].Teeth[i].Movement.Value.MovementValues.Value;
          stagingTimeLineArray[teethNumber][r]["movement"] = false;
        }
      }
    }
  }

  return stagingTimeLineArray;
};
