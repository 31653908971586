import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  Attachment,
  SuperImpose,
  Numbering,
  Pontics,
  Grid,
  Split,
  Occ,
  Ipr,
  Tools,
  Arrow,
  Staging,
  BoltonIcon,
  BiteIcon,
  Elastics,
  RecordsIcon,
  Bite,
} from "../Icons";
import { controller } from "./controller";
import { ToolTip } from "../../shared-components/ToolTip";
import { useWindowResize } from "../../../hooks/useWindowResize";
import { OverbitejetIcon, TmtIcon } from "../../../assets";
import { getParams } from "@/helpers/getParams";
import { useGlobalStore } from "../../../store";
import { cn } from "@/helpers/functions";
import { useQuery } from "@tanstack/react-query";
import { apiResolver } from "@/helpers/apiResolver";
import { TurnElastics } from "../Elastics";
import { useCustomTranslation } from "@/hooks/useTranslation";

function getText(feature) {
  switch (feature) {
    case "att":
      return "Attachment";
    case "ipr":
      return "IPR";
    case "elastics":
      return "Elastics";
    case "super":
      return "SuperImpose";
    case "occ":
      return "Occlusion";
    case "grid":
      return "Grid";
    case "split":
      return "Split view";
    case "num":
      return "Numbering";
    case "obOj":
      return "Over Bite/Over Jump";
    case "bolton":
      return "Bolton";
    case "stg":
      return "Staging";
    case "tmt":
      return "Treatment Movement Table";
    case "3DControls":
      return "Plan Modification";
    case "compare":
      return "Compare Mode";
    default:
      return "";
  }
}

function Render({
  view,
  setView,
  GLBData,
  boltonInfo,
  disableMovement,
  disableStaging,
}) {
  const { content } = useCustomTranslation();

  const getContent = (group, key) => {
    return content(`nav.${group}.${key}`);
  };
  const demoTS = getParams().demoTS === "true" ? true : false;
  const withPatientInfo = getParams().withPatientInfo === "true" ? true : false;
  const { data: isRefinementPlan } = demoTS
    ? false
    : useQuery({
        queryKey: ["treatmentSetupInfo"],
        queryFn: apiResolver,
        staleTime: 600000, // Cache data for 60 seconds (1 minute)
        select: data => data?.isRefinementPlan,
      });
  const { controlsRef, controlsRef2, isMobile, setToggle, toggle } =
    useGlobalStore(state => ({
      controlsRef: state.refs.controlsRef,
      controlsRef2: state.refs.controlsRef2,
      isMobile: state.layoutConfig.isMobile,
      toggle: state.toggle,
      setToggle: state.setToggle,
    }));
  const renderRef = useRef();
  const { isPatient, tdms } = getParams();
  const closeOnResize = () => {
    if (!toggle.showBolton && !toggle.showMovement && !toggle.showStaging)
      return;

    setToggle("showBolton", false);
    setToggle("showMovement", false);
    setToggle("showStaging", false);
    setToggle("showReject", false);
    setToggle("showApprove", false);
  };

  const initialIcons = [
    <div
      onClick={() =>
        controller(
          "attachments-view",
          controlsRef.current,
          controlsRef2.current,
          view,
          setView,
          setToggle,
          toggle,
          boltonInfo,
        )
      }
      style={{
        padding: isMobile ? "0px 10px" : "0px",
      }}
      className={`bounty_nav_icon ${toggle.attachment ? "views_active" : ""}`}
      key={1}
    >
      {!isMobile && (
        <ToolTip text={getContent("tooltips", "show_attachment")} />
      )}
      <Attachment />
      <div>{getContent("labels", isMobile ? "att" : "attach")}</div>
    </div>,
    <div
      onClick={() =>
        toggle.hasIpr
          ? controller(
              "ipr-view",
              controlsRef.current,
              controlsRef2.current,
              view,
              setView,
              setToggle,
              toggle,
              boltonInfo,
            )
          : null
      }
      style={{
        padding: isMobile ? "0px 10px" : "0px",
      }}
      className={`bounty_nav_icon ${
        toggle.ipr
          ? "views_active"
          : !toggle.hasIpr
            ? "bounty_icon_disabled"
            : ""
      }`}
      key={2}
    >
      {!isMobile && (
        <ToolTip
          text={getContent(
            "tooltips",
            !toggle.hasIpr
              ? "the_current_ts_doesn't_have_any_elastics"
              : "show_ipr",
          )}
        />
      )}
      <Ipr />
      <div>{getContent("labels", "ipr")}</div>
    </div>,
    <div
      onClick={() => {
        toggle.hasElastics
          ? controller(
              "elastics-view",
              controlsRef.current,
              controlsRef2.current,
              view,
              setView,
              setToggle,
              toggle,
              boltonInfo,
            )
          : null;
      }}
      style={{
        padding: isMobile ? "0px 10px" : "0px",
      }}
      className={`bounty_nav_icon ${toggle.elastics ? "views_active" : toggle.occ || toggle.show3DControls || !toggle.hasElastics || toggle.superImpose ? "bounty_icon_disabled" : ""}`}
      key={3}
    >
      {!isMobile &&
        (!toggle.occ || !toggle.show3DControls || !toggle.hasElastics) && (
          <ToolTip
            text={
              !toggle.hasElastics
                ? getContent(
                    "tooltips",
                    "the_current_ts_doesn't_have_any_elastics",
                  )
                : toggle.occ || toggle.show3DControls || toggle.superImpose
                  ? getContent(
                      "tooltips",
                      toggle.occ
                        ? "elastics_isn_t_available_when_occ_is_toggled"
                        : toggle.superImpose
                          ? "elastics_isn_t_available_when_super_is_toggled"
                          : "elastics_isn_t_available_when_3d_controls_is_toggled",
                    )
                  : getContent("tooltips", "show_elastics")
            }
          />
        )}
      <Elastics />
      <div>{getContent("labels", "elastics")}</div>
    </div>,
    <div
      style={{
        display: isMobile || isPatient ? "none" : "grid",
      }}
      onClick={() =>
        !toggle.show3DControls &&
        toggle.hasPontics &&
        controller(
          "pontics-view",
          controlsRef.current,
          controlsRef2.current,
          view,
          setView,
          setToggle,
          toggle,
          boltonInfo,
        )
      }
      className={`bounty_nav_icon ${
        toggle.pontics
          ? "views_active"
          : !toggle.hasPontics || toggle.show3DControls
            ? "bounty_icon_disabled"
            : ""
      }`}
      key={4}
    >
      <ToolTip
        text={
          toggle.show3DControls
            ? `${getContent("tooltips", "pontics_isn_t_available_when_3d_controls_is_toggled")}`
            : !toggle.hasPontics
              ? `${getContent("tooltips", "the_current_ts_doesn't_have_any_pontics")}`
              : `${getContent("tooltips", "show_pontics")}`
        }
      />
      <Pontics />
      <div>{getContent("labels", "pontics")}</div>
    </div>,
    <div
      style={{
        display: isMobile || isPatient ? "none" : "grid",
      }}
      onClick={() =>
        toggle.compareModifications
          ? null
          : controller(
              "superImpose-view",
              controlsRef.current,
              controlsRef2.current,
              view,
              setView,
              setToggle,
              toggle,
              boltonInfo,
            )
      }
      className={`bounty_nav_icon ${
        toggle.superImpose
          ? "views_active"
          : toggle.split ||
              toggle.occ ||
              toggle.compareModifications ||
              toggle.elastics
            ? "bounty_icon_disabled"
            : ""
      }`}
      key={5}
    >
      {!isMobile && !toggle.compareModifications && (
        <ToolTip
          text={
            toggle.occ || toggle.split || toggle.elastics
              ? getContent(
                  "tooltips",
                  toggle.occ
                    ? "supeImpose_isn_t_available_when_occ_is_toggled"
                    : toggle.elastics
                      ? "supeImpose_isn_t_available_when_elastics_is_toggled"
                      : "supeImpose_isn_t_available_when_split_is_toggled",
                )
              : getContent("tooltips", "show_superImpose")
          }
        />
      )}
      <SuperImpose />
      <div>{getContent("labels", "super")}</div>
    </div>,
    <div
      style={{
        display: isMobile || isPatient ? "none" : "grid",
      }}
      onClick={() =>
        Object.values(GLBData[0].upper?.gum).length === 0 ||
        Object.values(GLBData[0].lower?.gum).length === 0
          ? null
          : controller(
              "occlus-view",
              controlsRef.current,
              controlsRef2.current,
              view,
              setView,
              setToggle,
              toggle,
              boltonInfo,
            )
      }
      className={`bounty_nav_icon ${
        toggle.occ
          ? "views_active"
          : toggle.superImpose ||
              toggle.elastics ||
              Object.values(GLBData[0].upper?.gum).length === 0 ||
              Object.values(GLBData[0].lower?.gum).length === 0
            ? "bounty_icon_disabled"
            : ""
      }`}
      key={6}
    >
      {!isMobile && (
        <ToolTip
          text={
            toggle.elastics || toggle.superImpose
              ? getContent(
                  "tooltips",
                  toggle.superImpose
                    ? "occlusion_isn_t_available_when_super_is_toggled"
                    : "occlusion_isn_t_available_when_elastics_is_toggled",
                )
              : getContent("tooltips", "show_occlusion")
          }
        />
      )}
      <Occ />
      <div>{getContent("labels", "occ")}</div>
    </div>,
    <div
      style={{
        display: isPatient ? "none" : "grid",
        padding: isMobile ? "0px 10px" : "0px",
      }}
      onClick={() =>
        toggle.show3DControls
          ? null
          : controller(
              "grid-view",
              controlsRef.current,
              controlsRef2.current,
              view,
              setView,
              setToggle,
              toggle,
              boltonInfo,
            )
      }
      className={`bounty_nav_icon ${
        toggle.show3DControls
          ? "bounty_icon_disabled"
          : toggle.grid
            ? "views_active"
            : ""
      }`}
      key={7}
    >
      {!isMobile && (
        <ToolTip
          text={getContent(
            "tooltips",
            toggle.show3DControls
              ? "grid_isn_t_available_when_3d_controls_is_toggled"
              : "show_occlusion",
          )}
        />
      )}
      <Grid />
      <div>{getContent("labels", "grid")}</div>
    </div>,
    <div
      style={{
        display: isMobile ? "none" : "grid",
      }}
      onClick={() => {
        controller(
          "split-view",
          controlsRef.current,
          controlsRef2.current,
          view,
          setView,
          setToggle,
          toggle,
          boltonInfo,
        );
      }}
      className={`bounty_nav_icon ${
        toggle.split
          ? "views_active"
          : toggle.superImpose ||
              toggle.show3DControls ||
              toggle.compareModifications
            ? "bounty_icon_disabled"
            : ""
      }`}
      key={8}
    >
      {!isMobile && (
        <ToolTip
          text={
            toggle.show3DControls || toggle.superImpose
              ? getContent(
                  "tooltips",
                  toggle.superImpose
                    ? "split_isn_t_available_when_super_is_toggled"
                    : "split_isn_t_available_when_3d_controls_is_toggled",
                )
              : getContent("tooltips", "show_occlusion")
          }
        />
      )}
      <Split />
      <div>{getContent("labels", "split")}</div>
    </div>,
    <div
      style={{
        display: isMobile || isPatient ? "none" : "grid",
      }}
      onClick={() =>
        controller(
          "numbers-view",
          controlsRef.current,
          controlsRef2.current,
          view,
          setView,
          setToggle,
          toggle,
          boltonInfo,
        )
      }
      className={`bounty_nav_icon ${toggle.numbering ? "views_active" : ""}`}
      key={9}
    >
      {!isMobile && <ToolTip text={getContent("tooltips", "show_numbering")} />}
      <Numbering />
      <div>{getContent("labels", "num")}</div>
    </div>,
    <div
      style={{
        display: isMobile || isPatient ? "none" : "grid",
      }}
      onClick={() =>
        !toggle.show3DControls && toggle.hasBiteJump && setToggle("biteJump")
      }
      className={`bounty_nav_icon ${
        toggle.show3DControls
          ? "bounty_icon_disabled"
          : toggle.hasBiteJump
            ? toggle.biteJump
              ? "views_active"
              : ""
            : "bounty_icon_disabled"
      }`}
      key={10}
    >
      {!isMobile && (
        <ToolTip
          text={
            toggle.show3DControls
              ? `${getContent("tooltips", "bitejump_isn_t_available_when_3d_controls_is_toggled")}`
              : `${getContent("tooltips", toggle.hasBiteJump ? "show_bitejump" : "the_current_ts_doesn't_bitejump")}`
          }
        />
      )}
      <Bite />
      <div>{getContent("labels", "bite")}</div>
    </div>,
    <div
      onClick={() => (toggle.show3DControls ? null : setToggle("showOverbite"))}
      className={cn("bounty_nav_icon grid", {
        views_active: toggle.showOverbite,
        "!hidden": isMobile || isPatient || isRefinementPlan,
        "mr-0": isMobile,
        bounty_icon_disabled: toggle.show3DControls,
      })}
      key={11}
    >
      {!isMobile && (
        <ToolTip
          text={
            toggle.show3DControls
              ? getContent(
                  "tooltips",
                  "ob_oj_isn_t_available_when_3d_controls_is_toggled",
                )
              : getContent(
                  "tooltips",
                  boltonInfo !== null
                    ? "show_ob_oj"
                    : "the_current_ts_doesn_t_have_any_ob_oj_information",
                )
          }
        />
      )}
      <OverbitejetIcon />
      <div>{getContent("labels", "ob_oj")}</div>
    </div>,
    <div
      style={{
        display: isMobile || isPatient ? "none" : "grid",
      }}
      onClick={() =>
        controller(
          "bolton-view",
          controlsRef.current,
          controlsRef2.current,
          view,
          setView,
          setToggle,
          toggle,
          boltonInfo,
        )
      }
      className={`bounty_nav_icon ${
        boltonInfo !== null
          ? toggle.showBolton
            ? "views_active"
            : ""
          : "bounty_icon_disabled"
      }`}
      key={12}
    >
      {!isMobile && (
        <ToolTip
          text={getContent(
            "tooltips",
            boltonInfo !== null
              ? "show_bolton"
              : "the_current_ts_doesn_t_have_any_bolton_information",
          )}
        />
      )}
      <BoltonIcon />
      <div>{getContent("labels", "bolton")}</div>
    </div>,
    <div
      style={{
        display: isMobile || isPatient ? "none" : "grid",
      }}
      onClick={() =>
        disableStaging || toggle.startRevision
          ? undefined
          : controller(
              "staging-view",
              controlsRef.current,
              controlsRef2.current,
              view,
              setView,
              setToggle,
              toggle,
              boltonInfo,
            )
      }
      className={`bounty_nav_icon ${
        disableStaging || toggle.startRevision
          ? "bounty_icon_disabled"
          : toggle.showStaging
            ? "views_active"
            : ""
      }`}
      key={13}
    >
      {!isMobile && (
        <ToolTip
          text={getContent(
            "tooltips",
            disableStaging
              ? "The_current_ts_doesn_t_have_any_staging_timeline_information"
              : "show_staging_movement_timeline",
          )}
        />
      )}
      <Staging />
      <div>{getContent("labels", "staging")}</div>
    </div>,
    <div
      style={{
        display: isMobile || isPatient ? "none" : "grid",
      }}
      onClick={() =>
        disableMovement
          ? undefined
          : controller(
              "movement-view",
              controlsRef.current,
              controlsRef2.current,
              view,
              setView,
              setToggle,
              toggle,
              boltonInfo,
            )
      }
      className={`bounty_nav_icon ${
        disableMovement
          ? "bounty_icon_disabled"
          : toggle.showMovement
            ? "views_active"
            : ""
      }`}
      key={14}
    >
      {!isMobile && (
        <ToolTip
          text={getContent(
            "tooltips",
            disableMovement
              ? "the_current_ts_doesn_t_have_any_movement_information"
              : "show_movement_table",
          )}
        />
      )}
      <TmtIcon className="w-6" />
      <div>{getContent("labels", "tmt")}</div>
    </div>,
    <div
      style={{
        display: isMobile || isPatient || withPatientInfo ? "none" : "grid",
      }}
      onClick={() =>
        !toggle.hasPhotosXrays
          ? null
          : controller(
              "records-view",
              controlsRef.current,
              controlsRef2.current,
              view,
              setView,
              setToggle,
              toggle,
              boltonInfo,
            )
      }
      className={`bounty_nav_icon ${
        !toggle.hasPhotosXrays
          ? "bounty_icon_disabled"
          : toggle.showRecords
            ? "views_active"
            : ""
      }`}
      key={15}
    >
      {!isMobile && (
        <ToolTip
          text={
            !toggle.hasPhotosXrays
              ? getContent(
                  "tooltips",
                  "this_case_doesn't_have_any_photos_x_rays",
                )
              : getContent("tooltips", "show_records")
          }
        />
      )}
      <RecordsIcon className="w-6" />
      <div>{getContent("labels", "records")}</div>
    </div>,
  ];

  const [icons, setIcons] = useState(initialIcons);
  const [dropDown, setDropDown] = useState([]);
  const [dropDownOpen, setDropDownOpen] = useState(false);

  const handleIcons = () => {
    const itemsToFit = Math.floor((window.innerWidth - 660) / 60);
    const how_many_asset_fit = isMobile
      ? 5
      : itemsToFit >= 1
        ? itemsToFit - 1
        : 0;
    setIcons(
      new Array(initialIcons.length)
        .fill(0)
        .map((_, index) => initialIcons[index])
        .slice(0, how_many_asset_fit),
    );
    setDropDown(
      new Array(initialIcons.length)
        .fill(0)
        .map((_, index) => initialIcons[index])
        .slice(how_many_asset_fit, initialIcons.length),
    );
  };

  useWindowResize(closeOnResize);
  useWindowResize(handleIcons);
  useEffect(() => {
    handleIcons();
  }, [toggle]);

  return (
    <div
      id={isMobile ? "bounty_render_controls_mobile" : "bounty_render_controls"}
      className="flex gap-[10px]"
      ref={renderRef}
      style={{ maxWidth: initialIcons.length * 60 + 5 }}
    >
      {icons.map((icon, i) => {
        return icon;
      })}
      {!isMobile && dropDown.length > 0 && (
        <>
          <div
            className="bounty_dropdown bounty_tools_icon"
            onClick={() => setDropDownOpen(() => !dropDownOpen)}
          >
            <div className="bounty_tools_div">
              <Tools />
              <div className="bounty_tools_arrow">
                <Arrow />
              </div>
            </div>

            <div>{getContent("labels", "tools")}</div>
            {dropDownOpen && (
              <div className="bounty_dropdown_content">
                {dropDown.map((icon, i) => {
                  return icon;
                })}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default Render;
