import React, { useEffect, useState, useCallback } from "react";
import { useGlobalStore } from "../../../store";
import { numberingUniversal } from "@/helpers/teethNumbering";
import { Plus, Tooth, EditIPR, EditSpacing, Minus, TrashCan } from "../Icons";
import { useRef } from "react";
import { MoveToothDistally, MoveToothMesially } from "./TeethPositionHelper";
import * as THREE from "three";
import {
  setToothMovementAmount,
  toothMovementAmount,
} from "./TeethPositionHelper";
import { useCustomTranslation } from "@/hooks/useTranslation";

function IPRSelector(props) {
  const {
    dentalNotation,
    iprOverLays,
    setIprOverlays,
    maxStepNumber,
    setAutoSave,
  } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [iprOnSelectedTooth, setIprOnSelectedTooth] = useState([]);
  const [totalAmountRight, setTotalAmountRight] = useState(0);
  const [totalAmountLeft, setTotalAmountLeft] = useState(0);
  const [addIPRSpacingRight, setAddIPRSpacingRight] = useState({
    active: false,
    IPROrSpacing: "",
  });
  const [addIPRSpacingLeft, setAddIPRSpacingLeft] = useState({
    active: false,
    IPROrSpacing: "",
  });
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // useEffect(() => {
  //   console.log(iprOnSelectedTooth);
  // }, [iprOnSelectedTooth]);

  const {
    selectedTooth,
    isUpper,
    logs,
    setLogs,
    scene,
    toggle,
    setToggle,
    groupRef,
  } = useGlobalStore(state => ({
    selectedTooth: state.controls3D.selectedTooth,
    isUpper: state.controls3D.isUpper,
    logs: state.logs,
    setLogs: state.setLogs,
    scene: state.scene,
    toggle: state.toggle,
    setToggle: state.setToggle,
    groupRef: state.refs.groupRef,
  }));

  let selectedToothNumber = Number(
    selectedTooth?.name.split("_")[2].split("t")[1],
  );

  const addIPRSpacing = type => {
    const isToothNameEqual = element => element.name === selectedTooth.name;
    const selectedTootIndex =
      selectedTooth?.parent?.children.findIndex(isToothNameEqual);
    let mesial;
    const box3 = new THREE.Box3().setFromObject(selectedTooth);

    const point = new THREE.Vector3();
    const pointForNeighborTooth = new THREE.Vector3();

    if (box3.getCenter(point).x < 0) {
      mesial = true;
    } else {
      mesial = false;
    }
    const box3ForNeighborTooth = new THREE.Box3().setFromObject(
      selectedTooth.parent.children[
        isUpper
          ? mesial
            ? selectedTootIndex + 1
            : selectedTootIndex - 1
          : mesial
            ? selectedTootIndex - 1
            : selectedTootIndex + 1
      ],
    );
    const moveSelectedTooth = moveFunction => {
      moveFunction(
        selectedTooth,
        logs,
        iprOverLays,
        setLogs,
        setIprOverlays,
        setAutoSave,
      );
    };

    const moveNextTooth = (indexOffset, moveFunction) => {
      const nextTooth =
        selectedTooth.parent.children[selectedTootIndex + indexOffset];
      nextTooth &&
        moveFunction(nextTooth, logs, [], setLogs, setIprOverlays, setAutoSave);
    };

    if (selectedTooth) {
      if (isUpper) {
        if (type === "ipr") {
          if (addIPRSpacingRight.active) {
            if (mesial) {
              moveSelectedTooth(MoveToothMesially);
              moveNextTooth(
                1,
                box3ForNeighborTooth.getCenter(pointForNeighborTooth).x < 0
                  ? MoveToothDistally
                  : MoveToothMesially,
              );
            } else {
              moveSelectedTooth(MoveToothDistally);
              moveNextTooth(1, MoveToothMesially);
            }
          } else {
            if (mesial) {
              moveSelectedTooth(MoveToothDistally);
              moveNextTooth(-1, MoveToothMesially);
            } else {
              moveSelectedTooth(MoveToothMesially);
              moveNextTooth(
                -1,
                box3ForNeighborTooth.getCenter(pointForNeighborTooth).x < 0
                  ? MoveToothMesially
                  : MoveToothDistally,
              );
            }
          }
        } else {
          if (addIPRSpacingRight.active) {
            if (mesial) {
              moveSelectedTooth(MoveToothDistally);
              moveNextTooth(
                1,
                box3ForNeighborTooth.getCenter(pointForNeighborTooth).x < 0
                  ? MoveToothMesially
                  : MoveToothDistally,
              );
            } else {
              moveSelectedTooth(MoveToothMesially);
              moveNextTooth(1, MoveToothDistally);
            }
          } else {
            if (mesial) {
              moveSelectedTooth(MoveToothMesially);
              moveNextTooth(-1, MoveToothDistally);
            } else {
              moveSelectedTooth(MoveToothDistally);
              moveNextTooth(
                -1,
                box3ForNeighborTooth.getCenter(pointForNeighborTooth).x < 0
                  ? MoveToothDistally
                  : MoveToothMesially,
              );
            }
          }
        }
      } else {
        if (type === "ipr") {
          if (addIPRSpacingRight.active) {
            if (mesial) {
              moveSelectedTooth(MoveToothMesially);
              moveNextTooth(
                -1,
                box3ForNeighborTooth.getCenter(pointForNeighborTooth).x < 0
                  ? MoveToothDistally
                  : MoveToothMesially,
              );
            } else {
              moveSelectedTooth(MoveToothDistally);
              moveNextTooth(-1, MoveToothMesially);
            }
          } else {
            if (mesial) {
              moveSelectedTooth(MoveToothDistally);
              moveNextTooth(1, MoveToothMesially);
            } else {
              moveSelectedTooth(MoveToothMesially);
              moveNextTooth(
                1,
                box3ForNeighborTooth.getCenter(pointForNeighborTooth).x < 0
                  ? MoveToothDistally
                  : MoveToothMesially,
              );
            }
          }
        } else {
          if (addIPRSpacingRight.active) {
            if (mesial) {
              moveSelectedTooth(MoveToothDistally);
              moveNextTooth(
                -1,
                box3ForNeighborTooth.getCenter(pointForNeighborTooth).x < 0
                  ? MoveToothMesially
                  : MoveToothMesially,
              );
            } else {
              moveSelectedTooth(MoveToothMesially);
              moveNextTooth(
                -1,
                box3ForNeighborTooth.getCenter(pointForNeighborTooth).x < 0
                  ? MoveToothDistally
                  : MoveToothDistally,
              );
            }
          } else {
            if (mesial) {
              moveSelectedTooth(MoveToothMesially);
              moveNextTooth(1, MoveToothDistally);
            } else {
              moveSelectedTooth(MoveToothDistally);
              moveNextTooth(
                box3ForNeighborTooth.getCenter(pointForNeighborTooth).x < 0
                  ? 1
                  : 1,
                box3ForNeighborTooth.getCenter(pointForNeighborTooth).x < 0
                  ? MoveToothMesially
                  : MoveToothMesially,
              );
            }
          }
        }
      }
    }

    addIPRSpacingRight.active
      ? setAddIPRSpacingRight(prevOptions => {
          return {
            ...prevOptions,
            active: false,
            IPROrSpacing: type,
          };
        })
      : setAddIPRSpacingLeft(prevOptions => {
          return {
            ...prevOptions,
            active: false,
            IPROrSpacing: type,
          };
        });
  };
  function findKeyByDentalNotation(value) {
    for (const key in numberingUniversal) {
      if (numberingUniversal.hasOwnProperty(key)) {
        if (numberingUniversal[key]["universal"].toString() === value) {
          return numberingUniversal[key]["universal"];
        }
      }
    }
    return null; // Return null if no match is found
  }

  useEffect(() => {
    const isToothNameEqual = element => element.name === selectedTooth.name;
    const selectedTootIndex =
      selectedTooth?.parent?.children.findIndex(isToothNameEqual);

    setIprOnSelectedTooth([]);
    if (iprOverLays.length > 0) {
      const iprArrayRight =
        iprOverLays[isUpper ? 0 : 1][
          selectedTooth?.parent?.children[selectedTootIndex]?.name.split("_")[2]
        ];
      const iprArrayLeft =
        iprOverLays[isUpper ? 0 : 1][
          selectedTooth?.parent?.children[
            isUpper ? selectedTootIndex - 1 : selectedTootIndex + 1
          ]?.name.split("_")[2]
        ];
      let totalAmountRight = 0;
      let totalAmountLeft = 0;
      let isSpacingRight = false;
      let isSpacingLeft = false;
      if (iprArrayRight && iprArrayRight.length > 0) {
        iprArrayRight.forEach((ipr, i) => {
          if (ipr.amount === null || isNaN(ipr.amount)) {
            return;
          }
          isSpacingRight = ipr?.amount > 0 ? false : true;
          totalAmountRight += Number(ipr?.amount);
        });
      }
      if (iprArrayLeft && iprArrayLeft.length > 0) {
        iprArrayLeft.forEach((ipr, i) => {
          if (ipr.amount === null || isNaN(ipr.amount)) {
            return;
          }
          isSpacingLeft = ipr?.amount > 0 ? false : true;
          totalAmountLeft += Number(ipr?.amount);
        });
      }

      if (
        Number(totalAmountRight.toFixed(2)) ||
        Number(totalAmountLeft.toFixed(2))
      ) {
        setAddIPRSpacingRight(prevOptions => {
          return {
            ...prevOptions,
            active: false,
          };
        });
        setAddIPRSpacingLeft(prevOptions => {
          return {
            ...prevOptions,
            active: false,
          };
        });
        setIprOnSelectedTooth([
          {
            amount: Number(totalAmountRight.toFixed(2)),
            right: true,
            fromTo: isUpper
              ? selectedToothNumber === 16
                ? `${numberingUniversal[selectedToothNumber][dentalNotation]} - ${numberingUniversal[selectedToothNumber][dentalNotation]}`
                : `${
                    numberingUniversal[selectedToothNumber][dentalNotation]
                  } - ${
                    numberingUniversal[selectedToothNumber + 1][dentalNotation]
                  }`
              : selectedToothNumber === 17
                ? `${numberingUniversal[selectedToothNumber][dentalNotation]} - ${numberingUniversal[selectedToothNumber][dentalNotation]}`
                : `${numberingUniversal[selectedToothNumber][dentalNotation]} - ${
                    numberingUniversal[selectedToothNumber - 1][dentalNotation]
                  }`,
            fromToTooth: isUpper
              ? selectedToothNumber === 16
                ? `${numberingUniversal[selectedToothNumber]["universal"]} - ${numberingUniversal[selectedToothNumber]["universal"]}`
                : `${numberingUniversal[selectedToothNumber]["universal"]} - ${
                    numberingUniversal[selectedToothNumber + 1]["universal"]
                  }`
              : selectedToothNumber === 17
                ? `${numberingUniversal[selectedToothNumber]["universal"]} - ${numberingUniversal[selectedToothNumber]["universal"]}`
                : `${numberingUniversal[selectedToothNumber]["universal"]} - ${
                    numberingUniversal[selectedToothNumber - 1]["universal"]
                  }`,
            isSpacing: isSpacingRight,
          },
          {
            amount: Number(totalAmountLeft.toFixed(2)),
            right: false,
            fromTo: isUpper
              ? selectedToothNumber === 16
                ? `${
                    numberingUniversal[selectedToothNumber - 1][dentalNotation]
                  } - ${
                    numberingUniversal[selectedToothNumber][dentalNotation]
                  }`
                : `${
                    numberingUniversal[selectedToothNumber - 1]
                      ? numberingUniversal[selectedToothNumber - 1][
                          dentalNotation
                        ]
                      : numberingUniversal[selectedToothNumber][dentalNotation]
                  } - ${
                    numberingUniversal[selectedToothNumber][dentalNotation]
                  }`
              : selectedToothNumber === 32
                ? `${numberingUniversal[selectedToothNumber][dentalNotation]} - ${numberingUniversal[selectedToothNumber][dentalNotation]}`
                : `${
                    numberingUniversal[selectedToothNumber + 1][dentalNotation]
                  } - ${numberingUniversal[selectedToothNumber][dentalNotation]}`,
            fromToTooth: isUpper
              ? selectedToothNumber === 16
                ? `${
                    numberingUniversal[selectedToothNumber - 1]["universal"]
                  } - ${numberingUniversal[selectedToothNumber]["universal"]}`
                : `${
                    numberingUniversal[selectedToothNumber - 1]
                      ? numberingUniversal[selectedToothNumber - 1]["universal"]
                      : numberingUniversal[selectedToothNumber]["universal"]
                  } - ${numberingUniversal[selectedToothNumber]["universal"]}`
              : selectedToothNumber === 32
                ? `${numberingUniversal[selectedToothNumber]["universal"]} - ${numberingUniversal[selectedToothNumber]["universal"]}`
                : `${
                    numberingUniversal[selectedToothNumber + 1]["universal"]
                  } - ${numberingUniversal[selectedToothNumber]["universal"]}`,
            isSpacing: isSpacingLeft,
          },
        ]);
      }
      setTotalAmountRight(Number(totalAmountRight.toFixed(2)));
      setTotalAmountLeft(Number(totalAmountLeft.toFixed(2)));
      //console.log({ totalAmountLeft, totalAmountRight });
    }
  }, [iprOverLays, selectedTooth]);

  const increaseOrDecreaseIPR = (
    iprOrSpacing,
    fromToTooth,
    isPlus,
    isDelete = false,
  ) => {
    !isDelete && setToothMovementAmount(toothMovementAmount / 2);
    // console.log(isSpacing, fromTo, plus);
    const isToothNameEqual = element =>
      element.name.split("t")[2] ==
      findKeyByDentalNotation(fromToTooth.split(" ")[0], dentalNotation);
    const selectedTootIndex =
      selectedTooth?.parent?.children.findIndex(isToothNameEqual);
    let left;
    const box3 = new THREE.Box3().setFromObject(selectedTooth);
    const point = new THREE.Vector3();

    if (box3.getCenter(point).x < 0) {
      left = true;
    } else {
      left = false;
    }

    const moveSelectedTooth = moveFunction => {
      moveFunction(
        selectedTooth.parent.children[selectedTootIndex],
        logs,
        iprOverLays,
        setLogs,
        setIprOverlays,
        setAutoSave,
      );
    };

    const moveNextTooth = moveFunction => {
      const nextTooth =
        selectedTooth.parent.children[selectedTootIndex + (isUpper ? 1 : -1)];
      nextTooth &&
        moveFunction(nextTooth, logs, [], setLogs, setIprOverlays, setAutoSave);
    };
    const isToothInSameQuarter = () => {
      const box3 = new THREE.Box3().setFromObject(
        selectedTooth.parent.children[selectedTootIndex],
      );
      const point = new THREE.Vector3();
      const nextToothBox3 = new THREE.Box3().setFromObject(
        selectedTooth.parent.children[selectedTootIndex + (isUpper ? 1 : -1)],
      );
      const nextToothPoint = new THREE.Vector3();
      if (
        (box3.getCenter(point).x < 0 &&
          nextToothBox3.getCenter(nextToothPoint).x < 0) ||
        (box3.getCenter(point).x > 0 &&
          nextToothBox3.getCenter(nextToothPoint).x > 0)
      ) {
        return true;
      } else {
        return false;
      }
    };
    if (iprOrSpacing) {
      // true => IPR
      console.log("IPR");
      if (isPlus) {
        if (isToothInSameQuarter()) {
          if (left) {
            moveSelectedTooth(MoveToothMesially);
            moveNextTooth(MoveToothDistally);
          } else {
            moveSelectedTooth(MoveToothDistally);
            moveNextTooth(MoveToothMesially);
          }
          console.log("isPlus same Q");
        } else {
          moveSelectedTooth(MoveToothMesially);
          moveNextTooth(MoveToothMesially);
          console.log("isPlus not same Q");
        }
      } else {
        if (isToothInSameQuarter()) {
          if (left) {
            moveSelectedTooth(MoveToothDistally);
            moveNextTooth(MoveToothMesially);
          } else {
            moveSelectedTooth(MoveToothMesially);
            moveNextTooth(MoveToothDistally);
          }
          console.log("not isPlus same Q");
        } else {
          moveSelectedTooth(MoveToothDistally);
          moveNextTooth(MoveToothDistally);
          console.log("not isPlus not same Q");
        }
      }
    } else {
      console.log("Spacing");
      if (isPlus) {
        if (isToothInSameQuarter()) {
          if (left) {
            moveSelectedTooth(MoveToothDistally);
            moveNextTooth(MoveToothMesially);
          } else {
            moveSelectedTooth(MoveToothMesially);
            moveNextTooth(MoveToothDistally);
          }
          console.log("isPlus same Q");
        } else {
          moveSelectedTooth(MoveToothDistally);
          moveNextTooth(MoveToothDistally);
          console.log("isPlus not same Q");
        }
      } else {
        if (isToothInSameQuarter()) {
          if (left) {
            moveSelectedTooth(MoveToothMesially);
            moveNextTooth(MoveToothDistally);
          } else {
            moveSelectedTooth(MoveToothDistally);
            moveNextTooth(MoveToothMesially);
          }
          console.log("not isPlus same Q");
        } else {
          moveSelectedTooth(MoveToothMesially);
          moveNextTooth(MoveToothMesially);
          console.log("not isPlus not same Q");
        }
      }
    }
    // if (iprOrSpacing * isPlus === -1) {
    //   if (isToothInSameQuarter()) {
    //     moveSelectedTooth(MoveToothMesially);
    //     moveNextTooth(MoveToothDistally);
    //     console.log("isPlus same Q");
    //   } else {
    //     moveSelectedTooth(MoveToothMesially);
    //     moveNextTooth(MoveToothMesially);
    //     console.log("isPlus not same Q");
    //   }
    // } else if (iprOrSpacing * isPlus === 1) {
    //   if (isToothInSameQuarter()) {
    //     moveSelectedTooth(MoveToothMesially);
    //     moveNextTooth(MoveToothDistally);
    //     console.log("notIsPlus same Q");
    //   } else {
    //     moveSelectedTooth(MoveToothDistally);
    //     moveNextTooth(MoveToothDistally);
    //     console.log("notIsPlus not same Q");
    //   }
    // }

    // if (isUpper) {
    //   if (!isSpacing) {
    //     if (mesial) {
    //       if (plus) {
    //         moveSelectedTooth(MoveToothMesially);
    //         moveNextTooth(
    //           1,
    //           NextToothMesialOrDistal(1)
    //             ? MoveToothDistally
    //             : MoveToothMesially,
    //         );
    //       } else {
    //         moveSelectedTooth(MoveToothDistally);
    //         moveNextTooth(
    //           1,
    //           NextToothMesialOrDistal(1)
    //             ? MoveToothMesially
    //             : MoveToothDistally,
    //         );
    //       }
    //     } else {
    //       if (plus) {
    //         moveSelectedTooth(MoveToothDistally);
    //         moveNextTooth(
    //           1,
    //           NextToothMesialOrDistal(1)
    //             ? MoveToothDistally
    //             : MoveToothMesially,
    //         );
    //       } else {
    //         moveSelectedTooth(MoveToothMesially);
    //         moveNextTooth(
    //           1,
    //           NextToothMesialOrDistal(1)
    //             ? MoveToothMesially
    //             : MoveToothDistally,
    //         );
    //       }
    //     }
    //   } else {
    //     if (mesial) {
    //       if (plus) {
    //         moveSelectedTooth(MoveToothDistally);
    //         moveNextTooth(
    //           1,
    //           NextToothMesialOrDistal(1)
    //             ? MoveToothDistally
    //             : MoveToothMesially,
    //         );
    //       } else {
    //         moveSelectedTooth(MoveToothMesially);
    //         moveNextTooth(
    //           1,
    //           NextToothMesialOrDistal(1)
    //             ? MoveToothDistally
    //             : MoveToothMesially,
    //         );
    //       }
    //     } else {
    //       if (plus) {
    //         moveSelectedTooth(MoveToothMesially);
    //         moveNextTooth(
    //           1,
    //           NextToothMesialOrDistal(1)
    //             ? MoveToothMesially
    //             : MoveToothDistally,
    //         );
    //       } else {
    //         moveSelectedTooth(MoveToothDistally);
    //         moveNextTooth(
    //           1,
    //           NextToothMesialOrDistal(1)
    //             ? MoveToothMesially
    //             : MoveToothDistally,
    //         );
    //       }
    //     }
    //   }
    // } else {
    //   if (!isSpacing) {
    //     if (mesial) {
    //       console.log("l 1");
    //       moveSelectedTooth(MoveToothMesially);
    //       moveNextTooth(
    //         1,
    //         NextToothMesialOrDistal(-1) ? MoveToothDistally : MoveToothMesially,
    //       );
    //     } else {
    //       console.log("l 2");
    //       moveSelectedTooth(MoveToothDistally);
    //       moveNextTooth(
    //         1,
    //         NextToothMesialOrDistal(-1) ? MoveToothMesially : MoveToothDistally,
    //       );
    //     }
    //   } else {
    //     if (mesial) {
    //       console.log("l 3");
    //       moveSelectedTooth(MoveToothDistally);
    //       moveNextTooth(
    //         1,
    //         NextToothMesialOrDistal(-1) ? MoveToothMesially : MoveToothDistally,
    //       );
    //     } else {
    //       console.log("l 4");
    //       moveSelectedTooth(MoveToothMesially);
    //       moveNextTooth(
    //         1,
    //         NextToothMesialOrDistal(-1) ? MoveToothDistally : MoveToothMesially,
    //       );
    //     }
    //   }
    // }
    !isDelete && setToothMovementAmount(toothMovementAmount * 2);
  };
  const deleteIPR = (fromToTooth, isSpacing) => {
    const newArray = JSON.parse(JSON.stringify(iprOverLays)); //create deep copy of ipr array
    const tooth = "t" + fromToTooth.split(" ")[0];

    let i = newArray[isUpper ? 0 : 1][tooth].length - 1;
    //console.log(newArray[isUpper ? 0 : 1], tooth);
    do {
      increaseOrDecreaseIPR(isSpacing, fromToTooth, false, true);
      i--;
    } while (
      i >= 0 &&
      newArray[isUpper ? 0 : 1][tooth][i].amount !== 0 &&
      newArray[isUpper ? 0 : 1][tooth][i].amount !== null
    );
  };
  const fromToRef = useRef(null);
  const renderOcc = () => {
    if (toggle.occ) {
      setToggle("occ");
    }
  };

  const { content } = useCustomTranslation();

  const getContent = (group, key) => {
    return content(`controls.${group}.${key}`);
  };
  return (
    <div
      className="bounty_tooth_position"
      style={{ zIndex: "99999", position: "relative" }}
    >
      <div className="bounty_tooth_position_div_1">
        <div id="ipr_and_spacing" className="text-sm font-medium">
          {getContent("labels", "ipr_spacing")}
        </div>
        <div
          onClick={toggleModal}
          style={{ cursor: "pointer", zIndex: "99" }}
          className={isModalOpen ? "arrow arrow_active" : "arrow"}
        />
      </div>
      {!isModalOpen && (
        <div>
          <div style={{ display: "grid", justifyItems: "center" }}>
            <div className="bounty_divider" />
          </div>
          <div>
            <div
              className={`bounty_tooth_position_div_2 ${
                selectedTooth?.userData["isLocked"] || !selectedTooth
                  ? "bounty_controls_disabled"
                  : ""
              }`}
            >
              <div className="relative flex flex-wrap items-center justify-center gap-3">
                <div>
                  <div>
                    {selectedToothNumber
                      ? isUpper
                        ? numberingUniversal[selectedToothNumber - 1]
                          ? numberingUniversal[selectedToothNumber - 1][
                              dentalNotation
                            ]
                          : numberingUniversal[selectedToothNumber][
                              dentalNotation
                            ]
                        : numberingUniversal[selectedToothNumber + 1]
                          ? numberingUniversal[selectedToothNumber + 1][
                              dentalNotation
                            ]
                          : numberingUniversal[selectedToothNumber][
                              dentalNotation
                            ]
                      : "--"}
                  </div>
                  <Tooth />
                </div>
                <div
                  className="add_ipr_spacing"
                  style={{
                    cursor: selectedTooth ? "pointer" : "not-allowed",
                    backgroundColor:
                      totalAmountRight !== 0
                        ? totalAmountRight > 0
                          ? "#009ACE"
                          : "#FFF1A8"
                        : "white",
                    right: "55px",
                    border: addIPRSpacingRight.active
                      ? "2px solid #009ACE"
                      : "unset",
                  }}
                  onClick={
                    selectedTooth
                      ? () => {
                          setAddIPRSpacingLeft(prevOptions => {
                            return {
                              ...prevOptions,
                              active: false,
                            };
                          });
                          setAddIPRSpacingRight(prevOptions => {
                            return {
                              ...prevOptions,
                              active: !addIPRSpacingRight.active,
                            };
                          });
                        }
                      : null
                  }
                >
                  {Math.abs(totalAmountRight) > 0 ? (
                    <div
                      style={{
                        display: "grid",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                        width: "100%",
                        color:
                          totalAmountRight !== 0
                            ? totalAmountRight > 0
                              ? "white"
                              : "black"
                            : "white",
                      }}
                    >
                      {Math.abs(totalAmountRight).toFixed(2)}
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "grid",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="h-5 w-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 4.5v15m7.5-7.5h-15"
                        />
                      </svg>
                    </div>
                  )}
                </div>

                <div className="selected_tooth">
                  <div>
                    {selectedTooth
                      ? selectedToothNumber
                        ? numberingUniversal[selectedToothNumber][
                            dentalNotation
                          ]
                        : "--"
                      : "--"}
                  </div>
                  <Tooth />
                </div>
                <div
                  className="add_ipr_spacing"
                  style={{
                    cursor: selectedTooth ? "pointer" : "not-allowed",
                    backgroundColor:
                      totalAmountLeft !== 0
                        ? totalAmountLeft > 0
                          ? "#009ACE"
                          : "#FFF1A8"
                        : "white",
                    left: "55px",
                    border: addIPRSpacingLeft.active
                      ? "2px solid #009ACE"
                      : "unset",
                  }}
                  onClick={
                    selectedTooth
                      ? () => {
                          setAddIPRSpacingRight(prevOptions => {
                            return {
                              ...prevOptions,
                              active: false,
                            };
                          });
                          setAddIPRSpacingLeft(prevOptions => {
                            return {
                              ...prevOptions,
                              active: !addIPRSpacingLeft.active,
                            };
                          });
                        }
                      : null
                  }
                >
                  {Math.abs(totalAmountLeft) > 0 ? (
                    <div
                      style={{
                        display: "grid",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                        width: "100%",
                        color:
                          totalAmountLeft !== 0
                            ? totalAmountLeft > 0
                              ? "white"
                              : "black"
                            : "white",
                      }}
                    >
                      {Math.abs(totalAmountLeft).toFixed(2)}
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "grid",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="h-5 w-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 4.5v15m7.5-7.5h-15"
                        />
                      </svg>
                    </div>
                  )}
                </div>
                <div>
                  <div>
                    {selectedToothNumber
                      ? isUpper
                        ? numberingUniversal[selectedToothNumber + 1]
                          ? numberingUniversal[selectedToothNumber + 1][
                              dentalNotation
                            ]
                          : numberingUniversal[selectedToothNumber][
                              dentalNotation
                            ]
                        : numberingUniversal[selectedToothNumber - 1]
                          ? numberingUniversal[selectedToothNumber - 1][
                              dentalNotation
                            ]
                          : numberingUniversal[selectedToothNumber][
                              dentalNotation
                            ]
                      : "--"}
                  </div>
                  <Tooth />
                </div>
              </div>
            </div>
          </div>
          {(addIPRSpacingRight.active || addIPRSpacingLeft.active) && (
            <>
              <div
                className="adjust_spacing text-sm font-medium"
                onClick={() => {
                  selectedTooth?.userData["isLocked"] || !selectedTooth
                    ? null
                    : addIPRSpacing("spacing"),
                    renderOcc();
                }}
              >
                {getContent("actions", "adjust_spacing")}
              </div>
              <div
                className="adjust_IPR text-sm font-medium"
                onClick={() => {
                  selectedTooth?.userData["isLocked"] || !selectedTooth
                    ? null
                    : addIPRSpacing("ipr"),
                    renderOcc();
                }}
              >
                {getContent("actions", "adjust_ipr")}
              </div>
            </>
          )}
        </div>
      )}
      <div>
        {iprOnSelectedTooth.map((ipr, i) => {
          return (
            Math.abs(ipr.amount) > 0 && (
              <div key={i}>
                <div className="text-left" ref={fromToRef}>
                  {ipr.fromTo}
                </div>
                <div
                  className={`${
                    selectedTooth?.userData["isLocked"] || !selectedTooth
                      ? "bounty_controls_disabled"
                      : ""
                  } mt-2 flex items-center justify-around`}
                >
                  <div className="tooth_movement_icon_div">
                    {ipr.isSpacing ? <EditSpacing /> : <EditIPR />}
                  </div>
                  <div className="tooth_position_value">
                    {Number(Math.abs(ipr.amount)).toFixed(2)}
                  </div>
                  <div
                    onClick={() => {
                      selectedTooth?.userData["isLocked"] || !selectedTooth
                        ? null
                        : increaseOrDecreaseIPR(
                            ipr.isSpacing ? 0 : 1,
                            ipr.fromToTooth,
                            0,
                          ),
                        renderOcc(),
                        setAutoSave();
                    }}
                    className={
                      selectedTooth?.userData["isLocked"] || !selectedTooth
                        ? ""
                        : "bounty_minus"
                    }
                    style={{
                      cursor:
                        selectedTooth?.userData["isLocked"] || !selectedTooth
                          ? "not-allowed"
                          : "pointer",
                    }}
                  >
                    <Minus />
                  </div>
                  <div
                    onClick={() => {
                      selectedTooth?.userData["isLocked"] || !selectedTooth
                        ? null
                        : increaseOrDecreaseIPR(
                            ipr.isSpacing ? 0 : 1,
                            ipr.fromToTooth,
                            1,
                          ),
                        renderOcc(),
                        setAutoSave();
                    }}
                    className={
                      selectedTooth?.userData["isLocked"] || !selectedTooth
                        ? ""
                        : "bounty_plus"
                    }
                    style={{
                      cursor:
                        selectedTooth?.userData["isLocked"] || !selectedTooth
                          ? "not-allowed"
                          : "pointer",
                    }}
                  >
                    <Plus />
                  </div>
                  {/* <div
                    onClick={() => {
                      deleteIPR(ipr.fromToTooth, ipr.isSpacing ? 0 : 1, true),
                        renderOcc(),
                        setAutoSave();
                    }}
                    className={
                      selectedTooth?.userData["isLocked"] || !selectedTooth
                        ? ""
                        : "bounty_plus"
                    }
                    style={{
                      cursor:
                        selectedTooth?.userData["isLocked"] || !selectedTooth
                          ? "not-allowed"
                          : "pointer",
                    }}
                  >
                    <TrashCan />
                  </div> */}
                </div>
              </div>
            )
          );
        })}
      </div>
    </div>
  );
}

export default IPRSelector;
