import { SavingIcon, SavedIcon } from "../viewer/Icons";
import moment from "moment";
import "./autosave.scss";
import { useCustomTranslation } from "@/hooks/useTranslation";

export const Saving = () => {
  const { content } = useCustomTranslation();

  const getContent = (group, key) => {
    return content(`controls.${group}.${key}`);
  };
  return (
    <div className="autosave top-[80px] bg-[#525252] p-[10px]">
      <div className="rotating">
        <SavingIcon />
      </div>
      <div className="text-xs font-medium text-[white]">
        {getContent("labels", "saving")}..
      </div>
    </div>
  );
};
export const ChangeSaved = autoSaveDate => {
  const { content } = useCustomTranslation();

  const getContent = (group, key) => {
    return content(`controls.${group}.${key}`);
  };
  return (
    <div className="autosave top-[80px] bg-[#EBFFED] p-[10px]">
      <div className="rotating">
        <SavedIcon />
      </div>
      <div>
        <div className="text-xs font-medium text-[#21A300]">
          {getContent("labels", "all_changes_saved")}
        </div>
        <div className="text-xs font-medium text-[#535353]">
          {getContent("labels", "modified")}
          {moment(autoSaveDate.autoSaveDate).format("h:mm A, DD/MM/YYYY")}
        </div>
      </div>
    </div>
  );
};
