import { useCustomTranslation } from "@/hooks/useTranslation";
import React from "react";

export default function ViewerNotFound(props) {
  const { isTSViewerFound, complementaryColor } = props;
  const { content } = useCustomTranslation();

  const getContent = (group, key) => {
    return content(`other.${group}.${key}`);
  };
  return (
    <div
      className={"bounty_viewer_not_found"}
      style={
        isTSViewerFound
          ? { display: "none" }
          : { display: "block", color: complementaryColor }
      }
    >
      <div className={"bounty_viewer_not_found_text"}>
        {getContent("labels", "treatment_plan_not_found")}
      </div>
    </div>
  );
}
