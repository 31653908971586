import {
  actions_logs,
  getMovementName,
  movement_dictionary,
} from "@/components/viewer/modifications/helpers";

export const findKeyByValue = (obj, value) => {
  for (let key in obj) {
    if (obj[key].includes(value)) {
      return key;
    }
  }
  return null;
};

export const generateModificationsList = data => {
  let result = [];
  // Check if the value of the 'amount' key is not equal to 0 for all entries
  for (const tooth in data) {
    if (tooth.endsWith("_IPR")) {
      const amount = data[tooth]?.amount ?? 0;
      if (
        amount !== 0 &&
        amount.toFixed(2) !== "-0.00" &&
        amount.toFixed(2) !== "0.00"
      ) {
        // Determine if it's IPR or spacing
        const spacing_ipr = amount > 0 ? "IPR" : "spacing";
        // Determine the action (e.g., IPR Added, Spacing Added)
        const ipr_spacing_action = `${spacing_ipr} ${actions_logs[data[tooth]?.action]}`;
        result.push(ipr_spacing_action);
      }
    } else if (tooth.endsWith("_Movement")) {
      for (const movement in data[tooth]) {
        if (data[tooth][movement] !== 0) {
          if (
            movement === "attachment" &&
            data[tooth][movement].action !== ""
          ) {
            // Determine the action for attachment (e.g., Attachment Added, Attachment Removed)
            const attachment_action = `Attachment ${actions_logs[data[tooth]?.[movement]?.action]}`;
            result.push(attachment_action);
          } else {
            // Get movement key
            const keyFromMapper = findKeyByValue(movement_dictionary, movement);
            // Get movement name
            const name = getMovementName(
              tooth.replace("_Movement", ""),
              keyFromMapper,
              data[tooth][movement],
            );
            result.push(name);
          }
        }
      }
    } else {
      continue; // Skip non-'_IPR' entries
    }
  }
  return result;
};

// Determine the Revision Method
export const checkRevisionMethod = data => {
  const logsLength = Object.keys(data?.logs)?.length || 0;
  const hasRevisionNotes = data?.revisionNotes !== "";

  if (logsLength > 0) {
    return hasRevisionNotes ? "Both" : "3D Controls";
  } else {
    return hasRevisionNotes ? "Text" : "";
  }
};
