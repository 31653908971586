import { useGlobalStore } from "@/store";
import React from "react";

export const TableStepper = ({ maxStepNumber }) => {
  const { currentStep, setStepperControls } = useGlobalStore(state => ({
    currentStep: state.stepperControls.currentStep,
    setStepperControls: state.setStepperControls,
  }));

  return (
    <input
      className="slider left-4 top-0 h-[calc(100%+7px)] w-[calc(100%-2rem)]"
      name="stepper"
      min={0}
      max={maxStepNumber}
      value={currentStep}
      type="range"
      onPointerDown={e => {
        setStepperControls("isDragging", true);
        setStepperControls("animate", false);
        setStepperControls("currentStep", Number(e.target.value));
        setStepperControls("storedStep", Number(e.target.value));
      }}
      onPointerUp={e => {
        setStepperControls("isDragging", false);
        e.currentTarget.blur();
      }}
      onChange={e => {
        setStepperControls("currentStep", Number(e.target.value));
        setStepperControls("storedStep", Number(e.target.value));
      }}
    />
  );
};
