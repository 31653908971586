import React, { useEffect, useRef, useCallback } from "react";
import {
  BackStep,
  ForwardStep,
  SkipFirst,
  SkipLast,
  Play,
  PlayMobile,
  Pause,
  PauseMobile,
} from "../Icons";
import { useGlobalStore } from "../../../store";
function Player({ maxStepNumber, className }) {
  const {
    controlsRef,
    controlsRef2,
    isMobile,
    split,
    startRevision,
    animate,
    currentStep,
    storedStep,
    setStepperControls,
    stepperRef,
  } = useGlobalStore(state => ({
    controlsRef: state.refs.controlsRef,
    controlsRef2: state.refs.controlsRef2,
    isMobile: state.layoutConfig.isMobile,
    split: state.toggle.split,
    startRevision: state.toggle.startRevision,
    animate: state.stepperControls.animate,
    currentStep: state.stepperControls.currentStep,
    storedStep: state.stepperControls.storedStep,
    setStepperControls: state.setStepperControls,
    stepperRef: state.stepperControls.stepperRef,
  }));

  const intervalId = useRef(null);
  const camera = useGlobalStore(state => state.computed.camera);

  const animateStep = currentStep => {
    if (currentStep === maxStepNumber) {
      setStepperControls("currentStep", 0);
    }
    intervalId.current = setInterval(() => {
      const latestCurrentStep =
        useGlobalStore.getState().stepperControls.currentStep;
      if (latestCurrentStep === maxStepNumber) {
        setStepperControls("animate", false);
        setStepperControls("storedStep", maxStepNumber);
        setStepperControls("currentStep", maxStepNumber);
        clearInterval(intervalId.current);
      } else {
        setStepperControls("currentStep", latestCurrentStep + 1);
      }
    }, 500);
  };

  useEffect(() => {
    if (!animate) {
      clearInterval(intervalId.current);
    }
  }, [animate]);

  const animateShortcuts = useCallback(
    e => {
      if (e.key === "+" || e.key === "=") {
        controlsRef.current.zoom((camera.zoom = +0.25), true);
        controlsRef2.current.zoom((camera.zoom += 0.25), true);
      }
      if (e.key === "-") {
        controlsRef.current.zoom((camera.zoom = -0.25), true);
        controlsRef2.current.zoom((camera.zoom -= 0.25), true);
      }
      if (!split) {
        if (e.key === " ") {
          if (!animate) {
            animateStep(currentStep);
          } else {
            clearInterval(intervalId.current);
            setStepperControls("storedStep", currentStep);
            stepperRef.current.value = currentStep;
          }
          setStepperControls("animate", !animate);
        }
        if (e.key === "ArrowRight") {
          if (currentStep === maxStepNumber) {
            return;
          } else {
            setStepperControls("currentStep", currentStep + 1);
            setStepperControls("storedStep", currentStep + 1);
          }
        }
        if (e.key === "ArrowLeft") {
          if (currentStep === 0) {
            return;
          } else {
            setStepperControls("currentStep", currentStep - 1);
            setStepperControls("storedStep", currentStep - 1);
          }
        }
        if (e.key === "ArrowUp") {
          if (currentStep === maxStepNumber) {
            return;
          } else {
            setStepperControls("currentStep", maxStepNumber);
            setStepperControls("storedStep", maxStepNumber);
          }
        }
        if (e.key === "ArrowDown") {
          if (currentStep === 0) {
            return;
          } else {
            setStepperControls("currentStep", 0);
            setStepperControls("storedStep", 0);
          }
        }
      }
    },
    [currentStep, storedStep, animate, maxStepNumber, split],
  );

  useEffect(() => {
    clearInterval(intervalId.current);
    if (split) {
      setStepperControls("animate", false);
      setStepperControls("currentStep", 0);
    } else {
      setStepperControls("currentStep", storedStep);
    }
  }, [split]);

  useEffect(() => {
    window.addEventListener("keydown", animateShortcuts);
    return () => {
      window.removeEventListener("keydown", animateShortcuts);
    };
  }, [animateShortcuts]);

  return (
    <div
      id={
        maxStepNumber > 50 && window.innerWidth < 720 && !isMobile
          ? "bounty_player_large_steps"
          : isMobile
          ? "bounty_player_mobile"
          : "bounty_player"
      }
      className={className}
    >
      {!isMobile && (
        <div
          onClick={() => {
            if (currentStep === 0) {
              return;
            } else {
              setStepperControls("currentStep", 0);
              setStepperControls("storedStep", 0);
            }
          }}
        >
          <SkipFirst />
        </div>
      )}
      {!isMobile && (
        <div
          onClick={() => {
            if (currentStep === 0) {
              return;
            } else {
              setStepperControls("currentStep", currentStep - 1);
              setStepperControls("storedStep", currentStep - 1);
            }
          }}
        >
          <BackStep />
        </div>
      )}
      <div
        className="bounty_play_pause"
        style={{ height: isMobile ? "39px" : "unset" }}
        onClick={() => {
          if (!animate) {
            animateStep(currentStep);
          } else {
            clearInterval(intervalId.current);
            setStepperControls("storedStep", currentStep);
            stepperRef.current.value = currentStep;
          }
          setStepperControls("animate", !animate);
        }}
      >
        {!animate ? (
          isMobile ? (
            <PlayMobile />
          ) : (
            <Play />
          )
        ) : isMobile ? (
          <PauseMobile />
        ) : (
          <Pause />
        )}
      </div>
      {!isMobile && (
        <div
          onClick={() => {
            if (currentStep === maxStepNumber) {
              return;
            } else {
              setStepperControls("currentStep", currentStep + 1);
              setStepperControls("storedStep", currentStep + 1);
            }
          }}
        >
          <ForwardStep />
        </div>
      )}
      {!isMobile && (
        <div
          onClick={() => {
            if (currentStep === maxStepNumber) {
              return;
            } else {
              setStepperControls("currentStep", maxStepNumber);
              setStepperControls("storedStep", maxStepNumber);
            }
          }}
        >
          <SkipLast />
        </div>
      )}
    </div>
  );
}

export default Player;
