import { CopyCheckIcon, CopyIcon } from "@/assets";
import React from "react";
import toast, { Toaster } from "react-hot-toast";

export const CopyToClipboard = ({ text, issueType }) => {
  const notify = () => {
    toast("Copied to Clipboard", {
      icon: <CopyCheckIcon />,
      style: {
        borderRadius: "10px",
        background: "#333",
        color: "#fff",
      },
    });
  };

  const handleClipboard = async () => {
    const copyText = formatCopyData(text);
    await navigator.clipboard.writeText(copyText);
    notify();
  };

  const formatCopyData = data => {
    let formattedData = "";
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const issues = data[key];
        issues.forEach(issue => {
          if (issue.applied) {
            // selected
            formattedData += `${issueType[issue.issueIdentifier]} :\n on tooth ${issue.detectedAtTooth} at Stage ${issue.detectedAtStages.join(", ")}\n`;
          }
        });
      }
    }
    return formattedData;
  };
  return (
    <div
      onClick={handleClipboard}
      className="grid  cursor-pointer  bg-[#ffff] grid-cols-[auto,auto]  place-content-center   gap-2 rounded border border-[#c7c6c6]  p-2   shadow-md  "
    >
      <CopyIcon />{" "}
      <button className="text-[14px] text-[#6D6D6D]">
        Copy selected issues to clipboard
      </button>
      <Toaster position="bottom-center" reverseOrder={false} />
    </div>
  );
};
