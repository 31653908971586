import React, { useEffect, useRef } from "react";
import { CameraControls, View } from "@react-three/drei";
import Lights from "./Lights";
import Mesh from "./Mesh";
import Camera from "./Camera";
import Split from "./Split";
import { useGlobalStore } from "../../store";
import { Canvas } from "@react-three/fiber";
import { cn } from "@/helpers/functions";
import { isIpad } from "@/helpers/detectBrowser";

export const ThreeJsViews = ({
  tpLoaded,
  setTpLoaded,
  maxStepNumber,
  model,
  setModel,
  view,
  GLBData,
  fakeAttachmentReplacer,
  iprOverLays,
  setIprOverlays,
  numberingOverLays,
  isViewerControlsEnabled,
  teethTransformationData,
  redoAllComparingMode,
  ponticsData,
  setAutoSave,
  biteJumpsSteps,
}) => {
  const view1 = useRef(null);
  const view2 = useRef(null);

  const canvas = useRef();
  const orthographicCameraRef = useRef();
  const perspectiveCameraRef = useRef();
  const orthographicCameraRef2 = useRef();
  const perspectiveCameraRef2 = useRef();
  const controlsRef = useRef();
  const controlsRef2 = useRef();

  const {
    camera,
    camera2,
    grid,
    isMobile,
    leftMouse,
    setRefs,
    toggle,
    backGroundColor,
    orientation,
  } = useGlobalStore(state => ({
    ...state.computed,
    leftMouse: state.layoutConfig.leftMouse,
    isMobile: state.layoutConfig.isMobile,
    grid: state.toggle.grid,
    setRefs: state.setRefs,
    toggle: state.toggle,
    backGroundColor: state.layoutConfig.backGroundColor,
    orientation: state.layoutConfig.orientation,
  }));

  useEffect(() => {
    setRefs({
      canvas,
      orthographicCameraRef,
      perspectiveCameraRef,
      orthographicCameraRef2,
      perspectiveCameraRef2,
      controlsRef,
      controlsRef2,
    });
  }, []);

  const getViewerHeight = () => {
    if (!tpLoaded) return "100vh";
    if (isMobile) return "calc(100vh - 120px)";
    if (isIpad) return "calc(100vh - 190px)";
    if (toggle.showMovement) return "calc(100vh - 430px)";
    if (toggle.split || toggle.compareModifications)
      return "calc(100vh - 60px)";
    return "calc(100vh - 120px)";
  };

  return (
    <>
      <div
        ref={view1}
        id="view1"
        className={`absolute overflow-hidden ${!isMobile && tpLoaded ? "top-[60px]" : ""} ${
          toggle.split || toggle.compareModifications
            ? "border-r-2 border-[#2b2f2e]"
            : ""
        } ${
          toggle.split || toggle.compareModifications ? "w-[50%]" : "w-full"
        }`}
        style={{
          height: getViewerHeight(),
          backgroundColor: backGroundColor, // Use inline style for dynamic background color
        }}
      />
      <div
        ref={view2}
        id="view2"
        className={`absolute left-[50%] top-[60px]  w-[50%] overflow-hidden border-l-2 border-[#2b2f2e] ${
          toggle.split || toggle.compareModifications ? "block" : "hidden"
        }`}
        style={{
          height: getViewerHeight(),
          backgroundColor: backGroundColor, // Use inline style for dynamic background color
        }}
      />
      <div
        id="canvas"
        style={{
          height: getViewerHeight(),
          gridRow: isMobile ? "1" : "2",
        }}
      >
        <Canvas
          ref={canvas}
          //frameloop="demand"
          //eventSource={canvas}
          gl={{
            antialias: true,
            physicallyCorrectLights: true,
            ColorManagement: true,
          }}
          shadows={true}
        >
          <View index={1} track={view1}>
            {tpLoaded && (
              <>
                {/* <axesHelper args={[100]} /> */}
                <Camera
                  orthographicCameraRef={orthographicCameraRef}
                  perspectiveCameraRef={perspectiveCameraRef}
                  grid={grid}
                  orientation={orientation}
                  isMobile={isMobile}
                  cameraControls={controlsRef}
                  cameraControls2={controlsRef2}
                  view={view}
                />
                <Lights />

                <Mesh
                  redoAllComparingMode={redoAllComparingMode}
                  setAutoSave={setAutoSave}
                  iprOverLays={iprOverLays}
                  setIprOverlays={setIprOverlays}
                  tpLoaded={tpLoaded}
                  setTpLoaded={setTpLoaded}
                  maxStepNumber={maxStepNumber}
                  model={model}
                  setModel={setModel}
                  view={view}
                  numberingOverLays={numberingOverLays}
                  GLBData={GLBData}
                  fakeAttachmentReplacer={fakeAttachmentReplacer}
                  perspectiveCameraRef={perspectiveCameraRef}
                  controlsRef={controlsRef}
                  isViewerControlsEnabled={isViewerControlsEnabled}
                  biteJumpsSteps={biteJumpsSteps}
                  teethTransformationData={teethTransformationData}
                  ponticsData={ponticsData}
                />
              </>
            )}
          </View>
          <View index={2} track={view2}>
            {tpLoaded && (
              <>
                <Camera
                  orthographicCameraRef={orthographicCameraRef2}
                  perspectiveCameraRef={perspectiveCameraRef2}
                  grid={grid}
                  orientation={orientation}
                  isMobile={isMobile}
                  cameraControls2={controlsRef2}
                />
                <Lights />
                <Split
                  maxStepNumber={maxStepNumber}
                  model={model}
                  toggle={toggle}
                  GLBData={GLBData}
                  fakeAttachmentReplacer={fakeAttachmentReplacer}
                  biteJumpsSteps={biteJumpsSteps}
                />
              </>
            )}
          </View>
          <CameraControls
            ref={controlsRef}
            camera={camera}
            mouseButtons={{
              left: leftMouse === "rotate" ? 1 : 4,
              wheel: 16,
              right: 4,
            }}
            maxDistance={850}
            minDistance={25}
            minZoom={grid ? (window.innerWidth > 1900 ? 6 : 3.5) : 0.25}
            maxZoom={
              isMobile
                ? 750
                : grid
                  ? window.innerWidth > 1900
                    ? 1500
                    : 1250
                  : 30
            }
            dollySpeed={grid && isMobile ? 10 : 0.5}
            azimuthRotateSpeed={0.5}
            polarRotateSpeed={0.5}
          />
          <CameraControls
            ref={controlsRef2}
            camera={camera2}
            mouseButtons={{
              left: leftMouse === "rotate" ? 1 : 4,
              wheel: 16,
              right: 4,
            }}
            maxDistance={850}
            minDistance={25}
            maxZoom={
              isMobile
                ? 750
                : grid
                  ? window.innerWidth > 1900
                    ? 1500
                    : 1250
                  : 30
            }
            minZoom={grid ? (window.innerWidth > 1900 ? 6 : 3.5) : 0.25}
            dollySpeed={grid && isMobile ? 10 : 0.5}
            azimuthRotateSpeed={0.5}
            polarRotateSpeed={0.5}
          />
        </Canvas>
      </div>
    </>
  );
};
