import { isAppleMobile } from "@/helpers/detectBrowser";
import { useGlobalStore } from "@/store";
import React, { useCallback, useEffect, useRef } from "react";

export const InputStepGrabber = ({ maxStepNumber, tpLoaded }) => {
  const stepperRef = useRef(null);

  const { animate, currentStep, storedStep, setStepperControls, split } =
    useGlobalStore(state => ({
      animate: state.stepperControls.animate,
      currentStep: state.stepperControls.currentStep,
      storedStep: state.stepperControls.storedStep,
      setStepperControls: state.setStepperControls,
      split: state.toggle.split,
    }));

  useEffect(() => {
    split
      ? setStepperControls("currentStep", 0)
      : setStepperControls("currentStep", storedStep);
  }, [split]);

  const handleMouseMove = e => {
    let w = stepperRef.current.clientWidth;
    let x = e.offsetX;
    let percents = x / w;
    let max = parseInt(stepperRef.current.max);
    setStepperControls("storedStep", Math.floor(percents * max + 0.5));
  };

  const handleMouseOut = e => {
    setStepperControls(
      "storedStep",
      useGlobalStore.getState().stepperControls.currentStep,
    );
  };

  useEffect(() => {
    stepperRef?.current.addEventListener("mousemove", handleMouseMove);
    stepperRef?.current.addEventListener("mouseout", handleMouseOut);
    return () => {
      stepperRef?.current?.removeEventListener("mousemove", handleMouseMove);
      stepperRef?.current?.removeEventListener("mouseout", handleMouseOut);
    };
  }, [split]);

  const updateThumbPosition = useCallback(event => {
    const touch = event.touches?.[0]
      ? event.touches?.[0].clientX
      : event.clientX;

    const rangeRect = stepperRef.current.getBoundingClientRect();
    const offsetX = touch - rangeRect.left;
    let w = stepperRef.current.clientWidth;
    let x = offsetX;
    let percents = x / w;
    let max = parseInt(stepperRef.current.max);
    if (
      Math.floor(percents * max + 0.5) >= 0 &&
      Math.floor(percents * max + 0.5) <= max
    ) {
      setStepperControls("currentStep", Math.floor(percents * max + 0.5));
      setStepperControls("storedStep", Math.floor(percents * max + 0.5));
    }
  });

  useEffect(() => {
    window.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        if (tpLoaded) {
          setStepperControls("animate", true);
          setTimeout(() => setStepperControls("animate", false), 500);
        }
      }
    });
  }, [tpLoaded, animate]);

  useEffect(() => {
    setStepperControls("stepperRef", stepperRef);

    if (isAppleMobile()) {
      stepperRef?.current.addEventListener("pointermove", updateThumbPosition);
    }
    return () => {
      stepperRef?.current?.removeEventListener(
        "pointermove",
        updateThumbPosition,
      );
    };
  }, []);

  return (
    <input
      ref={stepperRef}
      defaultValue={0}
      className="slider"
      name="stepper"
      min={0}
      max={maxStepNumber}
      type="range"
      onPointerDown={e => {
        setStepperControls("isDragging", true);
        setStepperControls("animate", false);
        setStepperControls("currentStep", Number(e.target.value));
        setStepperControls("storedStep", Number(e.target.value));
      }}
      onPointerUp={e => setStepperControls("isDragging", false)}
      onChange={e => {
        setStepperControls("currentStep", Number(e.target.value));
        setStepperControls("storedStep", Number(e.target.value));
      }}
    />
  );
};
