export function groupIssuesByIdentifier(jsonResponse) {
  const groupedIssues = {};

  // Group the issues based on issueIdentifier
  jsonResponse.forEach(issue => {
    const issueIdentifier = issue.issueIdentifier;
    if (!groupedIssues[issueIdentifier]) {
      groupedIssues[issueIdentifier] = [];
    }
    groupedIssues[issueIdentifier].push({...issue , applied :false});
  });

  return groupedIssues;
}
