import React, { useEffect, useState, useRef, useCallback } from "react";
import { stepsStyleValidation } from "@/helpers/stepsStyleValidation";
import Player from "./Player";
import "./stepper.scss";
import { cn, isEmpty } from "@/helpers/functions";
import { useQuery } from "@tanstack/react-query";
import { getStepsWithIpr } from "@/helpers/queryTransformers";
import { apiResolver } from "@/helpers/apiResolver";
import { useGlobalStore } from "../../../store";
import { InputStepGrabber } from "./InputStepGrabber";
import { IprIndicator } from "./IprIndicator";
import { getParams } from "@/helpers/getParams";
import { demoTSData } from "@/components/demo-tp/demoTSData";

function isMultiple(index, maxStepNumber) {
  if (maxStepNumber >= 50) {
    return index % 10 === 0;
  } else {
    return index % 5 === 0;
  }
}
const Stepper = ({ maxStepNumber, steps, tpLoaded, model }) => {
  const demoTS = getParams().demoTS === "true" ? true : false;
  const {
    isLoading,
    error,
    data: stepsWithIpr,
  } = demoTS
    ? false
    : useQuery({
        queryKey: ["treatmentSetupInfo"],
        queryFn: apiResolver,
        staleTime: 600000, // Cache data for 60 seconds (1 minute)
        select: getStepsWithIpr,
      });
  const [demoStepsWithIpr, setDemoStepsWithIpr] = useState([]);

  useEffect(() => {
    setDemoStepsWithIpr(getStepsWithIpr(demoTSData));
    // console.log(demoStepsWithIpr);
  }, []);

  const {
    isMobile,
    split,
    startRevision,
    animate,
    currentStep,
    storedStep,
    isDragging,
    bitejump,
    setStepperControls,
    hasBiteJump,
  } = useGlobalStore(state => ({
    controlsRef: state.refs.controlsRef,
    controlsRef2: state.refs.controlsRef2,
    isMobile: state.layoutConfig.isMobile,
    split: state.toggle.split,
    startRevision: state.toggle.startRevision,
    animate: state.stepperControls.animate,
    currentStep: state.stepperControls.currentStep,
    storedStep: state.stepperControls.storedStep,
    isDragging: state.stepperControls.isDragging,
    bitejump: state.toggle.biteJump,
    setStepperControls: state.setStepperControls,
    hasBiteJump: state.toggle.hasBiteJump,
  }));
  const [isPassiveAligners, setIsPassiveAligners] =
    useState("bounty_emptyStep");

  useEffect(() => {
    if (model) {
      setIsPassiveAligners(
        model.children[2].userData.has_passive
          ? "bounty_passiveAligners"
          : "bounty_step_empty",
      );
    }
  }, [model]);

  return (
    <div
      style={{ display: split || !tpLoaded ? "none" : "flex" }}
      id={isMobile ? "bounty_stepper_mobile" : "bounty_stepper"}
    >
      <Player
        className={cn({
          "h-[55px]": isEmpty(demoTS ? demoStepsWithIpr : stepsWithIpr),
          "h-[80px]": !isEmpty(demoTS ? demoStepsWithIpr : stepsWithIpr),
        })}
        maxStepNumber={maxStepNumber}
      />
      {!isMobile && (
        <div className="bounty_steps_total m-auto w-[80px]">
          {currentStep + "/" + maxStepNumber}
        </div>
      )}
      <div
        style={{ height: isMobile ? "55px" : "60px" }}
        className="bounty_steps_content"
      >
        <InputStepGrabber maxStepNumber={maxStepNumber} tpLoaded={tpLoaded} />
        {steps
          ? steps.map((step, stepNumber) => {
              return (
                <div
                  style={{
                    height: "60px",
                    width: "100%",
                    display: "grid",
                    justifyItems: "center",
                    cursor: "pointer",
                  }}
                  key={stepNumber}
                  onClick={() => {
                    setStepperControls("currentStep", stepNumber);
                    setStepperControls("storedStep", stepNumber);
                  }}
                  data-step-number={stepNumber}
                >
                  <IprIndicator
                    {...{
                      currentStep,
                      stepNumber,
                      stepsWithIpr: demoTS ? demoStepsWithIpr : stepsWithIpr,
                    }}
                  />
                  <div
                    style={{
                      backgroundColor:
                        currentStep === stepNumber ||
                        (storedStep === stepNumber && !animate)
                          ? isMobile
                            ? "white"
                            : "lightgray"
                          : "white",
                    }}
                    className={
                      currentStep === stepNumber ||
                      (storedStep === stepNumber && !animate)
                        ? isMobile
                          ? isDragging
                            ? "bounty_step_tooltip_mobile_active bounty_step_div"
                            : "bounty_step_tooltip_mobile bounty_step_div"
                          : "bounty_step_div"
                        : hasBiteJump &&
                            bitejump &&
                            stepNumber === maxStepNumber
                          ? "bounty_active_bitejump bounty_step_div"
                          : "bounty_step_div"
                    }
                  >
                    <div
                      style={{
                        display:
                          isMultiple(stepNumber, maxStepNumber) ||
                          currentStep === stepNumber ||
                          storedStep === stepNumber
                            ? "block"
                            : "none",
                        color:
                          (currentStep === stepNumber ||
                            storedStep === stepNumber) &&
                          isMobile
                            ? "white"
                            : "black",
                      }}
                      className={
                        isMobile &&
                        isDragging &&
                        (currentStep === stepNumber ||
                          storedStep === stepNumber)
                          ? "bounty_step_label_active"
                          : "bounty_step_label"
                      }
                    >
                      {stepNumber}
                    </div>
                    {step.u === true ? (
                      <div
                        style={{
                          width:
                            currentStep === stepNumber ||
                            (storedStep === stepNumber && !animate)
                              ? isMobile
                                ? "10%"
                                : "80%"
                              : "100%",
                        }}
                        className={stepsStyleValidation(
                          stepNumber,
                          model.children[0].children.length,
                          model.children[1].children.length,
                          model.children[2].userData.start_together,
                          model.children[2].userData.has_passive,
                          model.children[2].userData
                            .upper_over_correction_steps_number,
                          model.children[2].userData
                            .lower_over_correction_steps_number,
                          true,
                        )}
                      />
                    ) : (
                      <div
                        style={{
                          width:
                            currentStep === stepNumber ||
                            (storedStep === stepNumber && !animate)
                              ? isMobile
                                ? "10%"
                                : "80%"
                              : "100%",
                        }}
                        className={
                          stepNumber === 0
                            ? "bounty_firstStep"
                            : `${isPassiveAligners}`
                        }
                      />
                    )}
                    {step.l === true ? (
                      <div
                        style={{
                          width:
                            currentStep === stepNumber ||
                            (storedStep === stepNumber && !animate)
                              ? isMobile
                                ? "10%"
                                : "80%"
                              : "100%",
                        }}
                        className={stepsStyleValidation(
                          stepNumber,
                          model.children[0].children.length,
                          model.children[1].children.length,
                          model.children[2].userData.start_together,
                          model.children[2].userData.has_passive,
                          model.children[2].userData
                            .upper_over_correction_steps_number,
                          model.children[2].userData
                            .lower_over_correction_steps_number,
                          false,
                        )}
                      />
                    ) : (
                      <div
                        style={{
                          width:
                            currentStep === stepNumber ||
                            (storedStep === stepNumber && !animate)
                              ? isMobile
                                ? "10%"
                                : "80%"
                              : "100%",
                        }}
                        className={
                          stepNumber === 0
                            ? "bounty_firstStep"
                            : `${isPassiveAligners}`
                        }
                      />
                    )}
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default Stepper;
