import React, { useCallback, useState } from "react";
import Modal from "../components/shared-components/Modal";

export const useModal = () => {
  const [open, setOpen] = useState(false);

  const toggleModal = () => setOpen(prev => !prev);

  return {
    ModalContainer: useCallback(
      ({
        title,
        className,
        customActions,
        primaryAction,
        primaryActionText,
        secondaryAction,
        secondaryActionText,
        toggle,
        children,
        footerChildren, 
        primaryActionColor, 
        ConfirmApprove
        ,ConfirmSkip
      }) => (
        <Modal
          {...{
            title,
            className,
            customActions,
            primaryActionColor, 
            primaryAction,
            primaryActionText,
            secondaryAction,
            secondaryActionText,
            toggle,
            children,
            footerChildren, 
            ConfirmApprove
            ,ConfirmSkip
          }}
          isOpen={open}
          toggle={toggleModal}
        />
      ),
      [open],
    ),
    toggleModal,
  };
};
