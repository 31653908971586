import Scene from "./components/viewer/Scene";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

import "./app.css";
import TranslationCustomizer from "./components/customizer/TranslationCustomizer";
import { useEffect, useState } from "react";

import Login from "./components/auth/Login";
import { getParams } from "./helpers/getParams";
import { changeLanguage } from "i18next";

function App() {
  const queryClient = new QueryClient();
  const [user_data, setUserData] = useState(
    JSON.parse(localStorage.getItem("user_data")),
  );
  const translator =
    JSON.parse(process.env.REACT_APP_TRANSLATORS)?.filter(
      elem => elem.email === user_data?.email,
    )?.[0] || null;
  const isTranslator = JSON.parse(process.env.REACT_APP_TRANSLATOR);
  const checkLogoutTime = () => {
    const loginTime = new Date();

    // Adding one day to the loginTime
    loginTime.setDate(loginTime.getDate() + 1);
    if (Date.now() >= loginTime) {
      // Log out the user
      localStorage.clear();
      // Perform logout actions (e.g., redirect to login page)
    }
  };
  useEffect(() => {
    if (!isTranslator) {
      const lang = getParams()?.lang;
      localStorage.setItem("language", lang);
      changeLanguage(lang || "en");
    }

    if (user_data) {
      const logoutTimer = setInterval(() => {
        checkLogoutTime();
      }, 1000); // Check every second
      // Cleanup the interval on component unmount
      return () => clearInterval(logoutTimer);
    }
  }, [user_data]);

  return (
    <QueryClientProvider client={queryClient}>
      {isTranslator ? (
        user_data ? (
          <div id="bounty_template">
            {translator && <TranslationCustomizer />}
            <Scene />
          </div>
        ) : (
          <>
            <Login setUserData={setUserData} />
          </>
        )
      ) : (
        <div id="bounty_template">
          <Scene />
        </div>
      )}

      {/*<ReactQueryDevtools />*/}
    </QueryClientProvider>
  );
}

export default App;
