import nav from "./components/nav_en.json";
import movement_table from "./components/movement_table_en.json";
import staging_timeline from "./components/staging_timeline_en.json";
import bolton from "./components/bolton_en.json";
import overjet_overbite from "./components/overjet_overbite_en.json";
import controls from "./components/controls_en.json";
import others from "./components/others_en.json";
export const en = {
  nav: { ...nav },
  movement_table: { ...movement_table },
  staging_timeline: { ...staging_timeline },
  bolton: { ...bolton },
  overjet_overbite: { ...overjet_overbite },
  controls: { ...controls },
  other: { ...others },
};
