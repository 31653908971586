import React, { useState } from "react";

import { Rnd } from "react-rnd";
import { cn } from "@/helpers/functions";
import { useGlobalStore } from "@/store";
import { getParams } from "@/helpers/getParams";
import clsx from "clsx";

/**
 * @typedef {React.FC<object} OverlayWrapper
 *
 * @property {boolean} showOverlay
 *
 * @property {string} className
 * original css values:
 * shadow-eon h-full cursor-grab flex-col rounded-lg bg-white p-3
 *
 * @property {function} renderChild
 * @returns {JSX.Element}
 */
export const OverlayWrapper = ({
  showOverlay,
  overlayKey,
  className,
  minWidth = 0,
  defaultHeight,
  children,
  lessHeight,
  rejectHightWrapper
}) => {
  const demoTS = getParams().demoTS;
  const [grabbing, setGrabbing] = useState(false);
  const { zIndexOverlay } = useGlobalStore(state => ({
    zIndexOverlay: state.zIndexOverlay,
  }));

  if (!showOverlay) return <></>;

  return (
    <div
      className={`absolute left-0 ${demoTS ? "top-[120px]" : "top-16"}  h-screen
      max-h-[calc(100vh-125px)] w-screen`}
    >

      <Rnd
        dragHandleClassName="handle"
        bounds="parent"
        style={{
          zIndex: zIndexOverlay.findIndex(key => key === overlayKey) + 10,
        }}
        default={{
          x:
            document.getElementById("root")?.getBoundingClientRect().width -
            minWidth -
            10,
          y: 10,
          width: minWidth,
          height: Math.min(
            Math.max(defaultHeight, 0),
            document.getElementById("root")?.getBoundingClientRect().height -
              140,
          ),
        }}
        // change cursor to grabbing
        onDragStart={() => setGrabbing(true)}
        onDragStop={() => setGrabbing(false)}
        minWidth={minWidth / 2}
        minHeight={lessHeight ? lessHeight : 300}
      >
        {/* Mobile currently does not show overlay */}
        <div
          className={cn(
            `absolute left-0 top-0 h-full ${
              demoTS ? "max-h-[calc(100vh-205px)]" : ""
            } ${lessHeight ? "min-h-[150px]" :(rejectHightWrapper)? " max-h-[70vh] min-h-[300px] h-[50vh] ": "min-h-[300px]"} w-full flex-col rounded-lg bg-white shadow-eon`,
            className,
            {
              "cursor-grabbing": grabbing,
            },
          )}
        >
          <div
            className={cn("handle absolute z-[1] h-10 w-full", {
              "cursor-grab": !grabbing,
            })}
          ></div>
          <div
            className={clsx({
              "relative  p-3": lessHeight,
              "relative   max-h-[70vh] min-h-[300px] h-[50vh]   p-3" :rejectHightWrapper , 
              "relative h-full min-h-[300px] p-3": !lessHeight && !rejectHightWrapper ,
            })}
          >
            {children}
          </div>
        </div>
      </Rnd>
    </div>
  );
};
