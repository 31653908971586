import React, { useState } from "react";
import { toothMovementTypes } from "./teethControls/TeethPositionHelper";
import { useGlobalStore } from "@/store";
import { EditIPR, EditSpacing, Ipr } from "./Icons";
import { attachmentTypes } from "./teethControls/AttachmentSelector";
import { useModal } from "@/hooks/useModal";
import { redoMovementList } from "./teethControls/TeethPositionHelper";
import { numberingUniversal } from "@/helpers/teethNumbering";
import { getParams } from "@/helpers/getParams";
import { PencilIcon } from "./Icons";
import { cn } from "@/helpers/functions";
import { TooltipPopper } from "@/components/shared-components/ToolTip";

import { prepareRedoListToExport } from "./teethControls/TeethControls";
import { StartRevision } from "@/mixpanel/Mixpanel";
import {
  TranslationComponent,
  useCustomTranslation,
} from "@/hooks/useTranslation";
import { addRemovedPonticTeeth, removePonticTeeth } from "./controls/Pontics";

//console.log(Object.values(logs), Object.keys(logs));
/**
 *
 * @param {string} toothKey movement tooth key
 * @param {string} movementKey Teeth Movement Types
 * @param {int} value the movement amount
 * @returns {string} the actual movement name
 */
function getMovementName(toothKey, movementKey, value) {
  const toothQuarter = getToothQuarter(Number(toothKey.match(/[0-9]+/)));
  if (toothQuarter === 5) {
    return "incorrect_tooth_key";
  }
  switch (movementKey) {
    case "Extrusion/Intrusion":
      if (value > 0) {
        if (toothQuarter <= 2) {
          return "intrusion";
        } else if (toothQuarter <= 4) {
          return "extrusion";
        }
      } else {
        if (toothQuarter <= 2) {
          return "extrusion";
        } else if (toothQuarter <= 4) {
          return "intrusion";
        }
      }
    case "Buccal/Lingual":
      if (value > 0) {
        return "buccal_translation";
      } else {
        return "lingual_translation";
      }
    case "Mesial/Distal":
      if (value > 0) {
        return "distal_translation";
      } else {
        return "mesial_translation";
      }
    case "Rotation":
      if (value > 0) {
        if (toothQuarter === 1 || toothQuarter === 4) {
          return "mesial_in_rotation";
        } else if (toothQuarter === 2 || toothQuarter === 3) {
          return "distal_in_rotation";
        }
      } else {
        if (toothQuarter === 1 || toothQuarter === 4) {
          return "distal_in_rotation";
        } else if (toothQuarter === 2 || toothQuarter === 3) {
          return "mesial_in_rotation";
        }
      }
    case "Inclination":
      if (value > 0) {
        if (toothQuarter <= 2) {
          return "buccal_inclination";
        } else if (toothQuarter <= 4) {
          return "lingual_inclination";
        }
      } else {
        if (toothQuarter <= 2) {
          return "lingual_inclination";
        } else if (toothQuarter <= 4) {
          return "buccal_inclination";
        }
      }
    case "Angulation":
      if (value > 0) {
        if (toothQuarter === 2 || toothQuarter === 4) {
          return "distal_crown_angulation";
        } else if (toothQuarter === 1 || toothQuarter === 3) {
          return "mesial_crown_angulation";
        }
      } else {
        if (toothQuarter === 2 || toothQuarter === 4) {
          return "mesial_crown_angulation";
        } else if (toothQuarter === 1 || toothQuarter === 3) {
          return "distal_crown_angulation";
        }
      }
    case "LeftHinge":
      if (value > 0) {
        if (toothQuarter === 2 || toothQuarter === 3) {
          return "distal_in_hinge";
        } else if (toothQuarter === 1 || toothQuarter === 4) {
          return "distal_out_hinge";
        }
      } else {
        if (toothQuarter === 2 || toothQuarter === 3) {
          return "distal_out_hinge";
        } else if (toothQuarter === 1 || toothQuarter === 4) {
          return "distal_in_hinge";
        }
      }
    case "RightHinge":
      if (value > 0) {
        if (toothQuarter === 2 || toothQuarter === 3) {
          return "mesial_out_hinge";
        } else if (toothQuarter === 1 || toothQuarter === 4) {
          return "mesial_in_hinge";
        }
      } else {
        if (toothQuarter === 2 || toothQuarter === 3) {
          return "mesial_in_hinge";
        } else if (toothQuarter === 1 || toothQuarter === 4) {
          return "mesial_out_hinge";
        }
      }
    default:
      "movement_key_is_not_found";
  }
}

/**
 * @param {int} toothNumber the Universal tooth number
 * @returns {int} 1 => LU, 2 => RU, 3 => RL, 4 => LL, 5 => wrong tooth number
 */
function getToothQuarter(toothNumber) {
  if (toothNumber < 9) {
    return 1;
  } else if (toothNumber < 17) {
    return 2;
  } else if (toothNumber < 25) {
    return 3;
  } else if (toothNumber < 33) {
    return 4;
  } else {
    return 5;
  }
}
function ReviseTreatment({
  reset,
  clearAutoSave,
  isViewerControlsEnabled,
  startTracking,
  TPdata,
}) {
  const { content } = useCustomTranslation();

  const getContent = (group, key) => {
    return content(`controls.${group}.${key}`);
  };
  const { dentalNotation } = getParams();
  const demoTS = getParams().demoTS === "true" ? true : false;
  const {
    logs,
    isMobile,
    setToggle,
    setControls3D,
    setLogs,
    show3DControls,
    groupRef,
    currentStep,
  } = useGlobalStore(state => ({
    logs: state.logs,
    isMobile: state.layoutConfig.isMobile,
    show3DControls: state.toggle.show3DControls,
    setLogs: state.setLogs,
    setToggle: state.setToggle,
    setControls3D: state.setControls3D,
    groupRef: state.refs.groupRef,
    currentStep: state.stepperControls.currentStep,
  }));

  const { ModalContainer: SubmitModal, toggleModal: toggleSubmit } = useModal();
  const { ModalContainer: DiscardModal, toggleModal: toggleDiscard } =
    useModal();

  const [revisionNotes, setRevisionNotes] = useState("");
  const sendMessage = async () => {
    // track data in mixpanal
    trackDataInMixPanal("Submitted");
    let newArray = JSON.parse(JSON.stringify(logs));
    reset();
    prepareRedoListToExport(redoMovementList);
    let message = {
      logs: newArray,
      revisionNotes,
      reviseTreatment: false,
      controlsRedoList: redoMovementList,
    };
    message = JSON.parse(JSON.stringify(message));
    parent.postMessage(message, "*");

    toggleSubmit();
    setControls3D("selectedTooth", null);
    setLogs({});
    redoMovementList.splice(0, redoMovementList.length);
    clearAutoSave();
    setToggle("startRevision", false);
    setToggle("show3DControls", false);
  };

  const trackDataInMixPanal = action => {
    let newArray = JSON.parse(JSON.stringify(logs));
    let message = {
      logs: newArray,
      revisionNotes,
    };
    message = JSON.parse(JSON.stringify(message));
    // track data in mixpanal
    StartRevision(message, TPdata, action);
  };
  const toothLogs = Object.keys(logs).map(toothKey => {
    if (toothKey.includes("Movement")) {
      const toothData = logs[toothKey];

      // Check if all values in the toothData object are equal to 0 or an empty string
      const allValuesAreZeroOrEmpty = Object.values(toothData).every(
        value => value === 0 || value === "",
      );

      // If all values are zero or empty, skip rendering this tooth
      if (allValuesAreZeroOrEmpty) {
        return null;
      }
      // Create an array of JSX elements for each key-value pair in toothData
      const toothDataEntries = Object.entries(toothData)
        .map(([key, value], idx) => {
          const svg = toothMovementTypes.find(
            typeObj => typeObj.type === key,
          )?.svg;
          if (key === "attachment") {
            if (
              toothData?.[key]["action"] === "" ||
              toothData?.["attachment"]?.["action"] === "No action done"
            ) {
              return;
            }
            // we have to change actions for attachments
            return (
              <div key={key} className="attachment_log flex items-center gap-1">
                {attachmentTypes.map((elem, i) => {
                  if (elem.name === toothData[key]["type"]) {
                    return elem.svg;
                  }
                })}
                <div>
                  {getContent(
                    "labels",
                    `attachment_${toothData[key]["action"]}`,
                  )}
                </div>
              </div>
            );
          } else {
            return (
              value !== 0 &&
              value !== "" && (
                <div key={key} className="flex gap-1">
                  {svg && <div>{svg}</div>}
                  <div className="capitalize">
                    {Math.abs(value)}
                    {idx !== Object.entries(toothData).length - 1 && ","}
                  </div>
                  <div className="capitalize">
                    {getContent(
                      "labels",
                      getMovementName(toothKey, key, value),
                    )}
                  </div>
                </div>
              )
            );
          }
        })
        .filter(Boolean);
      console.log(toothData?.["attachment"]?.["action"]);
      if (
        toothData?.["attachment"]?.["action"] === "" ||
        toothData?.["attachment"]?.["action"] === "No action done"
      ) {
        return;
      } else {
        return (
          <div key={toothKey} className="flex flex-col gap-2">
            <div style={{ color: "#717171" }} className="text-xs font-bold">
              {`${getContent("labels", "tooth")} ${
                numberingUniversal?.[Number(toothKey.split("_")[0])][
                  dentalNotation
                ]
              } ${getContent("labels", "adjusted")}`}
            </div>
            <div className="font-sm flex flex-col gap-3 font-normal">
              {toothDataEntries}
            </div>
          </div>
        );
      }
    }
  });
  const iprSpacingLogs = Object.keys(logs).map(toothKey => {
    if (!toothKey.includes("Movement")) {
      const toothData = logs[toothKey];

      const typeText = toothData["type"]
        ? toothData.type === 1
          ? "ipr"
          : "spacing"
        : "";
      if (Math.abs(Number(toothData.amount).toFixed(2) == 0)) {
        return;
      }
      const icon =
        Math.abs(Number(toothData.amount)).toFixed(2) > 0.01 ? (
          <EditIPR />
        ) : (
          <EditSpacing />
        );
      let text;
      if (toothData["type"]) {
        if (isNaN(toothData.amount)) return;
        if (toothData.action == "added" || toothData.action == "deleted") {
          text = (
            <div key={toothKey}>
              {`${
                Math.abs(Number(toothData?.amount)).toFixed(2) +
                " " +
                getContent(
                  "labels",
                  `${typeText}_${toothData.action}_between`,
                ) +
                " " +
                numberingUniversal[
                  toothData.from.includes("t")
                    ? Number(toothData.from.split("t")[1])
                    : Number(toothData.from)
                ][dentalNotation] +
                " " +
                getContent("labels", "and") +
                " " +
                numberingUniversal[
                  toothData.to.includes("t")
                    ? Number(toothData.to.split("t")[1])
                    : Number(toothData.to)
                ][dentalNotation]
              }`}
            </div>
          );
        } else if (toothData.action == "changed") {
          // not used as hamzeh and othman said
          text = (
            <div key={toothKey}>
              {`${
                typeText +
                " " +
                toothData.action +
                "from" +
                " " +
                "<s>{Math.abs(toothData.preAmount)}</s>" +
                " " +
                Math.abs(Number(toothData.amount)).toFixed(2) +
                " " +
                toothData.action +
                " " +
                "between" +
                " " +
                numberingUniversal[
                  toothData.from.includes("t")
                    ? Number(toothData.from.split("t")[1])
                    : Number(toothData.from)
                ][dentalNotation] +
                " " +
                "and" +
                " " +
                numberingUniversal[
                  toothData.to.includes("t")
                    ? Number(toothData.to.split("t")[1])
                    : Number(toothData.to)
                ][dentalNotation]
              }`}
            </div>
          );
        }
      }

      return (
        <div key={toothKey} className="font-sm flex flex-col gap-3 font-normal">
          <div
            className="font-sm grid font-normal"
            style={{ gridTemplateColumns: "auto 1fr", gap: "8px" }}
          >
            {icon}
            {text}
          </div>
        </div>
      );
    }
  });
  const enable3DControls = true;
  const disabledSubmit =
    Object.keys(logs).length === 0 && revisionNotes.length === 0;

  return (
    <>
      <SubmitModal
        title={getContent("labels", "submit_modifications")}
        primaryAction={() => {
          sendMessage();
        }}
        primaryActionText={getContent("actions", "submit")}
        secondaryActionText={getContent("actions", "cancel")}
      >
        {demoTS ? (
          <>{getContent("labels", "confirmation_submit_demo")}</>
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html: getContent("labels", "confirmation_submit"),
            }}
          />
        )}
      </SubmitModal>
      <DiscardModal
        title={getContent("labels", "discard_modifications")}
        primaryAction={() => {
          trackDataInMixPanal("Discarded");
          setToggle("startRevision", false);
          setToggle("show3DControls", false);
          setLogs({});
          redoMovementList.splice(0, redoMovementList.length);
          setControls3D("selectedTooth", null);
          reset();
          addRemovedPonticTeeth(groupRef, currentStep);
          clearAutoSave();
        }}
        primaryActionText={getContent("actions", "discard")}
        secondaryActionText={getContent("actions", "cancel")}
      >
        {getContent(
          "labels",
          "discard_3d_modifications_and_return_to_previous_treatment_review",
        )}
      </DiscardModal>
      <div
        className="controls  grid w-[258px] grid-rows-[auto,1px,1fr,auto] bg-white px-4 py-6"
        style={{
          backgroundColor: "#FFFFFF",
          width: "258px",
          height: "calc(100% - 80px)",
          display: "grid",
          gridTemplateRows: "auto 1px 1fr",
        }}
      >
        <TranslationComponent group={"controls"} color={"secondary"} />
        <div
          id="revision_notes"
          className="pb-2 text-start text-base font-medium"
        >
          {getContent("actions", "revise_treatment")}
        </div>
        <div className="bounty_divider" />
        <div className="flex flex-col py-2">
          {(isViewerControlsEnabled || demoTS) &&
          !isMobile &&
          !show3DControls ? (
            <div
              className="mb-[16px] mt-[8px] flex cursor-pointer justify-center rounded-[3px] border-2 border-[#009ACE] px-[12px] py-[8px]"
              onClick={() => {
                removePonticTeeth(groupRef, currentStep);
                setToggle("biteJump", false);
                setToggle("grid", false);
                setToggle("show3DControls", true), startTracking();
              }}
            >
              <PencilIcon />
              <div className="text-base font-medium text-[#009ACE]">
                {getContent("actions", "modify_plan")}
              </div>
            </div>
          ) : (
            <></>
          )}
          <label
            className="mb-2 text-left text-xs font-bold text-[#717171]"
            htmlFor="postContent"
          >
            {getContent("labels", "comments_to_technicians")}
          </label>
          <textarea
            onChange={e => setRevisionNotes(e.target.value)}
            name="postContent"
            placeholder={getContent(
              "labels",
              "add_your_revision_notes_and_comments_here",
            )}
            id="postContent"
            className="
          min-h-[100px]
           flex-1 p-2 placeholder:text-sm placeholder:font-medium placeholder:text-[#c5c5c5]"
          />
        </div>
        <div
          id="change_log"
          className="bounty-scrollbar overflow-y-auto overflow-x-hidden"
        >
          <div className="flex flex-col gap-4">{toothLogs}</div>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              {iprSpacingLogs[0] && (
                <div style={{ color: "#717171" }} className="text-xs font-bold">
                  {getContent("labels", "ipr_and_spacing")}
                </div>
              )}
              {iprSpacingLogs}
            </div>
          </div>
          {Object.keys(logs).length === 0 && (
            <div className="pt-4 text-sm font-normal">
              {getContent(
                "labels",
                "your_modifications_will_appear_here_once_you_make_one",
              )}
            </div>
          )}
        </div>
        <div className="mt-4 flex justify-end gap-2">
          <div
            className="grid text-center text-sm font-bold"
            style={{
              width: "94px",
              height: "37px",
              border: "1px solid #BCBCBC",
              borderRadius: "3px",
              color: "#666666",
            }}
          >
            <button
              onClick={toggleDiscard}
              style={{
                cursor: "pointer",
              }}
            >
              {getContent("actions", "discard")}
            </button>
          </div>
          <div
            className="grid text-center text-sm font-bold"
            style={{
              backgroundColor: disabledSubmit ? "#D4D4D4" : "#009ACE",
              color: "#FFF",
              width: "94px",
              height: "37px",
              border: "1px solid #BCBCBC",
              borderRadius: "3px",
            }}
          >
            <button
              onClick={disabledSubmit ? undefined : toggleSubmit}
              style={{
                cursor: disabledSubmit ? "not-allowed" : "pointer",
              }}
            >
              {getContent("actions", "send")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReviseTreatment;
