import * as THREE from "three";

import poppins from "@/static/poppins_regular.json";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader.js";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry.js";

export const numberingUniversal = {
  // upper
  1: {
    palmer: "UR8",
    fdi: 18,
    universal: 1,
  },

  2: {
    palmer: "UR7",
    fdi: 17,
    universal: 2,
  },

  3: {
    palmer: "UR6",
    fdi: 16,
    universal: 3,
  },

  4: {
    palmer: "UR5",
    fdi: 15,
    universal: 4,
  },

  5: {
    palmer: "UR4",
    fdi: 14,
    universal: 5,
  },

  6: {
    palmer: "UR3",
    fdi: 13,
    universal: 6,
  },

  7: {
    palmer: "UR2",
    fdi: 12,
    universal: 7,
  },

  8: {
    palmer: "UR1",
    fdi: 11,
    universal: 8,
  },

  9: {
    palmer: "UL1",
    fdi: 21,
    universal: 9,
  },

  10: {
    palmer: "UL2",
    fdi: 22,
    universal: 10,
  },

  11: {
    palmer: "UL3",
    fdi: 23,
    universal: 11,
  },

  12: {
    palmer: "UL4",
    fdi: 24,
    universal: 12,
  },

  13: {
    palmer: "UL5",
    fdi: 25,
    universal: 13,
  },

  14: {
    palmer: "UL6",
    fdi: 26,
    universal: 14,
  },

  15: {
    palmer: "UL7",
    fdi: 27,
    universal: 15,
  },

  16: {
    palmer: "UL8",
    fdi: 28,
    universal: 16,
  },

  // lower

  17: {
    palmer: "LL8",
    fdi: 38,
    universal: 17,
  },

  18: {
    palmer: "LL7",
    fdi: 37,
    universal: 18,
  },

  19: {
    palmer: "LL6",
    fdi: 36,
    universal: 19,
  },

  20: {
    palmer: "LL5",
    fdi: 35,
    universal: 20,
  },

  21: {
    palmer: "LL4",
    fdi: 34,
    universal: 21,
  },

  22: {
    palmer: "LL3",
    fdi: 33,
    universal: 22,
  },

  23: {
    palmer: "LL2",
    fdi: 32,
    universal: 23,
  },

  24: {
    palmer: "LL1",
    fdi: 31,
    universal: 24,
  },

  25: {
    palmer: "LR1",
    fdi: 41,
    universal: 25,
  },

  26: {
    palmer: "LR2",
    fdi: 42,
    universal: 26,
  },

  27: {
    palmer: "LR3",
    fdi: 43,
    universal: 27,
  },

  28: {
    palmer: "LR4",
    fdi: 44,
    universal: 28,
  },

  29: {
    palmer: "LR5",
    fdi: 45,
    universal: 29,
  },

  30: {
    palmer: "LR6",
    fdi: 46,
    universal: 30,
  },

  31: {
    palmer: "LR7",
    fdi: 47,
    universal: 31,
  },

  32: {
    palmer: "LR8",
    fdi: 48,
    universal: 32,
  },
};

export const numberingFdi = {
  18: {
    palmer: "UR8",
    fdi: 18,
    universal: 1,
  },

  17: {
    palmer: "UR7",
    fdi: 17,
    universal: 2,
  },

  16: {
    palmer: "UR6",
    fdi: 16,
    universal: 3,
  },

  15: {
    palmer: "UR5",
    fdi: 15,
    universal: 4,
  },

  14: {
    palmer: "UR4",
    fdi: 14,
    universal: 5,
  },

  13: {
    palmer: "UR3",
    fdi: 13,
    universal: 6,
  },

  12: {
    palmer: "UR2",
    fdi: 12,
    universal: 7,
  },

  11: {
    palmer: "UR1",
    fdi: 11,
    universal: 8,
  },

  // upper left side

  21: {
    palmer: "UL1",
    fdi: 21,
    universal: 9,
  },

  22: {
    palmer: "UL2",
    fdi: 22,
    universal: 10,
  },

  23: {
    palmer: "UL3",
    fdi: 23,
    universal: 11,
  },

  24: {
    palmer: "UL4",
    fdi: 24,
    universal: 12,
  },

  25: {
    palmer: "UL5",
    fdi: 25,
    universal: 13,
  },

  26: {
    palmer: "UL6",
    fdi: 26,
    universal: 14,
  },

  27: {
    palmer: "UL7",
    fdi: 27,
    universal: 15,
  },

  28: {
    palmer: "UL8",
    fdi: 28,
    universal: 16,
  },

  // lower left side

  38: {
    palmer: "LL8",
    fdi: 38,
    universal: 17,
  },

  37: {
    palmer: "LL7",
    fdi: 37,
    universal: 18,
  },

  36: {
    palmer: "LL6",
    fdi: 36,
    universal: 19,
  },

  35: {
    palmer: "LL5",
    fdi: 35,
    universal: 20,
  },

  34: {
    palmer: "LL4",
    fdi: 34,
    universal: 21,
  },

  33: {
    palmer: "LL3",
    fdi: 33,
    universal: 22,
  },

  32: {
    palmer: "LL2",
    fdi: 32,
    universal: 23,
  },

  31: {
    palmer: "LL1",
    fdi: 31,
    universal: 24,
  },

  // lower right side

  41: {
    palmer: "LR1",
    fdi: 41,
    universal: 25,
  },

  42: {
    palmer: "LR2",
    fdi: 42,
    universal: 26,
  },

  43: {
    palmer: "LR3",
    fdi: 43,
    universal: 27,
  },

  44: {
    palmer: "LR4",
    fdi: 44,
    universal: 28,
  },

  45: {
    palmer: "LR5",
    fdi: 45,
    universal: 29,
  },

  46: {
    palmer: "LR6",
    fdi: 46,
    universal: 30,
  },

  47: {
    palmer: "LR7",
    fdi: 47,
    universal: 31,
  },

  48: {
    palmer: "LR8",
    fdi: 48,
    universal: 32,
  },
};

export const placeNumbersOnTeeth = (
  group,
  group2,
  toggle,
  currentStep,
  numberingOverLays,
  dentalNotation,
  maxStepNumber,
) => {
  const childrenToRemove = [];

  group2.current?.children.forEach(child => {
    if (child.name === "u_teethNum" || child.name === "l_teethNum") {
      childrenToRemove.push(child);
    }
  });
  console.log(numberingOverLays?.[maxStepNumber]);
  childrenToRemove.forEach(child => group2.current.remove(child));
  if (toggle.numbering) {
    if (
      numberingOverLays?.[currentStep]?.[0] ||
      numberingOverLays?.[maxStepNumber]?.[0]
    ) {
      numberingOverLays?.[maxStepNumber]?.[0]?.forEach((label, i) => {
        if (i === 0) {
          return;
        } else {
          var font = new FontLoader().parse(poppins);
          let text = (numberingUniversal[i][dentalNotation] || "").toString();
          const tGeo = new TextGeometry(text, {
            font: font,
            size: 2,
            height: 0.05,
          });
          const textMesh = new THREE.Mesh(
            tGeo,
            new THREE.MeshPhongMaterial({ color: "black" }),
          );
          const label_distance = 0.01;
          const labelpos = new THREE.Vector3(
            label.label_position_center?.[0] - label.label_position_end?.[0],
            label.label_position_center?.[1] - label.label_position_end?.[1],
            label.label_position_center?.[2] - label.label_position_end?.[2],
          ).multiplyScalar(1.1);
          tGeo.computeBoundingBox();
          tGeo.center();
          textMesh.geometry.dispose();
          textMesh.geometry = tGeo;
          textMesh.position.set(
            label.label_position_center?.[0] - labelpos.x,
            label.label_position_center?.[1] - labelpos.y,
            label.label_position_center?.[2] - labelpos.z,
          );
          let y_rotation = Math.atan2(
            label.label_position_end?.[0],
            label.label_position_end?.[2],
          );
          textMesh.lookAt(textMesh.position.clone().sub(labelpos));
          textMesh.visible = toggle.showLower ? false : true;
          textMesh.name = "u_teethNum";
          group2.current.add(textMesh);
        }
      });
    }
    console.log(numberingOverLays);
    if (
      numberingOverLays?.[currentStep]?.[1] ||
      numberingOverLays?.[maxStepNumber]?.[1]
    ) {
      numberingOverLays?.[maxStepNumber]?.[1]?.forEach((label, i) => {
        if (i === 0) {
          return;
        } else {
          var font = new FontLoader().parse(poppins);
          let text = (numberingUniversal[i][dentalNotation] || "").toString();
          const tGeo = new TextGeometry(text, {
            font: font,
            size: 2,
            height: 0.05,
          });
          const textMesh = new THREE.Mesh(
            tGeo,
            new THREE.MeshPhongMaterial({ color: "black" }),
          );
          const label_distance = 0.01;
          const labelpos = new THREE.Vector3(
            label.label_position_center?.[0] - label.label_position_end?.[0],
            label.label_position_center?.[1] - label.label_position_end?.[1],
            label.label_position_center?.[2] - label.label_position_end?.[2],
          ).multiplyScalar(1.1);
          tGeo.computeBoundingBox();
          tGeo.center();
          textMesh.geometry.dispose();
          textMesh.geometry = tGeo;
          textMesh.position.set(
            label.label_position_center?.[0] - labelpos.x,
            label.label_position_center?.[1] - labelpos.y,
            label.label_position_center?.[2] - labelpos.z,
          );
          let y_rotation = Math.atan2(
            label.label_position_end?.[0],
            label.label_position_end?.[2],
          );
          textMesh.lookAt(textMesh.position.clone().sub(labelpos));
          // textMesh.rotateY(180)
          //textMesh.rotation.set(label.label_position_end?.[0], label.label_position_end?.[1], label.label_position_end?.[2]);
          textMesh.visible = toggle.showUpper ? false : true;
          textMesh.name = "u_teethNum";
          group2.current.add(textMesh);
        }
      });
    }
  }
};
