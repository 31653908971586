import { cn } from "@/helpers/functions";
import React from "react";

function Beta({ className }) {
  return (
    <div
      className={cn("bounty_beta text-center text-base font-bold", className)}
    >
      Beta
    </div>
  );
}

export default Beta;
