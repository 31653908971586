export const getParams = () => {
  const params = new URLSearchParams(document.location.search);
  return {
    dentalNotation: params.get("dn") || "universal",
    isM: params.get("isM"),
    treatmentSetupsId: params.get("treatmentSetupsId"),
    isPatient: params.get("isPatient"),
    tdms: params.get("tdms") || false,
    isO: params.get("isO") || "d", // ownerType
    withPatientInfo: params.get("withPatientInfo") || false,
    demoTS: params.get("demoTS") || false,
    isC: params.get("isC") || false,
    lang: params.get("lang") || "en",
  };
};
