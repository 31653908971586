import React, { useEffect, useState, useLayoutEffect } from "react";
import * as THREE from "three";
import { mergeVertices } from "three/examples/jsm/utils/BufferGeometryUtils";
import { useGlobalStore } from "../../store";
import { attachmentMaterial } from "./Mesh";
import { globalBiteJump } from "./Scene";
let meshGroup2;
let teethMaterial;
function Split({ maxStepNumber, toggle, GLBData, biteJumpsSteps }) {
  const setRefs = useGlobalStore(state => state.setRefs);

  const groupRef2 = React.useRef(null);
  React.useEffect(() => {
    setRefs({
      groupRef2,
    });
  }, []);

  if (!teethMaterial) {
    teethMaterial = new THREE.MeshPhysicalMaterial({
      roughness: 0.4,
      reflectivity: 8,
      specularIntensity: 3,
      specularColor: "#2e2e2e",
      emissiveIntensity: 1,
      emissive: "#303030",
      color: "#fff9f9",
      side: THREE.DoubleSide,
      flatShading: false,
    });
  }
  if (toggle.occ) {
    teethMaterial.transparent = true;
    teethMaterial.opacity = 0.7;
    teethMaterial.side = THREE.FrontSide;
    teethMaterial.needsUpdate = true;
  } else {
    teethMaterial.transparent = false;
    teethMaterial.side = THREE.DoubleSide;
    teethMaterial.opacity = 1;
    teethMaterial.needsUpdate = true;
  }
  const gumMaterial = new THREE.MeshPhysicalMaterial({
    roughness: 0.35,
    reflectivity: 0.65,
    specularIntensity: 0.35,
    specularColor: "#ffe2e6",
    color: "#ff9090",
    emissive: "#ff93ab",

    emissiveIntensity: 0.15,
    side: THREE.DoubleSide,
  });
  function PrepareSplit(GLBData) {
    if (
      (GLBData[0].upper.teeth.length !== 0 &&
        GLBData[0].upper.teeth[0].children.length === 0) ||
      (GLBData[0].lower.teeth.length !== 0 &&
        GLBData[0].lower.teeth[0].children.length === 0)
    ) {
      console.log("GLB not ready");
      return;
    }
    let maxStepNumber = GLBData.length - 1 - (globalBiteJump ? 1 : 0);
    let modelGroup = new THREE.Group();
    let gumHolder, toothHolder;
    GLBData.forEach((gingiva, stepNumber) => {
      gumHolder = new THREE.Group();
      if (gingiva.upper.gum?.name) {
        gumHolder = gingiva.upper.gum.clone();
        gumHolder.castShadow = true;
        gumHolder.receiveShadow = true;
        gumHolder.material = gumMaterial;
        gumHolder.name = gingiva.upper.gum.name;
        gumHolder.visible = stepNumber === maxStepNumber;
        gingiva.upper.teeth.forEach((tooth, toothIndex) => {
          toothHolder = tooth;
          toothHolder.castShadow = true;
          toothHolder.receiveShadow = true;
          toothHolder.material = teethMaterial;
          toothHolder.name = tooth.name;
          tooth.children[0].children.forEach(attachment => {
            attachment.castShadow = true;
            attachment.receiveShadow = true;
            attachment.name = attachment.name + "_att";
            attachment.visible = true;
            attachment.material = attachmentMaterial;
          });
          gumHolder.add(toothHolder.clone());
        });
      }
      modelGroup.add(gumHolder);
      gumHolder = new THREE.Group();

      if (gingiva.lower.gum?.name) {
        gumHolder = gingiva.lower.gum.clone();
        gumHolder.castShadow = true;
        gumHolder.receiveShadow = true;
        gumHolder.material = gumMaterial;
        gumHolder.name = gingiva.lower.gum.name;
        gumHolder.visible = stepNumber === maxStepNumber;
        gingiva.lower.teeth.forEach((tooth, toothIndex) => {
          toothHolder = tooth;
          toothHolder.castShadow = true;
          toothHolder.receiveShadow = true;
          toothHolder.material = teethMaterial;
          toothHolder.name = tooth.name;
          tooth.children[0].children.forEach(attachment => {
            attachment.castShadow = true;
            attachment.receiveShadow = true;
            attachment.name = attachment.name + "_att";
            attachment.visible = true;
            attachment.material = attachmentMaterial;
          });
          gumHolder.add(toothHolder.clone());
        });
      }
      modelGroup.add(gumHolder);
    });

    return modelGroup;
  }
  const centering_group = new THREE.Group();
  let center = { x: 0, y: 0, z: 0 };
  useEffect(() => {
    for (let i = 0; i < 2; i++) {
      let child = groupRef2.current.children[maxStepNumber * 2 + i];
      if (!child?.name) {
        break;
      }
      if (child?.name.includes("st")) {
        centering_group.add(child.clone());
      }
    }

    // centering mesh (offset)
    const aabb = new THREE.Box3();
    aabb.setFromObject(centering_group);
    center = new THREE.Vector3(0, 0, 0);
    aabb.getCenter(center);
    groupRef2.current.position.set(-center.x, -center.y, -center.z);
    // }
  }, [groupRef2.current]);

  if (!meshGroup2) {
    meshGroup2 = PrepareSplit(GLBData);
  }

  return <primitive ref={groupRef2} object={meshGroup2} />;
}

export default Split;
