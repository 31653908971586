import React from "react";
import { Undo, Redo, Reset } from "../Icons";
import {
  MovementList,
  undoMovementList,
  redoMovementList,
  MovementTypes,
  toothMovementAmount,
  teethRotationAmount,
} from "./TeethPositionHelper";
import { useGlobalStore } from "@/store";
import { rotateTooth } from "./TeethPositionHelper";
import { lockTooth } from "./TeethPositionHelper";
import { useThree } from "@react-three/fiber";
import { useModal } from "@/hooks/useModal";
import { attachmentsObjects, attachmentMaterial } from "./addRemoveAttachment";
import { attachmentMaterial as oldAttachmentMaterial } from "../Mesh";
import { useCustomTranslation } from "@/hooks/useTranslation";

function URR({
  reset,
  redo,
  reflectToChangelog,
  iprOverLays,
  setIprOverlays,
  GLBData,
  setAutoSave,
  clearAutoSave,
}) {
  const { ModalContainer: ResetModal, toggleModal: toggleReset } = useModal();

  const {
    selectedTooth,
    setControls3D,
    logs,
    setLogs,
    scene,
    attachmentArray,
    groupRef,
  } = useGlobalStore(state => ({
    selectedTooth: state.controls3D.selectedTooth,
    setControls3D: state.setControls3D,
    logs: state.logs,
    setLogs: state.setLogs,
    scene: state.scene,
    attachmentArray: state.controls3D.attachmentArray,
    groupRef: state.refs.groupRef,
  }));

  // Undo/Redo/Reset Components
  // function reflectToChangelog(theMovement, isUndo = 1) {
  //   const toothNum = theMovement.tooth.name.split("_")[2].substring(1, 4);
  //   switch (theMovement.actionType) {
  //     case MovementTypes.EXTRUSIONINTRUSION: {
  //       let factor = 1;
  //       if (theMovement.movement.y < 0) {
  //         factor = -1;
  //       }
  //       logs[toothNum + "_Movement"][MovementTypes.EXTRUSIONINTRUSION] =
  //         Math.round(
  //           (logs[toothNum + "_Movement"][MovementTypes.EXTRUSIONINTRUSION] +
  //             toothMovementAmount * factor * isUndo) *
  //             100,
  //         ) / 100;
  //       break;
  //     }
  //     case MovementTypes.MESIALDISTAL: {
  //       let factor = 1;
  //       if (theMovement.type === "Mesial") {
  //         factor = -1;
  //       }
  //       console.log(factor);
  //       logs[toothNum + "_Movement"][MovementTypes.MESIALDISTAL] =
  //         Math.round(
  //           (logs[toothNum + "_Movement"][MovementTypes.MESIALDISTAL] +
  //             toothMovementAmount * factor * isUndo) *
  //             100,
  //         ) / 100;
  //       break;
  //     }
  //     case MovementTypes.BUCCALLINGUAL: {
  //       let factor = 1;
  //       if (theMovement.type === "Lingual") {
  //         factor = -1;
  //       }
  //       logs[toothNum + "_Movement"][MovementTypes.BUCCALLINGUAL] =
  //         Math.round(
  //           (logs[toothNum + "_Movement"][MovementTypes.BUCCALLINGUAL] +
  //             toothMovementAmount * factor * isUndo) *
  //             100,
  //         ) / 100;
  //       break;
  //     }
  //     case MovementTypes.ROTATION: {
  //       let factor = 1;
  //       if (theMovement.degrees < 0) {
  //         factor = -1;
  //       }
  //       logs[toothNum + "_Movement"][MovementTypes.ROTATION] =
  //         Math.round(
  //           (logs[toothNum + "_Movement"][MovementTypes.ROTATION] +
  //             teethRotationAmount * factor * isUndo) *
  //             100,
  //         ) / 100;
  //       break;
  //     }
  //     case MovementTypes.ANGULATION: {
  //       let factor = 1;
  //       if (theMovement.degrees < 0) {
  //         factor = -1;
  //       }
  //       logs[toothNum + "_Movement"][MovementTypes.ANGULATION] =
  //         Math.round(
  //           (logs[toothNum + "_Movement"][MovementTypes.ANGULATION] +
  //             teethRotationAmount * factor * isUndo) *
  //             100,
  //         ) / 100;
  //       break;
  //     }
  //     case MovementTypes.INCLINATION: {
  //       let factor = 1;
  //       if (theMovement.degrees < 0) {
  //         factor = -1;
  //       }
  //       logs[toothNum + "_Movement"][MovementTypes.INCLINATION] =
  //         Math.round(
  //           (logs[toothNum + "_Movement"][MovementTypes.INCLINATION] +
  //             teethRotationAmount * factor * isUndo) *
  //             100,
  //         ) / 100;
  //       break;
  //     }
  //     case MovementTypes.LEFTHINGE: {
  //       let factor = 1;
  //       if (theMovement.degrees < 0) {
  //         factor = -1;
  //       }
  //       logs[toothNum + "_Movement"][MovementTypes.LEFTHINGE] =
  //         Math.round(
  //           (logs[toothNum + "_Movement"][MovementTypes.LEFTHINGE] +
  //             teethRotationAmount * factor * isUndo) *
  //             100,
  //         ) / 100;
  //       break;
  //     }
  //     case MovementTypes.RIGHTHINGE: {
  //       let factor = 1;
  //       if (theMovement.degrees < 0) {
  //         factor = -1;
  //       }
  //       logs[toothNum + "_Movement"][MovementTypes.RIGHTHINGE] =
  //         Math.round(
  //           (logs[toothNum + "_Movement"][MovementTypes.RIGHTHINGE] +
  //             teethRotationAmount * factor * isUndo) *
  //             100,
  //         ) / 100;
  //       break;
  //     }
  //   }
  //   setLogs(logs);
  //   console.log(logs);
  // }
  function undo(addToList = true) {
    let newArray = JSON.parse(JSON.stringify(iprOverLays));
    //debugger;
    let isArray = false;
    let lastElem = false;
    if (undoMovementList.length > 0) {
      isArray = Array.isArray(undoMovementList[undoMovementList.length - 1]);
    }
    let movementType, toothNum, arrLen;
    if (!isArray) {
      movementType = undoMovementList[undoMovementList.length - 1]?.actionType;
      toothNum = undoMovementList[undoMovementList.length - 1]?.tooth.name
        .split("_")[2]
        .substring(1, 4);
    } else {
      arrLen = undoMovementList[undoMovementList.length - 1].length - 1;
      movementType =
        undoMovementList[undoMovementList.length - 1][arrLen].actionType;
      toothNum = undoMovementList[undoMovementList.length - 1][
        arrLen
      ].tooth.name
        .split("_")[2]
        .substring(1, 4);
    }

    while (true) {
      if (!isArray) {
        if (undoMovementList.length === 0) {
          console.log("No more Changes");
          lastElem = true;
          return;
        }

        if (
          movementType !==
            undoMovementList[undoMovementList.length - 1].actionType ||
          toothNum !==
            undoMovementList[undoMovementList.length - 1].tooth.name
              .split("_")[2]
              .substring(1, 4)
        ) {
          break;
        }
        //console.log(undoMovementList);
        const t = undoMovementList.pop();
        if (t.movement) {
          t.tooth.position.add(t.movement);
          reflectToChangelog(t);
        } else if (t.degrees) {
          rotateTooth(
            t.rotationPoint,
            t.teethMovementRotationDirection,
            t.degrees,
            t.tooth,
          );
          reflectToChangelog(t);
          // rotateAroundPoint(t.tooth, t.rotationPoint, t.teethMovementRotationDirection, t.degrees, true)
        } else if (t.actionType === MovementTypes.LOCK) {
          setControls3D("selectedTooth", t.tooth);
          lockTooth(true, t.tooth);
        } else if (t.actionType === MovementTypes.ATTACHMENTS) {
          if (t.action === "add") {
            for (let i = 0; i < t.tooth.children[0].children.length; i++) {
              if (t.tooth.children[0].children[i].name.includes("att")) {
                redoMovementList.push({
                  tooth: t.tooth,
                  action: t.action,
                  actionType: t.actionType,
                  attachmentName: t.tooth.children[0].children[i].name,
                  relativePosition:
                    t.tooth.children[0].children[i].position.clone(),
                  relativeRotation:
                    t.tooth.children[0].children[i].rotation.clone(),
                });

                let selectedToothNum = t.tooth.name
                  .split("_")[2]
                  .substring(1, 4);
                let action;
                if (attachmentArray[selectedToothNum]?.length > 0) {
                  action = "remove";
                } else {
                  action = "";
                }

                let key = selectedToothNum.toString() + "_Movement";
                if (logs[key]) {
                  const obj = Object.assign({}, logs[key]);
                  delete logs[key];
                  obj["attachment"] = {
                    action: action,
                    type: t.tooth.children[0].children[i].name,
                  };
                  logs[key] = obj;
                } else {
                  logs[key] = {};
                  logs[key]["attachment"] = {
                    action: action,
                    type: t.tooth.children[0].children[i].name,
                  };
                }
                setLogs(logs);
                t.tooth.children[0].children[i].removeFromParent();
              }
            }
          } else if (t.action === "remove") {
            let attachment;
            for (let i = 0; i < attachmentsObjects.length; i++) {
              if (attachmentsObjects[i].name === t.attachmentName) {
                attachment = attachmentsObjects[i].clone();
              }
            }
            if (!attachment) {
              // old attachment
              attachment =
                attachmentArray[
                  t.tooth.name.split("_")[2].substring(1)
                ][0].clone();
              attachment.material = oldAttachmentMaterial;
            } else {
              attachment.material = attachmentMaterial;
            }
            t.tooth.children[0].add(attachment);
            attachment.position.copy(t.relativePosition);
            attachment.rotation.copy(t.relativeRotation);
            attachment.visible = true;

            redoMovementList.push({
              tooth: t.tooth,
              action: t.action,
              actionType: t.actionType,
            });

            let selectedToothNum = t.tooth.name.split("_")[2].substring(1, 4);
            let action;
            if (attachmentArray[selectedToothNum]?.length > 0) {
              action = "replace";
            } else {
              action = "add";
            }

            let key = selectedToothNum.toString() + "_Movement";
            if (logs[key]) {
              const obj = Object.assign({}, logs[key]);
              delete logs[key];
              obj["attachment"] = {
                action: action,
                type: attachment.name,
              };
              logs[key] = obj;
            } else {
              logs[key] = {};
              logs[key]["attachment"] = {
                action: action,
                type: attachment.name,
              };
            }
            setLogs(logs);
          } else if (t.action === "move") {
            for (let i = 0; i < t.tooth.children[0].children.length; i++) {
              if (t.tooth.children[0].children[i].name.includes("att")) {
                redoMovementList.push({
                  tooth: t.tooth,
                  action: t.action,
                  actionType: t.actionType,
                  attachmentName: t.tooth.children[0].children[i].name,
                  relativePosition:
                    t.tooth.children[0].children[i].position.clone(),
                  relativeRotation:
                    t.tooth.children[0].children[i].rotation.clone(),
                });
                t.tooth.children[0].children[i].removeFromParent();
              }

              let attachment;
              let isExistingAttachment = false;
              for (let i = 0; i < attachmentsObjects.length; i++) {
                if (attachmentsObjects[i].name === t.attachmentName) {
                  attachment = attachmentsObjects[i].clone();
                }
              }
              if (!attachment) {
                // old attachment
                isExistingAttachment = true;
                attachment =
                  attachmentArray[
                    t.tooth.name.split("_")[2].substring(1)
                  ][0].clone();
                attachment.material = oldAttachmentMaterial;
              } else {
                attachment.material = attachmentMaterial;
              }
              t.tooth.children[0].add(attachment);
              attachment.position.copy(t.relativePosition);
              attachment.rotation.copy(t.relativeRotation);
              attachment.visible = true;

              let selectedToothNum = t.tooth.name.split("_")[2].substring(1, 4);
              let action;
              if (attachmentArray[selectedToothNum]?.length > 0) {
                if (isExistingAttachment) {
                  action = "";
                } else {
                  action = "replace";
                }
              } else {
                action = "add";
              }

              let key = selectedToothNum.toString() + "_Movement";
              if (logs[key]) {
                const obj = Object.assign({}, logs[key]);
                delete logs[key];
                obj["attachment"] = {
                  action: action,
                  type: attachment.name,
                };
                logs[key] = obj;
              } else {
                logs[key] = {};
                logs[key]["attachment"] = {
                  action: action,
                  type: attachment.name,
                };
              }
              setLogs(logs);
            }
          }
          return;
          // reflectAttachments(
          //   t.tooth,
          //   t.attachment,
          //   t.initialAttachmentPosition,
          //   t.initialAttachmentRotation,
          //   t.attachmentPosition,
          //   t.attachmentRotation,
          //   t.action,
          //   scene,
          //   attachmentArray,
          //   GLBData,
          //   0,
          // );
          if (undoMovementList.length === 0) {
            reflectToChangelog(t);
          }
          // console.log(undoMovementList, t.attachmentRotationMatrix);
        }
        // else if (t.actionType === MovementTypes.ATTACHMENTS) {
        //   reflectAttachments(
        //     t.tooth,
        //     t.attachment,
        //     t.initialAttachmentPosition,
        //     t.initialAttachmentRotation,
        //     t.attachmentPosition,
        //     t.attachmentRotation,
        //     t.action,
        //     scene,
        //     attachmentArray,
        //     GLBData,
        //     0,
        //   );
        //   if (undoMovementList.length === 0) {
        //     reflectToChangelog(t);
        //   }
        //   // console.log(undoMovementList, t.attachmentRotationMatrix);
        // }
        if (addToList) {
          redoMovementList.push(t);
        }
        break;
      } else {
        let len = undoMovementList[undoMovementList.length - 1]?.length - 1;
        if (
          undoMovementList.length === 0 ||
          movementType !==
            undoMovementList[undoMovementList.length - 1][len]?.actionType ||
          toothNum !==
            undoMovementList[undoMovementList.length - 1][len]?.tooth.name
              .split("_")[2]
              .substring(1, 4)
        ) {
          break;
        }
        const r = undoMovementList.pop();
        redoMovementList.push([]);
        let t;
        for (let i = 0; i <= len; i++) {
          t = r[i];
          if (t.movement) {
            t.tooth.position.add(t.movement);
            reflectToChangelog(t);
          } else if (t.degrees) {
            rotateTooth(
              t.rotationPoint,
              t.teethMovementRotationDirection,
              t.degrees,
              t.tooth,
            );
            reflectToChangelog(t);
            // rotateAroundPoint(t.tooth, t.rotationPoint, t.teethMovementRotationDirection, t.degrees, true)
          } else if (t.actionType === MovementTypes.LOCK) {
            setControls3D("selectedTooth", t.tooth);
            lockTooth(true, t.tooth);
          } else if (t.actionType === MovementTypes.IPR) {
            if (t.from < 17) {
              for (let i = 0; i < newArray[0]["t" + t.from].length; i++) {
                if (
                  newArray[0]["t" + t.from][i].step ===
                  newArray[0]["t" + t.from].length - 1
                ) {
                  newArray[0]["t" + t.from][i].amount =
                    newArray[0]["t" + t.from][i].amount -
                    (t.amount - t.preAmount);
                }
              }
            } else {
              for (let i = 0; i < newArray[1]["t" + t.from].length; i++) {
                if (
                  newArray[1]["t" + t.from][i].step ===
                  newArray[1]["t" + t.from].length - 1
                ) {
                  newArray[1]["t" + t.from][i].amount =
                    newArray[1]["t" + t.from][i].amount -
                    (t.amount - t.preAmount);
                }
              }
            }
            reflectToChangelog(t);
          }
          if (addToList) {
            redoMovementList[redoMovementList.length - 1].push(t);
          }
        }
      }
    }
    setIprOverlays(newArray);
  }

  function undoWithOrder() {
    // for hamzeh's revert injection
    undo(false);
  }

  const { content } = useCustomTranslation();

  const getContent = (group, key) => {
    return content(`controls.${group}.${key}`);
  };
  return (
    <div className="bounty_urr">
      <div
        id="undo"
        onClick={() => {
          undo(), setAutoSave();
        }}
        className="bounty_undo"
      >
        <Undo />
      </div>
      <div
        id="redo"
        onClick={() => {
          redo(), setAutoSave();
        }}
        className="bounty_redo"
      >
        <Redo />
      </div>
      <ResetModal
        title={getContent("labels", "reset_modifications")}
        primaryActionText={getContent("actions", "reset")}
        primaryAction={() => {
          reset();
          clearAutoSave(), toggleReset();
        }}
        secondaryActionText={getContent("actions", "cancel")}
      >
        {getContent(
          "labels",
          "are_you_sure_you_want_to_reset_all_of_your_modifications_to_the_treatment_plan",
        )}
      </ResetModal>
      <div id="reset" onClick={toggleReset} className="bounty_reset">
        <Reset />
      </div>
    </div>
  );
}

export default URR;
