import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";

const credentials = fromCognitoIdentityPool({
  clientConfig: { region: process.env.REACT_APP_AWS_REGION },
  identityPoolId: process.env.REACT_APP_AWS_IDENTITYPOOLID,
});

const s3Client = new S3Client({
  region: process.env.REACT_APP_AWS_REGION,
  credentials,
});

export const getSingedURL = async () => {
  // Create a command to get object

  const command = new GetObjectCommand({
    Bucket: process.env.REACT_APP_AWS_S3_BUCKET,
    Key: `tp-viewer-demo-ts/23127107TC.OMAR_7e556.glb`,
  });

  // Generate the pre-signed URL
  const signedUrl = await getSignedUrl(s3Client, command, {
    expiresIn: 3600,
  });
  return signedUrl;
};
