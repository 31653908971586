import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import { ToolTipToothMovement } from "../../shared-components/ToolTip";
import { usePopper } from "react-popper";
import { useGlobalStore } from "../../../store";
import { numberingFdi, numberingUniversal } from "@/helpers/teethNumbering";
import {
  Plus,
  Minus,
  MesialDistal,
  BuccalLingual,
  ExtrusionIntrusion,
  Rotation,
  MesialHingeRotation,
  DistalHingeRotation,
  CrownAngulation,
  Inclination,
} from "../Icons";
import * as THREE from "three";
import * as LR from "../teethControls/LinearRegreession";
import * as SPLINE from "../teethControls/Spline";
import { overlays } from "@/components/fakeData";
import { Vector3 } from "three";
import { Object } from "core-js";
import { lockedTeethMaterial, selectedTeethMaterial } from "../Mesh";
import { useCustomTranslation } from "@/hooks/useTranslation";
import { globalBiteJump } from "../Scene";
let once = true;
let sampleListUpper, sampleListLower;
let newArray;
export let MovementList = [],
  redoMovementList = [],
  undoMovementList = [],
  neglectionIPR = [];
export function setRedoList(value) {
  redoMovementList = value;
}
export let teethMovementRotationAxis = new THREE.Vector3(0, 1, 0);
export let teethMovementRotationDirection = new THREE.Vector3(0, 1, 0);
export let toothRotationPoint = new THREE.Vector3();
let IPRLimit = 1.1;
const spacingNeglictionAmount = -0.1;
let initialMatrix = [];
let cashedAttachmentName = [];
export function clearRedoMovementList() {
  redoMovementList = [];
}
// not used old usable logic
export const reflectAttachments = (
  tooth,
  attachmentName,
  initialPosition,
  initialRotation,
  translation,
  rotation,
  action,
  scene,
  attachmentArray,
  GLBData,
  redo = 0,
) => {
  const teeth =
    GLBData[GLBData.length - 1][tooth.name.includes("u") ? "upper" : "lower"]
      .teeth;
  if (!redo) {
    if (action === "added") {
      let attachmentToAdd;
      if (tooth.children[0].name !== attachmentName) {
        if (attachmentName === "") {
          if (
            attachmentArray[tooth.name.split("_")[2].substring(1, 4)].length ===
            2
          ) {
            attachmentToAdd = new THREE.Group();
            attachmentToAdd.add(
              attachmentArray[tooth.name.split("_")[2].substring(1, 4)][0],
            );
            attachmentToAdd.add(
              attachmentArray[tooth.name.split("_")[2].substring(1, 4)][1],
            );
          } else {
            attachmentToAdd =
              attachmentArray[tooth.name.split("_")[2].substring(1, 4)][0];
          }
        } else {
          attachmentToAdd = scene.getObjectByName(attachmentName).clone();
        }
        for (var i = tooth.children.length - 1; i >= 0; i--) {
          tooth.remove(tooth.children[i]);
        }
        for (let i = 0; i <= teeth.length; i++) {
          if (teeth[i].name == tooth.name) {
            teeth[i].children.push(attachmentToAdd);
            break;
          }
        }
        if (attachmentName !== "") {
          setTimeout(() => {
            const retrievedObject = tooth.children[0];
            retrievedObject.position.set(
              translation.x,
              translation.y,
              translation.z,
            );
            retrievedObject.setRotationFromQuaternion(rotation);
          }, 100);
        }
      } else {
        for (var i = tooth.children.length - 1; i >= 0; i--) {
          tooth.remove(tooth.children[i]);
        }
      }
    } else if (action === "deleted") {
      let attachmentToAdd;
      if (attachmentName === "") {
        if (
          attachmentArray[tooth.name.split("_")[2].substring(1, 4)].length === 2
        ) {
          attachmentToAdd = new THREE.Group();
          attachmentToAdd.add(
            attachmentArray[tooth.name.split("_")[2].substring(1, 4)][0],
          );
          attachmentToAdd.add(
            attachmentArray[tooth.name.split("_")[2].substring(1, 4)][1],
          );
        } else {
          attachmentToAdd =
            attachmentArray[tooth.name.split("_")[2].substring(1, 4)][0];
        }
      } else {
        attachmentToAdd = scene.getObjectByName(attachmentName).clone();
      }

      for (let i = 0; i <= teeth.length; i++) {
        if (teeth[i].name == tooth.name) {
          teeth[i].children.push(attachmentToAdd);
          break;
        }
      }

      if (attachmentName !== "") {
        setTimeout(() => {
          const retrievedObject = tooth.children[0];

          retrievedObject.position.set(
            initialPosition.x,
            initialPosition.y,
            initialPosition.z,
          );
          retrievedObject.setRotationFromQuaternion(initialRotation);
        }, 100);
      }
    } else if (action === "modified") {
      setTimeout(() => {
        const retrievedObject = tooth.children[0];
        retrievedObject.position.set(
          initialPosition.x,
          initialPosition.y,
          initialPosition.z,
        );
        retrievedObject.setRotationFromQuaternion(initialRotation);
      }, 100);
    }
  } else {
    console.log("redo");
  }
};
export function setToothMovementAmount(amount) {
  toothMovementAmount = amount;
}
function areColliding(mesh1, mesh2) {
  let t = Date.now();
  mesh1.updateMatrixWorld();
  mesh2.updateMatrixWorld();
  if (!mesh1.geometry.hasOwnProperty("boundsTree")) {
    mesh1.geometry.computeBoundsTree();
  }
  if (!mesh2.geometry.hasOwnProperty("boundsTree")) {
    mesh2.geometry.computeBoundsTree();
  }
  // console.log(Date.now() - t, "time after tree");
  let matrix2to1 = new THREE.Matrix4()
    .copy(mesh1.matrixWorld)
    .invert()
    .multiply(mesh2.matrixWorld);
  const edge = new THREE.Line3();
  const results = [];
  mesh1.geometry.boundsTree.bvhcast(mesh2.geometry.boundsTree, matrix2to1, {
    intersectsTriangles(triangle1, triangle2) {
      if (triangle1.intersectsTriangle(triangle2, edge)) {
        const { start, end } = edge;
        results.push(start.x, start.y, start.z, end.x, end.y, end.z);
        // console.log(Date.now() - t, "time occ Final");
        return true;
      }
    },
  });
  // console.log(Date.now() - t, "time occ Final false");

  if (results.length) {
    return true;
  } else {
    return false;
  }
}
function teethKeysSorter(a, b) {
  if (parseInt(a.substring(1)) < parseInt(b.substring(1))) {
    return -1;
  } else {
    return 1;
  }
}
function RemoveExtractedTeethFromKeys(newArray, sortedKeys) {
  let maxLength = 0;
  let keys = Object.keys(newArray);
  for (let index = 0; index < keys.length; index++) {
    if (maxLength < newArray[keys[index]].length) {
      maxLength = newArray[keys[index]].length;
    }
  }
  for (let index = 0; index < keys.length; index++) {
    if (maxLength != newArray[keys[index]].length) {
      sortedKeys.splice(sortedKeys.indexOf(keys[index]), 1);
    }
  }
}
export function MoveToothDistally(
  selectedTooth,
  logs,
  iprOverLays,
  setLogs,
  setIprOverlays,
  setAutoSave,
) {
  if (iprOverLays.length !== 0) {
    newArray = JSON.parse(JSON.stringify(iprOverLays));
  } //create deep copy of ipr array
  if (selectedTooth === undefined || selectedTooth === null) {
    return false;
  }

  let upperOrLower = selectedTooth.name.charAt(0);

  let samplesList2 = [];
  let distance = 100;
  let index = 0;
  let cacher;
  let selectedToothNum = selectedTooth.name.split("_")[2].substring(1, 4);
  let isLower = 1;
  let teethKeys;
  if (selectedTooth.name.charAt(0) === "u") {
    samplesList2 = sampleListUpper;
    isLower = 0;
  } else {
    samplesList2 = sampleListLower;
  }
  teethKeys = Object.keys(newArray[isLower]);
  teethKeys.sort(teethKeysSorter);
  RemoveExtractedTeethFromKeys(newArray[isLower], teethKeys);
  let selectedTeethCenter = new THREE.Vector3(); // selected
  let center1 = new THREE.Vector3();
  let holder = selectedTooth.parent;
  let box3 = new THREE.Box3().setFromObject(selectedTooth);
  box3.getCenter(selectedTeethCenter);

  if (true) {
    // new
    if (selectedTooth.userData["isLocked"]) {
      return false;
    }

    let selectedIndex = holder.children.indexOf(selectedTooth);

    let firstLockIndex, secondLockIndex;
    for (let i = selectedIndex; i < holder.children.length; i++) {
      if (holder.children[i].userData["isLocked"]) {
        break;
      }
      secondLockIndex = i;
    }

    for (let i = selectedIndex; i >= 0; i--) {
      if (holder.children[i].userData["isLocked"]) {
        break;
      }
      firstLockIndex = i;
    }

    firstLockIndex = selectedIndex;
    secondLockIndex = selectedIndex;
    let counter = -1;
    if (selectedTeethCenter.x > 0) {
      counter = 1;
    }

    let tooth;
    let isArray = false;
    if (upperOrLower === "u") {
      //Done
      if (counter === -1) {
        for (let i = firstLockIndex; i <= secondLockIndex; i++) {
          if (holder.children[i].userData["isLocked"]) {
            continue;
          }
          tooth = holder.children[i];
          let fIndex, sIndex;
          let maxStepNumber = newArray[isLower][teethKeys[0]].length - 1;
          if (i > 0) {
            for (let r = 0; r < maxStepNumber; r++) {
              if (
                newArray[isLower][teethKeys[i - 1]][r].step === maxStepNumber ||
                newArray[isLower][teethKeys[i - 1]][r].amount === null
              ) {
                fIndex = r;
                newArray[isLower][teethKeys[i - 1]][r].step = maxStepNumber;
                break;
              }
            }
          }
          for (let r = 0; r < maxStepNumber; r++) {
            if (
              newArray[isLower][teethKeys[i]][r].step === maxStepNumber ||
              newArray[isLower][teethKeys[i]][r].amount === null
            ) {
              sIndex = r;
              newArray[isLower][teethKeys[i]][r].step = maxStepNumber;
              break;
            }
          }
          if (i > 0 && areColliding(tooth, holder.children[i - 1])) {
            if (
              Number(newArray[isLower][teethKeys[i - 1]][fIndex].amount) +
                getToothTotalIPR(isLower, teethKeys[i - 1], newArray) >=
              IPRLimit
            ) {
              continue;
            }
          }
          distance = 100;
          index = 0;
          box3 = new THREE.Box3().setFromObject(tooth);
          box3.getCenter(center1);

          if (!tooth.userData["index"]) {
            for (
              let i = 0;
              i < samplesList2.length;
              i++ // closest point on the equation line
            ) {
              cacher = center1.distanceTo(samplesList2[i]);
              if (distance > cacher) {
                distance = cacher;
                index = i;
              }
            }
          } else {
            index = tooth.userData["index"];
          }
          cacher = index;
          let length = 0;
          for (let i = counter; length < toothMovementAmount; i += counter) {
            cacher = index + i;
            if (cacher >= samplesList2.length || cacher < 0) {
              cacher = THREE.MathUtils.clamp(
                cacher,
                0,
                samplesList2.length - 1,
              );
              break;
            }
            length += samplesList2[cacher - counter].distanceTo(
              samplesList2[cacher],
            );
          }
          tooth.userData["index"] = cacher;

          cacher = samplesList2[index].clone().sub(samplesList2[cacher]);
          if (cacher.length() === 0) {
            return false;
          }
          tooth.position.sub(cacher);
          tooth.userData["center"].sub(cacher);
          tooth.userData["rightHinge"].sub(cacher);
          tooth.userData["leftHinge"].sub(cacher);
          tooth.userData["gumPoint"].sub(cacher);
          tooth.updateMatrixWorld();
          undoMovementList.push([]);
          if (i > 0) {
            let newIPRAmount =
              Math.round(
                calculateIPRBetweenTeeth(tooth, holder.children[i - 1]) * 100,
              ) / 100;
            AddIPRToLogs(
              logs,
              "added",
              newIPRAmount,
              newArray[isLower][teethKeys[i - 1]][fIndex].amount,
              teethKeys[i - 1].substring(1),
              selectedToothNum,
            );

            undoMovementList[undoMovementList.length - 1].push({
              type: 1,
              amount: newIPRAmount,
              preAmount: newArray[isLower][teethKeys[i - 1]][fIndex].amount,
              action: "added",
              actionType: MovementTypes.IPR,
              from: teethKeys[i - 1].substring(1),
              to: teethKeys[i],
              tooth: selectedTooth,
            });
            newArray[isLower][teethKeys[i - 1]][fIndex].amount = newIPRAmount;
          }
          let newIPRAmount =
            Math.round(
              calculateIPRBetweenTeeth(tooth, holder.children[i + 1]) * 100,
            ) / 100;
          AddIPRToLogs(
            logs,
            "added",
            newIPRAmount,
            newArray[isLower][teethKeys[i]][sIndex].amount,
            selectedToothNum,
            teethKeys[i + 1].substring(1),
          );
          undoMovementList[undoMovementList.length - 1].push({
            type: 1,
            amount: newIPRAmount,
            preAmount: newArray[isLower][teethKeys[i]][sIndex].amount,
            action: "added",
            actionType: MovementTypes.IPR,
            from: teethKeys[i].substring(1),
            to: teethKeys[i + 1],
            tooth: selectedTooth,
          });
          newArray[isLower][teethKeys[i]][sIndex].amount = newIPRAmount;
          isArray = true;
        }
      } else {
        for (let i = secondLockIndex; i >= firstLockIndex; i--) {
          if (holder.children[i].userData["isLocked"]) {
            continue;
          }

          tooth = holder.children[i];
          let fIndex, sIndex;
          let maxStepNumber = newArray[isLower][teethKeys[0]].length - 1;
          for (let r = 0; r < maxStepNumber; r++) {
            if (
              newArray[isLower][teethKeys[i]][r].step === maxStepNumber ||
              newArray[isLower][teethKeys[i]][r].amount === null
            ) {
              fIndex = r;
              newArray[isLower][teethKeys[i]][r].step = maxStepNumber;
              break;
            }
          }
          for (let r = 0; r < maxStepNumber; r++) {
            if (
              newArray[isLower][teethKeys[i - 1]][r].step === maxStepNumber ||
              newArray[isLower][teethKeys[i - 1]][r].amount === null
            ) {
              sIndex = r;
              newArray[isLower][teethKeys[i - 1]][r].step = maxStepNumber;
              break;
            }
          }
          if (
            i + counter < holder.children.length &&
            areColliding(tooth, holder.children[i + 1])
          ) {
            if (
              Number(newArray[isLower][teethKeys[i]][fIndex].amount) +
                getToothTotalIPR(isLower, teethKeys[i], newArray) >=
              IPRLimit
            ) {
              continue;
            }
          }
          distance = 100;
          index = 0;
          box3 = new THREE.Box3().setFromObject(tooth);
          box3.getCenter(center1);

          if (!tooth.userData["index"]) {
            for (
              let i = 0;
              i < samplesList2.length;
              i++ // closest point on the equation line
            ) {
              cacher = center1.distanceTo(samplesList2[i]);
              if (distance > cacher) {
                distance = cacher;
                index = i;
              }
            }
          } else {
            index = tooth.userData["index"];
          }

          cacher = index;
          let length = 0;
          for (let i = counter; length < toothMovementAmount; i += counter) {
            cacher = index + i;
            if (cacher >= samplesList2.length || cacher < 0) {
              cacher = THREE.MathUtils.clamp(
                cacher,
                0,
                samplesList2.length - 1,
              );
              break;
            }
            length += samplesList2[cacher - counter].distanceTo(
              samplesList2[cacher],
            );
          }
          tooth.userData["index"] = cacher;
          cacher = samplesList2[index].clone().sub(samplesList2[cacher]);
          if (cacher.length() === 0) {
            return false;
          }
          tooth.position.sub(cacher);
          tooth.userData["center"].sub(cacher);
          tooth.userData["rightHinge"].sub(cacher);
          tooth.userData["leftHinge"].sub(cacher);
          tooth.userData["gumPoint"].sub(cacher);
          tooth.updateMatrixWorld();
          undoMovementList.push([]);
          if (i < teethKeys.length - 1) {
            let newIPRAmount =
              Math.round(
                calculateIPRBetweenTeeth(tooth, holder.children[i + 1]) * 100,
              ) / 100;
            AddIPRToLogs(
              logs,
              "added",
              newIPRAmount,
              newArray[isLower][teethKeys[i]][fIndex].amount,
              selectedToothNum,
              teethKeys[i + 1].substring(1),
            );
            undoMovementList[undoMovementList.length - 1].push({
              type: 1,
              amount: newIPRAmount,
              preAmount: newArray[isLower][teethKeys[i]][fIndex].amount,
              action: "added",
              actionType: MovementTypes.IPR,
              from: teethKeys[i].substring(1),
              to: teethKeys[i + 1],
              tooth: selectedTooth,
            });
            newArray[isLower][teethKeys[i]][fIndex].amount = newIPRAmount;
          }
          let newIPRAmount =
            Math.round(
              calculateIPRBetweenTeeth(tooth, holder.children[i - 1]) * 100,
            ) / 100;
          AddIPRToLogs(
            logs,
            "added",
            newIPRAmount,
            newArray[isLower][teethKeys[i - 1]][sIndex].amount,
            teethKeys[i - 1].substring(1),
            selectedToothNum,
          );
          undoMovementList[undoMovementList.length - 1].push({
            type: 1,
            amount: newIPRAmount,
            preAmount: newArray[isLower][teethKeys[i - 1]][sIndex].amount,
            action: "added",
            actionType: MovementTypes.IPR,
            from: teethKeys[i - 1].substring(1),
            to: teethKeys[i],
            tooth: selectedTooth,
          });
          newArray[isLower][teethKeys[i - 1]][sIndex].amount = newIPRAmount;
          isArray = true;
        }
      }
    } else {
      if (counter === -1) {
        for (let i = secondLockIndex; i >= firstLockIndex; i--) {
          if (holder.children[i].userData["isLocked"]) {
            continue;
          }
          tooth = holder.children[i];
          let fIndex, sIndex;
          let maxStepNumber = newArray[isLower][teethKeys[0]].length - 1;
          if (i < teethKeys.length - 1) {
            for (let r = 0; r < maxStepNumber; r++) {
              if (
                newArray[isLower][teethKeys[i + 1]][r].step === maxStepNumber ||
                newArray[isLower][teethKeys[i + 1]][r].amount === null
              ) {
                fIndex = r;
                newArray[isLower][teethKeys[i + 1]][r].step = maxStepNumber;
                break;
              }
            }
          }
          for (let r = 0; r < maxStepNumber; r++) {
            if (
              newArray[isLower][teethKeys[i]][r].step === maxStepNumber ||
              newArray[isLower][teethKeys[i]][r].amount === null
            ) {
              sIndex = r;
              newArray[isLower][teethKeys[i]][r].step = maxStepNumber;
              break;
            }
          }
          if (
            i - counter < holder.children.length &&
            areColliding(tooth, holder.children[i + 1])
          ) {
            if (
              Number(newArray[isLower][teethKeys[i + 1]][fIndex].amount) +
                getToothTotalIPR(isLower, teethKeys[i + 1], newArray) >=
              IPRLimit
            ) {
              continue;
            }
          }

          distance = 100;
          index = 0;
          box3 = new THREE.Box3().setFromObject(tooth);
          box3.getCenter(center1);

          if (!tooth.userData["index"]) {
            for (
              let i = 0;
              i < samplesList2.length;
              i++ // closest point on the equation line
            ) {
              cacher = center1.distanceTo(samplesList2[i]);
              if (distance > cacher) {
                distance = cacher;
                index = i;
              }
            }
          } else {
            index = tooth.userData["index"];
          }

          cacher = index;
          let length = 0;
          for (let i = counter; length < toothMovementAmount; i += counter) {
            cacher = index + i;
            if (cacher >= samplesList2.length || cacher < 0) {
              cacher = THREE.MathUtils.clamp(
                cacher,
                0,
                samplesList2.length - 1,
              );
              break;
            }
            length += samplesList2[cacher - counter].distanceTo(
              samplesList2[cacher],
            );
          }

          tooth.userData["index"] = cacher;

          cacher = samplesList2[index].clone().sub(samplesList2[cacher]);
          if (cacher.length() === 0) {
            return false;
          }
          tooth.position.sub(cacher);
          tooth.userData["center"].sub(cacher);
          tooth.userData["rightHinge"].sub(cacher);
          tooth.userData["leftHinge"].sub(cacher);
          tooth.userData["gumPoint"].sub(cacher);

          tooth.updateMatrixWorld();
          undoMovementList.push([]);
          if (i < teethKeys.length - 1) {
            let newIPRAmount =
              Math.round(
                calculateIPRBetweenTeeth(tooth, holder.children[i + 1]) * 100,
              ) / 100;
            // console.log(teethKeys[i + 1]);
            AddIPRToLogs(
              logs,
              "added",
              newIPRAmount,
              newArray[isLower][teethKeys[i + 1]][fIndex].amount,
              teethKeys[i + 1].substring(1),
              selectedToothNum,
            );
            undoMovementList[undoMovementList.length - 1].push({
              type: 1,
              amount: newIPRAmount,
              preAmount: newArray[isLower][teethKeys[i + 1]][fIndex].amount,
              action: "added",
              actionType: MovementTypes.IPR,
              from: teethKeys[i + 1].substring(1),
              to: teethKeys[i],
              tooth: selectedTooth,
            });
            newArray[isLower][teethKeys[i + 1]][fIndex].amount = newIPRAmount;
          }
          let newIPRAmount =
            Math.round(
              calculateIPRBetweenTeeth(tooth, holder.children[i - 1]) * 100,
            ) / 100;
          AddIPRToLogs(
            logs,
            "added",
            newIPRAmount,
            newArray[isLower][teethKeys[i]][sIndex].amount,
            selectedToothNum,
            teethKeys[i - 1].substring(1),
          );
          undoMovementList[undoMovementList.length - 1].push({
            type: 1,
            amount: newIPRAmount,
            preAmount: newArray[isLower][teethKeys[i]][sIndex].amount,
            action: "added",
            actionType: MovementTypes.IPR,
            from: teethKeys[i].substring(1),
            to: teethKeys[i - 1],
            tooth: selectedTooth,
          });
          newArray[isLower][teethKeys[i]][sIndex].amount = newIPRAmount;
          isArray = true;
        }
      } else {
        for (let i = firstLockIndex; i <= secondLockIndex; i++) {
          if (holder.children[i].userData["isLocked"]) {
            continue;
          }
          tooth = holder.children[i];
          let fIndex, sIndex;
          let maxStepNumber = newArray[isLower][teethKeys[0]].length - 1;
          for (let r = 0; r < maxStepNumber; r++) {
            if (
              newArray[isLower][teethKeys[i]][r].step === maxStepNumber ||
              newArray[isLower][teethKeys[i]][r].amount === null
            ) {
              fIndex = r;
              newArray[isLower][teethKeys[i]][r].step = maxStepNumber;
              break;
            }
          }
          for (let r = 0; r < maxStepNumber; r++) {
            if (
              newArray[isLower][teethKeys[i + 1]][r].step === maxStepNumber ||
              newArray[isLower][teethKeys[i + 1]][r].amount === null
            ) {
              sIndex = r;
              newArray[isLower][teethKeys[i + 1]][r].step = maxStepNumber;
              break;
            }
          }

          if (i - counter > -1 && areColliding(tooth, holder.children[i - 1])) {
            if (
              Number(newArray[isLower][teethKeys[i]][fIndex].amount) +
                getToothTotalIPR(isLower, teethKeys[i], newArray) >=
              IPRLimit
            ) {
              continue;
            }
          }

          distance = 100;
          index = 0;
          box3 = new THREE.Box3().setFromObject(tooth);
          box3.getCenter(center1);

          if (!tooth.userData["index"]) {
            for (
              let i = 0;
              i < samplesList2.length;
              i++ // closest point on the equation line
            ) {
              cacher = center1.distanceTo(samplesList2[i]);
              if (distance > cacher) {
                distance = cacher;
                index = i;
              }
            }
          } else {
            index = tooth.userData["index"];
          }

          cacher = index;
          let length = 0;
          for (let i = counter; length < toothMovementAmount; i += counter) {
            cacher = index + i;
            if (cacher >= samplesList2.length || cacher < 0) {
              cacher = THREE.MathUtils.clamp(
                cacher,
                0,
                samplesList2.length - 1,
              );
              break;
            }
            length += samplesList2[cacher - counter].distanceTo(
              samplesList2[cacher],
            );
          }
          tooth.userData["index"] = cacher;
          //cacher var now contains the movement amount
          cacher = samplesList2[index].clone().sub(samplesList2[cacher]);
          if (cacher.length() === 0) {
            return false;
          }
          tooth.position.sub(cacher);
          tooth.userData["center"].sub(cacher);
          tooth.userData["rightHinge"].sub(cacher);
          tooth.userData["leftHinge"].sub(cacher);
          tooth.userData["gumPoint"].sub(cacher);
          tooth.updateMatrixWorld();

          undoMovementList.push([]);
          if (i > 0) {
            let newIPRAmount =
              Math.round(
                calculateIPRBetweenTeeth(tooth, holder.children[i - 1]) * 100,
              ) / 100;
            AddIPRToLogs(
              logs,
              "added",
              newIPRAmount,
              newArray[isLower][teethKeys[i]][fIndex].amount,
              selectedToothNum,
              teethKeys[i - 1].substring(1),
            );
            undoMovementList[undoMovementList.length - 1].push({
              type: 1,
              amount: newIPRAmount,
              preAmount: newArray[isLower][teethKeys[i]][fIndex].amount,
              action: "added",
              actionType: MovementTypes.IPR,
              from: teethKeys[i].substring(1),
              to: teethKeys[i - 1],
              tooth: selectedTooth,
            });
            newArray[isLower][teethKeys[i]][fIndex].amount = newIPRAmount;
          }

          let newIPRAmount =
            Math.round(
              calculateIPRBetweenTeeth(tooth, holder.children[i + 1]) * 100,
            ) / 100;
          AddIPRToLogs(
            logs,
            "added",
            newIPRAmount,
            newArray[isLower][teethKeys[i + 1]][sIndex].amount,
            teethKeys[i + 1].substring(1),
            selectedToothNum,
          );
          undoMovementList[undoMovementList.length - 1].push({
            type: 1,
            amount: newIPRAmount,
            preAmount: newArray[isLower][teethKeys[i + 1]][sIndex].amount,
            action: "added",
            actionType: MovementTypes.IPR,
            from: teethKeys[i + 1].substring(1),
            to: teethKeys[i],
            tooth: selectedTooth,
          });
          newArray[isLower][teethKeys[i + 1]][sIndex].amount = newIPRAmount;
          isArray = true;
        }
      }
    }
    if (cacher) {
      MovementList.push({ tooth, movement: cacher });
      if (isArray) {
        undoMovementList[undoMovementList.length - 1].push({
          tooth,
          movement: cacher,
          actionType: MovementTypes.MESIALDISTAL,
          type: "Mesial",
        });
      } else {
        undoMovementList.push({
          tooth,
          movement: cacher,
          actionType: MovementTypes.MESIALDISTAL,
          type: "Mesial",
        });
      }
      let key = selectedToothNum.toString() + "_Movement";
      if (logs[key]) {
        const obj = Object.assign({}, logs[key]);
        delete logs[key];
        obj[MovementTypes.MESIALDISTAL] =
          Math.round(
            (toothMovementAmount + obj[MovementTypes.MESIALDISTAL]) * 100,
          ) / 100;
        logs[key] = obj;
      } else {
        logs[key] = {};
        logs[key][MovementTypes.MESIALDISTAL] = toothMovementAmount;
        logs[key][MovementTypes.BUCCALLINGUAL] = 0;
        logs[key][MovementTypes.EXTRUSIONINTRUSION] = 0;
        logs[key][MovementTypes.ROTATION] = 0;
        logs[key][MovementTypes.ANGULATION] = 0;
        logs[key][MovementTypes.INCLINATION] = 0;
        logs[key][MovementTypes.LEFTHINGE] = 0;
        logs[key][MovementTypes.RIGHTHINGE] = 0;
      }
    }
  }
  setLogs(logs);
  setIprOverlays(JSON.parse(JSON.stringify(newArray)));
  clearRedoMovementList();
  setAutoSave();
}

export function MoveToothMesially(
  selectedTooth,
  logs,
  iprOverLays,
  setLogs,
  setIprOverlays,
  setAutoSave,
) {
  if (iprOverLays.length !== 0) {
    newArray = JSON.parse(JSON.stringify(iprOverLays));
  } //create deep copy of ipr array
  if (selectedTooth === undefined || selectedTooth === null) {
    return false;
  }
  let upperOrLower = selectedTooth.name.charAt(0);
  let samplesList2 = [];
  let distance = Infinity;
  let index = 0;
  let cacher;
  let selectedToothNum = selectedTooth.name.split("_")[2].substring(1, 4);
  let isLower = 0;
  let teethKeys;
  let isArray = false;
  if (upperOrLower === "u") {
    samplesList2 = sampleListUpper;
  } else {
    samplesList2 = sampleListLower;
    isLower = 1;
  }
  teethKeys = Object.keys(newArray[isLower]);
  teethKeys.sort(teethKeysSorter);
  RemoveExtractedTeethFromKeys(newArray[isLower], teethKeys);
  let selectedTeethCenter = new THREE.Vector3(); // selected
  let center1 = new THREE.Vector3();
  let holder = selectedTooth.parent;
  let box3 = new THREE.Box3().setFromObject(selectedTooth);
  box3.getCenter(selectedTeethCenter);

  if (true) {
    // new
    if (selectedTooth.userData["isLocked"]) {
      return false;
    }

    let selectedIndex = holder.children.indexOf(selectedTooth);
    let firstLockIndex, secondLockIndex;
    for (let i = selectedIndex; i < holder.children.length; i++) {
      if (holder.children[i].userData["isLocked"]) {
        break;
      }
      secondLockIndex = i;
    }

    for (let i = selectedIndex; i >= 0; i--) {
      if (holder.children[i].userData["isLocked"]) {
        break;
      }
      firstLockIndex = i;
    }
    firstLockIndex = selectedIndex;
    secondLockIndex = selectedIndex;

    /**
     * indicates if the position is to right of the center or left
     * 1 = to the right
     */
    let counter = 1;
    if (selectedTeethCenter.x > 0) {
      counter = -1;
    }
    let tooth;
    if (upperOrLower === "u") {
      //Done
      if (counter === -1) {
        for (let i = firstLockIndex; i <= secondLockIndex; i++) {
          if (holder.children[i].userData["isLocked"]) {
            continue;
          }
          tooth = holder.children[i];
          let fIndex, sIndex;
          let maxStepNumber = newArray[isLower][teethKeys[0]].length - 1;
          for (let r = 0; r < maxStepNumber; r++) {
            if (
              newArray[isLower][teethKeys[i - 1]][r].step === maxStepNumber ||
              newArray[isLower][teethKeys[i - 1]][r].amount === null
            ) {
              fIndex = r;
              newArray[isLower][teethKeys[i - 1]][r].step = maxStepNumber;
              break;
            }
          }
          if (i < teethKeys.length - 1) {
            for (let r = 0; r < maxStepNumber; r++) {
              if (
                newArray[isLower][teethKeys[i]][r].step === maxStepNumber ||
                newArray[isLower][teethKeys[i]][r].amount === null
              ) {
                sIndex = r;
                newArray[isLower][teethKeys[i]][r].step = maxStepNumber;
                break;
              }
            }
          }
          if (i + counter > -1 && areColliding(tooth, holder.children[i - 1])) {
            if (
              Number(newArray[isLower][teethKeys[i - 1]][fIndex].amount) +
                getToothTotalIPR(isLower, teethKeys[i - 1], newArray) >=
              IPRLimit
            ) {
              continue;
            }
          }

          distance = 100;
          index = 0;
          box3 = new THREE.Box3().setFromObject(tooth);
          box3.getCenter(center1);

          if (!tooth.userData["index"]) {
            for (
              let i = 0;
              i < samplesList2.length;
              i++ // closest point on the equation line
            ) {
              cacher = center1.distanceTo(samplesList2[i]);
              if (distance > cacher) {
                distance = cacher;
                index = i;
              }
            }
          } else {
            index = tooth.userData["index"];
          }

          cacher = index;
          let length = 0;
          for (let i = counter; length < toothMovementAmount; i += counter) {
            cacher = index + i;
            if (cacher >= samplesList2.length || cacher < 0) {
              cacher = THREE.MathUtils.clamp(
                cacher,
                0,
                samplesList2.length - 1,
              );
              break;
            }
            length += samplesList2[cacher - counter].distanceTo(
              samplesList2[cacher],
            );
          }
          tooth.userData["index"] = cacher;
          cacher = samplesList2[index].clone().sub(samplesList2[cacher]);
          if (cacher.length() === 0) {
            return false;
          }
          tooth.position.sub(cacher);
          tooth.userData["center"].sub(cacher);
          tooth.userData["rightHinge"].sub(cacher);
          tooth.userData["leftHinge"].sub(cacher);
          tooth.userData["gumPoint"].sub(cacher);

          tooth.updateMatrixWorld();
          undoMovementList.push([]);

          let newIPRAmount =
            Math.round(
              calculateIPRBetweenTeeth(tooth, holder.children[i - 1]) * 100,
            ) / 100;
          AddIPRToLogs(
            logs,
            "added",
            newIPRAmount,
            newArray[isLower][teethKeys[i - 1]][fIndex].amount,
            teethKeys[i - 1].substring(1),
            selectedToothNum,
          );
          undoMovementList[undoMovementList.length - 1].push({
            type: 1,
            amount: newIPRAmount,
            preAmount: newArray[isLower][teethKeys[i - 1]][fIndex].amount,
            action: "added",
            actionType: MovementTypes.IPR,
            from: teethKeys[i - 1].substring(1),
            to: teethKeys[i],
            tooth: selectedTooth,
          });
          newArray[isLower][teethKeys[i - 1]][fIndex].amount = newIPRAmount;
          if (i < teethKeys.length - 1) {
            let newIPRAmount =
              Math.round(
                calculateIPRBetweenTeeth(tooth, holder.children[i + 1]) * 100,
              ) / 100;
            AddIPRToLogs(
              logs,
              "added",
              newIPRAmount,
              newArray[isLower][teethKeys[i]][sIndex].amount,
              selectedToothNum,
              teethKeys[i + 1].substring(1),
            );
            undoMovementList[undoMovementList.length - 1].push({
              type: 1,
              amount: newIPRAmount,
              preAmount: newArray[isLower][teethKeys[i]][sIndex].amount,
              action: "added",
              actionType: MovementTypes.IPR,
              from: teethKeys[i].substring(1),
              to: teethKeys[i + 1],
              tooth: selectedTooth,
            });
            newArray[isLower][teethKeys[i]][sIndex].amount = newIPRAmount;
          }
          isArray = true;
        }
      } else {
        for (let i = secondLockIndex; i >= firstLockIndex; i--) {
          if (holder.children[i].userData["isLocked"]) {
            continue;
          }

          tooth = holder.children[i];
          let fIndex, sIndex;
          let maxStepNumber = newArray[isLower][teethKeys[0]].length - 1;
          for (let r = 0; r < maxStepNumber; r++) {
            if (
              newArray[isLower][teethKeys[i]][r].step === maxStepNumber ||
              newArray[isLower][teethKeys[i]][r].amount === null
            ) {
              fIndex = r;
              newArray[isLower][teethKeys[i]][r].step = maxStepNumber;
              break;
            }
          }
          if (i > 0) {
            for (let r = 0; r < maxStepNumber; r++) {
              if (
                newArray[isLower][teethKeys[i - 1]][r].step === maxStepNumber ||
                newArray[isLower][teethKeys[i - 1]][r].amount === null
              ) {
                sIndex = r;
                newArray[isLower][teethKeys[i - 1]][r].step = maxStepNumber;
                break;
              }
            }
          }
          if (
            i + counter < holder.children.length &&
            areColliding(tooth, holder.children[i + 1])
          ) {
            if (
              Number(newArray[isLower][teethKeys[i]][fIndex].amount) +
                getToothTotalIPR(isLower, teethKeys[i], newArray) >=
              IPRLimit
            ) {
              console.log("ipr limit stop");

              continue;
            }
          }

          distance = 100;
          index = 0;
          box3 = new THREE.Box3().setFromObject(tooth);
          box3.getCenter(center1);

          if (!tooth.userData["index"]) {
            for (
              let i = 0;
              i < samplesList2.length;
              i++ // closest point on the equation line
            ) {
              cacher = center1.distanceTo(samplesList2[i]);
              if (distance > cacher) {
                distance = cacher;
                index = i;
              }
            }
          } else {
            index = tooth.userData["index"];
          }
          cacher = index;
          let length = 0;
          for (let i = counter; length < toothMovementAmount; i += counter) {
            cacher = index + i;
            if (cacher >= samplesList2.length || cacher < 0) {
              cacher = THREE.MathUtils.clamp(
                cacher,
                0,
                samplesList2.length - 1,
              );
              break;
            }
            length += samplesList2[cacher - counter].distanceTo(
              samplesList2[cacher],
            );
          }
          tooth.userData["index"] = cacher;
          cacher = samplesList2[index].clone().sub(samplesList2[cacher]);
          if (cacher.length() === 0) {
            return false;
          }
          tooth.position.sub(cacher);
          tooth.userData["center"].sub(cacher);
          tooth.userData["rightHinge"].sub(cacher);
          tooth.userData["leftHinge"].sub(cacher);
          tooth.userData["gumPoint"].sub(cacher);
          tooth.updateMatrixWorld();
          undoMovementList.push([]);

          if (i > 0) {
            let newIPRAmount =
              Math.round(
                calculateIPRBetweenTeeth(tooth, holder.children[i - 1]) * 100,
              ) / 100;
            AddIPRToLogs(
              logs,
              "added",
              newIPRAmount,
              newArray[isLower][teethKeys[i - 1]][sIndex].amount,
              teethKeys[i - 1].substring(1),
              selectedToothNum,
            );
            undoMovementList[undoMovementList.length - 1].push({
              type: 1,
              amount: newIPRAmount,
              preAmount: newArray[isLower][teethKeys[i - 1]][sIndex].amount,
              action: "added",
              actionType: MovementTypes.IPR,
              from: teethKeys[i - 1].substring(1),
              to: teethKeys[i],
              tooth: selectedTooth,
            });
            newArray[isLower][teethKeys[i - 1]][sIndex].amount = newIPRAmount;
          }

          let newIPRAmount =
            Math.round(
              calculateIPRBetweenTeeth(tooth, holder.children[i + 1]) * 100,
            ) / 100;
          AddIPRToLogs(
            logs,
            "added",
            newIPRAmount,
            newArray[isLower][teethKeys[i]][fIndex].amount,
            selectedToothNum,
            teethKeys[i + 1].substring(1),
          );
          undoMovementList[undoMovementList.length - 1].push({
            type: 1,
            amount: newIPRAmount,
            preAmount: newArray[isLower][teethKeys[i]][fIndex].amount,
            action: "added",
            actionType: MovementTypes.IPR,
            from: teethKeys[i].substring(1),
            to: teethKeys[i + 1],
            tooth: selectedTooth,
          });
          newArray[isLower][teethKeys[i]][fIndex].amount = newIPRAmount;
          isArray = true;
        }
      }
    } else {
      if (counter === -1) {
        for (let i = secondLockIndex; i >= firstLockIndex; i--) {
          if (holder.children[i].userData["isLocked"]) {
            continue;
          }
          tooth = holder.children[i];
          let fIndex, sIndex;
          let maxStepNumber = newArray[isLower][teethKeys[0]].length - 1;
          for (let r = 0; r < maxStepNumber; r++) {
            if (
              newArray[isLower][teethKeys[i + 1]][r].step === maxStepNumber ||
              newArray[isLower][teethKeys[i + 1]][r].amount === null
            ) {
              fIndex = r;
              newArray[isLower][teethKeys[i + 1]][r].step = maxStepNumber;
              break;
            }
          }
          if (i > 0) {
            for (let r = 0; r < maxStepNumber; r++) {
              if (
                newArray[isLower][teethKeys[i]][r].step === maxStepNumber ||
                newArray[isLower][teethKeys[i]][r].amount === null
              ) {
                sIndex = r;
                newArray[isLower][teethKeys[i]][r].step = maxStepNumber;
                break;
              }
            }
          }
          if (
            i - counter < holder.children.length &&
            areColliding(tooth, holder.children[i + 1])
          ) {
            if (
              Number(newArray[isLower][teethKeys[i + 1]][fIndex].amount) +
                getToothTotalIPR(isLower, teethKeys[i + 1], newArray) >=
              IPRLimit
            ) {
              console.log("ipr limit stop");

              continue;
            }
          }

          distance = 100;
          index = 0;
          box3 = new THREE.Box3().setFromObject(tooth);
          box3.getCenter(center1);

          if (!tooth.userData["index"]) {
            for (
              let i = 0;
              i < samplesList2.length;
              i++ // closest point on the equation line
            ) {
              cacher = center1.distanceTo(samplesList2[i]);
              if (distance > cacher) {
                distance = cacher;
                index = i;
              }
            }
          } else {
            index = tooth.userData["index"];
          }

          cacher = index;
          let length = 0;
          for (let i = counter; length < toothMovementAmount; i += counter) {
            cacher = index + i;
            if (cacher >= samplesList2.length || cacher < 0) {
              cacher = THREE.MathUtils.clamp(
                cacher,
                0,
                samplesList2.length - 1,
              );
              break;
            }
            length += samplesList2[cacher - counter].distanceTo(
              samplesList2[cacher],
            );
          }
          tooth.userData["index"] = cacher;

          cacher = samplesList2[index].clone().sub(samplesList2[cacher]);
          if (cacher.length() === 0) {
            return false;
          }
          tooth.position.sub(cacher);
          tooth.userData["center"].sub(cacher);
          tooth.userData["rightHinge"].sub(cacher);
          tooth.userData["leftHinge"].sub(cacher);
          tooth.userData["gumPoint"].sub(cacher);

          tooth.updateMatrixWorld();
          undoMovementList.push([]);

          let newIPRAmount =
            Math.round(
              calculateIPRBetweenTeeth(tooth, holder.children[i + 1]) * 100,
            ) / 100;
          AddIPRToLogs(
            logs,
            "added",
            newIPRAmount,
            newArray[isLower][teethKeys[i + 1]][fIndex].amount,
            teethKeys[i + 1].substring(1),
            selectedToothNum,
          );

          undoMovementList[undoMovementList.length - 1].push({
            type: 1,
            amount: newIPRAmount,
            preAmount: newArray[isLower][teethKeys[i + 1]][fIndex].amount,
            action: "added",
            actionType: MovementTypes.IPR,
            from: teethKeys[i + 1].substring(1),
            to: teethKeys[i],
            tooth: selectedTooth,
          });
          newArray[isLower][teethKeys[i + 1]][fIndex].amount = newIPRAmount;
          if (i > 0) {
            let newIPRAmount =
              Math.round(
                calculateIPRBetweenTeeth(tooth, holder.children[i - 1]) * 100,
              ) / 100;
            AddIPRToLogs(
              logs,
              "added",
              newIPRAmount,
              newArray[isLower][teethKeys[i]][sIndex].amount,
              selectedToothNum,
              teethKeys[i - 1].substring(1),
            );

            undoMovementList[undoMovementList.length - 1].push({
              type: 1,
              amount: newIPRAmount,
              preAmount: newArray[isLower][teethKeys[i]][sIndex].amount,
              action: "added",
              actionType: MovementTypes.IPR,
              from: teethKeys[i].substring(1),
              to: teethKeys[i - 1],
              tooth: selectedTooth,
            });
            newArray[isLower][teethKeys[i]][sIndex].amount = newIPRAmount;
          }
          isArray = true;
        }
      } else {
        for (let i = firstLockIndex; i <= secondLockIndex; i++) {
          if (holder.children[i].userData["isLocked"]) {
            continue;
          }

          tooth = holder.children[i];
          let fIndex, sIndex;
          let maxStepNumber = newArray[isLower][teethKeys[0]].length - 1;
          for (let r = 0; r < maxStepNumber; r++) {
            if (
              newArray[isLower][teethKeys[i]][r].step === maxStepNumber ||
              newArray[isLower][teethKeys[i]][r].amount === null
            ) {
              fIndex = r;
              newArray[isLower][teethKeys[i]][r].step = maxStepNumber;
              break;
            }
          }
          if (i < teethKeys.length - 1) {
            for (let r = 0; r < maxStepNumber; r++) {
              if (
                newArray[isLower][teethKeys[i + 1]][r].step === maxStepNumber ||
                newArray[isLower][teethKeys[i + 1]][r].amount === null
              ) {
                sIndex = r;
                newArray[isLower][teethKeys[i + 1]][r].step = maxStepNumber;
                break;
              }
            }
          }
          if (
            i - counter > -1 &&
            areColliding(tooth, holder.children[i - counter])
          ) {
            if (
              Number(newArray[isLower][teethKeys[i]][fIndex].amount) +
                getToothTotalIPR(isLower, teethKeys[i], newArray) >=
              IPRLimit
            ) {
              console.log("ipr limit stop");

              continue;
            }
          }

          distance = 100;
          index = 0;
          box3 = new THREE.Box3().setFromObject(tooth);
          box3.getCenter(center1);

          if (!tooth.userData["index"]) {
            for (
              let i = 0;
              i < samplesList2.length;
              i++ // closest point on the equation line
            ) {
              cacher = center1.distanceTo(samplesList2[i]);
              if (distance > cacher) {
                distance = cacher;
                index = i;
              }
            }
          } else {
            index = tooth.userData["index"];
          }

          cacher = index;
          let length = 0;
          for (let i = counter; length < toothMovementAmount; i += counter) {
            cacher = index + i;
            if (cacher >= samplesList2.length || cacher < 0) {
              cacher = THREE.MathUtils.clamp(
                cacher,
                0,
                samplesList2.length - 1,
              );
              break;
            }
            length += samplesList2[cacher - counter].distanceTo(
              samplesList2[cacher],
            );
          }
          tooth.userData["index"] = cacher;
          cacher = samplesList2[index].clone().sub(samplesList2[cacher]);
          if (cacher.length() === 0) {
            return false;
          }
          tooth.position.sub(cacher);
          tooth.userData["center"].sub(cacher);
          tooth.userData["rightHinge"].sub(cacher);
          tooth.userData["leftHinge"].sub(cacher);
          tooth.userData["gumPoint"].sub(cacher);
          tooth.updateMatrixWorld();
          undoMovementList.push([]);

          let newIPRAmount =
            Math.round(
              calculateIPRBetweenTeeth(tooth, holder.children[i - 1]) * 100,
            ) / 100;
          AddIPRToLogs(
            logs,
            "added",
            newIPRAmount,
            newArray[isLower][teethKeys[i]][fIndex].amount,
            selectedToothNum,
            teethKeys[i - 1].substring(1),
          );
          undoMovementList[undoMovementList.length - 1].push({
            type: 1,
            amount: newIPRAmount,
            preAmount: newArray[isLower][teethKeys[i]][fIndex].amount,
            action: "added",
            actionType: MovementTypes.IPR,
            from: teethKeys[i].substring(1),
            to: teethKeys[i - 1],
            tooth: selectedTooth,
          });
          newArray[isLower][teethKeys[i]][fIndex].amount = newIPRAmount;
          if (i < teethKeys.length - 1) {
            let newIPRAmount =
              Math.round(
                calculateIPRBetweenTeeth(tooth, holder.children[i + 1]) * 100,
              ) / 100;
            AddIPRToLogs(
              logs,
              "added",
              newIPRAmount,
              newArray[isLower][teethKeys[i + 1]][sIndex].amount,
              teethKeys[i + 1].substring(1),
              selectedToothNum,
            );

            undoMovementList[undoMovementList.length - 1].push({
              type: 1,
              amount: newIPRAmount,
              preAmount: newArray[isLower][teethKeys[i + 1]][sIndex].amount,
              action: "added",
              actionType: MovementTypes.IPR,
              from: teethKeys[i + 1].substring(1),
              to: teethKeys[i],
              tooth: selectedTooth,
            });
            newArray[isLower][teethKeys[i + 1]][sIndex].amount = newIPRAmount;
          }
          isArray = true;
        }
      }
    }
    if (cacher) {
      MovementList.push({ tooth, movement: cacher });
      if (isArray) {
        undoMovementList[undoMovementList.length - 1].push({
          tooth,
          movement: cacher,
          actionType: MovementTypes.MESIALDISTAL,
          type: "Distal",
        });
      } else {
        undoMovementList.push({
          tooth,
          movement: cacher,
          actionType: MovementTypes.MESIALDISTAL,
          type: "Distal",
        });
      }
      let selectedToothNum = selectedTooth.name.split("_")[2].substring(1, 4);
      let key = selectedToothNum.toString() + "_Movement";
      if (logs[key]) {
        const obj = Object.assign({}, logs[key]);

        delete logs[key];

        obj[MovementTypes.MESIALDISTAL] =
          Math.round(
            (obj[MovementTypes.MESIALDISTAL] - toothMovementAmount) * 100,
          ) / 100;
        logs[key] = obj;
      } else {
        logs[key] = {};
        logs[key][MovementTypes.MESIALDISTAL] = -toothMovementAmount;
        logs[key][MovementTypes.BUCCALLINGUAL] = 0;
        logs[key][MovementTypes.EXTRUSIONINTRUSION] = 0;
        logs[key][MovementTypes.ROTATION] = 0;
        logs[key][MovementTypes.ANGULATION] = 0;
        logs[key][MovementTypes.INCLINATION] = 0;
        logs[key][MovementTypes.LEFTHINGE] = 0;
        logs[key][MovementTypes.RIGHTHINGE] = 0;
      }
    }
  }
  setIprOverlays(JSON.parse(JSON.stringify(newArray)));
  setLogs(logs);
  clearRedoMovementList();
  setAutoSave();
}
export function AddIPRToLogs(logs, action, amount, preAmount, from, to) {
  if (!amount) {
    amount = 0;
  }
  const key = from + "_IPR";
  if (logs[key]) {
    const obj = Object.assign({}, logs[key]);
    delete logs[key];
    obj["action"] = action;
    obj["amount"] = amount;
    obj["preAmount"] = preAmount;
    obj["from"] = from;
    obj["to"] = to;
    obj["type"] = amount < 0 ? 2 : 1;
    logs[key] = obj;
  } else {
    logs[key] = {};
    logs[key].action = "added";
    logs[key].amount = amount;
    logs[key].preAmount = preAmount;
    logs[key].from = from;
    logs[key].to = to;
    logs[key].type = amount < 0 ? 2 : 1;
  }
}
function moveToothUp(
  selectedTooth,
  logs,
  iprOverLays,
  setLogs,
  setIprOverlays,
  setAutoSave,
) {
  let isArray = false;
  if (!newArray || true) {
    newArray = JSON.parse(JSON.stringify(iprOverLays));
  }
  if (!selectedTooth) {
    return;
  }
  if (selectedTooth.userData["isLocked"]) {
    return;
  }
  let isLower = 1;
  if (selectedTooth.name.charAt(0) === "u") {
    isLower = 0;
    toothMovementAmount *= -1;
  }

  let teethKeys = Object.keys(iprOverLays[isLower]);
  teethKeys.sort(teethKeysSorter);
  RemoveExtractedTeethFromKeys(newArray[isLower], teethKeys);
  let maxStepNumber = newArray[isLower][teethKeys[0]].length - 1;
  let parentHolder = selectedTooth.parent;
  let selectedTeethIndex = parentHolder.children.indexOf(selectedTooth);
  let fIndex, sIndex;
  for (let r = 0; r < maxStepNumber; r++) {
    if (
      newArray[isLower][teethKeys[selectedTeethIndex]][r].step ===
        maxStepNumber ||
      newArray[isLower][teethKeys[selectedTeethIndex]][r].amount === null
    ) {
      fIndex = r;
      newArray[isLower][teethKeys[selectedTeethIndex]][r].step = maxStepNumber;
      break;
    }
  }
  for (let r = 0; r < maxStepNumber; r++) {
    if (isLower === 1) {
      if (selectedTeethIndex < teethKeys.length - 1) {
        if (
          newArray[isLower][teethKeys[selectedTeethIndex + 1]][r].step ===
            maxStepNumber ||
          newArray[isLower][teethKeys[selectedTeethIndex + 1]][r].amount ===
            null
        ) {
          sIndex = r;
          newArray[isLower][teethKeys[selectedTeethIndex + 1]][r].step =
            maxStepNumber;
          break;
        }
      }
    } else {
      if (selectedTeethIndex > 0) {
        if (
          newArray[isLower][teethKeys[selectedTeethIndex - 1]][r].step ===
            maxStepNumber ||
          newArray[isLower][teethKeys[selectedTeethIndex - 1]][r].amount ===
            null
        ) {
          sIndex = r;
          newArray[isLower][teethKeys[selectedTeethIndex - 1]][r].step =
            maxStepNumber;
          break;
        }
      }
    }
  }

  if (selectedTeethIndex < parentHolder.children.length - 1 || true) {
    selectedTooth.position.add(new THREE.Vector3(0, toothMovementAmount, 0));
    selectedTooth.updateMatrixWorld();

    MovementList.push({
      tooth: selectedTooth,
      movement: new THREE.Vector3(0, -toothMovementAmount, 0),
    });

    let newIPRAmount1, newIPRAmount2;
    if (isLower === 1) {
      if (selectedTeethIndex > 0) {
        newIPRAmount1 =
          Math.round(
            calculateIPRBetweenTeeth(
              selectedTooth,
              parentHolder.children[selectedTeethIndex - 1],
            ) * 100,
          ) / 100;
      }
      if (selectedTeethIndex < teethKeys.length - 1) {
        newIPRAmount2 =
          Math.round(
            calculateIPRBetweenTeeth(
              selectedTooth,
              parentHolder.children[selectedTeethIndex + 1],
            ) * 100,
          ) / 100;
      }
    } else {
      if (selectedTeethIndex < teethKeys.length - 1) {
        newIPRAmount1 =
          Math.round(
            calculateIPRBetweenTeeth(
              selectedTooth,
              parentHolder.children[selectedTeethIndex + 1],
            ) * 100,
          ) / 100;
      }
      if (selectedTeethIndex > 0) {
        newIPRAmount2 =
          Math.round(
            calculateIPRBetweenTeeth(
              selectedTooth,
              parentHolder.children[selectedTeethIndex - 1],
            ) * 100,
          ) / 100;
      }
    }

    if (
      (Number.isFinite(newIPRAmount1) || Number.isFinite(newIPRAmount2)) &&
      (newIPRAmount1 <= IPRLimit || newIPRAmount2 <= IPRLimit)
    ) {
      undoMovementList.push([]);
      isArray = true;
      if (isLower === 1) {
        if (selectedTeethIndex > 0) {
          AddIPRToLogs(
            logs,
            "added",
            newIPRAmount1,
            newArray[isLower][teethKeys[selectedTeethIndex]][fIndex].amount,
            teethKeys[selectedTeethIndex].substring(1),
            teethKeys[selectedTeethIndex - 1].substring(1),
          );
          undoMovementList[undoMovementList.length - 1].push({
            type: 1,
            amount: newIPRAmount1,
            preAmount:
              newArray[1][teethKeys[selectedTeethIndex]][fIndex].amount,
            action: "added",
            actionType: MovementTypes.IPR,
            from: teethKeys[selectedTeethIndex].substring(1),
            to: teethKeys[selectedTeethIndex - 1],
            tooth: selectedTooth,
          });
          newArray[1][teethKeys[selectedTeethIndex]][fIndex].amount =
            newIPRAmount1;
        }
        if (selectedTeethIndex < teethKeys.length - 1) {
          AddIPRToLogs(
            logs,
            "added",
            newIPRAmount2,
            newArray[isLower][teethKeys[selectedTeethIndex + 1]][sIndex].amount,
            teethKeys[selectedTeethIndex + 1].substring(1),
            teethKeys[selectedTeethIndex].substring(1),
          );
          undoMovementList[undoMovementList.length - 1].push({
            type: 1,
            amount: newIPRAmount2,
            preAmount:
              newArray[1][teethKeys[selectedTeethIndex + 1]][sIndex].amount,
            action: "added",
            actionType: MovementTypes.IPR,
            from: teethKeys[selectedTeethIndex + 1].substring(1),
            to: teethKeys[selectedTeethIndex],
            tooth: selectedTooth,
          });
          newArray[1][teethKeys[selectedTeethIndex + 1]][sIndex].amount =
            newIPRAmount2;
        }
      } else {
        if (selectedTeethIndex < teethKeys.length - 1) {
          AddIPRToLogs(
            logs,
            "added",
            newIPRAmount1,
            newArray[isLower][teethKeys[selectedTeethIndex]][fIndex].amount,
            teethKeys[selectedTeethIndex].substring(1),
            teethKeys[selectedTeethIndex + 1].substring(1),
          );
          undoMovementList[undoMovementList.length - 1].push({
            type: 1,
            amount: newIPRAmount1,
            preAmount:
              newArray[0][teethKeys[selectedTeethIndex]][fIndex].amount,
            action: "added",
            actionType: MovementTypes.IPR,
            from: teethKeys[selectedTeethIndex].substring(1),
            to: teethKeys[selectedTeethIndex + 1],
            tooth: selectedTooth,
          });
          newArray[0][teethKeys[selectedTeethIndex]][fIndex].amount =
            newIPRAmount1;
        }
        if (selectedTeethIndex > 0) {
          AddIPRToLogs(
            logs,
            "added",
            newIPRAmount2,
            newArray[isLower][teethKeys[selectedTeethIndex - 1]][sIndex].amount,
            teethKeys[selectedTeethIndex - 1].substring(1),
            teethKeys[selectedTeethIndex].substring(1),
          );
          undoMovementList[undoMovementList.length - 1].push({
            type: 1,
            amount: newIPRAmount2,
            preAmount:
              newArray[0][teethKeys[selectedTeethIndex - 1]][sIndex].amount,
            action: "added",
            actionType: MovementTypes.IPR,
            from: teethKeys[selectedTeethIndex - 1].substring(1),
            to: teethKeys[selectedTeethIndex],
            tooth: selectedTooth,
          });
          newArray[0][teethKeys[selectedTeethIndex - 1]][sIndex].amount =
            newIPRAmount2;
        }
      }
      setIprOverlays(JSON.parse(JSON.stringify(newArray)));
    } else {
      selectedTooth.position.sub(new THREE.Vector3(0, toothMovementAmount, 0));
      if (isLower === 0) {
        toothMovementAmount *= -1;
      }
      MovementList.pop();
      return;
    }
  }
  let cacher = new THREE.Vector3(0, toothMovementAmount, 0);
  // selectedTooth.position.add(cacher);
  selectedTooth.userData["center"].add(cacher);
  selectedTooth.userData["rightHinge"].add(cacher);
  selectedTooth.userData["leftHinge"].add(cacher);
  selectedTooth.userData["gumPoint"].add(cacher);

  MovementList.push({
    tooth: selectedTooth,
    movement: cacher.clone().multiplyScalar(-1),
  });
  if (!isArray) {
    undoMovementList.push({
      tooth: selectedTooth,
      movement: cacher.clone().multiplyScalar(-1),
      actionType: MovementTypes.EXTRUSIONINTRUSION,
    });
  } else {
    undoMovementList[undoMovementList.length - 1].push({
      tooth: selectedTooth,
      movement: cacher.clone().multiplyScalar(-1),
      actionType: MovementTypes.EXTRUSIONINTRUSION,
    });
  }

  let selectedToothNum = selectedTooth.name.split("_")[2].substring(1, 4);
  let key = selectedToothNum.toString() + "_Movement";
  if (logs[key]) {
    const obj = Object.assign({}, logs[key]);

    delete logs[key];

    obj[MovementTypes.EXTRUSIONINTRUSION] =
      Math.round(
        (obj[MovementTypes.EXTRUSIONINTRUSION] + toothMovementAmount) * 100,
      ) / 100;
    logs[key] = obj;
  } else {
    logs[key] = {};
    logs[key][MovementTypes.MESIALDISTAL] = 0;
    logs[key][MovementTypes.BUCCALLINGUAL] = 0;
    logs[key][MovementTypes.EXTRUSIONINTRUSION] = +toothMovementAmount;
    logs[key][MovementTypes.ROTATION] = 0;
    logs[key][MovementTypes.ANGULATION] = 0;
    logs[key][MovementTypes.INCLINATION] = 0;
    logs[key][MovementTypes.LEFTHINGE] = 0;
    logs[key][MovementTypes.RIGHTHINGE] = 0;
  }
  if (selectedTooth.name.charAt(0) === "u") {
    toothMovementAmount *= -1;
  }
  // setIprOverlays(JSON.parse(JSON.stringify(newArray)));
  setLogs(logs);
  setAutoSave();
  clearRedoMovementList();
}
function moveToothDown(
  selectedTooth,
  logs,
  iprOverLays,
  setLogs,
  setIprOverlays,
  setAutoSave,
) {
  let isArray = false;
  if (!newArray || true) {
    newArray = JSON.parse(JSON.stringify(iprOverLays));
  }
  if (!selectedTooth) {
    return;
  }

  if (selectedTooth.userData["isLocked"]) {
    return;
  }
  let isLower = 1;

  if (selectedTooth.name.charAt(0) === "u") {
    isLower = 0;
    toothMovementAmount *= -1;
  }

  let teethKeys = Object.keys(iprOverLays[isLower]);
  teethKeys.sort(teethKeysSorter);
  RemoveExtractedTeethFromKeys(newArray[isLower], teethKeys);
  let maxStepNumber = newArray[isLower][teethKeys[0]].length - 1;

  let parentHolder = selectedTooth.parent;
  let selectedTeethIndex = parentHolder.children.indexOf(selectedTooth);
  let fIndex, sIndex;
  for (let r = 0; r < maxStepNumber; r++) {
    if (
      newArray[isLower][teethKeys[selectedTeethIndex]][r].step ===
        maxStepNumber ||
      newArray[isLower][teethKeys[selectedTeethIndex]][r].amount === null
    ) {
      fIndex = r;
      newArray[isLower][teethKeys[selectedTeethIndex]][r].step = maxStepNumber;
      break;
    }
  }
  for (let r = 0; r < maxStepNumber; r++) {
    if (isLower === 1) {
      if (selectedTeethIndex < teethKeys.length - 1) {
        if (
          newArray[isLower][teethKeys[selectedTeethIndex + 1]][r].step ===
            maxStepNumber ||
          newArray[isLower][teethKeys[selectedTeethIndex + 1]][r].amount ===
            null
        ) {
          sIndex = r;
          newArray[isLower][teethKeys[selectedTeethIndex + 1]][r].step =
            maxStepNumber;
          break;
        }
      }
    } else {
      if (selectedTeethIndex > 0) {
        if (
          newArray[isLower][teethKeys[selectedTeethIndex - 1]][r].step ===
            maxStepNumber ||
          newArray[isLower][teethKeys[selectedTeethIndex - 1]][r].amount ===
            null
        ) {
          sIndex = r;
          newArray[isLower][teethKeys[selectedTeethIndex - 1]][r].step =
            maxStepNumber;
          break;
        }
      }
    }
  }

  if (selectedTeethIndex < parentHolder.children.length - 1 || true) {
    selectedTooth.position.add(new THREE.Vector3(0, -toothMovementAmount, 0));
    selectedTooth.updateMatrixWorld();

    MovementList.push({
      tooth: selectedTooth,
      movement: new THREE.Vector3(0, toothMovementAmount, 0),
    });
    let newIPRAmount1, newIPRAmount2;
    if (true) {
      if (isLower === 1) {
        if (selectedTeethIndex > 0) {
          newIPRAmount1 =
            Math.round(
              calculateIPRBetweenTeeth(
                selectedTooth,
                parentHolder.children[selectedTeethIndex - 1],
              ) * 100,
            ) / 100;
        }
        if (selectedTeethIndex < teethKeys.length - 1) {
          newIPRAmount2 =
            Math.round(
              calculateIPRBetweenTeeth(
                selectedTooth,
                parentHolder.children[selectedTeethIndex + 1],
              ) * 100,
            ) / 100;
        }
      } else {
        if (selectedTeethIndex < teethKeys.length - 1) {
          newIPRAmount1 =
            Math.round(
              calculateIPRBetweenTeeth(
                selectedTooth,
                parentHolder.children[selectedTeethIndex + 1],
              ) * 100,
            ) / 100;
        }
        if (selectedTeethIndex > 0) {
          newIPRAmount2 =
            Math.round(
              calculateIPRBetweenTeeth(
                selectedTooth,
                parentHolder.children[selectedTeethIndex - 1],
              ) * 100,
            ) / 100;
        }
      }
    }
    if (
      (Number.isFinite(newIPRAmount1) || Number.isFinite(newIPRAmount2)) &&
      (newIPRAmount1 <= IPRLimit || newIPRAmount2 <= IPRLimit)
    ) {
      undoMovementList.push([]);
      isArray = true;
      if (isLower === 1) {
        if (selectedTeethIndex > 0) {
          AddIPRToLogs(
            logs,
            "added",
            newIPRAmount1,
            newArray[isLower][teethKeys[selectedTeethIndex]][fIndex].amount,
            teethKeys[selectedTeethIndex].substring(1),
            teethKeys[selectedTeethIndex - 1].substring(1),
          );
          undoMovementList[undoMovementList.length - 1].push({
            type: 1,
            amount: newIPRAmount1,
            preAmount:
              newArray[1][teethKeys[selectedTeethIndex]][fIndex].amount,
            action: "added",
            actionType: MovementTypes.IPR,
            from: teethKeys[selectedTeethIndex].substring(1),
            to: teethKeys[selectedTeethIndex - 1],
            tooth: selectedTooth,
          });
          newArray[1][teethKeys[selectedTeethIndex]][fIndex].amount =
            newIPRAmount1;
        }
        if (selectedTeethIndex < teethKeys.length - 1) {
          AddIPRToLogs(
            logs,
            "added",
            newIPRAmount2,
            newArray[isLower][teethKeys[selectedTeethIndex + 1]][sIndex].amount,
            teethKeys[selectedTeethIndex + 1].substring(1),
            teethKeys[selectedTeethIndex].substring(1),
          );
          undoMovementList[undoMovementList.length - 1].push({
            type: 1,
            amount: newIPRAmount2,
            preAmount:
              newArray[1][teethKeys[selectedTeethIndex + 1]][sIndex].amount,
            action: "added",
            actionType: MovementTypes.IPR,
            from: teethKeys[selectedTeethIndex + 1].substring(1),
            to: teethKeys[selectedTeethIndex],
            tooth: selectedTooth,
          });
          newArray[1][teethKeys[selectedTeethIndex + 1]][sIndex].amount =
            newIPRAmount2;
        }
      } else {
        if (selectedTeethIndex < teethKeys.length - 1) {
          AddIPRToLogs(
            logs,
            "added",
            newIPRAmount1,
            newArray[isLower][teethKeys[selectedTeethIndex]][fIndex].amount,
            teethKeys[selectedTeethIndex].substring(1),
            teethKeys[selectedTeethIndex + 1].substring(1),
          );
          undoMovementList[undoMovementList.length - 1].push({
            type: 1,
            amount: newIPRAmount1,
            preAmount:
              newArray[0][teethKeys[selectedTeethIndex]][fIndex].amount,
            action: "added",
            actionType: MovementTypes.IPR,
            from: teethKeys[selectedTeethIndex].substring(1),
            to: teethKeys[selectedTeethIndex + 1],
            tooth: selectedTooth,
          });
          newArray[0][teethKeys[selectedTeethIndex]][fIndex].amount =
            newIPRAmount1;
        }
        if (selectedTeethIndex > 0) {
          AddIPRToLogs(
            logs,
            "added",
            newIPRAmount2,
            newArray[isLower][teethKeys[selectedTeethIndex - 1]][sIndex].amount,
            teethKeys[selectedTeethIndex - 1].substring(1),
            teethKeys[selectedTeethIndex].substring(1),
          );
          undoMovementList[undoMovementList.length - 1].push({
            type: 1,
            amount: newIPRAmount2,
            preAmount:
              newArray[0][teethKeys[selectedTeethIndex - 1]][sIndex].amount,
            action: "added",
            actionType: MovementTypes.IPR,
            from: teethKeys[selectedTeethIndex - 1].substring(1),
            to: teethKeys[selectedTeethIndex],
            tooth: selectedTooth,
          });
          newArray[0][teethKeys[selectedTeethIndex - 1]][sIndex].amount =
            newIPRAmount2;
        }
      }
      setIprOverlays(JSON.parse(JSON.stringify(newArray)));
    } else {
      selectedTooth.position.sub(new THREE.Vector3(0, -toothMovementAmount, 0));
      if (isLower === 0) {
        toothMovementAmount *= -1;
      }
      MovementList.pop();
      return;
    }
  }

  let cacher = new THREE.Vector3(0, -toothMovementAmount, 0);
  // selectedTooth.position.add(cacher);
  selectedTooth.userData["center"].add(cacher);
  selectedTooth.userData["rightHinge"].add(cacher);
  selectedTooth.userData["leftHinge"].add(cacher);
  selectedTooth.userData["gumPoint"].add(cacher);
  MovementList.push({
    tooth: selectedTooth,
    movement: cacher.clone().multiplyScalar(-1),
  });
  if (!isArray) {
    undoMovementList.push({
      tooth: selectedTooth,
      movement: cacher.clone().multiplyScalar(-1),
      actionType: MovementTypes.EXTRUSIONINTRUSION,
    });
  } else {
    undoMovementList[undoMovementList.length - 1].push({
      tooth: selectedTooth,
      movement: cacher.clone().multiplyScalar(-1),
      actionType: MovementTypes.EXTRUSIONINTRUSION,
    });
  }

  let selectedToothNum = selectedTooth.name.split("_")[2].substring(1, 4);
  let key = selectedToothNum.toString() + "_Movement";
  if (logs[key]) {
    const obj = Object.assign({}, logs[key]);

    delete logs[key];

    obj[MovementTypes.EXTRUSIONINTRUSION] =
      Math.round(
        (obj[MovementTypes.EXTRUSIONINTRUSION] - toothMovementAmount) * 100,
      ) / 100;
    logs[key] = obj;
  } else {
    logs[key] = {};
    logs[key][MovementTypes.MESIALDISTAL] = 0;
    logs[key][MovementTypes.BUCCALLINGUAL] = 0;
    logs[key][MovementTypes.EXTRUSIONINTRUSION] = -toothMovementAmount;
    logs[key][MovementTypes.ROTATION] = 0;
    logs[key][MovementTypes.ANGULATION] = 0;
    logs[key][MovementTypes.INCLINATION] = 0;
    logs[key][MovementTypes.LEFTHINGE] = 0;
    logs[key][MovementTypes.RIGHTHINGE] = 0;
  }
  if (selectedTooth.name.charAt(0) === "u") {
    toothMovementAmount *= -1;
  }
  setLogs(logs);
  setAutoSave();
  clearRedoMovementList();
}
function moveToothBuccal(
  selectedTooth,
  logs,
  iprOverLays,
  setLogs,
  setIprOverlays,
  setAutoSave,
) {
  let isArray = false;
  if (!newArray || true) {
    newArray = JSON.parse(JSON.stringify(iprOverLays));
  }
  if (!selectedTooth) {
    return;
  }
  if (selectedTooth.userData["isLocked"]) {
    return;
  }
  let parentHolder = selectedTooth.parent;
  let selectedTeethIndex = parentHolder.children.indexOf(selectedTooth);

  let isLower = 1;

  let pointsList;
  if (selectedTooth.name.charAt(0) === "u") {
    isLower = 0;
    pointsList = sampleListUpper;
  } else {
    pointsList = sampleListLower;
  }

  let teethKeys = Object.keys(iprOverLays[isLower]);
  teethKeys.sort(teethKeysSorter);
  RemoveExtractedTeethFromKeys(newArray[isLower], teethKeys);
  let maxStepNumber = newArray[isLower][teethKeys[0]].length - 1;

  let fIndex, sIndex;
  for (let r = 0; r < maxStepNumber; r++) {
    if (
      newArray[isLower][teethKeys[selectedTeethIndex]][r].step ===
        maxStepNumber ||
      newArray[isLower][teethKeys[selectedTeethIndex]][r].amount === null
    ) {
      fIndex = r;
      newArray[isLower][teethKeys[selectedTeethIndex]][r].step = maxStepNumber;
      break;
    }
  }
  for (let r = 0; r < maxStepNumber; r++) {
    if (isLower === 1) {
      if (selectedTeethIndex < teethKeys.length - 1) {
        if (
          newArray[isLower][teethKeys[selectedTeethIndex + 1]][r].step ===
            maxStepNumber ||
          newArray[isLower][teethKeys[selectedTeethIndex + 1]][r].amount ===
            null
        ) {
          sIndex = r;
          newArray[isLower][teethKeys[selectedTeethIndex + 1]][r].step =
            maxStepNumber;
          break;
        }
      }
    } else {
      if (selectedTeethIndex > 0) {
        if (
          newArray[isLower][teethKeys[selectedTeethIndex - 1]][r].step ===
            maxStepNumber ||
          newArray[isLower][teethKeys[selectedTeethIndex - 1]][r].amount ===
            null
        ) {
          sIndex = r;
          newArray[isLower][teethKeys[selectedTeethIndex - 1]][r].step =
            maxStepNumber;
          break;
        }
      }
    }
  }

  let centerPoint = new THREE.Vector3();
  const box2 = new THREE.Box3().setFromObject(
    parentHolder.children[selectedTeethIndex],
  );
  box2.getCenter(centerPoint);
  let index = GetClosestToList(centerPoint, pointsList);
  let next = 0;
  if (index < pointsList.length - 1) {
    next = 1;
  } else {
    next = -1;
  }
  let toothZAxis = prepareNormal([
    pointsList[index].clone().setY(0),
    pointsList[index + next].clone().setY(0),
  ])
    .normalize()
    .multiplyScalar(toothMovementAmount);

  if (selectedTeethIndex < parentHolder.children.length || true) {
    selectedTooth.position.add(toothZAxis);
    selectedTooth.updateMatrixWorld();

    MovementList.push({
      tooth: selectedTooth,
      movement: toothZAxis.clone().multiplyScalar(-1),
    });
    let newIPRAmount1, newIPRAmount2;
    if (true) {
      if (isLower === 1) {
        if (selectedTeethIndex > 0) {
          newIPRAmount1 =
            Math.round(
              calculateIPRBetweenTeeth(
                selectedTooth,
                parentHolder.children[selectedTeethIndex - 1],
              ) * 100,
            ) / 100;
        }
        if (selectedTeethIndex < teethKeys.length - 1) {
          newIPRAmount2 =
            Math.round(
              calculateIPRBetweenTeeth(
                selectedTooth,
                parentHolder.children[selectedTeethIndex + 1],
              ) * 100,
            ) / 100;
        }
      } else {
        if (selectedTeethIndex < teethKeys.length - 1) {
          newIPRAmount1 =
            Math.round(
              calculateIPRBetweenTeeth(
                selectedTooth,
                parentHolder.children[selectedTeethIndex + 1],
              ) * 100,
            ) / 100;
        }
        if (selectedTeethIndex > 0) {
          newIPRAmount2 =
            Math.round(
              calculateIPRBetweenTeeth(
                selectedTooth,
                parentHolder.children[selectedTeethIndex - 1],
              ) * 100,
            ) / 100;
        }
      }
    }
    if (
      (Number.isFinite(newIPRAmount1) || Number.isFinite(newIPRAmount2)) &&
      (newIPRAmount1 <= IPRLimit || newIPRAmount2 <= IPRLimit)
    ) {
      undoMovementList.push([]);
      isArray = true;
      if (isLower === 1) {
        if (selectedTeethIndex > 0) {
          AddIPRToLogs(
            logs,
            "added",
            newIPRAmount1,
            newArray[isLower][teethKeys[selectedTeethIndex]][fIndex].amount,
            teethKeys[selectedTeethIndex].substring(1),
            teethKeys[selectedTeethIndex - 1].substring(1),
          );
          undoMovementList[undoMovementList.length - 1].push({
            type: 1,
            amount: newIPRAmount1,
            preAmount:
              newArray[1][teethKeys[selectedTeethIndex]][fIndex].amount,
            action: "added",
            actionType: MovementTypes.IPR,
            from: teethKeys[selectedTeethIndex].substring(1),
            to: teethKeys[selectedTeethIndex - 1],
            tooth: selectedTooth,
          });
          newArray[1][teethKeys[selectedTeethIndex]][fIndex].amount =
            newIPRAmount1;
        }
        if (selectedTeethIndex < teethKeys.length - 1) {
          AddIPRToLogs(
            logs,
            "added",
            newIPRAmount2,
            newArray[isLower][teethKeys[selectedTeethIndex + 1]][sIndex].amount,
            teethKeys[selectedTeethIndex + 1].substring(1),
            teethKeys[selectedTeethIndex].substring(1),
          );
          undoMovementList[undoMovementList.length - 1].push({
            type: 1,
            amount: newIPRAmount2,
            preAmount:
              newArray[1][teethKeys[selectedTeethIndex + 1]][sIndex].amount,
            action: "added",
            actionType: MovementTypes.IPR,
            from: teethKeys[selectedTeethIndex + 1].substring(1),
            to: teethKeys[selectedTeethIndex],
            tooth: selectedTooth,
          });
          newArray[1][teethKeys[selectedTeethIndex + 1]][sIndex].amount =
            newIPRAmount2;
        }
      } else {
        if (selectedTeethIndex < teethKeys.length - 1) {
          AddIPRToLogs(
            logs,
            "added",
            newIPRAmount1,
            newArray[isLower][teethKeys[selectedTeethIndex]][fIndex].amount,
            teethKeys[selectedTeethIndex].substring(1),
            teethKeys[selectedTeethIndex + 1].substring(1),
          );
          undoMovementList[undoMovementList.length - 1].push({
            type: 1,
            amount: newIPRAmount1,
            preAmount:
              newArray[0][teethKeys[selectedTeethIndex]][fIndex].amount,
            action: "added",
            actionType: MovementTypes.IPR,
            from: teethKeys[selectedTeethIndex].substring(1),
            to: teethKeys[selectedTeethIndex + 1],
            tooth: selectedTooth,
          });
          newArray[0][teethKeys[selectedTeethIndex]][fIndex].amount =
            newIPRAmount1;
        }
        if (selectedTeethIndex > 0) {
          AddIPRToLogs(
            logs,
            "added",
            newIPRAmount2,
            newArray[isLower][teethKeys[selectedTeethIndex - 1]][sIndex].amount,
            teethKeys[selectedTeethIndex - 1].substring(1),
            teethKeys[selectedTeethIndex].substring(1),
          );
          undoMovementList[undoMovementList.length - 1].push({
            type: 1,
            amount: newIPRAmount2,
            preAmount:
              newArray[0][teethKeys[selectedTeethIndex - 1]][sIndex].amount,
            action: "added",
            actionType: MovementTypes.IPR,
            from: teethKeys[selectedTeethIndex - 1].substring(1),
            to: teethKeys[selectedTeethIndex],
            tooth: selectedTooth,
          });
          newArray[0][teethKeys[selectedTeethIndex - 1]][sIndex].amount =
            newIPRAmount2;
        }
      }
      setIprOverlays(JSON.parse(JSON.stringify(newArray)));
    } else {
      selectedTooth.position.sub(toothZAxis);
      MovementList.pop();
      return;
    }
  }
  // selectedTooth.position.add(toothZAxis);
  selectedTooth.userData["center"].add(toothZAxis);
  selectedTooth.userData["rightHinge"].add(toothZAxis);
  selectedTooth.userData["leftHinge"].add(toothZAxis);
  selectedTooth.userData["gumPoint"].add(toothZAxis);
  MovementList.push({
    tooth: selectedTooth,
    movement: toothZAxis.clone().multiplyScalar(-1),
  });
  if (!isArray) {
    undoMovementList.push({
      tooth: selectedTooth,
      movement: toothZAxis.clone().multiplyScalar(-1),
      actionType: MovementTypes.BUCCALLINGUAL,
      type: "Lingual",
    });
  } else {
    undoMovementList[undoMovementList.length - 1].push({
      tooth: selectedTooth,
      movement: toothZAxis.clone().multiplyScalar(-1),
      actionType: MovementTypes.BUCCALLINGUAL,
      type: "Lingual",
    });
  }

  let selectedToothNum = selectedTooth.name.split("_")[2].substring(1, 4);
  let key = selectedToothNum.toString() + "_Movement";
  if (logs[key]) {
    const obj = Object.assign({}, logs[key]);

    delete logs[key];

    obj[MovementTypes.BUCCALLINGUAL] =
      Math.round(
        (obj[MovementTypes.BUCCALLINGUAL] + toothMovementAmount) * 100,
      ) / 100;
    logs[key] = obj;
  } else {
    logs[key] = {};
    logs[key][MovementTypes.MESIALDISTAL] = 0;
    logs[key][MovementTypes.BUCCALLINGUAL] = toothMovementAmount;
    logs[key][MovementTypes.EXTRUSIONINTRUSION] = 0;
    logs[key][MovementTypes.ROTATION] = 0;
    logs[key][MovementTypes.ANGULATION] = 0;
    logs[key][MovementTypes.INCLINATION] = 0;
    logs[key][MovementTypes.LEFTHINGE] = 0;
    logs[key][MovementTypes.RIGHTHINGE] = 0;
  }
  setLogs(logs);
  setAutoSave();
  clearRedoMovementList();
}
function moveToothLingual(
  selectedTooth,
  logs,
  iprOverLays,
  setLogs,
  setIprOverlays,
  setAutoSave,
) {
  let isArray = false;
  if (!newArray || true) {
    newArray = JSON.parse(JSON.stringify(iprOverLays));
  }
  if (!selectedTooth) {
    return;
  }
  if (selectedTooth.userData["isLocked"]) {
    return;
  }
  let parentHolder = selectedTooth.parent;
  let selectedTeethIndex = parentHolder.children.indexOf(selectedTooth);
  let isLower = 1;
  let pointsList;
  if (selectedTooth.name.charAt(0) === "u") {
    pointsList = sampleListUpper;
    isLower = 0;
  } else {
    pointsList = sampleListLower;
  }

  let teethKeys = Object.keys(iprOverLays[isLower]);
  teethKeys.sort(teethKeysSorter);
  RemoveExtractedTeethFromKeys(newArray[isLower], teethKeys);
  let maxStepNumber = newArray[isLower][teethKeys[0]].length - 1;

  let fIndex, sIndex;
  for (let r = 0; r < maxStepNumber; r++) {
    if (
      newArray[isLower][teethKeys[selectedTeethIndex]][r].step ===
        maxStepNumber ||
      newArray[isLower][teethKeys[selectedTeethIndex]][r].amount === null
    ) {
      fIndex = r;
      newArray[isLower][teethKeys[selectedTeethIndex]][r].step = maxStepNumber;
      break;
    }
  }
  for (let r = 0; r < maxStepNumber; r++) {
    if (isLower === 1) {
      if (selectedTeethIndex < teethKeys.length - 1) {
        if (
          newArray[isLower][teethKeys[selectedTeethIndex + 1]][r].step ===
            maxStepNumber ||
          newArray[isLower][teethKeys[selectedTeethIndex + 1]][r].amount ===
            null
        ) {
          sIndex = r;
          newArray[isLower][teethKeys[selectedTeethIndex + 1]][r].step =
            maxStepNumber;
          break;
        }
      }
    } else {
      if (selectedTeethIndex > 0) {
        if (
          newArray[isLower][teethKeys[selectedTeethIndex - 1]][r].step ===
            maxStepNumber ||
          newArray[isLower][teethKeys[selectedTeethIndex - 1]][r].amount ===
            null
        ) {
          sIndex = r;
          newArray[isLower][teethKeys[selectedTeethIndex - 1]][r].step =
            maxStepNumber;
          break;
        }
      }
    }
  }

  let centerPoint = new THREE.Vector3();
  const box2 = new THREE.Box3().setFromObject(
    parentHolder.children[selectedTeethIndex],
  );
  box2.getCenter(centerPoint);

  let index = GetClosestToList(centerPoint, pointsList);
  let next = 0;
  if (index < pointsList.length - 1) {
    next = 1;
  } else {
    next = -1;
  }
  let toothZAxis = prepareNormal([
    pointsList[index].clone().setY(0),
    pointsList[index + next].clone().setY(0),
  ])
    .normalize()
    .multiplyScalar(-toothMovementAmount);

  if (selectedTeethIndex < parentHolder.children.length || true) {
    selectedTooth.position.add(toothZAxis);
    selectedTooth.updateMatrixWorld();

    MovementList.push({
      tooth: selectedTooth,
      movement: toothZAxis.clone().multiplyScalar(-1),
    });
    let newIPRAmount1, newIPRAmount2;
    if (true) {
      if (isLower === 1) {
        if (selectedTeethIndex > 0) {
          newIPRAmount1 =
            Math.round(
              calculateIPRBetweenTeeth(
                selectedTooth,
                parentHolder.children[selectedTeethIndex - 1],
              ) * 100,
            ) / 100;
        }
        if (selectedTeethIndex < teethKeys.length - 1) {
          newIPRAmount2 =
            Math.round(
              calculateIPRBetweenTeeth(
                selectedTooth,
                parentHolder.children[selectedTeethIndex + 1],
              ) * 100,
            ) / 100;
        }
      } else {
        if (selectedTeethIndex < teethKeys.length - 1) {
          newIPRAmount1 =
            Math.round(
              calculateIPRBetweenTeeth(
                selectedTooth,
                parentHolder.children[selectedTeethIndex + 1],
              ) * 100,
            ) / 100;
        }
        if (selectedTeethIndex > 0) {
          newIPRAmount2 =
            Math.round(
              calculateIPRBetweenTeeth(
                selectedTooth,
                parentHolder.children[selectedTeethIndex - 1],
              ) * 100,
            ) / 100;
        }
      }
    }
    if (
      (Number.isFinite(newIPRAmount1) || Number.isFinite(newIPRAmount2)) &&
      (newIPRAmount1 <= IPRLimit || newIPRAmount2 <= IPRLimit)
    ) {
      undoMovementList.push([]);
      isArray = true;
      if (isLower === 1) {
        if (selectedTeethIndex > 0) {
          AddIPRToLogs(
            logs,
            "added",
            newIPRAmount1,
            newArray[isLower][teethKeys[selectedTeethIndex]][fIndex].amount,
            teethKeys[selectedTeethIndex].substring(1),
            teethKeys[selectedTeethIndex - 1].substring(1),
          );
          undoMovementList[undoMovementList.length - 1].push({
            type: 1,
            amount: newIPRAmount1,
            preAmount:
              newArray[1][teethKeys[selectedTeethIndex]][fIndex].amount,
            action: "added",
            actionType: MovementTypes.IPR,
            from: teethKeys[selectedTeethIndex].substring(1),
            to: teethKeys[selectedTeethIndex - 1],
            tooth: selectedTooth,
          });
          newArray[1][teethKeys[selectedTeethIndex]][fIndex].amount =
            newIPRAmount1;
        }
        if (selectedTeethIndex < teethKeys.length - 1) {
          AddIPRToLogs(
            logs,
            "added",
            newIPRAmount2,
            newArray[isLower][teethKeys[selectedTeethIndex + 1]][sIndex].amount,
            teethKeys[selectedTeethIndex + 1].substring(1),
            teethKeys[selectedTeethIndex].substring(1),
          );
          undoMovementList[undoMovementList.length - 1].push({
            type: 1,
            amount: newIPRAmount2,
            preAmount:
              newArray[1][teethKeys[selectedTeethIndex + 1]][sIndex].amount,
            action: "added",
            actionType: MovementTypes.IPR,
            from: teethKeys[selectedTeethIndex + 1].substring(1),
            to: teethKeys[selectedTeethIndex],
            tooth: selectedTooth,
          });
          newArray[1][teethKeys[selectedTeethIndex + 1]][sIndex].amount =
            newIPRAmount2;
        }
      } else {
        if (selectedTeethIndex < teethKeys.length - 1) {
          AddIPRToLogs(
            logs,
            "added",
            newIPRAmount1,
            newArray[isLower][teethKeys[selectedTeethIndex]][fIndex].amount,
            teethKeys[selectedTeethIndex].substring(1),
            teethKeys[selectedTeethIndex + 1].substring(1),
          );
          undoMovementList[undoMovementList.length - 1].push({
            type: 1,
            amount: newIPRAmount1,
            preAmount:
              newArray[0][teethKeys[selectedTeethIndex]][fIndex].amount,
            action: "added",
            actionType: MovementTypes.IPR,
            from: teethKeys[selectedTeethIndex].substring(1),
            to: teethKeys[selectedTeethIndex + 1],
            tooth: selectedTooth,
          });
          newArray[0][teethKeys[selectedTeethIndex]][fIndex].amount =
            newIPRAmount1;
        }
        if (selectedTeethIndex > 0) {
          AddIPRToLogs(
            logs,
            "added",
            newIPRAmount2,
            newArray[isLower][teethKeys[selectedTeethIndex - 1]][sIndex].amount,
            teethKeys[selectedTeethIndex - 1].substring(1),
            teethKeys[selectedTeethIndex].substring(1),
          );
          undoMovementList[undoMovementList.length - 1].push({
            type: 1,
            amount: newIPRAmount2,
            preAmount:
              newArray[0][teethKeys[selectedTeethIndex - 1]][sIndex].amount,
            action: "added",
            actionType: MovementTypes.IPR,
            from: teethKeys[selectedTeethIndex - 1].substring(1),
            to: teethKeys[selectedTeethIndex],
            tooth: selectedTooth,
          });
          newArray[0][teethKeys[selectedTeethIndex - 1]][sIndex].amount =
            newIPRAmount2;
        }
      }
      setIprOverlays(JSON.parse(JSON.stringify(newArray)));
    } else {
      selectedTooth.position.sub(toothZAxis);
      MovementList.pop();
      return;
    }
  }
  // selectedTooth.position.add(toothZAxis);
  selectedTooth.userData["center"].add(toothZAxis);
  selectedTooth.userData["rightHinge"].add(toothZAxis);
  selectedTooth.userData["leftHinge"].add(toothZAxis);
  selectedTooth.userData["gumPoint"].add(toothZAxis);
  MovementList.push({
    tooth: selectedTooth,
    movement: toothZAxis.clone().multiplyScalar(-1),
  });

  if (!isArray) {
    undoMovementList.push({
      tooth: selectedTooth,
      movement: toothZAxis.clone().multiplyScalar(-1),
      actionType: MovementTypes.BUCCALLINGUAL,
      type: "Buccal",
    });
  } else {
    undoMovementList[undoMovementList.length - 1].push({
      tooth: selectedTooth,
      movement: toothZAxis.clone().multiplyScalar(-1),
      actionType: MovementTypes.BUCCALLINGUAL,
      type: "Buccal",
    });
  }

  let selectedToothNum = selectedTooth.name.split("_")[2].substring(1, 4);
  let key = selectedToothNum.toString() + "_Movement";
  if (logs[key]) {
    const obj = Object.assign({}, logs[key]);

    delete logs[key];

    obj[MovementTypes.BUCCALLINGUAL] =
      Math.round(
        (obj[MovementTypes.BUCCALLINGUAL] - toothMovementAmount) * 100,
      ) / 100;
    logs[key] = obj;
  } else {
    logs[key] = {};
    logs[key][MovementTypes.MESIALDISTAL] = 0;
    logs[key][MovementTypes.BUCCALLINGUAL] = -toothMovementAmount;
    logs[key][MovementTypes.EXTRUSIONINTRUSION] = 0;
    logs[key][MovementTypes.ROTATION] = 0;
    logs[key][MovementTypes.ANGULATION] = 0;
    logs[key][MovementTypes.INCLINATION] = 0;
    logs[key][MovementTypes.LEFTHINGE] = 0;
    logs[key][MovementTypes.RIGHTHINGE] = 0;
  }
  setLogs(logs);
  setAutoSave();
  clearRedoMovementList();
}
function rotationP(
  selectedTooth,
  logs,
  iprOverLays,
  setLogs,
  setIprOverlays,
  setAutoSave,
) {
  if (!newArray || true) {
    newArray = JSON.parse(JSON.stringify(iprOverLays));
  }

  if (
    selectedTooth.userData.hasOwnProperty("isLocked") &&
    selectedTooth.userData["isLocked"]
  ) {
    return;
  }

  let isLower = 1;
  if (selectedTooth.name.charAt(0) === "u") {
    isLower = 0;
  }
  let teethKeys = Object.keys(iprOverLays[isLower]);
  teethKeys.sort(teethKeysSorter);
  RemoveExtractedTeethFromKeys(newArray[isLower], teethKeys);
  let parentHolder = selectedTooth.parent;
  let selectedTeethIndex = parentHolder.children.indexOf(selectedTooth);
  let maxStepNumber = newArray[isLower][teethKeys[0]].length - 1;
  let fIndex = 0,
    sIndex = 0;

  let selectedToothNum = selectedTooth.name.split("_")[2].substring(1, 4);

  let iprIndex1, iprIndex2;

  if (isLower === 0) {
    iprIndex1 = selectedTeethIndex;
    iprIndex2 = selectedTeethIndex - 1;
  } else {
    iprIndex1 = selectedTeethIndex + 1;
    iprIndex2 = selectedTeethIndex;
  }

  for (let r = 0; r < maxStepNumber; r++) {
    if (
      iprIndex1 < teethKeys.length &&
      (newArray[isLower][teethKeys[iprIndex1]][r].step === maxStepNumber ||
        newArray[isLower][teethKeys[iprIndex1]][r].amount === null)
    ) {
      fIndex = r;
      newArray[isLower][teethKeys[iprIndex1]][r].step = maxStepNumber;
      break;
    }
  }
  for (let r = 0; r < maxStepNumber; r++) {
    if (
      iprIndex2 >= 0 &&
      (newArray[isLower][teethKeys[iprIndex2]][r].step === maxStepNumber ||
        newArray[isLower][teethKeys[iprIndex2]][r].amount === null)
    ) {
      sIndex = r;
      newArray[isLower][teethKeys[iprIndex2]][r].step = maxStepNumber;
      break;
    }
  }

  undoMovementList.push([]);
  // auto adjust ipr and spacing before apply movement

  let selectedToothCenter = new THREE.Vector3();
  let box3;

  if (selectedTeethIndex + 1 < teethKeys.length) {
    box3 = new THREE.Box3().setFromObject(
      parentHolder.children[selectedTeethIndex + 1],
    );
    box3.getCenter(selectedToothCenter);
  }

  let selectedToothCenter2 = new THREE.Vector3();
  let box32;
  if (selectedTeethIndex - 1 > 0) {
    box32 = new THREE.Box3().setFromObject(
      parentHolder.children[selectedTeethIndex - 1],
    );
    box32.getCenter(selectedToothCenter2);
  }

  rotateTooth(
    selectedTooth.userData["center"],
    new THREE.Vector3(0, 1, 0),
    THREE.MathUtils.degToRad(teethRotationAmount).toFixed(5),
    selectedTooth,
  ); // y axis to right
  selectedTooth.updateMatrixWorld();
  let newIPRAmount1 =
      Math.round(
        calculateIPRBetweenTeeth(
          selectedTooth,
          parentHolder.children[iprIndex1 + (isLower ? 0 : 1)],
        ) * 100,
      ) / 100,
    newIPRAmount2 =
      Math.round(
        calculateIPRBetweenTeeth(
          selectedTooth,
          parentHolder.children[iprIndex2 - (isLower ? 1 : 0)],
        ) * 100,
      ) / 100;

  if (
    (Number.isFinite(newIPRAmount1) || Number.isFinite(newIPRAmount2)) &&
    (newIPRAmount1 <= IPRLimit || newIPRAmount2 <= IPRLimit)
  ) {
    if (Number.isFinite(newIPRAmount1)) {
      if (isLower === 0 && iprIndex1 < teethKeys.length - 1) {
        AddIPRToLogs(
          logs,
          "added",
          newIPRAmount1,
          newArray[isLower][teethKeys[iprIndex1]][fIndex].amount,
          teethKeys[iprIndex1].substring(1),
          teethKeys[iprIndex1 + 1].substring(1),
        );
      } else if (iprIndex1 > 0) {
        AddIPRToLogs(
          logs,
          "added",
          newIPRAmount1,
          newArray[isLower][teethKeys[iprIndex1]][fIndex].amount,
          teethKeys[iprIndex1].substring(1),
          teethKeys[iprIndex1 - 1].substring(1),
        );
      }
      undoMovementList[undoMovementList.length - 1].push({
        type: 1,
        amount: newIPRAmount1,
        preAmount: newArray[isLower][teethKeys[iprIndex1]][fIndex].amount,
        action: "added",
        actionType: MovementTypes.IPR,
        from:
          isLower === 0
            ? selectedToothNum
            : parentHolder.children[selectedTeethIndex + 1].name
                .split("_")[2]
                .substring(1, 4),
        to:
          isLower === 0
            ? teethKeys[selectedTeethIndex + 1]
            : teethKeys[selectedTeethIndex],
        tooth: selectedTooth,
      });
      newArray[isLower][teethKeys[iprIndex1]][fIndex].amount = newIPRAmount1;
    }
    if (Number.isFinite(newIPRAmount2)) {
      if (isLower === 0 && iprIndex2 < teethKeys.length - 1) {
        AddIPRToLogs(
          logs,
          "added",
          newIPRAmount2,
          newArray[isLower][teethKeys[iprIndex2]][sIndex].amount,
          teethKeys[iprIndex2].substring(1),
          teethKeys[iprIndex2 + 1].substring(1),
        );
      } else if (iprIndex2 > 0) {
        AddIPRToLogs(
          logs,
          "added",
          newIPRAmount2,
          newArray[isLower][teethKeys[iprIndex2]][sIndex].amount,
          teethKeys[iprIndex2].substring(1),
          teethKeys[iprIndex2 - 1].substring(1),
        );
      }

      undoMovementList[undoMovementList.length - 1].push({
        type: 1,
        amount: newIPRAmount2,
        preAmount: newArray[isLower][teethKeys[iprIndex2]][sIndex].amount,
        action: "added",
        actionType: MovementTypes.IPR,
        from:
          isLower === 1
            ? selectedToothNum
            : parentHolder.children[selectedTeethIndex - 1].name
                .split("_")[2]
                .substring(1, 4),
        to:
          isLower === 1
            ? teethKeys[selectedTeethIndex - 1]
            : teethKeys[selectedTeethIndex],
        tooth: selectedTooth,
      });
      newArray[isLower][teethKeys[iprIndex2]][sIndex].amount = newIPRAmount2;
    }
  } else {
    rotateTooth(
      selectedTooth.userData["center"],
      new THREE.Vector3(0, 1, 0),
      THREE.MathUtils.degToRad(-teethRotationAmount).toFixed(5),
      selectedTooth,
    ); // y axis to right
    undoMovementList.pop();
    return;
  }

  setIprOverlays(JSON.parse(JSON.stringify(newArray)));
  undoMovementList[undoMovementList.length - 1].push({
    tooth: selectedTooth,
    rotationPoint: selectedTooth.userData["center"].clone(),
    teethMovementRotationDirection: new THREE.Vector3(0, 1, 0),
    degrees: THREE.MathUtils.degToRad(-teethRotationAmount).toFixed(5),
    actionType: MovementTypes.ROTATION,
  });

  // save data in change log
  let key = selectedToothNum.toString() + "_Movement";
  if (logs[key]) {
    const obj = Object.assign({}, logs[key]);

    delete logs[key];

    obj[MovementTypes.ROTATION] =
      Math.round((obj[MovementTypes.ROTATION] + teethRotationAmount) * 100) /
      100;
    logs[key] = obj;
  } else {
    logs[key] = {};
    logs[key][MovementTypes.MESIALDISTAL] = 0;
    logs[key][MovementTypes.BUCCALLINGUAL] = 0;
    logs[key][MovementTypes.EXTRUSIONINTRUSION] = 0;
    logs[key][MovementTypes.ROTATION] = teethRotationAmount;
    logs[key][MovementTypes.ANGULATION] = 0;
    logs[key][MovementTypes.INCLINATION] = 0;
    logs[key][MovementTypes.LEFTHINGE] = 0;
    logs[key][MovementTypes.RIGHTHINGE] = 0;
  }
  setLogs(logs);
  setAutoSave();
  clearRedoMovementList();
}
function rotationN(
  selectedTooth,
  logs,
  iprOverLays,
  setLogs,
  setIprOverlays,
  setAutoSave,
) {
  if (!newArray || true) {
    newArray = JSON.parse(JSON.stringify(iprOverLays));
  }

  if (
    selectedTooth.userData.hasOwnProperty("isLocked") &&
    selectedTooth.userData["isLocked"]
  ) {
    return;
  }

  let isLower = 1;
  if (selectedTooth.name.charAt(0) === "u") {
    isLower = 0;
  }
  let teethKeys = Object.keys(iprOverLays[isLower]);
  teethKeys.sort(teethKeysSorter);
  RemoveExtractedTeethFromKeys(newArray[isLower], teethKeys);
  let parentHolder = selectedTooth.parent;
  let selectedTeethIndex = parentHolder.children.indexOf(selectedTooth);
  let maxStepNumber = newArray[isLower][teethKeys[0]].length - 1;
  let fIndex = 0,
    sIndex = 0;
  let selectedToothNum = selectedTooth.name.split("_")[2].substring(1, 4);

  let iprIndex1, iprIndex2;

  if (isLower === 0) {
    iprIndex1 = selectedTeethIndex;
    iprIndex2 = selectedTeethIndex - 1;
  } else {
    iprIndex1 = selectedTeethIndex + 1;
    iprIndex2 = selectedTeethIndex;
  }

  for (let r = 0; r < maxStepNumber; r++) {
    if (
      iprIndex1 < teethKeys.length &&
      (newArray[isLower][teethKeys[iprIndex1]][r].step === maxStepNumber ||
        newArray[isLower][teethKeys[iprIndex1]][r].amount === null)
    ) {
      fIndex = r;
      newArray[isLower][teethKeys[iprIndex1]][r].step = maxStepNumber;
      break;
    }
  }
  for (let r = 0; r < maxStepNumber; r++) {
    if (
      iprIndex2 >= 0 &&
      (newArray[isLower][teethKeys[iprIndex2]][r].step === maxStepNumber ||
        newArray[isLower][teethKeys[iprIndex2]][r].amount === null)
    ) {
      sIndex = r;
      newArray[isLower][teethKeys[iprIndex2]][r].step = maxStepNumber;
      break;
    }
  }

  undoMovementList.push([]);
  // auto adjust ipr and spacing before apply movement

  let selectedToothCenter = new THREE.Vector3();
  let box3;

  if (selectedTeethIndex + 1 < teethKeys.length) {
    box3 = new THREE.Box3().setFromObject(
      parentHolder.children[selectedTeethIndex + 1],
    );
    box3.getCenter(selectedToothCenter);
  }

  let selectedToothCenter2 = new THREE.Vector3();
  let box32;
  if (selectedTeethIndex - 1 > 0) {
    box32 = new THREE.Box3().setFromObject(
      parentHolder.children[selectedTeethIndex - 1],
    );
    box32.getCenter(selectedToothCenter2);
  }

  rotateTooth(
    selectedTooth.userData["center"],
    new THREE.Vector3(0, 1, 0),
    THREE.MathUtils.degToRad(-teethRotationAmount).toFixed(5),
    selectedTooth,
  );
  selectedTooth.updateMatrixWorld();
  let newIPRAmount1 =
      Math.round(
        calculateIPRBetweenTeeth(
          selectedTooth,
          parentHolder.children[iprIndex1 + (isLower ? 0 : 1)],
        ) * 100,
      ) / 100,
    newIPRAmount2 =
      Math.round(
        calculateIPRBetweenTeeth(
          selectedTooth,
          parentHolder.children[iprIndex2 - (isLower ? 1 : 0)],
        ) * 100,
      ) / 100;
  if (
    (Number.isFinite(newIPRAmount1) || Number.isFinite(newIPRAmount2)) &&
    (newIPRAmount1 <= IPRLimit || newIPRAmount2 <= IPRLimit)
  ) {
    console.log(teethKeys, iprIndex1);
    if (Number.isFinite(newIPRAmount1)) {
      if (isLower === 0 && iprIndex1 < teethKeys.length - 1) {
        AddIPRToLogs(
          logs,
          "added",
          newIPRAmount1,
          newArray[isLower][teethKeys[iprIndex1]][fIndex].amount,
          teethKeys[iprIndex1].substring(1),
          teethKeys[iprIndex1 + 1].substring(1),
        );
      } else if (iprIndex1 > 0) {
        AddIPRToLogs(
          logs,
          "added",
          newIPRAmount1,
          newArray[isLower][teethKeys[iprIndex1]][fIndex].amount,
          teethKeys[iprIndex1].substring(1),
          teethKeys[iprIndex1 - 1].substring(1),
        );
      }
      undoMovementList[undoMovementList.length - 1].push({
        type: 1,
        amount: newIPRAmount1,
        preAmount: newArray[isLower][teethKeys[iprIndex1]][fIndex].amount,
        action: "added",
        actionType: MovementTypes.IPR,
        from:
          isLower === 0
            ? selectedToothNum
            : parentHolder.children[selectedTeethIndex + 1].name
                .split("_")[2]
                .substring(1, 4),
        to:
          isLower === 0
            ? teethKeys[selectedTeethIndex + 1]
            : teethKeys[selectedTeethIndex],
        tooth: selectedTooth,
      });
      newArray[isLower][teethKeys[iprIndex1]][fIndex].amount = newIPRAmount1;
    }
    if (Number.isFinite(newIPRAmount2)) {
      if (isLower === 0 && iprIndex2 < teethKeys.length + 1) {
        AddIPRToLogs(
          logs,
          "added",
          newIPRAmount2,
          newArray[isLower][teethKeys[iprIndex2]][sIndex].amount,
          teethKeys[iprIndex2].substring(1),
          teethKeys[iprIndex2 + 1].substring(1),
        );
      } else if (iprIndex1 > 0) {
        AddIPRToLogs(
          logs,
          "added",
          newIPRAmount2,
          newArray[isLower][teethKeys[iprIndex2]][sIndex].amount,
          teethKeys[iprIndex2].substring(1),
          teethKeys[iprIndex2 - 1].substring(1),
        );
      }
      undoMovementList[undoMovementList.length - 1].push({
        type: 1,
        amount: newIPRAmount2,
        preAmount: newArray[isLower][teethKeys[iprIndex2]][sIndex].amount,
        action: "added",
        actionType: MovementTypes.IPR,
        from:
          isLower === 1
            ? selectedToothNum
            : parentHolder.children[selectedTeethIndex - 1].name
                .split("_")[2]
                .substring(1, 4),
        to:
          isLower === 1
            ? teethKeys[selectedTeethIndex - 1]
            : teethKeys[selectedTeethIndex],
        tooth: selectedTooth,
      });
      newArray[isLower][teethKeys[iprIndex2]][sIndex].amount = newIPRAmount2;
    }
  } else {
    rotateTooth(
      selectedTooth.userData["center"],
      new THREE.Vector3(0, 1, 0),
      THREE.MathUtils.degToRad(teethRotationAmount).toFixed(5),
      selectedTooth,
    ); // y axis to right
    undoMovementList.pop();
    return;
  }

  setIprOverlays(JSON.parse(JSON.stringify(newArray)));
  // y axis to right
  undoMovementList[undoMovementList.length - 1].push({
    tooth: selectedTooth,
    rotationPoint: selectedTooth.userData["center"].clone(),
    teethMovementRotationDirection: new THREE.Vector3(0, 1, 0),
    degrees: THREE.MathUtils.degToRad(teethRotationAmount).toFixed(5),
    actionType: MovementTypes.ROTATION,
  });

  // save data in change log
  let key = selectedToothNum.toString() + "_Movement";
  if (logs[key]) {
    const obj = Object.assign({}, logs[key]);

    delete logs[key];

    obj[MovementTypes.ROTATION] =
      Math.round((obj[MovementTypes.ROTATION] - teethRotationAmount) * 100) /
      100;
    logs[key] = obj;
  } else {
    logs[key] = {};
    logs[key][MovementTypes.MESIALDISTAL] = 0;
    logs[key][MovementTypes.BUCCALLINGUAL] = 0;
    logs[key][MovementTypes.EXTRUSIONINTRUSION] = 0;
    logs[key][MovementTypes.ROTATION] = -teethRotationAmount;
    logs[key][MovementTypes.ANGULATION] = 0;
    logs[key][MovementTypes.INCLINATION] = 0;
    logs[key][MovementTypes.LEFTHINGE] = 0;
    logs[key][MovementTypes.RIGHTHINGE] = 0;
  }
  setLogs(logs);
  setAutoSave();
  clearRedoMovementList();
}

/**
 *
 * @param {object} tooth
 * @param {int} place Value of -1 for previous tooth, 1 for next tooth
 */
export function calculateIPRToothForNegation(tooth, place) {
  let toothNumber = tooth.name.split("_")[2].substring(1, 4);
  if (toothNumber > 16) {
    if (place === -1 && neglectionIPR[toothNumber] === undefined) {
      const holder = calculateIPRBetweenTeeth(
        tooth,
        getPreviousTooth(tooth),
        true,
      );
      neglectionIPR[toothNumber] =
        holder < spacingNeglictionAmount ? 0 : holder;
    } else if (place === 1) {
      const adjacentTooth = getNextTooth(tooth);
      toothNumber = adjacentTooth?.name.split("_")[2].substring(1, 4);
      if (neglectionIPR[toothNumber] === undefined) {
        const holder = calculateIPRBetweenTeeth(tooth, adjacentTooth, true);
        neglectionIPR[toothNumber] =
          holder < spacingNeglictionAmount ? 0 : holder;
      }
    }
  } else {
    if (place === 1 && neglectionIPR[toothNumber] === undefined) {
      const holder = calculateIPRBetweenTeeth(tooth, getNextTooth(tooth), true);
      neglectionIPR[toothNumber] =
        holder < spacingNeglictionAmount ? 0 : holder;
    } else if (place === -1) {
      const adjacentTooth = getPreviousTooth(tooth);
      toothNumber = adjacentTooth?.name.split("_")[2].substring(1, 4);
      if (neglectionIPR[toothNumber] === undefined) {
        const holder = calculateIPRBetweenTeeth(tooth, adjacentTooth, true);
        neglectionIPR[toothNumber] =
          holder < spacingNeglictionAmount ? 0 : holder;
      }
    }
  }
}
function getNextTooth(tooth) {
  return (
    tooth.parent.children[tooth.parent.children.indexOf(tooth) + 1] ?? undefined
  );
}
function getPreviousTooth(tooth) {
  return (
    tooth.parent.children[tooth.parent.children.indexOf(tooth) - 1] ?? undefined
  );
}

function calculateIPRBetweenTeeth(firstTooth, secondTooth, isNeglict = false) {
  let t = Date.now();
  if (!firstTooth || !secondTooth) {
    // console.log("data not correct");
    return Infinity;
  }
  const fNumber = Number(firstTooth.name.split("_")[2].substring(1, 4)),
    sNumber = Number(secondTooth.name.split("_")[2].substring(1, 4));
  const isLower = fNumber > 16;
  if (isLower) {
    if (fNumber < sNumber) {
      [firstTooth, secondTooth] = [secondTooth, firstTooth];
    }
  } else {
    if (fNumber > sNumber) {
      [firstTooth, secondTooth] = [secondTooth, firstTooth];
    }
  }
  // console.log("time swap", Date.now() - t);
  let originalPosition = firstTooth.position.clone();
  let samplesList2 = isLower ? sampleListLower : sampleListUpper;
  const isIPR = areColliding(firstTooth, secondTooth) ? 1 : -1;
  let fIndex = getToothIndexOnPath(firstTooth, samplesList2),
    sIndex = getToothIndexOnPath(secondTooth, samplesList2);
  const originalFirstIndex = fIndex;
  let cacher = fIndex;
  let direction = isIPR * (fIndex > sIndex ? 1 : -1);
  let rangeIndex = THREE.MathUtils.clamp(
    fIndex + direction * Math.abs(fIndex - sIndex),
    0,
    samplesList2.length - 1,
  );

  let totalLength = 0;
  let lastCollisionStatus = isIPR === 1 ? true : false;
  let prevIndex = fIndex;
  let lowest, highest;
  if (fIndex > rangeIndex) {
    highest = fIndex;
    lowest = rangeIndex;
  } else {
    highest = rangeIndex;
    lowest = fIndex;
  }
  for (let r = 0; r < 10; r++) {
    cacher = parseInt((lowest + highest) / 2);
    firstTooth.position.sub(
      samplesList2[prevIndex].clone().sub(samplesList2[cacher]),
    );
    firstTooth.updateMatrixWorld();

    if (areColliding(firstTooth, secondTooth) === lastCollisionStatus) {
      // console.log("change f", cacher);
    } else {
      // console.log("change r", cacher);

      direction *= -1;

      lastCollisionStatus = !lastCollisionStatus;
    }

    // performance enhancement
    if (Math.abs(cacher - prevIndex) <= 1) {
      break;
    }
    prevIndex = cacher;
    if (fIndex < rangeIndex) {
      if (direction === -1) {
        highest = cacher;
      } else {
        lowest = cacher;
      }
    } else {
      if (direction === 1) {
        lowest = cacher;
      } else {
        highest = cacher;
      }
    }
  }

  if (originalFirstIndex > cacher) {
    direction = -1;
  } else {
    direction = 1;
  }
  for (let i = originalFirstIndex; i !== cacher; i += direction) {
    totalLength += samplesList2[cacher - direction].distanceTo(
      samplesList2[cacher],
    );
  }

  firstTooth.position.copy(originalPosition);
  // console.log(
  //   "IPR calculating process time is ",
  //   Date.now() - t,
  //   "original index",
  //   originalFirstIndex,
  //   "last index is ",
  //   cacher,
  //   "IPR amount is",
  //   totalLength * isIPR,
  //   "between",
  //   firstTooth.name,
  //   secondTooth.name,
  // );

  if (isNeglict) {
    return roundIPR(totalLength * isIPR);
  }
  if (isLower) {
    // console.log(
    //   roundIPR(
    //     totalLength * isIPR -
    //       neglectionIPR[fNumber < sNumber ? sNumber : fNumber],
    //   ),
    //   fNumber,
    //   sNumber,
    // );
    return roundIPR(
      totalLength * isIPR -
        neglectionIPR[fNumber < sNumber ? sNumber : fNumber],
    );
  } else {
    // console.log(
    //   roundIPR(
    //     totalLength * isIPR -
    //       neglectionIPR[fNumber > sNumber ? sNumber : fNumber],
    //   ),
    // isIPR,
    // fNumber,
    // sNumber,
    // );
    return roundIPR(
      totalLength * isIPR -
        neglectionIPR[fNumber > sNumber ? sNumber : fNumber],
    );
  }
}
function roundIPR(value) {
  return Math.round(value * 20) / 20;
}
function getToothIndexOnPath(object, path) {
  let r = Date.now();
  if (!object.userData["index"] || true) {
    // due to URR not reseting index, solution is to reset the index userdata for the tooth on undo redo reset
    let distance = Infinity,
      cacher,
      index;
    let center1 = new Vector3();
    const box3 = new THREE.Box3().setFromObject(object);
    box3.getCenter(center1);
    for (
      let i = 0;
      i < path.length;
      i++ // closest point on the equation line
    ) {
      cacher = center1.distanceTo(path[i]);
      if (distance > cacher) {
        distance = cacher;
        index = i;
      }
    }
    object.userData["index"] = index;
    // console.log("time closest", index, Date.now() - r);
    return index;
  } else {
    return object.userData["index"];
  }
}
export function calculateTeethPositions(maxStepNumber, group) {
  const upperHolder = group.current.children[maxStepNumber * 2];
  const lowerHolder = group.current.children[maxStepNumber * 2 + 1];
  let toothHolder;
  for (let i = 0; i < upperHolder.children.length; i++) {
    const geometry = new THREE.BoxGeometry(1, 1, 1);
    const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
    const material1 = new THREE.MeshBasicMaterial({ color: 0x0000ff });
    const cube = new THREE.Mesh(geometry, material);
    const cube1 = new THREE.Mesh(geometry, material);
    const cube2 = new THREE.Mesh(geometry, material);
    const cube3 = new THREE.Mesh(geometry, material1);
    toothHolder = upperHolder.children[i];
    const box3 = new THREE.Box3().setFromObject(toothHolder);
    box3.getCenter(toothRotationPoint);
    cube.position.copy(toothRotationPoint);
    toothHolder.userData["gumPoint"] = toothRotationPoint
      .clone()
      .add(new THREE.Vector3(0, box3.getSize(new THREE.Vector3()).y / 2, 0));
    cube3.position.copy(toothHolder.userData["gumPoint"]);

    toothHolder.userData["center"] = toothRotationPoint.clone();
    //console.log(toothHolder.userData["center"]);
    if (toothRotationPoint.x > 0) {
      toothHolder.userData["leftHinge"] = toothRotationPoint
        .clone()
        .add(new THREE.Vector3(box3.getSize(new THREE.Vector3()).x / -2, 0, 0));

      toothHolder.userData["rightHinge"] = toothRotationPoint
        .clone()
        .add(new THREE.Vector3(box3.getSize(new THREE.Vector3()).x / 2, 0, 0));
    } else {
      toothHolder.userData["leftHinge"] = toothRotationPoint
        .clone()
        .add(new THREE.Vector3(box3.getSize(new THREE.Vector3()).x / 2, 0, 0));
      toothHolder.userData["rightHinge"] = toothRotationPoint
        .clone()
        .add(new THREE.Vector3(box3.getSize(new THREE.Vector3()).x / -2, 0, 0));
    }
    let centerPoint = new THREE.Vector3();
    box3.getCenter(centerPoint);
    let pointsList;
    if (toothHolder.name.charAt(0) === "u") {
      pointsList = sampleListUpper;
    } else {
      pointsList = sampleListLower;
    }
    let index = GetClosestToList(centerPoint, pointsList);
    if (!pointsList[index + 1]) {
      toothHolder.userData["zAxis"] = prepareNormal([
        pointsList[index - 1],
        pointsList[index],
      ]).normalize();
      toothHolder.userData["xAxis"] = pointsList[index - 1]
        .clone()
        .sub(pointsList[index])
        .normalize();
    } else {
      toothHolder.userData["xAxis"] = pointsList[index]
        .clone()
        .sub(pointsList[index + 1])
        .normalize();
      toothHolder.userData["zAxis"] = prepareNormal([
        pointsList[index],
        pointsList[index + 1],
      ]).normalize();
    }
    cube1.position.copy(toothHolder.userData["leftHinge"]);
    cube2.position.copy(toothHolder.userData["rightHinge"]);

    // toothHolder.parent.parent.parent.add(cube);
    // toothHolder.parent.parent.parent.add(cube1);

    // toothHolder.parent.parent.parent.add(cube2);
    // toothHolder.parent.parent.parent.add(cube3);
  }

  for (let i = 0; i < lowerHolder.children.length; i++) {
    toothHolder = lowerHolder.children[i];
    const box3 = new THREE.Box3().setFromObject(toothHolder);
    box3.getCenter(toothRotationPoint);
    toothHolder.userData["center"] = toothRotationPoint.clone();
    toothHolder.userData["gumPoint"] = toothRotationPoint
      .clone()
      .add(new THREE.Vector3(0, box3.getSize(new THREE.Vector3()).y / -2, 0));
    if (toothRotationPoint.x > 0) {
      toothHolder.userData["leftHinge"] = toothRotationPoint
        .clone()
        .add(new THREE.Vector3(box3.getSize(new THREE.Vector3()).x / -2, 0, 0));
      toothHolder.userData["rightHinge"] = toothRotationPoint
        .clone()
        .add(new THREE.Vector3(box3.getSize(new THREE.Vector3()).x / 2, 0, 0));
    } else {
      toothHolder.userData["leftHinge"] = toothRotationPoint
        .clone()
        .add(new THREE.Vector3(box3.getSize(new THREE.Vector3()).x / 2, 0, 0));
      toothHolder.userData["rightHinge"] = toothRotationPoint
        .clone()
        .add(new THREE.Vector3(box3.getSize(new THREE.Vector3()).x / -2, 0, 0));
    }

    let centerPoint = new THREE.Vector3();
    box3.getCenter(centerPoint);
    let pointsList;
    if (toothHolder.name.charAt(0) === "u") {
      pointsList = sampleListUpper;
    } else {
      pointsList = sampleListLower;
    }
    let index = GetClosestToList(centerPoint, pointsList);
    if (!pointsList[index + 1]) {
      toothHolder.userData["zAxis"] = prepareNormal([
        pointsList[index - 1],
        pointsList[index],
      ]).normalize();
      toothHolder.userData["xAxis"] = pointsList[index - 1]
        .clone()
        .sub(pointsList[index])
        .normalize();
    } else {
      toothHolder.userData["xAxis"] = pointsList[index]
        .clone()
        .sub(pointsList[index + 1])
        .normalize();
      toothHolder.userData["zAxis"] = prepareNormal([
        pointsList[index],
        pointsList[index + 1],
      ]).normalize();
    }
  }

  console.log("pos is ready");
}
function rotateAroundPoint(obj, point, axis, theta, pointIsWorld = false) {
  if (pointIsWorld) {
    obj.parent.localToWorld(obj.position); // compensate for world coordinate
  }

  obj.position.sub(point); // remove the offset
  obj.position.applyAxisAngle(axis, theta); // rotate the POSITION
  obj.position.add(point); // re-add the offset

  if (pointIsWorld) {
    obj.parent.worldToLocal(obj.position); // undo world coordinates compensation
  }

  obj.rotateOnAxis(axis, theta); // rotate the OBJECT
  obj.updateMatrixWorld();
}
export function rotateTooth(
  toothRotationPoint,
  axis,
  theta,
  selectedTooth,
  tooth,
  isComparingMode = false,
) {
  if (tooth) {
    selectedTooth = tooth;
  }
  // rotate with negative rad
  if (!selectedTooth) {
    return;
  }
  // selectedTooth.matrixAutoUpdate = true
  // console.log(selectedTooth.position, selectedTooth.rotation, THREE.MathUtils.degToRad(teethRotationAmount).toFixed(5));
  // selectedTooth.rotateY(THREE.MathUtils.degToRad(teethRotationAmount).toFixed(5))
  rotateAroundPoint(selectedTooth, toothRotationPoint, axis, theta, true);
  if (isComparingMode) {
    return;
  }
  toothRotationPoint = new Vector3(
    toothRotationPoint.x,
    toothRotationPoint.y,
    toothRotationPoint.z,
  );

  let r = new THREE.Object3D();
  r.position.copy(selectedTooth.userData["center"]);
  rotateAroundPoint(r, toothRotationPoint, axis, theta, false);
  selectedTooth.userData["center"] = r.position.clone();
  r.position.copy(selectedTooth.userData["leftHinge"]);
  rotateAroundPoint(r, toothRotationPoint, axis, theta, false);
  selectedTooth.userData["leftHinge"] = r.position.clone();

  r.position.copy(selectedTooth.userData["rightHinge"]);
  rotateAroundPoint(r, toothRotationPoint, axis, theta, false);
  selectedTooth.userData["rightHinge"] = r.position.clone();

  r.position.copy(selectedTooth.userData["gumPoint"]);
  rotateAroundPoint(r, toothRotationPoint, axis, theta, false);
  selectedTooth.userData["gumPoint"] = r.position.clone();

  MovementList.push({
    tooth: selectedTooth,
    rotationPoint: toothRotationPoint.clone(),
    teethMovementRotationDirection: teethMovementRotationDirection.clone(),
    degrees: -theta,
  });
}
export function PrepareTeethMovementPath(group, GLBData) {
  let upperHolder;
  let samplesList = [];
  let samplesList2 = [];
  for (let i = 0; i < 2; i++) {
    if (group.current.children[i].children.length === 0) {
      console.log("no arch = ", i);
      continue;
    }
    samplesList = [];
    samplesList2 = [];
    upperHolder =
      group.current.children[
        (GLBData.length - 1 - (globalBiteJump ? 1 : 0)) * 2 + i
      ];
    let center = new THREE.Vector3();
    upperHolder.children.forEach(item => {
      let box3 = new THREE.Box3().setFromObject(item);
      box3.getCenter(center);
      samplesList.push(center.clone());
    });

    samplesList.sort(compareFn);

    let t1 = samplesList[0].x;
    let r = samplesList[samplesList.length - 1].x;
    let xs = [],
      ys = [];
    for (let index = 0; index < samplesList.length; index++) {
      xs.push(samplesList[index].x);
      ys.push(samplesList[index].z);
    }

    // const spline = new Spline(xs, ys);
    const spline = SPLINE.smoothingSplines(samplesList, samplesList);
    let lambda = 3;
    let params = LR.RidgeRegression(spline, lambda)[0];
    for (let i = t1 - 1; i < r + 1; i += 0.005) {
      let val = SPLINE.smoothingSplines([{ x: i, z: 0 }], samplesList);
      let yhat = 0.0;
      for (let j = 0; j < params.length; j++) {
        yhat += params[j][0] * val.x[0][j];
      }
      samplesList2.push(new THREE.Vector3(i, samplesList[0].y, yhat));
    }
    // for (let i = t1; i < r; i += 0.01) {
    //   i = parseFloat(i.toFixed(2));
    //   samplesList2.push(
    //     new THREE.Vector3(
    //       i,
    //       samplesList[0].y,
    //       parseFloat(spline.at(i).toFixed(2))
    //     )
    //   );
    // }
    const material = new THREE.LineBasicMaterial({ color: 0x0000ff });
    const material2 = new THREE.LineBasicMaterial({ color: 0xff0000 });
    const geometry = new THREE.BufferGeometry().setFromPoints(samplesList);
    const geometry2 = new THREE.BufferGeometry().setFromPoints(samplesList2);
    const line = new THREE.Line(geometry, material);
    const line2 = new THREE.Line(geometry2, material);
    // scene.add(line);
    // scene.add(line2);
    // line.position.setY(-25);
    // line2.position.setY(20);
    //console.log(samplesList2.length);
    if (i === 0) {
      //scene.add(line2);
      sampleListUpper = samplesList2;
    } else {
      sampleListLower = samplesList2;
    }
  }
  // console.log(samplesList, samplesList2);
  console.log("Path is ready");
  //console.log(GLBData);
}
function compareFn(a, b) {
  if (a.x < b.x) {
    return -1;
  }
  if (a.x > b.x) {
    return 1;
  }
  // a must be equal to b
  return 0;
}
function prepareNormal(list) {
  if (list) {
    const lineMaterial = new THREE.LineBasicMaterial({ color: 0x000000 });
    const points = [];
    let lineGeometry;
    let direction = new THREE.Vector3();
    let x, z;
    let result = new THREE.Vector3();
    for (let i = 0; i < list.length - 1; i += 1) {
      x = list[i + 1].x - list[i].x;
      z = list[i + 1].z - list[i].z;
      direction.set(-z, 0, x);
      direction.multiplyScalar(500);
      points.push(direction.add(list[i]));
      // lineGeometry = new THREE.BufferGeometry().setFromPoints([list[i], direction.add(list[i])]);
      // const line = new THREE.Line(lineGeometry, lineMaterial);
      // scene.add(line);
    }
    points.forEach(item => {
      result.add(item);
    });
    return result.multiplyScalar(1 / points.length);
  }
}
function GetClosestToList(point, list) {
  let distance = Infinity;
  let index = 0;
  let cacher;
  for (
    let i = 0;
    i < list.length;
    i++ // closest point on the equation line
  ) {
    cacher = point.distanceTo(list[i]);
    if (distance > cacher) {
      distance = cacher;
      index = i;
    }
  }
  return index;
}
function inclinationP(
  selectedTooth,
  logs,
  iprOverLays,
  setLogs,
  setIprOverlays,
  setAutoSave,
) {
  if (!newArray || true) {
    newArray = JSON.parse(JSON.stringify(iprOverLays));
  }

  if (
    selectedTooth.userData.hasOwnProperty("isLocked") &&
    selectedTooth.userData["isLocked"]
  ) {
    return;
  }

  let isLower = 1;
  if (selectedTooth.name.charAt(0) === "u") {
    isLower = 0;
  }
  let teethKeys = Object.keys(iprOverLays[isLower]);
  teethKeys.sort(teethKeysSorter);
  RemoveExtractedTeethFromKeys(newArray[isLower], teethKeys);
  let parentHolder = selectedTooth.parent;
  let selectedTeethIndex = parentHolder.children.indexOf(selectedTooth);
  let maxStepNumber = newArray[isLower][teethKeys[0]].length - 1;
  let fIndex = 0,
    sIndex = 0;

  let selectedToothNum = selectedTooth.name.split("_")[2].substring(1, 4);

  let iprIndex1, iprIndex2;

  if (isLower === 0) {
    iprIndex1 = selectedTeethIndex;
    iprIndex2 = selectedTeethIndex - 1;
  } else {
    iprIndex1 = selectedTeethIndex + 1;
    iprIndex2 = selectedTeethIndex;
  }

  for (let r = 0; r < maxStepNumber; r++) {
    if (
      iprIndex1 < teethKeys.length &&
      (newArray[isLower][teethKeys[iprIndex1]][r].step === maxStepNumber ||
        newArray[isLower][teethKeys[iprIndex1]][r].amount === null)
    ) {
      fIndex = r;
      newArray[isLower][teethKeys[iprIndex1]][r].step = maxStepNumber;
      break;
    }
  }
  for (let r = 0; r < maxStepNumber; r++) {
    if (
      iprIndex2 >= 0 &&
      (newArray[isLower][teethKeys[iprIndex2]][r].step === maxStepNumber ||
        newArray[isLower][teethKeys[iprIndex2]][r].amount === null)
    ) {
      sIndex = r;
      newArray[isLower][teethKeys[iprIndex2]][r].step = maxStepNumber;
      break;
    }
  }

  undoMovementList.push([]);
  // auto adjust ipr and spacing before apply movement

  let selectedToothCenter = new THREE.Vector3();
  let box3;

  if (selectedTeethIndex + 1 < teethKeys.length) {
    box3 = new THREE.Box3().setFromObject(
      parentHolder.children[selectedTeethIndex + 1],
    );
    box3.getCenter(selectedToothCenter);
  }

  let selectedToothCenter2 = new THREE.Vector3();
  let box32;
  if (selectedTeethIndex - 1 > 0) {
    box32 = new THREE.Box3().setFromObject(
      parentHolder.children[selectedTeethIndex - 1],
    );
    box32.getCenter(selectedToothCenter2);
  }

  if (selectedTooth.name.charAt(0) === "u") {
    teethRotationAmount *= -1;
  }
  rotateTooth(
    selectedTooth.userData["gumPoint"],
    selectedTooth.userData["xAxis"],
    THREE.MathUtils.degToRad(teethRotationAmount).toFixed(5),
    selectedTooth,
  );
  selectedTooth.updateMatrixWorld();

  let newIPRAmount1 =
      Math.round(
        calculateIPRBetweenTeeth(
          selectedTooth,
          parentHolder.children[iprIndex1 + (isLower ? 0 : 1)],
        ) * 100,
      ) / 100,
    newIPRAmount2 =
      Math.round(
        calculateIPRBetweenTeeth(
          selectedTooth,
          parentHolder.children[iprIndex2 - (isLower ? 1 : 0)],
        ) * 100,
      ) / 100;
  if (
    (Number.isFinite(newIPRAmount1) || Number.isFinite(newIPRAmount2)) &&
    (newIPRAmount1 <= IPRLimit || newIPRAmount2 <= IPRLimit)
  ) {
    if (Number.isFinite(newIPRAmount1)) {
      if (isLower === 0 && iprIndex1 < teethKeys.length - 1) {
        AddIPRToLogs(
          logs,
          "added",
          newIPRAmount1,
          newArray[isLower][teethKeys[iprIndex1]][fIndex].amount,
          teethKeys[iprIndex1].substring(1),
          teethKeys[iprIndex1 + 1].substring(1),
        );
      } else if (iprIndex1 > 0) {
        AddIPRToLogs(
          logs,
          "added",
          newIPRAmount1,
          newArray[isLower][teethKeys[iprIndex1]][fIndex].amount,
          teethKeys[iprIndex1].substring(1),
          teethKeys[iprIndex1 - 1].substring(1),
        );
      }
      undoMovementList[undoMovementList.length - 1].push({
        type: 1,
        amount: newIPRAmount1,
        preAmount: newArray[isLower][teethKeys[iprIndex1]][fIndex].amount,
        action: "added",
        actionType: MovementTypes.IPR,
        from:
          isLower === 0
            ? selectedToothNum
            : parentHolder.children[selectedTeethIndex + 1].name
                .split("_")[2]
                .substring(1, 4),
        to:
          isLower === 0
            ? teethKeys[selectedTeethIndex + 1]
            : teethKeys[selectedTeethIndex],
        tooth: selectedTooth,
      });
      newArray[isLower][teethKeys[iprIndex1]][fIndex].amount = newIPRAmount1;
    }
    if (Number.isFinite(newIPRAmount2)) {
      if (isLower === 0 && iprIndex2 < teethKeys.length - 1) {
        AddIPRToLogs(
          logs,
          "added",
          newIPRAmount2,
          newArray[isLower][teethKeys[iprIndex2]][sIndex].amount,
          teethKeys[iprIndex2].substring(1),
          teethKeys[iprIndex2 + 1].substring(1),
        );
      } else if (iprIndex2 > 0) {
        AddIPRToLogs(
          logs,
          "added",
          newIPRAmount2,
          newArray[isLower][teethKeys[iprIndex2]][sIndex].amount,
          teethKeys[iprIndex2].substring(1),
          teethKeys[iprIndex2 - 1].substring(1),
        );
      }
      undoMovementList[undoMovementList.length - 1].push({
        type: 1,
        amount: newIPRAmount2,
        preAmount: newArray[isLower][teethKeys[iprIndex2]][sIndex].amount,
        action: "added",
        actionType: MovementTypes.IPR,
        from:
          isLower === 1
            ? selectedToothNum
            : parentHolder.children[selectedTeethIndex - 1].name
                .split("_")[2]
                .substring(1, 4),
        to:
          isLower === 1
            ? teethKeys[selectedTeethIndex - 1]
            : teethKeys[selectedTeethIndex],
        tooth: selectedTooth,
      });
      newArray[isLower][teethKeys[iprIndex2]][sIndex].amount = newIPRAmount2;
    }
  } else {
    rotateTooth(
      selectedTooth.userData["gumPoint"],
      selectedTooth.userData["xAxis"],
      THREE.MathUtils.degToRad(-teethRotationAmount).toFixed(5),
      selectedTooth,
    );
    if (selectedTooth.name.charAt(0) === "u") {
      teethRotationAmount *= -1;
    }
    undoMovementList.pop();
    return;
  }

  setIprOverlays(JSON.parse(JSON.stringify(newArray)));
  // x Axis to up
  undoMovementList[undoMovementList.length - 1].push({
    tooth: selectedTooth,
    rotationPoint: selectedTooth.userData["gumPoint"].clone(),
    teethMovementRotationDirection: selectedTooth.userData["xAxis"].clone(),
    degrees: THREE.MathUtils.degToRad(-teethRotationAmount).toFixed(5),
    actionType: MovementTypes.INCLINATION,
  });

  // save data in change log
  let key = selectedToothNum.toString() + "_Movement";
  if (logs[key]) {
    const obj = Object.assign({}, logs[key]);

    delete logs[key];

    obj[MovementTypes.INCLINATION] =
      Math.round((obj[MovementTypes.INCLINATION] + teethRotationAmount) * 100) /
      100;
    logs[key] = obj;
  } else {
    logs[key] = {};
    logs[key][MovementTypes.MESIALDISTAL] = 0;
    logs[key][MovementTypes.BUCCALLINGUAL] = 0;
    logs[key][MovementTypes.EXTRUSIONINTRUSION] = 0;
    logs[key][MovementTypes.ROTATION] = 0;
    logs[key][MovementTypes.ANGULATION] = 0;
    logs[key][MovementTypes.INCLINATION] = teethRotationAmount;
    logs[key][MovementTypes.LEFTHINGE] = 0;
    logs[key][MovementTypes.RIGHTHINGE] = 0;
  }
  if (selectedTooth.name.charAt(0) === "u") {
    teethRotationAmount *= -1;
  }
  setLogs(logs);
  setAutoSave();
  clearRedoMovementList();
}
function inclinationN(
  selectedTooth,
  logs,
  iprOverLays,
  setLogs,
  setIprOverlays,
  setAutoSave,
) {
  if (!newArray || true) {
    newArray = JSON.parse(JSON.stringify(iprOverLays));
  }

  if (
    selectedTooth.userData.hasOwnProperty("isLocked") &&
    selectedTooth.userData["isLocked"]
  ) {
    return;
  }

  let isLower = 1;
  if (selectedTooth.name.charAt(0) === "u") {
    isLower = 0;
  }
  let teethKeys = Object.keys(iprOverLays[isLower]);
  teethKeys.sort(teethKeysSorter);
  RemoveExtractedTeethFromKeys(newArray[isLower], teethKeys);
  let parentHolder = selectedTooth.parent;
  let selectedTeethIndex = parentHolder.children.indexOf(selectedTooth);
  let maxStepNumber = newArray[isLower][teethKeys[0]].length - 1;
  let fIndex = 0,
    sIndex = 0;
  let iprIndex1, iprIndex2;

  if (isLower === 0) {
    iprIndex1 = selectedTeethIndex;
    iprIndex2 = selectedTeethIndex - 1;
  } else {
    iprIndex1 = selectedTeethIndex + 1;
    iprIndex2 = selectedTeethIndex;
  }

  for (let r = 0; r < maxStepNumber; r++) {
    if (
      iprIndex1 < teethKeys.length &&
      (newArray[isLower][teethKeys[iprIndex1]][r].step === maxStepNumber ||
        newArray[isLower][teethKeys[iprIndex1]][r].amount === null)
    ) {
      fIndex = r;
      newArray[isLower][teethKeys[iprIndex1]][r].step = maxStepNumber;
      break;
    }
  }
  for (let r = 0; r < maxStepNumber; r++) {
    if (
      iprIndex2 >= 0 &&
      (newArray[isLower][teethKeys[iprIndex2]][r].step === maxStepNumber ||
        newArray[isLower][teethKeys[iprIndex2]][r].amount === null)
    ) {
      sIndex = r;
      newArray[isLower][teethKeys[iprIndex2]][r].step = maxStepNumber;
      break;
    }
  }

  let selectedToothNum = selectedTooth.name.split("_")[2].substring(1, 4);

  undoMovementList.push([]);
  // auto adjust ipr and spacing before apply movement

  let selectedToothCenter = new THREE.Vector3();
  let box3;

  if (selectedTeethIndex + 1 < teethKeys.length) {
    box3 = new THREE.Box3().setFromObject(
      parentHolder.children[selectedTeethIndex + 1],
    );
    box3.getCenter(selectedToothCenter);
  }

  let selectedToothCenter2 = new THREE.Vector3();
  let box32;
  if (selectedTeethIndex - 1 > 0) {
    box32 = new THREE.Box3().setFromObject(
      parentHolder.children[selectedTeethIndex - 1],
    );
    box32.getCenter(selectedToothCenter2);
  }

  if (selectedTooth.name.charAt(0) === "u") {
    teethRotationAmount *= -1;
  }
  //console.log(selectedTooth.userData["xAxis"]);
  rotateTooth(
    selectedTooth.userData["gumPoint"],
    selectedTooth.userData["xAxis"],
    THREE.MathUtils.degToRad(-teethRotationAmount).toFixed(5),
    selectedTooth,
  ); // x Axis to up
  let newIPRAmount1 =
      Math.round(
        calculateIPRBetweenTeeth(
          selectedTooth,
          parentHolder.children[iprIndex1 + (isLower ? 0 : 1)],
        ) * 100,
      ) / 100,
    newIPRAmount2 =
      Math.round(
        calculateIPRBetweenTeeth(
          selectedTooth,
          parentHolder.children[iprIndex2 - (isLower ? 1 : 0)],
        ) * 100,
      ) / 100;
  if (
    (Number.isFinite(newIPRAmount1) || Number.isFinite(newIPRAmount2)) &&
    (newIPRAmount1 <= IPRLimit || newIPRAmount2 <= IPRLimit)
  ) {
    if (Number.isFinite(newIPRAmount1)) {
      if (isLower === 0 && iprIndex1 < teethKeys.length - 1) {
        AddIPRToLogs(
          logs,
          "added",
          newIPRAmount1,
          newArray[isLower][teethKeys[iprIndex1]][fIndex].amount,
          teethKeys[iprIndex1].substring(1),
          teethKeys[iprIndex1 + 1].substring(1),
        );
      } else if (iprIndex1 > 0) {
        AddIPRToLogs(
          logs,
          "added",
          newIPRAmount1,
          newArray[isLower][teethKeys[iprIndex1]][fIndex].amount,
          teethKeys[iprIndex1].substring(1),
          teethKeys[iprIndex1 - 1].substring(1),
        );
      }
      undoMovementList[undoMovementList.length - 1].push({
        type: 1,
        amount: newIPRAmount1,
        preAmount: newArray[isLower][teethKeys[iprIndex1]][fIndex].amount,
        action: "added",
        actionType: MovementTypes.IPR,
        from:
          isLower === 0
            ? selectedToothNum
            : parentHolder.children[selectedTeethIndex + 1].name
                .split("_")[2]
                .substring(1, 4),
        to:
          isLower === 0
            ? teethKeys[selectedTeethIndex + 1]
            : teethKeys[selectedTeethIndex],
        tooth: selectedTooth,
      });
      newArray[isLower][teethKeys[iprIndex1]][fIndex].amount = newIPRAmount1;
    }

    if (Number.isFinite(newIPRAmount2)) {
      if (isLower === 0 && iprIndex2 < teethKeys.length - 1) {
        AddIPRToLogs(
          logs,
          "added",
          newIPRAmount2,
          newArray[isLower][teethKeys[iprIndex2]][sIndex].amount,
          teethKeys[iprIndex2].substring(1),
          teethKeys[iprIndex2 + 1].substring(1),
        );
      } else if (iprIndex2 > 0) {
        AddIPRToLogs(
          logs,
          "added",
          newIPRAmount2,
          newArray[isLower][teethKeys[iprIndex2]][sIndex].amount,
          teethKeys[iprIndex2].substring(1),
          teethKeys[iprIndex2 - 1].substring(1),
        );
      }
      undoMovementList[undoMovementList.length - 1].push({
        type: 1,
        amount: newIPRAmount2,
        preAmount: newArray[isLower][teethKeys[iprIndex2]][sIndex].amount,
        action: "added",
        actionType: MovementTypes.IPR,
        from:
          isLower === 1
            ? selectedToothNum
            : parentHolder.children[selectedTeethIndex - 1].name
                .split("_")[2]
                .substring(1, 4),
        to:
          isLower === 1
            ? teethKeys[selectedTeethIndex - 1]
            : teethKeys[selectedTeethIndex],
        tooth: selectedTooth,
      });
      newArray[isLower][teethKeys[iprIndex2]][sIndex].amount = newIPRAmount2;
    }
  } else {
    rotateTooth(
      selectedTooth.userData["gumPoint"],
      selectedTooth.userData["xAxis"],
      THREE.MathUtils.degToRad(teethRotationAmount).toFixed(5),
      selectedTooth,
    );
    if (selectedTooth.name.charAt(0) === "u") {
      teethRotationAmount *= -1;
    }
    undoMovementList.pop();
    return;
  }

  setIprOverlays(JSON.parse(JSON.stringify(newArray)));
  undoMovementList[undoMovementList.length - 1].push({
    tooth: selectedTooth,
    rotationPoint: selectedTooth.userData["gumPoint"].clone(),
    teethMovementRotationDirection: selectedTooth.userData["xAxis"].clone(),
    degrees: THREE.MathUtils.degToRad(teethRotationAmount).toFixed(5),
    actionType: MovementTypes.INCLINATION,
  });
  // save data in change log
  let key = selectedToothNum.toString() + "_Movement";
  //
  if (logs[key]) {
    const obj = Object.assign({}, logs[key]);

    delete logs[key];

    obj[MovementTypes.INCLINATION] =
      Math.round((obj[MovementTypes.INCLINATION] - teethRotationAmount) * 100) /
      100;
    logs[key] = obj;
  } else {
    logs[key] = {};
    logs[key][MovementTypes.MESIALDISTAL] = 0;
    logs[key][MovementTypes.BUCCALLINGUAL] = 0;
    logs[key][MovementTypes.EXTRUSIONINTRUSION] = 0;
    logs[key][MovementTypes.ROTATION] = 0;
    logs[key][MovementTypes.ANGULATION] = 0;
    logs[key][MovementTypes.INCLINATION] = -teethRotationAmount;
    logs[key][MovementTypes.LEFTHINGE] = 0;
    logs[key][MovementTypes.RIGHTHINGE] = 0;
  }
  if (selectedTooth.name.charAt(0) === "u") {
    teethRotationAmount *= -1;
  }
  setLogs(logs);
  setAutoSave();
  clearRedoMovementList();
}
function angulationP(
  selectedTooth,
  logs,
  iprOverLays,
  setLogs,
  setIprOverlays,
  setAutoSave,
) {
  if (!newArray || true) {
    newArray = JSON.parse(JSON.stringify(iprOverLays));
  }
  if (
    selectedTooth.userData.hasOwnProperty("isLocked") &&
    selectedTooth.userData["isLocked"]
  ) {
    return;
  }
  let isLower = 1;
  if (selectedTooth.name.charAt(0) === "u") {
    isLower = 0;
  }
  let teethKeys = Object.keys(iprOverLays[isLower]);
  teethKeys.sort(teethKeysSorter);
  RemoveExtractedTeethFromKeys(newArray[isLower], teethKeys);
  let parentHolder = selectedTooth.parent;
  let selectedTeethIndex = parentHolder.children.indexOf(selectedTooth);
  let maxStepNumber = newArray[isLower][teethKeys[0]].length - 1;
  let fIndex = 0,
    sIndex = 0;

  let iprIndex1, iprIndex2;

  if (isLower === 0) {
    iprIndex1 = selectedTeethIndex;
    iprIndex2 = selectedTeethIndex - 1;
  } else {
    iprIndex1 = selectedTeethIndex + 1;
    iprIndex2 = selectedTeethIndex;
  }

  if (iprIndex1 <= teethKeys.length - 1) {
    for (let r = 0; r < maxStepNumber; r++) {
      if (
        newArray[isLower][teethKeys[iprIndex1]][r].step === maxStepNumber ||
        newArray[isLower][teethKeys[iprIndex1]][r].amount === null
      ) {
        fIndex = r;
        newArray[isLower][teethKeys[iprIndex1]][r].step = maxStepNumber;
        break;
      }
    }
  }
  if (iprIndex2 >= 0) {
    for (let r = 0; r < maxStepNumber; r++) {
      if (
        iprIndex2 >= 0 &&
        (newArray[isLower][teethKeys[iprIndex2]][r].step === maxStepNumber ||
          newArray[isLower][teethKeys[iprIndex2]][r].amount === null)
      ) {
        sIndex = r;
        newArray[isLower][teethKeys[iprIndex2]][r].step = maxStepNumber;
        break;
      }
    }
  }

  let selectedToothNum = selectedTooth.name.split("_")[2].substring(1, 4);
  undoMovementList.push([]);
  rotateTooth(
    selectedTooth.userData["center"],
    selectedTooth.userData["zAxis"],
    THREE.MathUtils.degToRad(teethRotationAmount).toFixed(5),
    selectedTooth,
  ); // zAxis to right
  let newIPRAmount1 =
      Math.round(
        calculateIPRBetweenTeeth(
          selectedTooth,
          parentHolder.children[iprIndex1 + (isLower ? 0 : 1)],
        ) * 100,
      ) / 100,
    newIPRAmount2 =
      Math.round(
        calculateIPRBetweenTeeth(
          selectedTooth,
          parentHolder.children[iprIndex2 - (isLower ? 1 : 0)],
        ) * 100,
      ) / 100;
  if (
    (Number.isFinite(newIPRAmount1) || Number.isFinite(newIPRAmount2)) &&
    (newIPRAmount1 <= IPRLimit || newIPRAmount2 <= IPRLimit)
  ) {
    if (Number.isFinite(newIPRAmount1)) {
      if (isLower === 0 && iprIndex1 < teethKeys.length - 1) {
        AddIPRToLogs(
          logs,
          "added",
          newIPRAmount1,
          newArray[isLower][teethKeys[iprIndex1]][fIndex].amount,
          teethKeys[iprIndex1].substring(1),
          teethKeys[iprIndex1 + 1].substring(1),
        );
      } else if (iprIndex1 > 0) {
        AddIPRToLogs(
          logs,
          "added",
          newIPRAmount1,
          newArray[isLower][teethKeys[iprIndex1]][fIndex].amount,
          teethKeys[iprIndex1].substring(1),
          teethKeys[iprIndex1 - 1].substring(1),
        );
      }
      undoMovementList[undoMovementList.length - 1].push({
        type: 1,
        amount: newIPRAmount1,
        preAmount: newArray[isLower][teethKeys[iprIndex1]][fIndex].amount,
        action: "added",
        actionType: MovementTypes.IPR,
        from:
          isLower === 0
            ? selectedToothNum
            : parentHolder.children[selectedTeethIndex + 1].name
                .split("_")[2]
                .substring(1, 4),
        to:
          isLower === 0
            ? teethKeys[selectedTeethIndex + 1]
            : teethKeys[selectedTeethIndex],
        tooth: selectedTooth,
      });
      newArray[isLower][teethKeys[iprIndex1]][fIndex].amount = newIPRAmount1;
    }
    if (Number.isFinite(newIPRAmount2)) {
      if (isLower === 0 && iprIndex2 < teethKeys.length - 1) {
        AddIPRToLogs(
          logs,
          "added",
          newIPRAmount2,
          newArray[isLower][teethKeys[iprIndex2]][sIndex].amount,
          teethKeys[iprIndex2].substring(1),
          teethKeys[iprIndex2 + 1].substring(1),
        );
      } else if (iprIndex2 > 0) {
        AddIPRToLogs(
          logs,
          "added",
          newIPRAmount2,
          newArray[isLower][teethKeys[iprIndex2]][sIndex].amount,
          teethKeys[iprIndex2].substring(1),
          teethKeys[iprIndex2 - 1].substring(1),
        );
      }
      undoMovementList[undoMovementList.length - 1].push({
        type: 1,
        amount: newIPRAmount2,
        preAmount: newArray[isLower][teethKeys[iprIndex2]][sIndex].amount,
        action: "added",
        actionType: MovementTypes.IPR,
        from:
          isLower === 1
            ? selectedToothNum
            : parentHolder.children[selectedTeethIndex - 1].name
                .split("_")[2]
                .substring(1, 4),
        to:
          isLower === 1
            ? teethKeys[selectedTeethIndex - 1]
            : teethKeys[selectedTeethIndex],
        tooth: selectedTooth,
      });
      newArray[isLower][teethKeys[iprIndex2]][sIndex].amount = newIPRAmount2;
    }
  } else {
    rotateTooth(
      selectedTooth.userData["center"],
      selectedTooth.userData["zAxis"],
      THREE.MathUtils.degToRad(-teethRotationAmount).toFixed(5),
      selectedTooth,
    );
    undoMovementList.pop();
    return;
  }
  setIprOverlays(JSON.parse(JSON.stringify(newArray)));

  undoMovementList[undoMovementList.length - 1].push({
    tooth: selectedTooth,
    rotationPoint: selectedTooth.userData["center"].clone(),
    teethMovementRotationDirection: selectedTooth.userData["zAxis"].clone(),
    degrees: THREE.MathUtils.degToRad(-teethRotationAmount).toFixed(5),
    actionType: MovementTypes.ANGULATION,
  });
  // save data in change log
  let key = selectedToothNum.toString() + "_Movement";
  if (logs[key]) {
    const obj = Object.assign({}, logs[key]);

    delete logs[key];

    obj[MovementTypes.ANGULATION] =
      Math.round((obj[MovementTypes.ANGULATION] + teethRotationAmount) * 100) /
      100;
    logs[key] = obj;
  } else {
    logs[key] = {};
    logs[key][MovementTypes.MESIALDISTAL] = 0;
    logs[key][MovementTypes.BUCCALLINGUAL] = 0;
    logs[key][MovementTypes.EXTRUSIONINTRUSION] = 0;
    logs[key][MovementTypes.ROTATION] = 0;
    logs[key][MovementTypes.ANGULATION] = teethRotationAmount;
    logs[key][MovementTypes.INCLINATION] = 0;
    logs[key][MovementTypes.LEFTHINGE] = 0;
    logs[key][MovementTypes.RIGHTHINGE] = 0;
  }
  setLogs(logs);
  setAutoSave();
  clearRedoMovementList();
}
function angulationN(
  selectedTooth,
  logs,
  iprOverLays,
  setLogs,
  setIprOverlays,
  setAutoSave,
) {
  if (!newArray || true) {
    newArray = JSON.parse(JSON.stringify(iprOverLays));
  }
  if (
    selectedTooth.userData.hasOwnProperty("isLocked") &&
    selectedTooth.userData["isLocked"]
  ) {
    return;
  }
  let isLower = 1;
  if (selectedTooth.name.charAt(0) === "u") {
    isLower = 0;
  }
  let teethKeys = Object.keys(iprOverLays[isLower]);
  teethKeys.sort(teethKeysSorter);
  RemoveExtractedTeethFromKeys(newArray[isLower], teethKeys);
  let parentHolder = selectedTooth.parent;
  let selectedTeethIndex = parentHolder.children.indexOf(selectedTooth);
  let maxStepNumber = newArray[isLower][teethKeys[0]].length - 1;
  let fIndex = 0,
    sIndex = 0;
  let iprIndex1, iprIndex2;

  if (isLower === 0) {
    iprIndex1 = selectedTeethIndex - 1;
    iprIndex2 = selectedTeethIndex;
  } else {
    iprIndex1 = selectedTeethIndex;
    iprIndex2 = selectedTeethIndex + 1;
  }

  if (iprIndex1 >= 0) {
    for (let r = 0; r < maxStepNumber; r++) {
      if (
        newArray[isLower][teethKeys[iprIndex1]][r].step === maxStepNumber ||
        newArray[isLower][teethKeys[iprIndex1]][r].amount === null
      ) {
        fIndex = r;
        newArray[isLower][teethKeys[iprIndex1]][r].step = maxStepNumber;
        break;
      }
    }
  }
  if (iprIndex2 <= teethKeys.length - 1) {
    for (let r = 0; r < maxStepNumber; r++) {
      if (
        iprIndex2 >= 0 &&
        (newArray[isLower][teethKeys[iprIndex2]][r].step === maxStepNumber ||
          newArray[isLower][teethKeys[iprIndex2]][r].amount === null)
      ) {
        sIndex = r;
        newArray[isLower][teethKeys[iprIndex2]][r].step = maxStepNumber;
        break;
      }
    }
  }

  let selectedToothNum = selectedTooth.name.split("_")[2].substring(1, 4);
  undoMovementList.push([]);
  rotateTooth(
    selectedTooth.userData["center"],
    selectedTooth.userData["zAxis"],
    THREE.MathUtils.degToRad(-teethRotationAmount).toFixed(5),
    selectedTooth,
  ); // zAxis to right

  let newIPRAmount1 =
      Math.round(
        calculateIPRBetweenTeeth(
          selectedTooth,
          parentHolder.children[iprIndex1 - (isLower ? 1 : 0)],
        ) * 100,
      ) / 100,
    newIPRAmount2 =
      Math.round(
        calculateIPRBetweenTeeth(
          selectedTooth,
          parentHolder.children[iprIndex2 + (isLower ? 0 : 1)],
        ) * 100,
      ) / 100;

  if (
    (Number.isFinite(newIPRAmount1) || Number.isFinite(newIPRAmount2)) &&
    (newIPRAmount1 <= IPRLimit || newIPRAmount2 <= IPRLimit)
  ) {
    if (Number.isFinite(newIPRAmount1)) {
      if (isLower === 0 && iprIndex1 < teethKeys.length - 1) {
        AddIPRToLogs(
          logs,
          "added",
          newIPRAmount1,
          newArray[isLower][teethKeys[iprIndex1]][fIndex].amount,
          teethKeys[iprIndex1].substring(1),
          teethKeys[iprIndex1 + 1].substring(1),
        );
      } else if (iprIndex1 > 0) {
        AddIPRToLogs(
          logs,
          "added",
          newIPRAmount1,
          newArray[isLower][teethKeys[iprIndex1]][fIndex].amount,
          teethKeys[iprIndex1].substring(1),
          teethKeys[iprIndex1 - 1].substring(1),
        );
      }
      undoMovementList[undoMovementList.length - 1].push({
        type: 1,
        amount: newIPRAmount1,
        preAmount: newArray[isLower][teethKeys[iprIndex1]][fIndex].amount,
        action: "added",
        actionType: MovementTypes.IPR,
        from:
          isLower === 1
            ? selectedToothNum
            : parentHolder.children[selectedTeethIndex - 1].name
                .split("_")[2]
                .substring(1, 4),
        to:
          isLower === 1
            ? teethKeys[selectedTeethIndex - 1]
            : teethKeys[selectedTeethIndex],
        tooth: selectedTooth,
      });
      newArray[isLower][teethKeys[iprIndex1]][fIndex].amount = newIPRAmount1;
    }

    if (Number.isFinite(newIPRAmount2)) {
      if (isLower === 0 && iprIndex2 < teethKeys.length - 1) {
        AddIPRToLogs(
          logs,
          "added",
          newIPRAmount2,
          newArray[isLower][teethKeys[iprIndex2]][sIndex].amount,
          teethKeys[iprIndex2].substring(1),
          teethKeys[iprIndex2 + 1].substring(1),
        );
      } else if (iprIndex2 > 0) {
        AddIPRToLogs(
          logs,
          "added",
          newIPRAmount2,
          newArray[isLower][teethKeys[iprIndex2]][sIndex].amount,
          teethKeys[iprIndex2].substring(1),
          teethKeys[iprIndex2 - 1].substring(1),
        );
      }

      undoMovementList[undoMovementList.length - 1].push({
        type: 1,
        amount: newIPRAmount2,
        preAmount: newArray[isLower][teethKeys[iprIndex2]][sIndex].amount,
        action: "added",
        actionType: MovementTypes.IPR,
        from:
          isLower === 0
            ? selectedToothNum
            : parentHolder.children[selectedTeethIndex + 1].name
                .split("_")[2]
                .substring(1, 4),
        to:
          isLower === 0
            ? teethKeys[selectedTeethIndex + 1]
            : teethKeys[selectedTeethIndex],
        tooth: selectedTooth,
      });
      newArray[isLower][teethKeys[iprIndex2]][sIndex].amount = newIPRAmount2;
    }
  } else {
    rotateTooth(
      selectedTooth.userData["center"],
      selectedTooth.userData["zAxis"],
      THREE.MathUtils.degToRad(teethRotationAmount).toFixed(5),
      selectedTooth,
    );
    undoMovementList.pop();
    return;
  }
  setIprOverlays(JSON.parse(JSON.stringify(newArray)));
  undoMovementList[undoMovementList.length - 1].push({
    tooth: selectedTooth,
    rotationPoint: selectedTooth.userData["center"].clone(),
    teethMovementRotationDirection: selectedTooth.userData["zAxis"].clone(),
    degrees: THREE.MathUtils.degToRad(teethRotationAmount).toFixed(5),
    actionType: MovementTypes.ANGULATION,
  });
  // save data in change log
  let key = selectedToothNum.toString() + "_Movement";
  if (logs[key]) {
    const obj = Object.assign({}, logs[key]);

    delete logs[key];

    obj[MovementTypes.ANGULATION] =
      Math.round((obj[MovementTypes.ANGULATION] - teethRotationAmount) * 100) /
      100;
    logs[key] = obj;
  } else {
    logs[key] = {};
    logs[key][MovementTypes.MESIALDISTAL] = 0;
    logs[key][MovementTypes.BUCCALLINGUAL] = 0;
    logs[key][MovementTypes.EXTRUSIONINTRUSION] = 0;
    logs[key][MovementTypes.ROTATION] = 0;
    logs[key][MovementTypes.ANGULATION] = -teethRotationAmount;
    logs[key][MovementTypes.INCLINATION] = 0;
    logs[key][MovementTypes.LEFTHINGE] = 0;
    logs[key][MovementTypes.RIGHTHINGE] = 0;
  }

  setLogs(logs);
  setAutoSave();
  clearRedoMovementList();
}
function leftHingeP(
  selectedTooth,
  logs,
  iprOverLays,
  setLogs,
  setIprOverlays,
  setAutoSave,
) {
  if (!newArray || true) {
    newArray = JSON.parse(JSON.stringify(iprOverLays));
  }

  if (
    selectedTooth.userData.hasOwnProperty("isLocked") &&
    selectedTooth.userData["isLocked"]
  ) {
    return;
  }

  let isLower = 1;
  if (selectedTooth.name.charAt(0) === "u") {
    isLower = 0;
  }
  let teethKeys = Object.keys(iprOverLays[isLower]);
  teethKeys.sort(teethKeysSorter);
  RemoveExtractedTeethFromKeys(newArray[isLower], teethKeys);
  let parentHolder = selectedTooth.parent;
  let selectedTeethIndex = parentHolder.children.indexOf(selectedTooth);
  let maxStepNumber = newArray[isLower][teethKeys[0]].length - 1;
  let fIndex = 0,
    sIndex = 0;
  let selectedToothNum = selectedTooth.name.split("_")[2].substring(1, 4);

  let iprIndex1, iprIndex2;

  if (isLower === 0) {
    iprIndex1 = selectedTeethIndex;
    iprIndex2 = selectedTeethIndex - 1;
  } else {
    iprIndex1 = selectedTeethIndex + 1;
    iprIndex2 = selectedTeethIndex;
  }

  for (let r = 0; r < maxStepNumber; r++) {
    if (
      iprIndex1 < teethKeys.length &&
      (newArray[isLower][teethKeys[iprIndex1]][r].step === maxStepNumber ||
        newArray[isLower][teethKeys[iprIndex1]][r].amount === null)
    ) {
      fIndex = r;
      newArray[isLower][teethKeys[iprIndex1]][r].step = maxStepNumber;
      break;
    }
  }
  for (let r = 0; r < maxStepNumber; r++) {
    if (
      iprIndex2 >= 0 &&
      (newArray[isLower][teethKeys[iprIndex2]][r].step === maxStepNumber ||
        newArray[isLower][teethKeys[iprIndex2]][r].amount === null)
    ) {
      sIndex = r;
      newArray[isLower][teethKeys[iprIndex2]][r].step = maxStepNumber;
      break;
    }
  }

  undoMovementList.push([]);
  // auto adjust ipr and spacing before apply movement

  let selectedToothCenter = new THREE.Vector3();
  let box3;

  if (selectedTeethIndex + 1 < teethKeys.length) {
    box3 = new THREE.Box3().setFromObject(
      parentHolder.children[selectedTeethIndex + 1],
    );
    box3.getCenter(selectedToothCenter);
  }

  let selectedToothCenter2 = new THREE.Vector3();
  let box32;
  if (selectedTeethIndex - 1 > 0) {
    box32 = new THREE.Box3().setFromObject(
      parentHolder.children[selectedTeethIndex - 1],
    );
    box32.getCenter(selectedToothCenter2);
  }

  rotateTooth(
    selectedTooth.userData["leftHinge"],
    new THREE.Vector3(0, 1, 0),
    THREE.MathUtils.degToRad(teethRotationAmount).toFixed(5),
    selectedTooth,
  ); // y axis to right
  let newIPRAmount1 =
      Math.round(
        calculateIPRBetweenTeeth(
          selectedTooth,
          parentHolder.children[iprIndex1 + (isLower ? 0 : 1)],
        ) * 100,
      ) / 100,
    newIPRAmount2 =
      Math.round(
        calculateIPRBetweenTeeth(
          selectedTooth,
          parentHolder.children[iprIndex2 - (isLower ? 1 : 0)],
        ) * 100,
      ) / 100;
  if (
    (Number.isFinite(newIPRAmount1) || Number.isFinite(newIPRAmount2)) &&
    (newIPRAmount1 <= IPRLimit || newIPRAmount2 <= IPRLimit)
  ) {
    if (Number.isFinite(newIPRAmount1)) {
      if (isLower === 0 && iprIndex1 < teethKeys.length - 1) {
        AddIPRToLogs(
          logs,
          "added",
          newIPRAmount1,
          newArray[isLower][teethKeys[iprIndex1]][fIndex].amount,
          teethKeys[iprIndex1].substring(1),
          teethKeys[iprIndex1 + 1].substring(1),
        );
      } else if (iprIndex1 > 0) {
        AddIPRToLogs(
          logs,
          "added",
          newIPRAmount1,
          newArray[isLower][teethKeys[iprIndex1]][fIndex].amount,
          teethKeys[iprIndex1].substring(1),
          teethKeys[iprIndex1 - 1].substring(1),
        );
      }
      undoMovementList[undoMovementList.length - 1].push({
        type: 1,
        amount: newIPRAmount1,
        preAmount: newArray[isLower][teethKeys[iprIndex1]][fIndex].amount,
        action: "added",
        actionType: MovementTypes.IPR,
        from:
          isLower === 0
            ? selectedToothNum
            : parentHolder.children[selectedTeethIndex + 1].name
                .split("_")[2]
                .substring(1, 4),
        to:
          isLower === 0
            ? teethKeys[selectedTeethIndex + 1]
            : teethKeys[selectedTeethIndex],
        tooth: selectedTooth,
      });
      newArray[isLower][teethKeys[iprIndex1]][fIndex].amount = newIPRAmount1;
    }

    if (Number.isFinite(newIPRAmount2)) {
      if (isLower === 0 && iprIndex2 < teethKeys.length - 1) {
        AddIPRToLogs(
          logs,
          "added",
          newIPRAmount2,
          newArray[isLower][teethKeys[iprIndex2]][sIndex].amount,
          teethKeys[iprIndex2].substring(1),
          teethKeys[iprIndex2 + 1].substring(1),
        );
      } else if (iprIndex2 > 0) {
        AddIPRToLogs(
          logs,
          "added",
          newIPRAmount2,
          newArray[isLower][teethKeys[iprIndex2]][sIndex].amount,
          teethKeys[iprIndex2].substring(1),
          teethKeys[iprIndex2 - 1].substring(1),
        );
      }
      undoMovementList[undoMovementList.length - 1].push({
        type: 1,
        amount: newIPRAmount2,
        preAmount: newArray[isLower][teethKeys[iprIndex2]][sIndex].amount,
        action: "added",
        actionType: MovementTypes.IPR,
        from:
          isLower === 1
            ? selectedToothNum
            : parentHolder.children[selectedTeethIndex - 1].name
                .split("_")[2]
                .substring(1, 4),
        to:
          isLower === 1
            ? teethKeys[selectedTeethIndex - 1]
            : teethKeys[selectedTeethIndex],
        tooth: selectedTooth,
      });
      newArray[isLower][teethKeys[iprIndex2]][sIndex].amount = newIPRAmount2;
    }
  } else {
    rotateTooth(
      selectedTooth.userData["leftHinge"],
      new THREE.Vector3(0, 1, 0),
      THREE.MathUtils.degToRad(-teethRotationAmount).toFixed(5),
      selectedTooth,
    );
    undoMovementList.pop();
    return;
  }

  setIprOverlays(JSON.parse(JSON.stringify(newArray)));
  undoMovementList[undoMovementList.length - 1].push({
    tooth: selectedTooth,
    rotationPoint: selectedTooth.userData["leftHinge"].clone(),
    teethMovementRotationDirection: new THREE.Vector3(0, 1, 0),
    degrees: THREE.MathUtils.degToRad(-teethRotationAmount).toFixed(5),
    actionType: MovementTypes.LEFTHINGE,
  });

  // save data in change log
  let key = selectedToothNum.toString() + "_Movement";
  if (logs[key]) {
    const obj = Object.assign({}, logs[key]);

    delete logs[key];

    obj[MovementTypes.LEFTHINGE] =
      Math.round((obj[MovementTypes.LEFTHINGE] + teethRotationAmount) * 100) /
      100;
    logs[key] = obj;
  } else {
    logs[key] = {};
    logs[key][MovementTypes.MESIALDISTAL] = 0;
    logs[key][MovementTypes.BUCCALLINGUAL] = 0;
    logs[key][MovementTypes.EXTRUSIONINTRUSION] = 0;
    logs[key][MovementTypes.ROTATION] = 0;
    logs[key][MovementTypes.ANGULATION] = 0;
    logs[key][MovementTypes.INCLINATION] = 0;
    logs[key][MovementTypes.LEFTHINGE] = teethRotationAmount;
    logs[key][MovementTypes.RIGHTHINGE] = 0;
  }
  setLogs(logs);
  setAutoSave();
  clearRedoMovementList();
}
function leftHingeN(
  selectedTooth,
  logs,
  iprOverLays,
  setLogs,
  setIprOverlays,
  setAutoSave,
) {
  if (!newArray || true) {
    newArray = JSON.parse(JSON.stringify(iprOverLays));
  }

  if (
    selectedTooth.userData.hasOwnProperty("isLocked") &&
    selectedTooth.userData["isLocked"]
  ) {
    return;
  }

  let isLower = 1;
  if (selectedTooth.name.charAt(0) === "u") {
    isLower = 0;
  }
  let teethKeys = Object.keys(iprOverLays[isLower]);
  teethKeys.sort(teethKeysSorter);
  RemoveExtractedTeethFromKeys(newArray[isLower], teethKeys);
  let parentHolder = selectedTooth.parent;
  let selectedTeethIndex = parentHolder.children.indexOf(selectedTooth);
  let maxStepNumber = newArray[isLower][teethKeys[0]].length - 1;
  let fIndex = 0,
    sIndex = 0;
  let selectedToothNum = selectedTooth.name.split("_")[2].substring(1, 4);

  let iprIndex1, iprIndex2;

  if (isLower === 0) {
    iprIndex1 = selectedTeethIndex;
    iprIndex2 = selectedTeethIndex - 1;
  } else {
    iprIndex1 = selectedTeethIndex + 1;
    iprIndex2 = selectedTeethIndex;
  }

  for (let r = 0; r < maxStepNumber; r++) {
    if (
      iprIndex1 < teethKeys.length &&
      (newArray[isLower][teethKeys[iprIndex1]][r].step === maxStepNumber ||
        newArray[isLower][teethKeys[iprIndex1]][r].amount === null)
    ) {
      fIndex = r;
      newArray[isLower][teethKeys[iprIndex1]][r].step = maxStepNumber;
      break;
    }
  }
  for (let r = 0; r < maxStepNumber; r++) {
    if (
      iprIndex2 >= 0 &&
      (newArray[isLower][teethKeys[iprIndex2]][r].step === maxStepNumber ||
        newArray[isLower][teethKeys[iprIndex2]][r].amount === null)
    ) {
      sIndex = r;
      newArray[isLower][teethKeys[iprIndex2]][r].step = maxStepNumber;
      break;
    }
  }

  undoMovementList.push([]);
  // auto adjust ipr and spacing before apply movement

  let selectedToothCenter = new THREE.Vector3();
  let box3;

  if (selectedTeethIndex + 1 < teethKeys.length) {
    box3 = new THREE.Box3().setFromObject(
      parentHolder.children[selectedTeethIndex + 1],
    );
    box3.getCenter(selectedToothCenter);
  }

  let selectedToothCenter2 = new THREE.Vector3();
  let box32;
  if (selectedTeethIndex - 1 > 0) {
    box32 = new THREE.Box3().setFromObject(
      parentHolder.children[selectedTeethIndex - 1],
    );
    box32.getCenter(selectedToothCenter2);
  }

  rotateTooth(
    selectedTooth.userData["leftHinge"],
    new THREE.Vector3(0, 1, 0),
    THREE.MathUtils.degToRad(-teethRotationAmount).toFixed(5),
    selectedTooth,
  ); // y axis to right
  let newIPRAmount1 =
      Math.round(
        calculateIPRBetweenTeeth(
          selectedTooth,
          parentHolder.children[iprIndex1 + (isLower ? 0 : 1)],
        ) * 100,
      ) / 100,
    newIPRAmount2 =
      Math.round(
        calculateIPRBetweenTeeth(
          selectedTooth,
          parentHolder.children[iprIndex2 - (isLower ? 1 : 0)],
        ) * 100,
      ) / 100;
  if (
    (Number.isFinite(newIPRAmount1) || Number.isFinite(newIPRAmount2)) &&
    (newIPRAmount1 <= IPRLimit || newIPRAmount2 <= IPRLimit)
  ) {
    if (Number.isFinite(newIPRAmount1)) {
      if (isLower === 0 && iprIndex1 < teethKeys.length - 1) {
        AddIPRToLogs(
          logs,
          "added",
          newIPRAmount1,
          newArray[isLower][teethKeys[iprIndex1]][fIndex].amount,
          teethKeys[iprIndex1].substring(1),
          teethKeys[iprIndex1 + 1].substring(1),
        );
      } else if (iprIndex1 > 0) {
        AddIPRToLogs(
          logs,
          "added",
          newIPRAmount1,
          newArray[isLower][teethKeys[iprIndex1]][fIndex].amount,
          teethKeys[iprIndex1].substring(1),
          teethKeys[iprIndex1 - 1].substring(1),
        );
      }
      undoMovementList[undoMovementList.length - 1].push({
        type: 1,
        amount: newIPRAmount1,
        preAmount: newArray[isLower][teethKeys[iprIndex1]][fIndex].amount,
        action: "added",
        actionType: MovementTypes.IPR,
        from:
          isLower === 0
            ? selectedToothNum
            : parentHolder.children[selectedTeethIndex + 1].name
                .split("_")[2]
                .substring(1, 4),
        to:
          isLower === 0
            ? teethKeys[selectedTeethIndex + 1]
            : teethKeys[selectedTeethIndex],
        tooth: selectedTooth,
      });
      newArray[isLower][teethKeys[iprIndex1]][fIndex].amount = newIPRAmount1;
    }

    if (Number.isFinite(newIPRAmount2)) {
      if (isLower === 0 && iprIndex2 < teethKeys.length - 1) {
        AddIPRToLogs(
          logs,
          "added",
          newIPRAmount2,
          newArray[isLower][teethKeys[iprIndex2]][sIndex].amount,
          teethKeys[iprIndex2].substring(1),
          teethKeys[iprIndex2 + 1].substring(1),
        );
      } else if (iprIndex2 > 0) {
        AddIPRToLogs(
          logs,
          "added",
          newIPRAmount2,
          newArray[isLower][teethKeys[iprIndex2]][sIndex].amount,
          teethKeys[iprIndex2].substring(1),
          teethKeys[iprIndex2 - 1].substring(1),
        );
      }
      undoMovementList[undoMovementList.length - 1].push({
        type: 1,
        amount: newIPRAmount2,
        preAmount: newArray[isLower][teethKeys[iprIndex2]][sIndex].amount,
        action: "added",
        actionType: MovementTypes.IPR,
        from:
          isLower === 1
            ? selectedToothNum
            : parentHolder.children[selectedTeethIndex - 1].name
                .split("_")[2]
                .substring(1, 4),
        to:
          isLower === 1
            ? teethKeys[selectedTeethIndex - 1]
            : teethKeys[selectedTeethIndex],
        tooth: selectedTooth,
      });
      newArray[isLower][teethKeys[iprIndex2]][sIndex].amount = newIPRAmount2;
    }
  } else {
    rotateTooth(
      selectedTooth.userData["leftHinge"],
      new THREE.Vector3(0, 1, 0),
      THREE.MathUtils.degToRad(teethRotationAmount).toFixed(5),
      selectedTooth,
    );
    undoMovementList.pop();
    return;
  }

  setIprOverlays(JSON.parse(JSON.stringify(newArray)));
  undoMovementList[undoMovementList.length - 1].push({
    tooth: selectedTooth,
    rotationPoint: selectedTooth.userData["leftHinge"].clone(),
    teethMovementRotationDirection: new THREE.Vector3(0, 1, 0),
    degrees: THREE.MathUtils.degToRad(teethRotationAmount).toFixed(5),
    actionType: MovementTypes.LEFTHINGE,
  });

  // save data in change log
  let key = selectedToothNum.toString() + "_Movement";
  if (logs[key]) {
    const obj = Object.assign({}, logs[key]);

    delete logs[key];

    obj[MovementTypes.LEFTHINGE] =
      Math.round((obj[MovementTypes.LEFTHINGE] - teethRotationAmount) * 100) /
      100;
    logs[key] = obj;
  } else {
    logs[key] = {};
    logs[key][MovementTypes.MESIALDISTAL] = 0;
    logs[key][MovementTypes.BUCCALLINGUAL] = 0;
    logs[key][MovementTypes.EXTRUSIONINTRUSION] = 0;
    logs[key][MovementTypes.ROTATION] = 0;
    logs[key][MovementTypes.ANGULATION] = 0;
    logs[key][MovementTypes.INCLINATION] = 0;
    logs[key][MovementTypes.LEFTHINGE] = -teethRotationAmount;
    logs[key][MovementTypes.RIGHTHINGE] = 0;
  }
  setLogs(logs);
  setAutoSave();
  clearRedoMovementList();
}
function rightHingeP(
  selectedTooth,
  logs,
  iprOverLays,
  setLogs,
  setIprOverlays,
  setAutoSave,
) {
  if (!newArray || true) {
    newArray = JSON.parse(JSON.stringify(iprOverLays));
  }

  if (
    selectedTooth.userData.hasOwnProperty("isLocked") &&
    selectedTooth.userData["isLocked"]
  ) {
    return;
  }

  let isLower = 1;
  if (selectedTooth.name.charAt(0) === "u") {
    isLower = 0;
  }
  let teethKeys = Object.keys(iprOverLays[isLower]);
  teethKeys.sort(teethKeysSorter);
  RemoveExtractedTeethFromKeys(newArray[isLower], teethKeys);
  let parentHolder = selectedTooth.parent;
  let selectedTeethIndex = parentHolder.children.indexOf(selectedTooth);
  let maxStepNumber = newArray[isLower][teethKeys[0]].length - 1;
  let fIndex = 0,
    sIndex = 0;
  let selectedToothNum = selectedTooth.name.split("_")[2].substring(1, 4);

  let iprIndex1, iprIndex2, iprIndex3, iprIndex4;

  if (isLower === 0) {
    iprIndex1 = selectedTeethIndex;
    iprIndex2 = selectedTeethIndex - 1;
  } else {
    iprIndex1 = selectedTeethIndex + 1;
    iprIndex2 = selectedTeethIndex;
  }

  for (let r = 0; r < maxStepNumber; r++) {
    if (
      iprIndex1 < teethKeys.length &&
      (newArray[isLower][teethKeys[iprIndex1]][r].step === maxStepNumber ||
        newArray[isLower][teethKeys[iprIndex1]][r].amount === null)
    ) {
      fIndex = r;
      newArray[isLower][teethKeys[iprIndex1]][r].step = maxStepNumber;
      break;
    }
  }
  for (let r = 0; r < maxStepNumber; r++) {
    if (
      iprIndex2 >= 0 &&
      (newArray[isLower][teethKeys[iprIndex2]][r].step === maxStepNumber ||
        newArray[isLower][teethKeys[iprIndex2]][r].amount === null)
    ) {
      sIndex = r;
      newArray[isLower][teethKeys[iprIndex2]][r].step = maxStepNumber;
      break;
    }
  }

  undoMovementList.push([]);
  // auto adjust ipr and spacing before apply movement

  let selectedToothCenter = new THREE.Vector3();
  let box3;

  if (selectedTeethIndex + 1 < teethKeys.length) {
    box3 = new THREE.Box3().setFromObject(
      parentHolder.children[selectedTeethIndex + 1],
    );
    box3.getCenter(selectedToothCenter);
  }

  let selectedToothCenter2 = new THREE.Vector3();
  let box32;
  if (selectedTeethIndex - 1 > 0) {
    box32 = new THREE.Box3().setFromObject(
      parentHolder.children[selectedTeethIndex - 1],
    );
    box32.getCenter(selectedToothCenter2);
  }
  rotateTooth(
    selectedTooth.userData["rightHinge"],
    new THREE.Vector3(0, 1, 0),
    THREE.MathUtils.degToRad(teethRotationAmount).toFixed(5),
    selectedTooth,
  ); // y axis to right

  let newIPRAmount1 =
      Math.round(
        calculateIPRBetweenTeeth(
          selectedTooth,
          parentHolder.children[iprIndex1 + (isLower ? 0 : 1)],
        ) * 100,
      ) / 100,
    newIPRAmount2 =
      Math.round(
        calculateIPRBetweenTeeth(
          selectedTooth,
          parentHolder.children[iprIndex2 - (isLower ? 1 : 0)],
        ) * 100,
      ) / 100;
  if (
    (Number.isFinite(newIPRAmount1) || Number.isFinite(newIPRAmount2)) &&
    (newIPRAmount1 <= IPRLimit || newIPRAmount2 <= IPRLimit)
  ) {
    if (Number.isFinite(newIPRAmount1)) {
      if (isLower === 0 && iprIndex1 < teethKeys.length - 1) {
        AddIPRToLogs(
          logs,
          "added",
          newIPRAmount1,
          newArray[isLower][teethKeys[iprIndex1]][fIndex].amount,
          teethKeys[iprIndex1].substring(1),
          teethKeys[iprIndex1 + 1].substring(1),
        );
      } else if (iprIndex1 > 0) {
        AddIPRToLogs(
          logs,
          "added",
          newIPRAmount1,
          newArray[isLower][teethKeys[iprIndex1]][fIndex].amount,
          teethKeys[iprIndex1].substring(1),
          teethKeys[iprIndex1 - 1].substring(1),
        );
      }
      undoMovementList[undoMovementList.length - 1].push({
        type: 1,
        amount: newIPRAmount1,
        preAmount: newArray[isLower][teethKeys[iprIndex1]][fIndex].amount,
        action: "added",
        actionType: MovementTypes.IPR,
        from:
          isLower === 0
            ? selectedToothNum
            : parentHolder.children[selectedTeethIndex + 1].name
                .split("_")[2]
                .substring(1, 4),
        to:
          isLower === 0
            ? teethKeys[selectedTeethIndex + 1]
            : teethKeys[selectedTeethIndex],
        tooth: selectedTooth,
      });
      newArray[isLower][teethKeys[iprIndex1]][fIndex].amount = newIPRAmount1;
    }

    if (Number.isFinite(newIPRAmount2)) {
      if (isLower === 0 && iprIndex2 < teethKeys.length - 1) {
        AddIPRToLogs(
          logs,
          "added",
          newIPRAmount2,
          newArray[isLower][teethKeys[iprIndex2]][sIndex].amount,
          teethKeys[iprIndex2].substring(1),
          teethKeys[iprIndex2 + 1].substring(1),
        );
      } else if (iprIndex2 > 0) {
        AddIPRToLogs(
          logs,
          "added",
          newIPRAmount2,
          newArray[isLower][teethKeys[iprIndex2]][sIndex].amount,
          teethKeys[iprIndex2].substring(1),
          teethKeys[iprIndex2 - 1].substring(1),
        );
      }
      undoMovementList[undoMovementList.length - 1].push({
        type: 1,
        amount: newIPRAmount2,
        preAmount: newArray[isLower][teethKeys[iprIndex2]][sIndex].amount,
        action: "added",
        actionType: MovementTypes.IPR,
        from:
          isLower === 1
            ? selectedToothNum
            : parentHolder.children[selectedTeethIndex - 1].name
                .split("_")[2]
                .substring(1, 4),
        to:
          isLower === 1
            ? teethKeys[selectedTeethIndex - 1]
            : teethKeys[selectedTeethIndex],
        tooth: selectedTooth,
      });
      newArray[isLower][teethKeys[iprIndex2]][sIndex].amount = newIPRAmount2;
    }
  } else {
    rotateTooth(
      selectedTooth.userData["rightHinge"],
      new THREE.Vector3(0, 1, 0),
      THREE.MathUtils.degToRad(-teethRotationAmount).toFixed(5),
      selectedTooth,
    );
    undoMovementList.pop();
    return;
  }

  setIprOverlays(JSON.parse(JSON.stringify(newArray)));

  undoMovementList[undoMovementList.length - 1].push({
    tooth: selectedTooth,
    rotationPoint: selectedTooth.userData["rightHinge"].clone(),
    teethMovementRotationDirection: new THREE.Vector3(0, 1, 0),
    degrees: THREE.MathUtils.degToRad(-teethRotationAmount).toFixed(5),
    actionType: MovementTypes.RIGHTHINGE,
  });

  // save data in change log
  let key = selectedToothNum.toString() + "_Movement";
  if (logs[key]) {
    const obj = Object.assign({}, logs[key]);

    delete logs[key];

    obj[MovementTypes.RIGHTHINGE] =
      Math.round((obj[MovementTypes.RIGHTHINGE] + teethRotationAmount) * 100) /
      100;
    logs[key] = obj;
  } else {
    logs[key] = {};
    logs[key][MovementTypes.MESIALDISTAL] = 0;
    logs[key][MovementTypes.BUCCALLINGUAL] = 0;
    logs[key][MovementTypes.EXTRUSIONINTRUSION] = 0;
    logs[key][MovementTypes.ROTATION] = 0;
    logs[key][MovementTypes.ANGULATION] = 0;
    logs[key][MovementTypes.INCLINATION] = 0;
    logs[key][MovementTypes.LEFTHINGE] = 0;
    logs[key][MovementTypes.RIGHTHINGE] = teethRotationAmount;
  }
  setLogs(logs);
  setAutoSave();
  clearRedoMovementList();
}
function rightHingeN(
  selectedTooth,
  logs,
  iprOverLays,
  setLogs,
  setIprOverlays,
  setAutoSave,
) {
  if (!newArray || true) {
    newArray = JSON.parse(JSON.stringify(iprOverLays));
  }

  if (
    selectedTooth.userData.hasOwnProperty("isLocked") &&
    selectedTooth.userData["isLocked"]
  ) {
    return;
  }

  let isLower = 1;
  if (selectedTooth.name.charAt(0) === "u") {
    isLower = 0;
  }
  let teethKeys = Object.keys(iprOverLays[isLower]);
  teethKeys.sort(teethKeysSorter);
  RemoveExtractedTeethFromKeys(newArray[isLower], teethKeys);
  let parentHolder = selectedTooth.parent;
  let selectedTeethIndex = parentHolder.children.indexOf(selectedTooth);
  let maxStepNumber = newArray[isLower][teethKeys[0]].length - 1;
  let fIndex = 0,
    sIndex = 0;
  let selectedToothNum = selectedTooth.name.split("_")[2].substring(1, 4);

  let iprIndex1, iprIndex2;

  if (isLower === 0) {
    iprIndex1 = selectedTeethIndex;
    iprIndex2 = selectedTeethIndex - 1;
  } else {
    iprIndex1 = selectedTeethIndex + 1;
    iprIndex2 = selectedTeethIndex;
  }

  for (let r = 0; r < maxStepNumber; r++) {
    if (
      iprIndex1 < teethKeys.length &&
      (newArray[isLower][teethKeys[iprIndex1]][r].step === maxStepNumber ||
        newArray[isLower][teethKeys[iprIndex1]][r].amount === null)
    ) {
      fIndex = r;
      newArray[isLower][teethKeys[iprIndex1]][r].step = maxStepNumber;
      break;
    }
  }
  for (let r = 0; r < maxStepNumber; r++) {
    if (
      iprIndex2 >= 0 &&
      (newArray[isLower][teethKeys[iprIndex2]][r].step === maxStepNumber ||
        newArray[isLower][teethKeys[iprIndex2]][r].amount === null)
    ) {
      sIndex = r;
      newArray[isLower][teethKeys[iprIndex2]][r].step = maxStepNumber;
      break;
    }
  }

  undoMovementList.push([]);
  // auto adjust ipr and spacing before apply movement

  let selectedToothCenter = new THREE.Vector3();
  let box3;

  if (selectedTeethIndex + 1 < teethKeys.length) {
    box3 = new THREE.Box3().setFromObject(
      parentHolder.children[selectedTeethIndex + 1],
    );
    box3.getCenter(selectedToothCenter);
  }

  let selectedToothCenter2 = new THREE.Vector3();
  let box32;
  if (selectedTeethIndex - 1 > 0) {
    box32 = new THREE.Box3().setFromObject(
      parentHolder.children[selectedTeethIndex - 1],
    );
    box32.getCenter(selectedToothCenter2);
  }
  rotateTooth(
    selectedTooth.userData["rightHinge"],
    new THREE.Vector3(0, 1, 0),
    THREE.MathUtils.degToRad(-teethRotationAmount).toFixed(5),
    selectedTooth,
  ); // y axis to right

  let newIPRAmount1 =
      Math.round(
        calculateIPRBetweenTeeth(
          selectedTooth,
          parentHolder.children[iprIndex1 + (isLower ? 0 : 1)],
        ) * 100,
      ) / 100,
    newIPRAmount2 =
      Math.round(
        calculateIPRBetweenTeeth(
          selectedTooth,
          parentHolder.children[iprIndex2 - (isLower ? 1 : 0)],
        ) * 100,
      ) / 100;
  if (
    (Number.isFinite(newIPRAmount1) || Number.isFinite(newIPRAmount2)) &&
    (newIPRAmount1 <= IPRLimit || newIPRAmount2 <= IPRLimit)
  ) {
    if (Number.isFinite(newIPRAmount1)) {
      if (isLower === 0 && iprIndex1 < teethKeys.length - 1) {
        AddIPRToLogs(
          logs,
          "added",
          newIPRAmount1,
          newArray[isLower][teethKeys[iprIndex1]][fIndex].amount,
          teethKeys[iprIndex1].substring(1),
          teethKeys[iprIndex1 + 1].substring(1),
        );
      } else if (iprIndex1 > 0) {
        AddIPRToLogs(
          logs,
          "added",
          newIPRAmount1,
          newArray[isLower][teethKeys[iprIndex1]][fIndex].amount,
          teethKeys[iprIndex1].substring(1),
          teethKeys[iprIndex1 - 1].substring(1),
        );
      }
      undoMovementList[undoMovementList.length - 1].push({
        type: 1,
        amount: newIPRAmount1,
        preAmount: newArray[isLower][teethKeys[iprIndex1]][fIndex].amount,
        action: "added",
        actionType: MovementTypes.IPR,
        from:
          isLower === 0
            ? selectedToothNum
            : parentHolder.children[selectedTeethIndex + 1].name
                .split("_")[2]
                .substring(1, 4),
        to:
          isLower === 0
            ? teethKeys[selectedTeethIndex + 1]
            : teethKeys[selectedTeethIndex],
        tooth: selectedTooth,
      });
      newArray[isLower][teethKeys[iprIndex1]][fIndex].amount = newIPRAmount1;
    }

    if (Number.isFinite(newIPRAmount2)) {
      if (isLower === 0 && iprIndex2 < teethKeys.length - 1) {
        AddIPRToLogs(
          logs,
          "added",
          newIPRAmount2,
          newArray[isLower][teethKeys[iprIndex2]][sIndex].amount,
          teethKeys[iprIndex2].substring(1),
          teethKeys[iprIndex2 + 1].substring(1),
        );
      } else if (iprIndex2 > 0) {
        AddIPRToLogs(
          logs,
          "added",
          newIPRAmount2,
          newArray[isLower][teethKeys[iprIndex2]][sIndex].amount,
          teethKeys[iprIndex2].substring(1),
          teethKeys[iprIndex2 - 1].substring(1),
        );
      }
      undoMovementList[undoMovementList.length - 1].push({
        type: 1,
        amount: newIPRAmount2,
        preAmount: newArray[isLower][teethKeys[iprIndex2]][sIndex].amount,
        action: "added",
        actionType: MovementTypes.IPR,
        from:
          isLower === 1
            ? selectedToothNum
            : parentHolder.children[selectedTeethIndex - 1].name
                .split("_")[2]
                .substring(1, 4),
        to:
          isLower === 1
            ? teethKeys[selectedTeethIndex - 1]
            : teethKeys[selectedTeethIndex],
        tooth: selectedTooth,
      });
      newArray[isLower][teethKeys[iprIndex2]][sIndex].amount = newIPRAmount2;
    }
  } else {
    rotateTooth(
      selectedTooth.userData["rightHinge"],
      new THREE.Vector3(0, 1, 0),
      THREE.MathUtils.degToRad(teethRotationAmount).toFixed(5),
      selectedTooth,
    );
    undoMovementList.pop();
    return;
  }

  setIprOverlays(JSON.parse(JSON.stringify(newArray)));
  undoMovementList[undoMovementList.length - 1].push({
    tooth: selectedTooth,
    rotationPoint: selectedTooth.userData["rightHinge"].clone(),
    teethMovementRotationDirection: new THREE.Vector3(0, 1, 0),
    degrees: THREE.MathUtils.degToRad(teethRotationAmount).toFixed(5),
    actionType: MovementTypes.RIGHTHINGE,
  });

  // save data in change log
  let key = selectedToothNum.toString() + "_Movement";
  if (logs[key]) {
    const obj = Object.assign({}, logs[key]);

    delete logs[key];

    obj[MovementTypes.RIGHTHINGE] =
      Math.round((obj[MovementTypes.RIGHTHINGE] - teethRotationAmount) * 100) /
      100;
    logs[key] = obj;
  } else {
    logs[key] = {};
    logs[key][MovementTypes.MESIALDISTAL] = 0;
    logs[key][MovementTypes.BUCCALLINGUAL] = 0;
    logs[key][MovementTypes.EXTRUSIONINTRUSION] = 0;
    logs[key][MovementTypes.ROTATION] = 0;
    logs[key][MovementTypes.ANGULATION] = 0;
    logs[key][MovementTypes.INCLINATION] = 0;
    logs[key][MovementTypes.LEFTHINGE] = 0;
    logs[key][MovementTypes.RIGHTHINGE] = -teethRotationAmount;
  }
  setLogs(logs);
  setAutoSave();
  clearRedoMovementList();
}
/**
 * (int) isLower (1 , 0)
 * (int) toothNumber
 */
function getToothTotalIPR(isLower, toothKey, iprOverLays) {
  let totalIpr = 0;
  for (let i = 0; i < iprOverLays[isLower][toothKey].length; i++) {
    if (iprOverLays[isLower][toothKey][i].amount === null) {
      break;
    }
    if (
      iprOverLays[isLower][toothKey][i].step !==
      iprOverLays[isLower][toothKey].length - 1
    ) {
      totalIpr = +iprOverLays[isLower][toothKey][i].amount;
    }
  }

  return totalIpr;
}
export function lockTooth(isUndo = false, selectedTooth) {
  //console.log(selectedTooth);
  if (selectedTooth === undefined || selectedTooth === null) {
    console.log("no selected teeth to lock/unlock");
    return;
  }

  if (selectedTooth.userData.hasOwnProperty("isLocked")) {
    if (selectedTooth.userData["isLocked"]) {
      //console.log(2);
      selectedTooth.userData["isLocked"] = false;
      // selectedTooth.material = selectedTeethMaterial;
      // selectedTooth.material.needsUpdate = true;
    } else {
      //console.log(1);
      selectedTooth.userData["isLocked"] = true;
      // selectedTooth.material = lockedTeethMaterial;
      // selectedTooth.material.needsUpdate = true;
    }
  } else {
    //console.log(44);
    selectedTooth.userData["isLocked"] = true;
    // selectedTooth.material = lockedTeethMaterial;
    // selectedTooth.material.needsUpdate = true;
    //console.log(selectedTooth.material);
  }
  if (!isUndo) {
    undoMovementList.push({
      tooth: selectedTooth,
      actionType: MovementTypes.LOCK,
    });
  }
  selectedTooth.material = selectedTooth.userData["isLocked"]
    ? lockedTeethMaterial
    : selectedTeethMaterial;
  clearRedoMovementList();
}

export const toothMovementTypes = [
  {
    svg: <ExtrusionIntrusion />,
    text: "extrusion_intrusion",
    type: "Extrusion/Intrusion",
    callback: moveToothUp,
    callback2: moveToothDown,
  },
  {
    svg: <BuccalLingual />,
    text: "forward_backward",
    type: "Buccal/Lingual",
    callback: moveToothLingual,
    callback2: moveToothBuccal,
  },
  {
    svg: <MesialDistal />,
    text: "left_right",
    type: "Mesial/Distal",
    callback: MoveToothMesially,
    callback2: MoveToothDistally,
  },
  {
    svg: <Rotation />,
    text: "rotation",
    type: "Rotation",
    callback: rotationP,
    callback2: rotationN,
  },
  {
    svg: <MesialHingeRotation />,
    text: "mesial_hinge_rotation",
    type: "LeftHinge",
    callback: leftHingeP,
    callback2: leftHingeN,
  },
  {
    svg: <DistalHingeRotation />,
    text: "distal_hinge_rotation",
    type: "RightHinge",
    callback: rightHingeP,
    callback2: rightHingeN,
  },
  {
    svg: <CrownAngulation />,
    text: "crown_angulation",
    type: "Angulation",
    callback: angulationP,
    callback2: angulationN,
  },
  {
    svg: <Inclination />,
    text: "inclination",
    type: "Inclination",
    callback: inclinationP,
    callback2: inclinationN,
  },
];

export const MovementTypes = {
  MESIALDISTAL: "Mesial/Distal",
  EXTRUSIONINTRUSION: "Extrusion/Intrusion",
  BUCCALLINGUAL: "Buccal/Lingual",
  ROTATION: "Rotation",
  INCLINATION: "Inclination",
  ANGULATION: "Angulation",
  LEFTHINGE: "LeftHinge",
  RIGHTHINGE: "RightHinge",
  LOCK: "Lock",
  IPR: "Ipr",
  ATTACHMENTS: "attachments",
};
export let toothMovementAmount = 0.1;
export let teethRotationAmount = 1;

export default function TeethPositionHelper(props) {
  const {
    toggle,
    selectedTooth,
    setLogs,
    logs,
    isLocked,
    teethMovements,
    setToggle,
    groupRef,
  } = useGlobalStore(state => ({
    selectedTooth: state.controls3D.selectedTooth,
    isLocked: state.controls3D.isLocked,
    setLogs: state.setLogs,
    setToggle: state.setToggle,
    toggle: state.toggle,
    logs: state.logs,
    teethMovements: state.controls3D.teethMovements,
    groupRef: state.refs.groupRef,
  }));

  const {
    callback1,
    callback2,
    value,
    text,
    svg,
    type,
    iprOverLays,
    setIprOverlays,
    renderOcc,
    setAutoSave,
    movementTable,
    parsedMovementTables,
    maxStepNumber,
  } = props;
  const [cashedMT, setCashedMT] = useState(movementTable);
  let selectedToothNum = selectedTooth
    ? selectedTooth?.name?.split("_")[2].substring(1, 4)
    : "";
  let key = selectedToothNum.toString() + "_Movement";
  const { content } = useCustomTranslation();
  let logsKey = selectedToothNum.toString() + "_Movement";
  const stringMapping = {
    "Extrusion/Intrusion": "Extrusion/Intrusion", // *
    Angulation: "Angulation",
    Rotation: "Rotation", // *
    "Mesial/Distal": "Left/Right", // *
    "Buccal/Lingual": "Forward/Backward", // *
    Inclination: "Inclination", // *
    RightHinge: "RightHinge", // *
    LeftHinge: "LeftHinge", // *
  };

  function fixMovementTableKeys(movementTable) {
    for (let step = 0; step < movementTable.length; step++) {
      movementTable[step]["Extrusion/Intrusion"] = (
        " " + movementTable[step]["Extrusion/ Intrusion"]
      ).slice(1);
      movementTable[step]["Left/Right"] = (
        " " + movementTable[step]["Left/ Right"]
      ).slice(1);
      movementTable[step]["Forward/Backward"] = (
        " " + movementTable[step]["Forward/ Backward"]
      ).slice(1);

      delete movementTable[step]["Extrusion/ Intrusion"];
      delete movementTable[step]["Left/ Right"];
      delete movementTable[step]["Forward/ Backward"];
    }
    console.log("once");
    once = false;
  }

  if (once) {
    fixMovementTableKeys(movementTable);
  }

  const getContent = (group, key) => {
    return content(`controls.${group}.${key}`);
  };
  return (
    <div>
      {isLocked && <div className="bounty_disabled"></div>}
      <div
        className={`${
          selectedTooth?.userData["isLocked"] || !selectedTooth
            ? "bounty_controls_disabled"
            : ""
        } mt-2 flex justify-around`}
      >
        <ToolTipToothMovement text={getContent("tooltips", text)} svg={svg} />

        {selectedToothNum ? (
          movementTable.some(
            value =>
              numberingFdi[value["Tooth number"]]["universal"] ===
              Number(selectedToothNum),
          ) ? (
            movementTable
              .map((value, i) => {
                if (
                  numberingFdi[value["Tooth number"]]["universal"] ===
                  Number(selectedToothNum)
                ) {
                  value["RightHinge"] = "--";
                  value["LeftHinge"] = "--";
                  return Object.keys(value).map((key, index) => {
                    if (key === stringMapping[type]) {
                      if (logs[logsKey] && logs?.[logsKey]?.[type]) {
                        const updatedValue =
                          (value[stringMapping[type]]
                            ? value[stringMapping[type]] === "--"
                              ? 0
                              : Number(value[stringMapping[type]].split(" ")[0])
                            : 0) + Number(logs[logsKey][type].toFixed(2));
                        return (
                          <div className="tooth_position_value" key={index}>
                            {updatedValue.toFixed(2)}
                          </div>
                        );
                      }
                      return (
                        <div className="tooth_position_value" key={index}>
                          {!value[stringMapping[type]]
                            ? "--"
                            : value[stringMapping[type]].split(" ")[0]}
                        </div>
                      );
                    }
                    return null;
                  });
                }
                return null;
              })
              .filter(Boolean)
          ) : logs[logsKey] && logs?.[logsKey]?.[type] ? (
            <div className="tooth_position_value">
              {Number(logs[logsKey][type].toFixed(2))}
            </div>
          ) : (
            <div className="tooth_position_value">--</div>
          )
        ) : (
          <div className="tooth_position_value">--</div>
        )}

        <div
          className={
            selectedTooth?.userData["isLocked"] || !selectedTooth
              ? ""
              : "bounty_minus"
          }
          style={{
            cursor:
              selectedTooth?.userData["isLocked"] || !selectedTooth
                ? "not-allowed"
                : "pointer",
          }}
          onClick={() => {
            selectedTooth?.userData["isLocked"] || !selectedTooth
              ? null
              : callback1(
                  selectedTooth,
                  logs,
                  iprOverLays,
                  setLogs,
                  setIprOverlays,
                  setAutoSave,
                ),
              renderOcc();
          }}
        >
          <Minus />
        </div>
        <div
          className={
            selectedTooth?.userData["isLocked"] || !selectedTooth
              ? ""
              : "bounty_plus"
          }
          style={{
            cursor:
              selectedTooth?.userData["isLocked"] || !selectedTooth
                ? "not-allowed"
                : "pointer",
          }}
          // onPointerDown={() => {
          //   if(toggle.occ){

          //     setToggle("occ", false);
          //   }
          // }}
          // onPointerUp={() => {
          //   setToggle("occ", true);
          // }}
          onClick={() => {
            selectedTooth?.userData["isLocked"] || !selectedTooth
              ? null
              : callback2(
                  selectedTooth,
                  logs,
                  iprOverLays,
                  setLogs,
                  setIprOverlays,
                  setAutoSave,
                ),
              renderOcc();
          }}
        >
          <Plus />
        </div>
      </div>
    </div>
  );
}
