import React from "react";
import { ReactComponent as EyeRefreshIcon } from "../../assets/icons/eye-refresh.svg";
import { useCustomTranslation } from "@/hooks/useTranslation";

let isResetDone = false;
let isRedoDone = true;
export const ShowOriginal = ({ reset, showOriginalRemovedHover }) => {
  const { content } = useCustomTranslation();

  const getContent = (group, key) => {
    return content(`controls.${group}.${key}`);
  };
  const handleOnMouseOver = () => {
    if (!isResetDone && isRedoDone) {
      reset(true);
      isResetDone = true;
      isRedoDone = false;
    }
  };
  const handleOnMouseOut = () => {
    if (isResetDone && !isRedoDone) {
      showOriginalRemovedHover();
      isResetDone = false;
      isRedoDone = true;
    } else {
      setTimeout(() => {
        handleOnMouseOut;
      }, 1000);
    }
  };

  return (
    <button
      className="  mb-2 grid w-[258px]  grid-cols-[1fr,auto] bg-white px-4 py-4"
      onMouseEnter={handleOnMouseOver}
      onMouseLeave={handleOnMouseOut}
    >
      <div className="text-base font-medium text-start">
        {getContent("actions", "show_original")}{" "}
      </div>
      <EyeRefreshIcon />
    </button>
  );
};
