import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import {
  MovementList,
  undoMovementList,
  redoMovementList,
  calculateIPRToothForNegation,
  clearRedoMovementList,
  MovementTypes,
} from "./TeethPositionHelper";
import * as THREE from "three";
import {
  teethMaterial,
  selectedTeethMaterial,
  lockedTeethMaterial,
} from "../Mesh";
import { getParams } from "@/helpers/getParams";
let previousSelectedTooth;
export const attachmentMaterial = new THREE.MeshPhysicalMaterial({
  color: 0x89cff0,
  roughness: 0,
  transparent: true,
});
export const names = [
  "att_hbra",
  "att_vbra",
  "att_oblique",
  "att_extrusion",
  "att_biolane",
  "att_root_control",
  "att_rotational",
];
/**
 * sorted in same orders as in (names) array
 */
export let attachmentsObjects = [];

const demoTS = getParams().demoTS === "true" ? true : false;

export function selectTooth(
  event,
  camera,
  raycaster,
  pointer,
  list,
  teethMaterial,
  scene,
  center,
  setControls3D,
  selectedTooth,
  selectedAttachmentType,
  selectedAttachment,
  numberingOverLays,
  GLBData,
  model,
  setModel,
  groupRef,
  showUpper,
  showLower,
  logs,
  setLogs,
  setScene,
  attachmentArray,
  setAutoSave,
) {
  setScene(scene);
  pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
  pointer.y = -(event.clientY / window.innerHeight) * 2 + 1;
  if (event.button === 0) {
    raycaster.setFromCamera(pointer, camera.current);
    const intersects = raycaster.intersectObjects(list, false);
    let mesial;
    if (intersects.length > 0) {
      const box3 = new THREE.Box3().setFromObject(intersects[0].object);
      const point = new THREE.Vector3();
      //console.log(box3.getCenter(point));
      if (box3.getCenter(point).x < 0) {
        mesial = true;
      } else {
        mesial = false;
      }
    }
    //console.log(intersects[0].object.name.includes("u"), "UwU rar XD");
    onPointerMove(
      event,
      camera,
      raycaster,
      pointer,
      list,
      teethMaterial,
      scene,
      center,
      setControls3D,
      selectedTooth,
      selectedAttachmentType,
      selectedAttachment,
      mesial,
      GLBData,
      model,
      setModel,
      groupRef,
      logs,
      setLogs,
      attachmentArray,
      setAutoSave,
    );
  }
}

// teeth selection
export function onPointerMove(
  event,
  camera,
  raycaster,
  pointer,
  list,
  teethMaterial,
  scene,
  center,
  setControls3D,
  selectedTooth,
  selectedAttachmentType,
  selectedAttachment,
  mesial,
  GLBData,
  model,
  setModel,
  groupRef,
  logs,
  setLogs,
  attachmentArray,
  setAutoSave,
) {
  // calculate pointer position in normalized device coordinates
  // (-1 to +1) for both components
  pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
  pointer.y = -(event.clientY / window.innerHeight) * 2 + 1;

  raycaster.setFromCamera(pointer, camera.current);
  let activeList = [];
  list.forEach(item => {
    if (item.parent.visible) activeList.push(item);
  });
  const intersects = raycaster.intersectObjects(activeList, false);
  const rootControl = new THREE.Group();
  rootControl.name = "root_att";
  let tempName = "";

  if (intersects.length > 0) {
    tempName = intersects[0].object.name;

    if (intersects[0].object.children[0].children.length > 1) {
      intersects[0].object.children[0].children.forEach(attachment => {
        rootControl.attach(attachment);
      });
      intersects[0].object.children[0].attach(rootControl);
    }

    if (intersects[0].object.name.charAt(0) === "u") {
      setControls3D("isUpper", true);
    } else {
      setControls3D("isUpper", false);
    }
    // if (previousSelectedTooth?.name != intersects[0].object.name) {
    // disableToothAnchor(previousSelectedTooth);
    // enableToothAnchor(intersects[0].object);
    // }

    // replace materials for tooth
    if (previousSelectedTooth) {
      previousSelectedTooth.material = previousSelectedTooth?.userData[
        "isLocked"
      ]
        ? lockedTeethMaterial
        : teethMaterial;
    }
    intersects[0].object.material = intersects[0].object?.userData["isLocked"]
      ? lockedTeethMaterial
      : selectedTeethMaterial;
    setControls3D("selectedTooth", intersects[0].object);
    previousSelectedTooth = intersects[0].object;
    calculateIPRToothForNegation(previousSelectedTooth, 1);
    calculateIPRToothForNegation(previousSelectedTooth, -1);

    if (tempName !== selectedTooth?.name) {
      //console.log(intersects[0].object);
      if (names.includes(intersects[0].object?.children?.[0]?.name)) {
        setControls3D(
          "selectedAttachmentType",
          intersects[0].object?.children?.[0]?.children?.[0]?.name,
        );
      } else {
        setControls3D("selectedAttachmentType", "");
      }
      // // Newly selected tooth's name is different from the old selected tooth's name
      // setControls3D("selectedAttachmentType", "");
      return;
    }
    if (intersects[0].object.children[0].children.length > 0) {
      for (
        let i = 0;
        i < intersects[0].object.children[0].children.length;
        i++
      ) {
        if (
          intersects[0].object.children[0].children[i].name.includes("att") &&
          selectedAttachmentType
          // intersects[0].object.children[0].name === selectedAttachmentType
        ) {
          const tooth = intersects[0].object;
          let selectedToothNum = tooth.name.split("_")[2].substring(1, 4);
          let action;
          if (attachmentArray[selectedToothNum]?.length > 0) {
            action = "replace";
          } else {
            action = "add";
          }

          let key = selectedToothNum.toString() + "_Movement";
          if (logs[key]) {
            const obj = Object.assign({}, logs[key]);
            delete logs[key];
            obj["attachment"] = {
              action: action,
              type: selectedAttachmentType,
            };
            logs[key] = obj;
          } else {
            logs[key] = {};
            logs[key]["attachment"] = {
              action: action,
              type: selectedAttachmentType,
            };
            logs[key][MovementTypes.MESIALDISTAL] = 0;
            logs[key][MovementTypes.BUCCALLINGUAL] = 0;
            logs[key][MovementTypes.EXTRUSIONINTRUSION] = 0;
            logs[key][MovementTypes.ROTATION] = 0;
            logs[key][MovementTypes.ANGULATION] = 0;
            logs[key][MovementTypes.INCLINATION] = 0;
            logs[key][MovementTypes.LEFTHINGE] = 0;
            logs[key][MovementTypes.RIGHTHINGE] = 0;
          }
          setLogs(logs);

          undoMovementList.push({
            actionType: "attachments",
            tooth: intersects[0].object,
            action: "move",
            attachmentName: intersects[0].object.children[0].children[i].name,
            relativePosition:
              intersects[0].object.children[0].children[i].position.clone(),
            relativeRotation:
              intersects[0].object.children[0].children[i].rotation.clone(),
          });
          setAutoSave();
          deleteAttachmentFromTooth(intersects[0].object, logs, setLogs, false);
          addAttachmentToTooth(
            names.indexOf(selectedAttachmentType),
            intersects,
          );
          clearRedoMovementList();
          return;
        }
      }
      return;
    } else {
      let tooth = intersects[0].object;
      if (selectedAttachmentType && tooth.name === tempName) {
        // add attachment to a tooth without attachment
        addAttachmentToTooth(names.indexOf(selectedAttachmentType), intersects);
        undoMovementList.push({
          actionType: "attachments",
          tooth: tooth,
          attachmentName: tooth.children[0].children[0].name,
          relativePosition: tooth.children[0].children[0].position.clone(),
          relativeRotation: tooth.children[0].children[0].rotation.clone(),
          action: "add",
        });
        let selectedToothNum = tooth.name.split("_")[2].substring(1, 4);
        let action;
        if (attachmentArray[selectedToothNum]?.length > 0) {
          action = "replace";
        } else {
          action = "add";
        }

        let key = selectedToothNum.toString() + "_Movement";
        if (logs[key]) {
          const obj = Object.assign({}, logs[key]);
          delete logs[key];
          obj["attachment"] = {
            action: action,
            type: selectedAttachmentType,
          };
          logs[key] = obj;
        } else {
          logs[key] = {};
          logs[key]["attachment"] = {
            action: action,
            type: selectedAttachmentType,
          };
          logs[key][MovementTypes.MESIALDISTAL] = 0;
          logs[key][MovementTypes.BUCCALLINGUAL] = 0;
          logs[key][MovementTypes.EXTRUSIONINTRUSION] = 0;
          logs[key][MovementTypes.ROTATION] = 0;
          logs[key][MovementTypes.ANGULATION] = 0;
          logs[key][MovementTypes.INCLINATION] = 0;
          logs[key][MovementTypes.LEFTHINGE] = 0;
          logs[key][MovementTypes.RIGHTHINGE] = 0;
        }
        setLogs(logs);
        setAutoSave();
        clearRedoMovementList();
        return;
      }
    }
  }
}
export function addRemoveAttachment(scene, camera, group, center) {
  const loader = new GLTFLoader();
  Promise.all([
    loader.loadAsync("att_hbra.glb"),
    loader.loadAsync("att_vbra.glb"),
    loader.loadAsync("att_oblique.glb"),
    loader.loadAsync("att_extrusion.glb"),
    loader.loadAsync("att_biolane.glb"),
    loader.loadAsync("att_root_control.glb"),
    loader.loadAsync("att_rotational.glb"),
  ])
    .then(results => {
      results.forEach((model, i) => {
        model.scene.children[0].name = names[i];
        attachmentsObjects.push(model.scene.children[0]);
        // modelA.scene.children[0].scale.multiply(new THREE.Vector3(1, 1, -1));
        model.scene.children[0].material.transparent = true;
        model.scene.children[0].material.opacity = 0;
        model.scene.children[0].visible = false;
        // model.scene.children[0].position.copy(center);
        scene.add(model.scene.children[0]);
      });
    })
    .catch(err => {
      console.log(err);
    });
}
function disableToothAnchor(tooth) {
  console.log(tooth, "off");
  if (!tooth) {
    return;
  }
  for (let i = 0; i < tooth.children[3].children.length; i++) {
    if (tooth.children[3].children[i].name.includes("root")) {
      tooth.children[3].children[i].visible = false;
      console.log("Rooty off");
    }
  }
}
function enableToothAnchor(tooth) {
  for (let i = 0; i < tooth.children[3].children.length; i++) {
    if (tooth.children[3].children[i].name.includes("root")) {
      tooth.children[3].children[i].visible = true;
      console.log("Rooty on");
    }
  }
}
function addAttachmentToTooth(attachmentIndex, clickRayCast) {
  let selectedTooth = clickRayCast[0].object;
  const attachment = attachmentsObjects[attachmentIndex].clone();
  selectedTooth.children[0].add(attachment);
  let permaVector = selectedTooth.worldToLocal(clickRayCast[0].point.clone());
  attachment.position.copy(permaVector);
  attachment.lookAt(clickRayCast[0].point.sub(clickRayCast[0].face.normal));
  attachment.material = attachmentMaterial;
  attachment.visible = true;
}

export function deleteAttachmentFromTooth(
  tooth,
  logs,
  setLogs,
  saveAction = true,
) {
  for (let i = 0; i < tooth.children[0].children.length; i++) {
    if (tooth.children[0].children[i].name.includes("att")) {
      if (saveAction) {
        undoMovementList.push({
          actionType: "attachments",
          tooth: tooth,
          action: "remove",
          attachmentName: tooth.children[0].children[i].name,
          relativePosition: tooth.children[0].children[i].position.clone(),
          relativeRotation: tooth.children[0].children[i].rotation.clone(),
        });
      }
      return tooth.children[0].children[i].removeFromParent();
    }
  }
}
