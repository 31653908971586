import React, { useState } from "react";
import { useGlobalStore } from "@/store";
import { useCustomTranslation } from "@/hooks/useTranslation";
import LogoutModal from "../auth/LogoutModal";

function Header() {
  const { startRevision, setToggle } = useGlobalStore(state => ({
    startRevision: state.toggle.startRevision,
    setToggle: state.setToggle,
  }));
  const [showModal, setShowModal] = useState(false);

  const { content } = useCustomTranslation();

  const getContent = (group, key) => {
    return content(`other.${group}.${key}`);
  };

  const isTranslator = JSON.parse(process.env.REACT_APP_TRANSLATOR);

  return (
    <div className="tp_header">
      <div style={{ gridColumn: "1" }}></div>
      <div className="tp_details">
        <div>{` Demo Patient / ${getContent("labels", "version")} 1 - #12345678DP.DEMO`}</div>
        <div>
          <span className="ts-dot mr-[8px] inline-flex bg-[#898a8d]"></span>
          <span>{getContent("labels", "pending_doctor_approval")}</span>
        </div>
      </div>
      <div className="btns">
        <button
          onClick={() => {
            startRevision ? null : setToggle("startRevision");
          }}
          className="btn_1"
        >
          {getContent("actions", "revise_treatment")}
        </button>
        <button
          onClick={() => {
            setToggle("approveDemo");
          }}
          className="btn_2"
        >
          {getContent("actions", "approve_treatment")}
        </button>
        {isTranslator && (
          <button
            onClick={() => {
              setShowModal(!showModal);
            }}
            className="btn_2 border-[#1c64f2] bg-[#1c64f2]"
          >
            Logout
          </button>
        )}
      </div>

      {showModal && <LogoutModal showModal={showModal} setShowModal={setShowModal}/>}
    </div>
  );
}

export default Header;
