import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import URR from "./URR";
import "./teethControls.scss";
import TeethPosition from "./TeethPosition";
import AttachmentSelector, { attachmentTypes } from "./AttachmentSelector";
import IPRSelector from "./IPRSelector";
import ReviseTreatment from "../ReviseTreatment";
import { useGlobalStore } from "../../../store";

import { ShowOriginal } from "../ShowOriginal";
import { attachmentMaterial as oldAttachmentMaterial } from "../Mesh";
import {
  MovementList,
  undoMovementList,
  redoMovementList,
  setRedoList,
  MovementTypes,
  toothMovementAmount,
  teethRotationAmount,
  AddIPRToLogs,
} from "./TeethPositionHelper";
import { attachmentsObjects, attachmentMaterial } from "./addRemoveAttachment";
import { rotateTooth } from "./TeethPositionHelper";
import { lockTooth } from "./TeethPositionHelper";

import { useQuery } from "@tanstack/react-query";
import { apiResolver, getDoctorConfig } from "@/helpers/apiResolver";
import moment from "moment";
import { hotjar } from "react-hotjar";
import { getParams } from "@/helpers/getParams";

let showOriginalLength = 0;
let IPRCopyArray = [];
let group = {};
export const prepareRedoListToExport = redoList => {
  for (let i = 0; i < redoList.length; i++) {
    if (Array.isArray(redoList[i])) {
      for (let t = 0; t < redoList[i].length; t++) {
        redoList[i][t].tooth = redoList[i][t]?.tooth.name;
      }
    } else {
      redoList[i].tooth = redoList[i].tooth.name;
    }
  }
  return redoList;
};
export const prepareRedoListToAutoSave = redoList => {
  const t = Date.now();
  if (Array.isArray(redoList[0])) {
    if (typeof redoList[0][0].tooth == "string") {
      console.log(redoList, "FAAAAAAAAAAAAIL");
      return;
    }
    group.current = redoList[0][0].tooth.parent.parent;
  } else {
    group.current = redoList[0].tooth.parent.parent;
  }

  for (let i = 0; i < redoList.length; i++) {
    if (Array.isArray(redoList[i])) {
      for (let t = 0; t < redoList[i].length; t++) {
        if (!redoList[i][t]?.tooth.name) {
          continue;
        }
        redoList[i][t].tooth = redoList[i][t]?.tooth.name;
      }
    } else {
      redoList[i].tooth = redoList[i].tooth.name;
    }
  }
  const redoListCopy = JSON.parse(JSON.stringify(redoList));
  return redoListCopy;
};

const TeethControls = forwardRef(
  (
    {
      startRevision,
      dentalNotation,
      maxStepNumber,
      currentStep,
      tpLoaded,
      GLBData,
      iprOverLays,
      setIprOverlays,
      movementTable,
      parsedMovementTables,
      model,
    },
    ref,
  ) => {
    const demoTS = getParams().demoTS === "true" ? true : false;
    const { data } = demoTS
      ? true
      : useQuery({
          queryKey: ["treatmentSetupInfo"],
          queryFn: apiResolver,
          staleTime: 600000, // Cache data for 60 seconds (1 minute)
        });

    const withPatientInfo =
      getParams().withPatientInfo === "true" ? true : false;
    const isViewerControlsEnabled = data?.isViewerControlsEnabled;
    const {
      setControls3D,
      show3DControls,
      setToggle,
      logs,
      setLogs,
      scene,
      attachmentArray,
    } = useGlobalStore(state => ({
      show3DControls: state.toggle.show3DControls,
      setToggle: state.setToggle,
      logs: state.logs,
      setLogs: state.setLogs,
      scene: state.scene,
      attachmentArray: state.controls3D.attachmentArray,
      setControls3D: state.setControls3D,
    }));

    // Object.values(GLBData[0].lower?.gum).length > 0 &&
    // Object.values(GLBData[0].upper?.gum).length > 0;

    function reset(isShowOriginal = false, ignoreIPR = false) {
      let counter = 0;
      let newArray = JSON.parse(JSON.stringify(iprOverLays));
      while (undoMovementList.length !== 0) {
        let isArray = Array.isArray(
          undoMovementList[undoMovementList.length - 1],
        );
        const t = undoMovementList.pop();
        counter++;
        if (!isArray) {
          if (t.movement) {
            t.tooth.position.add(t.movement);
            reflectToChangelog(t);
          } else if (t.degrees) {
            rotateTooth(
              t.rotationPoint,
              t.teethMovementRotationDirection,
              t.degrees,
              t.tooth,
            );
            reflectToChangelog(t);
            // rotateAroundPoint(t.tooth, t.rotationPoint, t.teethMovementRotationDirection, t.degrees, true)
          } else if (t.actionType === MovementTypes.LOCK) {
            setControls3D("selectedTooth", t.tooth);
            lockTooth(true, t.tooth);
          } else if (t.actionType === MovementTypes.ATTACHMENTS) {
            if (t.action === "add") {
              for (let i = 0; i < t.tooth.children[0].children.length; i++) {
                if (t.tooth.children[0].children[i].name.includes("att")) {
                  redoMovementList.push({
                    tooth: t.tooth,
                    action: t.action,
                    actionType: t.actionType,
                    attachmentName: t.tooth.children[0].children[i].name,
                    relativePosition:
                      t.tooth.children[0].children[i].position.clone(),
                    relativeRotation:
                      t.tooth.children[0].children[i].rotation.clone(),
                  });

                  let selectedToothNum = t.tooth.name
                    .split("_")[2]
                    .substring(1, 4);
                  let action;
                  if (attachmentArray[selectedToothNum]?.length > 0) {
                    action = "remove";
                  } else {
                    action = "";
                  }

                  let key = selectedToothNum.toString() + "_Movement";
                  if (logs[key]) {
                    const obj = Object.assign({}, logs[key]);
                    delete logs[key];
                    obj["attachment"] = {
                      action: action,
                      type: t.tooth.children[0].children[i].name,
                    };
                    logs[key] = obj;
                  } else {
                    logs[key] = {};
                    logs[key]["attachment"] = {
                      action: action,
                      type: t.tooth.children[0].children[i].name,
                    };
                  }
                  setLogs(logs);
                  t.tooth.children[0].children[i].removeFromParent();
                }
              }
            } else if (t.action === "remove") {
              let attachment;
              for (let i = 0; i < attachmentsObjects.length; i++) {
                if (attachmentsObjects[i].name === t.attachmentName) {
                  attachment = attachmentsObjects[i].clone();
                }
              }
              if (!attachment) {
                // old attachment
                attachment =
                  attachmentArray[
                    t.tooth.name.split("_")[2].substring(1)
                  ][0].clone();
                attachment.material = oldAttachmentMaterial;
              } else {
                attachment.material = attachmentMaterial;
              }
              t.tooth.children[0].add(attachment);
              attachment.position.copy(t.relativePosition);
              attachment.rotation.copy(t.relativeRotation);
              attachment.visible = true;

              redoMovementList.push({
                tooth: t.tooth,
                action: t.action,
                actionType: t.actionType,
              });

              let selectedToothNum = t.tooth.name.split("_")[2].substring(1, 4);
              let action;
              if (attachmentArray[selectedToothNum]?.length > 0) {
                action = "";
              } else {
                action = "add";
              }

              let key = selectedToothNum.toString() + "_Movement";
              if (logs[key]) {
                const obj = Object.assign({}, logs[key]);
                delete logs[key];
                obj["attachment"] = {
                  action: action,
                  type: attachment.name,
                };
                logs[key] = obj;
              } else {
                logs[key] = {};
                logs[key]["attachment"] = {
                  action: action,
                  type: attachment.name,
                };
              }
              setLogs(logs);
            } else if (t.action === "move") {
              for (let i = 0; i < t.tooth.children[0].children.length; i++) {
                if (t.tooth.children[0].children[i].name.includes("att")) {
                  redoMovementList.push({
                    tooth: t.tooth,
                    action: t.action,
                    actionType: t.actionType,
                    attachmentName: t.tooth.children[0].children[i].name,
                    relativePosition:
                      t.tooth.children[0].children[i].position.clone(),
                    relativeRotation:
                      t.tooth.children[0].children[i].rotation.clone(),
                  });
                  t.tooth.children[0].children[i].removeFromParent();
                }

                let attachment;
                let isExistingAttachment = false;
                for (let i = 0; i < attachmentsObjects.length; i++) {
                  if (attachmentsObjects[i].name === t.attachmentName) {
                    attachment = attachmentsObjects[i].clone();
                  }
                }
                if (!attachment) {
                  // old attachment
                  isExistingAttachment = true;
                  attachment =
                    attachmentArray[
                      t.tooth.name.split("_")[2].substring(1)
                    ][0].clone();
                  attachment.material = oldAttachmentMaterial;
                } else {
                  attachment.material = attachmentMaterial;
                }
                t.tooth.children[0].add(attachment);
                attachment.position.copy(t.relativePosition);
                attachment.rotation.copy(t.relativeRotation);
                attachment.visible = true;

                let selectedToothNum = t.tooth.name
                  .split("_")[2]
                  .substring(1, 4);
                let action;
                if (attachmentArray[selectedToothNum]?.length > 0) {
                  if (isExistingAttachment) {
                    action = "";
                  } else {
                    action = "replace";
                  }
                } else {
                  action = "add";
                }

                let key = selectedToothNum.toString() + "_Movement";
                if (logs[key]) {
                  const obj = Object.assign({}, logs[key]);
                  delete logs[key];
                  obj["attachment"] = {
                    action: action,
                    type: attachment.name,
                  };
                  logs[key] = obj;
                } else {
                  logs[key] = {};
                  logs[key]["attachment"] = {
                    action: action,
                    type: attachment.name,
                  };
                }
                setLogs(logs);
              }
            }
          }
          if (t.actionType !== MovementTypes.ATTACHMENTS) {
            redoMovementList.push(t);
          }
        } else {
          let len = t.length - 1;
          redoMovementList.push([]);
          let r;
          for (let i = 0; i <= len; i++) {
            r = t[i];
            if (r.movement) {
              r.tooth.position.add(r.movement);
              reflectToChangelog(r);
            } else if (r.degrees) {
              rotateTooth(
                r.rotationPoint,
                r.teethMovementRotationDirection,
                r.degrees,
                r.tooth,
              );
              reflectToChangelog(r);
              // rotateAroundPoint(t.tooth, t.rotationPoint, t.teethMovementRotationDirection, t.degrees, true)
            } else if (r.actionType === MovementTypes.LOCK) {
              setControls3D("selectedTooth", r.tooth);
              lockTooth(true, r.tooth);
            } else if (r.actionType === MovementTypes.IPR) {
              if (r.from < 17) {
                for (let i = 0; i < newArray[0]["t" + r.from].length; i++) {
                  if (
                    newArray[0]["t" + r.from][i].step ===
                    newArray[0]["t" + r.from].length - 1
                  ) {
                    newArray[0]["t" + r.from][i].amount =
                      newArray[0]["t" + r.from][i].amount -
                      (r.amount - r.preAmount);
                  }
                }
              } else {
                for (let i = 0; i < newArray[1]["t" + r.from].length; i++) {
                  if (
                    newArray[1]["t" + r.from][i].step ===
                    newArray[1]["t" + r.from].length - 1
                  ) {
                    console.log("setting changed");
                    newArray[1]["t" + r.from][i].amount =
                      newArray[1]["t" + r.from][i].amount -
                      (r.amount - r.preAmount);
                  }
                }
              }
              reflectToChangelog(r);
            }
            redoMovementList[redoMovementList.length - 1].push(r);
          }
        }
      }
      if (isShowOriginal) {
        showOriginalLength = counter;
        IPRCopyArray = newArray;
      } else {
        isShowOriginal = 0;
      }
      if (!ignoreIPR) {
        setIprOverlays(newArray);
      }
    }
    function redo(doesReturn = false, old, ignoreIPR = false) {
      let newArray;
      if (old) {
        newArray = JSON.parse(JSON.stringify(old));
      } else {
        newArray = JSON.parse(JSON.stringify(iprOverLays));
      }
      let isArray = false;
      if (redoMovementList.length > 0) {
        isArray = Array.isArray(redoMovementList[redoMovementList.length - 1]);
      }
      let movementType, toothNum, arrLen;
      if (!isArray) {
        movementType =
          redoMovementList[redoMovementList.length - 1]?.actionType;

        toothNum = redoMovementList[redoMovementList.length - 1]?.tooth.name
          .split("_")[2]
          .substring(1, 4);
      } else {
        arrLen = redoMovementList[redoMovementList.length - 1].length - 1;
        movementType =
          redoMovementList[redoMovementList.length - 1][arrLen].actionType;
        toothNum = redoMovementList[redoMovementList.length - 1][
          arrLen
        ].tooth.name
          .split("_")[2]
          .substring(1, 4);
      }

      while (true) {
        if (!isArray) {
          if (redoMovementList.length === 0) {
            showOriginalLength = -1;
            return;
          }
          if (
            movementType !==
              redoMovementList[redoMovementList.length - 1].actionType ||
            toothNum !==
              redoMovementList[redoMovementList.length - 1].tooth.name
                .split("_")[2]
                .substring(1, 4)
          ) {
            break;
          }
          const t = redoMovementList.pop();

          showOriginalLength--;
          if (t.movement) {
            t.tooth.position.sub(t.movement);
            reflectToChangelog(t, -1);
          } else if (t.degrees) {
            rotateTooth(
              t.rotationPoint,
              t.teethMovementRotationDirection,
              -t.degrees,
              t.tooth,
              null,
              isComparingRedo,
            );
            reflectToChangelog(t, -1);
            // rotateAroundPoint(t.tooth, t.rotationPoint, t.teethMovementRotationDirection, t.degrees, true)
          } else if (t.actionType === MovementTypes.ATTACHMENTS) {
            if (t.action === "add") {
              let attachment;
              for (let i = 0; i < attachmentsObjects.length; i++) {
                if (attachmentsObjects[i].name === t.attachmentName) {
                  attachment = attachmentsObjects[i].clone();
                }
              }
              if (!isComparingRedo) {
                t.tooth.children[0].add(attachment);
              } else {
                t.tooth.add(attachment);
              }
              attachment.position.copy(t.relativePosition);
              attachment.rotation.copy(t.relativeRotation);

              attachment.visible = true;
              attachment.material = attachmentMaterial;
              undoMovementList.push({
                tooth: t.tooth,
                action: t.action,
                actionType: t.actionType,
              });

              let selectedToothNum = t.tooth.name.split("_")[2].substring(1, 4);
              let action;
              if (attachmentArray[selectedToothNum]?.length > 0) {
                action = "replace";
              } else {
                action = "add";
              }

              let key = selectedToothNum.toString() + "_Movement";
              if (logs[key]) {
                const obj = Object.assign({}, logs[key]);
                delete logs[key];
                obj["attachment"] = {
                  action: action,
                  type: attachment.name,
                };
                logs[key] = obj;
              } else {
                logs[key] = {};
                logs[key]["attachment"] = {
                  action: action,
                  type: attachment.name,
                };
              }
              setLogs(logs);
            } else if (t.action === "remove") {
              if (!isComparingRedo) {
                for (let i = 0; i < t.tooth.children[0].children.length; i++) {
                  if (t.tooth.children[0].children[i].name.includes("att")) {
                    undoMovementList.push({
                      tooth: t.tooth,
                      action: t.action,
                      actionType: t.actionType,
                      attachmentName: t.tooth.children[0].children[i].name,
                      relativePosition:
                        t.tooth.children[0].children[i].position.clone(),
                      relativeRotation:
                        t.tooth.children[0].children[i].rotation.clone(),
                    });

                    let selectedToothNum = t.tooth.name
                      .split("_")[2]
                      .substring(1, 4);
                    let action;
                    if (attachmentArray[selectedToothNum]?.length > 0) {
                      action = "remove";
                    } else {
                      action = "";
                    }

                    let key = selectedToothNum.toString() + "_Movement";
                    if (logs[key]) {
                      const obj = Object.assign({}, logs[key]);
                      delete logs[key];
                      obj["attachment"] = {
                        action: action,
                        type: t.tooth.children[0].children[i].name,
                      };
                      logs[key] = obj;
                    } else {
                      logs[key] = {};
                      logs[key]["attachment"] = {
                        action: action,
                        type: t.tooth.children[0].children[i].name,
                      };
                    }
                    setLogs(logs);
                    t.tooth.children[0].children[i].removeFromParent();
                  }
                }
              } else {
                for (let i = 0; i < t.tooth.children.length; i++) {
                  t.tooth.children[i].removeFromParent();
                }
              }
            } else if (t.action === "move") {
              if (!isComparingRedo) {
                for (let i = 0; i < t.tooth.children[0].children.length; i++) {
                  if (t.tooth.children[0].children[i].name.includes("att")) {
                    undoMovementList.push({
                      tooth: t.tooth,
                      action: t.action,
                      actionType: t.actionType,
                      attachmentName: t.tooth.children[0].children[i].name,
                      relativePosition:
                        t.tooth.children[0].children[i].position.clone(),
                      relativeRotation:
                        t.tooth.children[0].children[i].rotation.clone(),
                    });
                    t.tooth.children[0].children[i].removeFromParent();
                  }

                  let attachment;
                  let isExistingAttachment = false;

                  for (let i = 0; i < attachmentsObjects.length; i++) {
                    if (attachmentsObjects[i].name === t.attachmentName) {
                      attachment = attachmentsObjects[i].clone();
                    }
                  }
                  if (!attachment) {
                    // old attachment
                    isExistingAttachment = true;
                    attachment =
                      attachmentArray[
                        t.tooth.name.split("_")[2].substring(1)
                      ][0].clone();
                    attachment.material = oldAttachmentMaterial;
                  } else {
                    attachment.material = attachmentMaterial;
                  }
                  t.tooth.children[0].add(attachment);
                  attachment.position.copy(t.relativePosition);
                  attachment.rotation.copy(t.relativeRotation);
                  attachment.visible = true;

                  let selectedToothNum = t.tooth.name
                    .split("_")[2]
                    .substring(1, 4);
                  let action;
                  if (attachmentArray[selectedToothNum]?.length > 0) {
                    if (isExistingAttachment) {
                      action = "";
                    } else {
                      action = "replace";
                    }
                  } else {
                    action = "add";
                  }

                  let key = selectedToothNum.toString() + "_Movement";
                  if (logs[key]) {
                    const obj = Object.assign({}, logs[key]);
                    delete logs[key];
                    obj["attachment"] = {
                      action: action,
                      type: attachment.name,
                    };
                    logs[key] = obj;
                  } else {
                    logs[key] = {};
                    logs[key]["attachment"] = {
                      action: action,
                      type: attachment.name,
                    };
                  }
                  setLogs(logs);
                }
              } else {
                for (let i = 0; i < t.tooth.children.length; i++) {
                  t.tooth.children[i].removeFromParent();
                }
                let attachment;
                let isExistingAttachment = false;

                for (let i = 0; i < attachmentsObjects.length; i++) {
                  if (attachmentsObjects[i].name === t.attachmentName) {
                    attachment = attachmentsObjects[i].clone();
                  }
                }
                if (!attachment) {
                  // old attachment
                  isExistingAttachment = true;
                  attachment =
                    attachmentArray[
                      t.tooth.name.split("_")[2].substring(1)
                    ][0].clone();
                  attachment.material = oldAttachmentMaterial;
                } else {
                  attachment.material = attachmentMaterial;
                }
                t.tooth.add(attachment);
                attachment.position.copy(t.relativePosition);
                attachment.rotation.copy(t.relativeRotation);
                attachment.visible = true;
              }
            }
            if (doesReturn) {
              return newArray;
            } else {
              return;
            }
          } else if (t.actionType === MovementTypes.LOCK) {
            setControls3D("selectedTooth", t.tooth);
            lockTooth(true, t.tooth);
          }
          undoMovementList.push(t);
          break;
        } else {
          let len = redoMovementList[redoMovementList.length - 1]?.length - 1;
          if (
            redoMovementList.length === 0 ||
            movementType !==
              redoMovementList[redoMovementList.length - 1][len]?.actionType ||
            toothNum !==
              redoMovementList[redoMovementList.length - 1][len]?.tooth.name
                .split("_")[2]
                .substring(1, 4)
          ) {
            break;
          }
          const r = redoMovementList.pop();
          showOriginalLength--;
          undoMovementList.push([]);
          let t;
          for (let i = 0; i <= len; i++) {
            t = r[i];

            if (t.movement) {
              t.tooth.position.sub(t.movement);
              reflectToChangelog(t, -1);
            } else if (t.degrees) {
              rotateTooth(
                t.rotationPoint,
                t.teethMovementRotationDirection,
                -t.degrees,
                t.tooth,
                null,
                isComparingRedo,
              );
              reflectToChangelog(t, -1);
              // rotateAroundPoint(t.tooth, t.rotationPoint, t.teethMovementRotationDirection, t.degrees, true)
            } else if (t.actionType === MovementTypes.LOCK) {
              setControls3D("selectedTooth", t.tooth);
              lockTooth(true, t.tooth);
            } else if (t.actionType === MovementTypes.IPR && !isComparingRedo) {
              if (t.from < 17) {
                for (let i = 0; i < newArray[0]["t" + t.from].length; i++) {
                  if (
                    newArray[0]["t" + t.from][i].step ===
                    newArray[0]["t" + t.from].length - 1
                  ) {
                    newArray[0]["t" + t.from][i].amount =
                      newArray[0]["t" + t.from][i].amount +
                      (t.amount - t.preAmount);
                  }
                }
              } else {
                for (let i = 0; i < newArray[1]["t" + t.from].length; i++) {
                  if (
                    newArray[1]["t" + t.from][i].step ===
                    newArray[1]["t" + t.from].length - 1
                  ) {
                    newArray[1]["t" + t.from][i].amount =
                      newArray[1]["t" + t.from][i].amount +
                      (t.amount - t.preAmount);
                  }
                }
              }
              reflectToChangelog(t, -1);
            }
            undoMovementList[undoMovementList.length - 1].push(t);
          }
        }
      }
      if (!ignoreIPR) {
        setIprOverlays(newArray);
      }
      if (doesReturn) {
        return newArray;
      }
    }

    const setAutoSave = () => {
      if (demoTS) return;
      const t = Date.now();
      let r = [];
      if (undoMovementList.length !== 0) {
        reset(true, true);
        r = prepareRedoListToAutoSave(redoMovementList);
      }
      setControls3D("saving", true);
      localStorage.setItem(
        getParams().treatmentSetupsId + "_TP",
        JSON.stringify({
          redo: r,
          date: moment().format(),
        }),
      );

      if (r.length !== 0) {
        prepareRedoListForAutoSave(redoMovementList, group);
        showOriginalRemovedHover(true);
      }
      setControls3D("autoSaveDate", moment().format());
      setTimeout(() => {
        setControls3D("saved", false);
      }, 300);
      setTimeout(() => {
        setControls3D("saving", false);
        setControls3D("saved", true);
      }, 500);
    };
    const clearAutoSave = () => {
      localStorage.removeItem(getParams().treatmentSetupsId + "_TP");
      setControls3D("saving", false);
      setControls3D("saved", false);
    };

    function showOriginalRemovedHover(ignoreIPR = false) {
      let old;
      if (showOriginalLength > 0) {
        old = IPRCopyArray;
      }
      //console.log(showOriginalLength, "the length is");
      while (showOriginalLength > 0) {
        if (old) {
          old = redo(true, old, true);
        } else {
          old = redo(true, undefined, true);
        }
      }
      if (!ignoreIPR && old) {
        setIprOverlays(old);
      }
    }

    function redoAll() {
      let old;
      prepareIPROverlaysLastStep();
      while (redoMovementList.length > 0) {
        if (old) {
          old = redo(true, old, true);
        } else {
          old = redo(true, undefined, true);
        }
      }
      console.log(old, old === null || old === undefined);
      setIprOverlays(old);
    }

    function prepareIPROverlaysLastStep() {
      let toothKeyCacher;
      for (let isLower = 0; isLower < 2; isLower++) {
        for (let toothNumber = 1; toothNumber < 33; toothNumber++) {
          toothKeyCacher = "t" + toothNumber;
          if (iprOverLays[isLower].hasOwnProperty(toothKeyCacher)) {
            for (
              let step = 0;
              step < iprOverLays[isLower][toothKeyCacher].length;
              step++
            ) {
              if (
                iprOverLays[isLower][toothKeyCacher][step].step ===
                  iprOverLays[isLower][toothKeyCacher].length - 1 ||
                iprOverLays[isLower][toothKeyCacher][step].amount === null
              ) {
                iprOverLays[isLower][toothKeyCacher][step].step =
                  iprOverLays[isLower][toothKeyCacher].length - 1;
                break;
              }
            }
          }
        }
      }
    }
    let hasDoneOnce = false;
    let isComparingRedo = false;
    /**
     * @param {List} redoList
     * @param {List} group a group in the shape of the main gums group
     */
    //useImperativeHandle(ref, () => ({increment}))
    useImperativeHandle(
      ref,
      () => ({ redoAllComparingMode, applyAutoSave, setAutoSave }),
      [],
    );

    function redoAllComparingMode(redoList, group) {
      if (!redoList || hasDoneOnce) {
        return;
      }
      const listHolder = JSON.parse(JSON.stringify(redoMovementList));
      prepareRedoListToImport(redoList, group);
      isComparingRedo = true;
      let old;
      setRedoList(redoList);
      while (redoMovementList.length > 0) {
        if (old) {
          old = redo(true, old);
        } else {
          old = redo(true, undefined);
        }
      }
      setRedoList(listHolder);
      isComparingRedo = false;
      hasDoneOnce = true;
    }
    const applyAutoSave = (redoList, group) => {
      // prepareRedoListForAutoSave(redoList, group);
      if (!redoList) {
        return;
      }

      prepareRedoListForAutoSave(redoList, group);
      isComparingRedo = false;
      setRedoList(redoList);
      if (redoMovementList.length > 0) {
        redoAll();
      }
      isComparingRedo = false;
    };

    function prepareRedoListToImport(redoList, group) {
      for (let i = 0; i < redoList.length; i++) {
        if (Array.isArray(redoList[i])) {
          for (let t = 0; t < redoList[i].length; t++) {
            redoList[i][t].tooth = getToothByName(redoList[i][t].tooth, group);
          }
        } else {
          redoList[i].tooth = getToothByName(redoList[i].tooth, group);
        }
      }
    }
    function prepareRedoListForAutoSave(redoList, group) {
      for (let i = 0; i < redoList.length; i++) {
        if (Array.isArray(redoList[i])) {
          for (let t = 0; t < redoList[i].length; t++) {
            redoList[i][t].tooth = getToothByNameAutoSave(
              redoList[i][t].tooth,
              group,
            );
          }
        } else {
          redoList[i].tooth = getToothByNameAutoSave(redoList[i].tooth, group);
        }
      }
    }
    function getToothByName(tooth, theGroup) {
      const toothNumber = Number(tooth.split("_")[2].substring(1, 4));
      const isLower = toothNumber < 17 ? 0 : 1;
      const maxStepNumber = theGroup.current.children.length / 2 - 1;
      const toothParent =
        theGroup.current.children[maxStepNumber * 2 + isLower];
      const toothAlter = toothParent.children.find(Element => {
        if (Element.name === tooth) return Element;
      });
      return toothAlter;
    }
    function getToothByNameAutoSave(tooth, theGroup) {
      const toothNumber = Number(tooth.split("_")[2].substring(1, 4));
      const isLower = toothNumber < 17 ? 0 : 1;
      let maxStepNumber = 0;
      theGroup.current.children.forEach((elem, i) => {
        if (!elem.name.includes("gum")) {
          return;
        }
        maxStepNumber = (i + 1) / 2 - 1;
      });
      const toothParent =
        theGroup.current.children[maxStepNumber * 2 + isLower];
      const toothAlter = toothParent.children.find(Element => {
        if (Element.name === tooth) return Element;
      });
      return toothAlter;
    }

    function reflectToChangelog(theMovement, isUndo = 1) {
      if (isComparingRedo) {
        return;
      }
      let toothNum;
      if (theMovement.tooth) {
        toothNum = theMovement.tooth.name.split("_")[2].substring(1, 4);
      }
      if (!logs[toothNum + "_Movement"]) {
        logs[toothNum + "_Movement"] = {};
        logs[toothNum + "_Movement"][MovementTypes.MESIALDISTAL] = 0;
        logs[toothNum + "_Movement"][MovementTypes.BUCCALLINGUAL] = 0;
        logs[toothNum + "_Movement"][MovementTypes.EXTRUSIONINTRUSION] = 0;
        logs[toothNum + "_Movement"][MovementTypes.ROTATION] = 0;
        logs[toothNum + "_Movement"][MovementTypes.ANGULATION] = 0;
        logs[toothNum + "_Movement"][MovementTypes.INCLINATION] = 0;
        logs[toothNum + "_Movement"][MovementTypes.LEFTHINGE] = 0;
        logs[toothNum + "_Movement"][MovementTypes.RIGHTHINGE] = 0;
      }
      switch (theMovement.actionType) {
        case MovementTypes.EXTRUSIONINTRUSION: {
          let factor = 1;
          if (theMovement.movement.y < 0) {
            factor = -1;
          }
          logs[toothNum + "_Movement"][MovementTypes.EXTRUSIONINTRUSION] =
            Math.round(
              (logs[toothNum + "_Movement"][MovementTypes.EXTRUSIONINTRUSION] +
                toothMovementAmount * factor * isUndo) *
                100,
            ) / 100;
          break;
        }
        case MovementTypes.MESIALDISTAL: {
          let factor = 1;
          if (theMovement.type === "Mesial") {
            factor = -1;
          }
          //console.log(factor);
          logs[toothNum + "_Movement"][MovementTypes.MESIALDISTAL] =
            Math.round(
              (logs[toothNum + "_Movement"][MovementTypes.MESIALDISTAL] +
                toothMovementAmount * factor * isUndo) *
                100,
            ) / 100;
          break;
        }
        case MovementTypes.BUCCALLINGUAL: {
          let factor = 1;
          if (theMovement.type === "Lingual") {
            factor = -1;
          }
          logs[toothNum + "_Movement"][MovementTypes.BUCCALLINGUAL] =
            Math.round(
              (logs[toothNum + "_Movement"][MovementTypes.BUCCALLINGUAL] +
                toothMovementAmount * factor * isUndo) *
                100,
            ) / 100;
          break;
        }
        case MovementTypes.ROTATION: {
          let factor = 1;
          if (theMovement.degrees < 0) {
            factor = -1;
          }
          logs[toothNum + "_Movement"][MovementTypes.ROTATION] =
            Math.round(
              (logs[toothNum + "_Movement"][MovementTypes.ROTATION] +
                teethRotationAmount * factor * isUndo) *
                100,
            ) / 100;
          break;
        }
        case MovementTypes.ANGULATION: {
          let factor = 1;
          if (theMovement.degrees < 0) {
            factor = -1;
          }
          logs[toothNum + "_Movement"][MovementTypes.ANGULATION] =
            Math.round(
              (logs[toothNum + "_Movement"][MovementTypes.ANGULATION] +
                teethRotationAmount * factor * isUndo) *
                100,
            ) / 100;
          break;
        }
        case MovementTypes.INCLINATION: {
          let factor = 1;
          if (theMovement.degrees < 0) {
            factor = -1;
          }
          logs[toothNum + "_Movement"][MovementTypes.INCLINATION] =
            Math.round(
              (logs[toothNum + "_Movement"][MovementTypes.INCLINATION] +
                teethRotationAmount * factor * isUndo) *
                100,
            ) / 100;
          break;
        }
        case MovementTypes.LEFTHINGE: {
          let factor = 1;
          if (theMovement.degrees < 0) {
            factor = -1;
          }
          logs[toothNum + "_Movement"][MovementTypes.LEFTHINGE] =
            Math.round(
              (logs[toothNum + "_Movement"][MovementTypes.LEFTHINGE] +
                teethRotationAmount * factor * isUndo) *
                100,
            ) / 100;
          break;
        }
        case MovementTypes.RIGHTHINGE: {
          let factor = 1;
          if (theMovement.degrees < 0) {
            factor = -1;
          }
          logs[toothNum + "_Movement"][MovementTypes.RIGHTHINGE] =
            Math.round(
              (logs[toothNum + "_Movement"][MovementTypes.RIGHTHINGE] +
                teethRotationAmount * factor * isUndo) *
                100,
            ) / 100;
          break;
        }
        case MovementTypes.IPR: {
          const name = theMovement.from + "_IPR";
          if (!logs[name]) {
            logs[name] = {};
            logs[name].action = theMovement.action;
            logs[name].amount = theMovement.amount;
            logs[name].preAmount = theMovement.preAmount;
            logs[name].from = theMovement.from;
            logs[name].to = theMovement.to;
            logs[name].type = theMovement.type;
          } else {
            logs[name].amount =
              (logs[name].amount ? logs[name].amount : 0) +
              isUndo * (theMovement.preAmount - theMovement.amount);
          }
          break;
        }
        case MovementTypes.ATTACHMENTS: {
          if (theMovement.action === "added" && isUndo !== 1) {
            logs[toothNum + "_Movement"]["attachment"] = {
              action: theMovement.action,
              type: theMovement.attachment,
            };
          } else {
            delete logs[toothNum + "_Movement"]["attachment"];
          }
          break;
        }
      }
      setLogs(logs);
      // console.log(logs);
    }
    function startTracking() {
      // hotjar tracking
      window.hj("event", "3d_controls");
      hotjar.event("3d_controls");

      // end hotjar tracking
    }
    return startRevision ? (
      <div
        className={`absolute right-0 ${
          withPatientInfo ? "top-[90px]" : demoTS ? "top-[120px]" : "top-[60px]"
        } z-10 flex ${
          withPatientInfo
            ? "h-[calc(100vh-100px)]"
            : demoTS
              ? "h-[calc(100vh-130px)]"
              : "h-[calc(100vh-70px)]"
        } w-fit gap-2 pr-2 pt-3`}
      >
        {show3DControls ? (
          <div className="bounty-scrollbar overflow-y-auto [&>div]:w-[215px]">
            <URR
              reset={reset}
              redo={redo}
              reflectToChangelog={reflectToChangelog}
              iprOverLays={iprOverLays}
              setIprOverlays={setIprOverlays}
              GLBData={GLBData}
              setAutoSave={setAutoSave}
              clearAutoSave={clearAutoSave}
            />
            <TeethPosition
              setAutoSave={setAutoSave}
              dentalNotation={dentalNotation}
              maxStepNumber={maxStepNumber}
              currentStep={currentStep}
              tpLoaded={tpLoaded}
              GLBData={GLBData}
              iprOverLays={iprOverLays}
              setIprOverlays={setIprOverlays}
              movementTable={movementTable}
              parsedMovementTables={parsedMovementTables}
            />
            <IPRSelector
              setAutoSave={setAutoSave}
              dentalNotation={dentalNotation}
              iprOverLays={iprOverLays}
              setIprOverlays={setIprOverlays}
              maxStepNumber={maxStepNumber}
            />
            <AttachmentSelector setAutoSave={setAutoSave} />
          </div>
        ) : (
          <></>
        )}
        <div className="w-[260px]">
          {show3DControls ? (
            <ShowOriginal
              reset={reset}
              showOriginalRemovedHover={showOriginalRemovedHover}
            />
          ) : null}
          <ReviseTreatment
            reset={reset}
            clearAutoSave={clearAutoSave}
            isViewerControlsEnabled={isViewerControlsEnabled}
            startTracking={startTracking}
            TPdata={data}
          />
        </div>
      </div>
    ) : (
      <></>
    );
  },
);

export default TeethControls;
