export function findRanges(arr) {
  const ranges = [];
  let start = arr[0];
  let end = arr[0];

  for (let i = 1; i < arr.length; i++) {
    if (arr[i] === end + 1) {
      end = arr[i];
    } else {
      if (start === end) {
        ranges.push(start.toString());
      } else {
        ranges.push(start + " to " + end);
      }
      start = arr[i];
      end = arr[i];
    }
  }

  // Add the last range
  if (start === end) {
    ranges.push(start.toString());
  } else {
    ranges.push(`${start} to ${end}`);
  }

  // Join ranges with commas
  const result = ranges.join(" , ");

  return result;
}
