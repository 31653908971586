import * as THREE from "three";
import { TDMSTeethNumbersToUniversal } from "../Elastics";

export let ponticsTeethMaterial;
export const ponticsData = [];
const ponticsGroupHolder = new THREE.Group();
const ponticsToothArray = [];

export function preparePonticsData(data) {
  if (!data) {
    return;
  }
  for (let i = 0; i < 32; i++) {
    ponticsData[TDMSTeethNumbersToUniversal[i]] = data.teeth[i];
  }
  console.log("pontics Data ", data);
}

export function assignPonticsToGroup(groupRef) {
  let gumHolder;
  let maxStepNumber = -1;
  while (!groupRef.current.children[++maxStepNumber].name.includes("Super")) {}

  for (let stepNumber = 0; stepNumber < maxStepNumber; stepNumber++) {
    for (let toothNum = 1; toothNum <= 32; toothNum++) {
      if (ponticsData[toothNum]) {
        gumHolder = groupRef.current.children[stepNumber];
        for (
          let childIndex = 0;
          childIndex < gumHolder.children.length;
          childIndex++
        ) {
          if (
            parseInt(
              gumHolder.children[childIndex].name.split("_")[2].substring(1, 4),
            ) === toothNum
          ) {
            ponticsToothArray.push(gumHolder.children[childIndex]);
            break;
          }
        }
      }
    }
  }
}

export function removePonticTeeth(groupRef, stepNumber) {
  if (!ponticsData || !groupRef) {
    console.log("failed to remove pontics");
    return;
  }
  let isLowerCacher, gumHolder;
  for (let toothNum = 1; toothNum <= 32; toothNum++) {
    if (ponticsData[toothNum]) {
      isLowerCacher = toothNum < 17 ? 0 : 1;
      gumHolder = groupRef.current.children[stepNumber * 2 + isLowerCacher];
      for (
        let childIndex = 0;
        childIndex < gumHolder.children.length;
        childIndex++
      ) {
        if (
          parseInt(
            gumHolder.children[childIndex].name.split("_")[2].substring(1, 4),
          ) === toothNum
        ) {
          gumHolder.children[childIndex].scale.multiplyScalar(0);
          gumHolder.children[childIndex].userData["parent"] =
            gumHolder.children[childIndex].parent;
          ponticsGroupHolder.attach(gumHolder.children[childIndex]);
          break;
        }
      }
    }
  }
}
export function addRemovedPonticTeeth(groupRef, stepNumber) {
  if (!ponticsData || !groupRef) {
    console.log("failed to remove pontics");
    return;
  }
  let isLowerCacher, gumHolder;
  for (let toothNum = 1; toothNum <= 32; toothNum++) {
    if (ponticsData[toothNum]) {
      isLowerCacher = toothNum < 17 ? 0 : 1;
      gumHolder = groupRef.current.children[stepNumber * 2 + isLowerCacher];
      for (
        let childIndex = 0;
        childIndex < gumHolder.children.length;
        childIndex++
      ) {
        if (
          parseInt(
            gumHolder.children[childIndex].name.split("_")[2].substring(1, 4),
          ) === toothNum
        ) {
          gumHolder.children[childIndex].scale.multiplyScalar(1);
          gumHolder.children[childIndex].userData["parent"].attach(
            gumHolder.children[childIndex],
          );

          break;
        }
      }
    }
  }
  for (let toothIndex = 1; toothIndex <= ponticsToothArray; toothIndex++) {
    ponticsGroupHolder.children[toothIndex].scale.multiplyScalar(1);
    ponticsGroupHolder.children[toothIndex].userData["parent"].attach(
      ponticsGroupHolder.children[toothIndex],
    );
  }
}
export function turnPontics(isOn) {
  ponticsToothArray.forEach(ponticTooth => {
    ponticTooth.visible = isOn;
  });
}

export function assignPonticsMaterial(material) {
  ponticsTeethMaterial = material;
}
