import { useEffect } from "react";
import { useGlobalStore } from "../store";

export const useWindowOrientation = () => {
  const {
    grid,
    isMobile,
    orientation,
    orthographicCameraRef,
    setLayout,
    toggle,
  } = useGlobalStore(state => ({
    isMobile: state.layoutConfig.isMobile,
    orthographicCameraRef: state.refs.orthographicCameraRef,
    grid: state.toggle.grid,
    orientation: state.layoutConfig.orientation,
    setLayout: state.setLayout,
    toggle: state.toggle,
  }));

  function handleOrientationChange(mediaQueryList) {
    if (mediaQueryList.matches) {
      setLayout("orientation", "Portrait");
    } else {
      setLayout("orientation", "Landscape");
    }
  }

  useEffect(() => {
    const mediaQueryList = window.matchMedia("(orientation: portrait)");
    mediaQueryList.addEventListener("change", e =>
      handleOrientationChange(e.target),
    );
    handleOrientationChange(mediaQueryList); // To handle initial orientation
    return () => {
      mediaQueryList.removeEventListener("change", e =>
        handleOrientationChange(e.target),
      );
    };
  }, []);

  const handleCameraSetting = () => {
    if (grid) {
      if (orthographicCameraRef?.current) {
        if (isMobile) {
          if (orientation === "Portrait") {
            orthographicCameraRef.current.zoom = 5.5;
          } else {
            orthographicCameraRef.current.zoom = 5.5;
          }
        } else if (window.innerWidth > 1900) {
          orthographicCameraRef.current.zoom = 12;
        } else {
          orthographicCameraRef.current.zoom = 9.5;
        }
      }
    } else {
      if (orthographicCameraRef?.current) {
        if (isMobile) {
          if (orientation === "Portrait") {
            orthographicCameraRef.current.zoom = 0.75;
          } else {
            orthographicCameraRef.current.zoom = 1.35;
          }
        } else {
          orthographicCameraRef.current.zoom = 1;
        }
      }
    }
  };

  useEffect(() => {
    handleCameraSetting();
    // console.log({ orientation });
  }, [grid, isMobile, orientation, toggle]);
};
