import { create } from "zustand";
import { isMobileBrowser } from "@/helpers/detectBrowser";
import { createRef } from "react";

const zIndexOverlay = [];

const stepperControls = {
  stepperRef: createRef(),
  currentStep: 0,
  storedStep: 0,
  animate: false,
  isDragging: false,
};

const toggle = {
  attachment: true,
  elastics: false,
  superImpose: false,
  grid: false,
  occ: false,
  pontics: false,
  split: false,
  ipr: false,
  expand: false,
  numbering: false,
  showUpper: false,
  showLower: false,
  showStaging: false,
  showReject: false,
  showApprove: false,
  showOverbite: false,
  showBolton: false,
  showMovement: false,
  showRecords: false,
  startRevision: false,
  show3DControls: false,
  compareModifications: false,
  hasIpr: false,
  hasElastics: false,
  hasBiteJump: false,
  hasPontics: false,
  hasPhotosXrays: false,
  approveDemo: false,
  biteJump: true,
  showBackGroundColorSlider: false,
};
const controls3D = {
  selectedTooth: null,
  isUpper: false,
  name: "",
  isLocked: false,
  revisionNotes: "",
  selectedAttachmentType: "",
  selectedAttachment: "",
  teethMovements: [],
  attachmentArray: [],
  autoSaveList: [],
  saving: false,
  saved: false,
  autoSaveDate: "",
};

const logs = {};

const scene = {};

const refs = {
  canvas: createRef(),
  controlsRef: createRef(),
  orthographicCameraRef: createRef(),
  perspectiveCameraRef: createRef(),
  groupRef: createRef(),
  controlsRef2: createRef(),
  orthographicCameraRef2: createRef(),
  perspectiveCameraRef2: createRef(),
  groupRef2: createRef(),
};

const layoutConfig = {
  isMobile: isMobileBrowser(),
  backGroundColor: "#dedede",
  complementaryColor: "#898a8d",
  isGrid: false,
  leftMouse: "rotate", // rotate, pan
  orientation: "Portrait",
};

/**
 * @typedef {{
 * zIndexOverlay: string[],
 * stepperControls: stepperControls,
 * toggle: toggle,
 * controls3D: controls3D,
 * refs: refs,
 * logs: logs,
 * scene:scene,
 * layoutConfig: layoutConfig,
 * computed: {
 * get camera(): THREE.Camera,
 * get camera2(): THREE.Camera,
 * },
 * setStepperControls: (key: (keyof stepperControls), value: any) => void,
 * setToggle: (key: (keyof toggle), value: boolean) => void,
 * setControls3D: (key: (keyof controls3D), value: any) => void,
 * setLogs: (value: any) => void,
 * setScene: (value: any) => void,
 * setRefs: (refs: refs) => void,
 * setLayout: (key: (keyof layoutConfig), value: any) => void,
 * setControlsRef: (ref: any) => void,
 * setMobile: (isMobile: boolean) => void,
 * setGrid: (isGrid: boolean) => void
 * }} globalStore
 * @type {import("zustand").UseBoundStore<import("zustand").StoreApi<globalStore>>}
 */
export const useGlobalStore = create((set, get) => ({
  zIndexOverlay,
  stepperControls,
  toggle,
  refs,
  logs,
  controls3D,
  scene,
  layoutConfig,
  computed: {
    get camera() {
      return get().toggle.grid
        ? get().refs.orthographicCameraRef?.current
        : get().refs.perspectiveCameraRef?.current;
    },
    get camera2() {
      return get().toggle.grid
        ? get().refs.orthographicCameraRef2?.current
        : get().refs.perspectiveCameraRef2?.current;
    },
  },
  setStepperControls(key, value) {
    set(state => {
      return {
        stepperControls: {
          ...state.stepperControls,
          [key]: value,
        },
      };
    });
  },
  setToggle(key, value = null) {
    set(state => {
      return {
        toggle: {
          ...state.toggle,
          [key]: value === null ? !state.toggle[key] : value,
        },
        ...([
          "showBolton",
          "showMovement",
          "showStaging",
          "showReject",
          "showRecords",
          "showApprove",
          "showApprove",
          "showOverbite",
          "showRejectionReasons",
        ].includes(key) && {
          zIndexOverlay: !state.toggle[key]
            ? [...state.zIndexOverlay, key]
            : [...state.zIndexOverlay.filter(item => item !== key)],
        }),
      };
    });
  },
  setRefs(refs) {
    set(
      state =>
        (state.refs = {
          ...state.refs,
          ...refs,
        }),
    );
  },
  setLayout(key, value) {
    set(
      state =>
        (state.layoutConfig = {
          ...state.layoutConfig,
          [key]: value,
        }),
    );
  },
  setControls3D(key, value) {
    set(
      state =>
        (state.controls3D = {
          ...state.controls3D,
          [key]: value,
        }),
    );
  },
  setLogs(logs) {
    set(state => (state.logs = logs));
  },
  setScene(scene) {
    set(state => (state.scene = scene));
  },
  // setLogs(value) {
  //   console.log(value);
  //   set(
  //     state =>
  //       (state.logs = {
  //         ...state.logs,
  //         value,
  //       }),
  //   );
  // },
}));
