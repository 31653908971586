import React, { useEffect, useLayoutEffect } from "react";
import { PerspectiveCamera, OrthographicCamera } from "@react-three/drei";
import GridView from "./Grid";
import { useGlobalStore } from "@/store";

function Camera({
  orthographicCameraRef,
  perspectiveCameraRef,
  grid,
  isMobile,
  orientation,
  tpLoaded,
  view,
}) {
  const { toggle, refs } = useGlobalStore(state => ({
    toggle: state.toggle,
    refs: state.refs,
  }));

  useEffect(() => {
    orthographicCameraRef.current.position.set(0, 0, 100);
    perspectiveCameraRef.current.position.set(0, 0, 200);
  }, [grid]);
  useLayoutEffect(() => {
    refs.controlsRef.current.setFocalOffset(
      toggle.startRevision ? 25 : 0,
      0,
      0,
      true,
    );
  }, [toggle.startRevision, view]);

  return (
    <>
      <OrthographicCamera
        visible={grid ? true : false}
        makeDefault={grid ? true : false}
        ref={orthographicCameraRef}
        left={window.innerWidth / -2}
        right={window.innerWidth / 2}
        top={window.innerHeight / 2}
        bottom={window.innerHeight / -2}
        zoom={
          isMobile
            ? orientation === "Portrait"
              ? 5.5
              : 6.5
            : window.innerWidth > 1900
              ? 12
              : 9.5
        }
        position={[0, 0, 100]}
      >
        <GridView grid={grid} />
      </OrthographicCamera>

      <PerspectiveCamera
        visible={grid ? false : true}
        ref={perspectiveCameraRef}
        makeDefault={grid ? false : true}
        fov={25}
        zoom={isMobile ? (orientation === "Portrait" ? 0.75 : 1.35) : 1}
        near={1}
        far={1000}
        position={[0, 0, 0]}
      />
    </>
  );
}

export default Camera;
