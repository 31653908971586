import React, { useEffect, useState } from "react";
import "./modifications.scss";
import ComparePlans from "./ComparePlans";
import Modifications from "./Modifications";
import ModificationCard from "./ModificationCard";
import { generateDesignerModifications } from "./helpers";
import { getParams } from "@/helpers/getParams";
import { useQuery } from "@tanstack/react-query";
import { apiResolver } from "@/helpers/apiResolver";
import { useGlobalStore } from "@/store";
import { redoMovementList } from "../teethControls/TeethPositionHelper";

const DoctorModifications = ({
  toggleModificationsCard,
  toggleModifications,
  tp_data,
  canModify,
  redoAllComparingMode,
}) => {
  const { tdms } = getParams();
  const { data: res } = useQuery({
    queryKey: ["treatmentSetupInfo"],
    queryFn: apiResolver,
    staleTime: 600000, // Cache data for 60 seconds (1 minute)
  });
  const { setToggle, groupRef2, toggle, groupRef, logs, setControls3D } =
    useGlobalStore(state => ({
      setToggle: state.setToggle,
      groupRef2: state.refs.groupRef2,
      groupRef: state.refs.groupRef,
      toggle: state.toggle,
      logs: state.logs,
      setControls3D: state.setControls3D,
    }));
  // rejection Reason if tp is rejected and if it has linked tp
  const rejectionReason =
    tp_data.status === "REJECTED"
      ? tp_data?.rejectionReason
      : tp_data?.originalPlan?.rejectionReason;

  // doctor modification if tp is rejected and if it has linked tp
  const doctor_modification =
    tp_data.status === "REJECTED"
      ? tp_data?.modificationsRequest || {}
      : tp_data?.originalPlan?.modificationsRequest || {};

  // designer modifications if tp is rejected and if it has linked tp
  const designer_modifications =
    tp_data.status === "REJECTED"
      ? tp_data?.appliedModificationsNotes ||
        generateDesignerModifications(doctor_modification)
      : tp_data?.originalPlan?.appliedModificationsNotes &&
          Object.keys(tp_data?.originalPlan?.appliedModificationsNotes)
            ?.length > 0
        ? tp_data?.originalPlan?.appliedModificationsNotes
        : doctor_modification
          ? generateDesignerModifications(doctor_modification) // show doctor modification not selected if no applied Modifications Notes
          : {} || {};
  const [data, setData] = useState(designer_modifications);
  const applyRedo = () => {
    if (res?.controlsRedoList) {
      redoAllComparingMode.redoAllComparingMode(
        res.controlsRedoList,
        groupRef2,
      );
    }
    setToggle("compareModifications");
  };
  useEffect(() => {
    if (
      toggle.show3DControls &&
      localStorage.getItem(getParams().treatmentSetupsId + "_TP")
    ) {
      const savedRedoMovementList = JSON.parse(
        localStorage.getItem(getParams().treatmentSetupsId + "_TP"),
      );
      setControls3D("autoSaveDate", savedRedoMovementList.date);
      //console.log(savedRedoMovementList);
      redoAllComparingMode.applyAutoSave(savedRedoMovementList.redo, groupRef);
      setControls3D("saved", true);
      redoMovementList.splice(0, redoMovementList.length);
    }
  }, [toggle.show3DControls]);
  return (
    <div>
      {(rejectionReason || Object.keys(doctor_modification)?.length > 0) &&
        tdms && (
          <>
            {toggleModifications ? (
              <div className="absolute right-[10px] top-[70px]  z-10 w-[280px]">
                {tp_data.status === "REJECTED" && (
                  <ComparePlans
                    applyRedo={applyRedo}
                    tp_data={tp_data}
                    showModifications={
                      Object.keys(doctor_modification)?.length > 0
                    }
                  />
                )}
                <Modifications
                  applyRedo={applyRedo}
                  toggleModificationsCard={toggleModificationsCard}
                  data={data}
                  compareModifications={toggle.compareModifications}
                  setData={setData}
                  rejectionReason={rejectionReason}
                  showModifications={
                    data ? Object.keys(data)?.length > 0 || false : false
                  }
                  canModify={canModify}
                />
              </div>
            ) : (
              <ModificationCard onClick={toggleModificationsCard} data={data} />
            )}
          </>
        )}
    </div>
  );
};

export default DoctorModifications;
