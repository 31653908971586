import React, { Fragment } from "react";
import Divider from "@mui/material/Divider";
import { Dialog, Transition } from "@headlessui/react";
import { useGlobalStore } from "@/store";
import { useCustomTranslation } from "@/hooks/useTranslation";

function ApproveTPModal() {
  const { approveDemo, setToggle } = useGlobalStore(state => ({
    approveDemo: state.toggle.approveDemo,
    setToggle: state.setToggle,
  }));

  const handleApproveClick = () => {
    window.location.reload(false);
  };
  const { content } = useCustomTranslation();

  const getContent = (group, key) => {
    return content(`other.${group}.${key}`);
  };
  return (
    <Transition appear show={approveDemo} as={Fragment}>
      <Dialog as="div" className="relative z-[101]" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto bg-black bg-opacity-75">
          <div className="bg-black-900 flex min-h-full w-full items-center justify-center bg-opacity-75 p-4 text-center ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`${"max-w-lg"}   overflow-initial z-10 h-full   w-full transform
                 rounded-lg  bg-white p-6 text-left align-middle shadow-xl transition-all md:h-auto`}
              >
                <Dialog.Title
                  as="h3"
                  className="mb-4 flex flex-row text-base leading-6"
                >
                  <span className="width-auto flex-1 flex-col text-base font-normal text-[#1d4655]">
                    {getContent(
                      "labels",
                      "are_you_sure_you_want_to_approve_t_s_version_1_0",
                    )}
                  </span>
                </Dialog.Title>
                <div className="mb-[16px]">
                  <div className="mb-[16px] text-[14px] text-[#2e2e2f]">
                    {getContent("labels", "patient_name")}
                    <span className="font-bold">{` Demo Patient`}</span>
                  </div>
                  <div>
                    <span className="font-bold">
                      {`27 `}
                      <span className="font-normal">
                        {getContent("labels", "upper_aligners")} ,
                      </span>
                    </span>
                    <span className="font-bold">
                     {` 66 `}
                      <span className="font-normal">
                        {getContent("labels", "lower_aligners")}
                      </span>
                    </span>
                  </div>
                </div>
                <Divider />
                <div className="mt-[18px] bg-[#ffcd5d59] p-[16px]">
                  <div className="mb-[8px] text-sm text-[#936701]">
                    {getContent("labels", "demo_case_approval_confirmation")}
                  </div>
                  <div className="text-sm">
                    {getContent("labels", "approval_note")}
                  </div>
                </div>
                <footer className="mt-4">
                  {
                    <div className="mt-4 flex justify-end gap-2">
                      <button
                        onClick={() => setToggle("approveDemo")}
                        className="rounded-3xl border border-solid border-[#444444] px-5 py-3 text-center text-sm font-normal text-[#444444]"
                      >
                        {getContent("actions", "cancel")}
                      </button>
                      <button
                        onClick={() => handleApproveClick()}
                        className="rounded-3xl border border-solid border-[#444444] bg-[#444444] px-5 py-3 text-center text-sm font-bold text-[#FFF]"
                      >
                        {getContent("actions", "approve")}
                      </button>
                    </div>
                  }
                </footer>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default ApproveTPModal;
