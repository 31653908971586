import React from "react";
import "./toggle.scss";

const CustomToggleSwitch = ({ title, checked, onChange }) => {
  return (
    <label className="toggle">
      <input
        className="toggle-checkbox"
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      <div className="toggle-switch"></div>
      <span className="toggle-label">{title}</span>
    </label>
  );
};

export default CustomToggleSwitch;
