import React from "react";
import CustomCheckBox from "../../shared-components/CustomCheckBox";
import { CloseIcon } from "@/assets";
import {
  actions,
  attachment_types,
  movement_dictionary,
  movements_mapper,
  not_allowed_to_show,
  getMovementName,
} from "./helpers";
import { numberingUniversal } from "@/helpers/teethNumbering";
import { getParams } from "@/helpers/getParams";
import { useCustomTranslation } from "@/hooks/useTranslation";

const Modifications = ({
  toggleModificationsCard,
  data,
  setData,
  rejectionReason,
  showModifications,
  canModify,
  applyRedo,
  compareModifications,
}) => {
  // handle applied movement modification from the designer

  const handleMovementCheckboxChange = (tooth, key, innerKey) => {
    setData({
      ...data,
      movement: {
        ...data?.movement,
        [tooth]: {
          ...data?.movement?.[tooth],
          [innerKey]: {
            ...data?.movement?.[tooth]?.[innerKey],
            applied: !data?.movement?.[tooth]?.[innerKey]?.applied, // Update the 'applied' property to true
          },
        },
      },
    });
  };

  // handle applied ip modification from the designer
  const handleIPRCheckboxChange = tooth => {
    setData({
      ...data,
      ipr: {
        ...data?.ipr,
        [tooth]: {
          ...data?.ipr?.[tooth],
          applied: !data?.ipr?.[tooth]?.applied, // Update the 'applied' property to true
        },
      },
    });
  };
  const SaveModification = () => {
    window.parent.postMessage(
      { updateModification: true, data: data },
      process.env.REACT_APP_TDMS_URL,
    );
    toggleModificationsCard();
  };

  const movement = data?.movement || {};
  const ipr = data?.ipr || {};
  const { dentalNotation } = getParams();

  const { content } = useCustomTranslation();

  const getContent = (group, key) => {
    return content(`controls.${group}.${key}`);
  };
  return (
    <div>
      <div className="modification_contanier bounty-scrollbar mt-2 flex h-[calc(100vh-200px)] w-[280px] gap-2 pr-2  pt-3">
        <div className="level_1_title header_grid">
          <div> {getContent("labels", "doctor_modifications")}</div>
          <div
            className="close_modification"
            onClick={() => {
              toggleModificationsCard();
              if (compareModifications) {
                applyRedo();
              }
            }}
          >
            <CloseIcon />
          </div>
        </div>
        {/* Rejection reason */}
        <div>
          <div className="level_2_title">
            {" "}
            {getContent("labels", "comments")}
          </div>
          <div className="movement_text">{rejectionReason || "--"}</div>
        </div>
        {/*end Rejection reason */}

        {/* Doctor modifications */}
        <div>
          <div className="level_2_title ">
            {getContent("labels", "doctor_modifications")}
          </div>
          {showModifications ? (
            <div className="timeline_container">
              {Object.keys(movement)?.map(tooth => (
                <div key={tooth} className="timeline-block ">
                  <div className="marker"></div>
                  <div className="timeline-content">
                    <div className="tooth_title">
                      {` ${getContent("labels", "tooth")}
                     ${
                       numberingUniversal?.[
                         Number(tooth.replace("_Movement", ""))
                       ]?.[dentalNotation || "universal"]
                     } 
                      ${getContent("labels", "final_position")}`}
                    </div>
                    <div>
                      {Object.keys(movement_dictionary)
                        .filter(key => !not_allowed_to_show.includes(key)) // Keys to be filtered would not appear in the ui.
                        .map(key => (
                          <div key={key}>
                            {movement_dictionary[key].map(
                              // iterate through all possible values for each key on the map.
                              innerKey => (
                                <div key={innerKey}>
                                  {movement[tooth]?.hasOwnProperty(
                                    innerKey,
                                  ) && ( // check if the key found in the main data
                                    <CustomCheckBox
                                      key={innerKey}
                                      onChange={() =>
                                        handleMovementCheckboxChange(
                                          tooth,
                                          key,
                                          innerKey,
                                        )
                                      }
                                      checked={
                                        movement[tooth]?.[innerKey]?.applied ===
                                        true
                                      }
                                      id={`${tooth}_${innerKey}`}
                                      title={
                                        key === "attachment" ? (
                                          <span>{`${
                                            actions[
                                              movement?.[tooth]?.attachment
                                                ?.action
                                            ] || ""
                                          } ${
                                            movement[tooth]?.attachment
                                              ?.action === "remove"
                                              ? "Attachment"
                                              : attachment_types[
                                                  movement?.[tooth]?.attachment
                                                    ?.type
                                                ] || ""
                                          } `}</span>
                                        ) : (
                                          <span>
                                            {getContent(
                                              "labels",
                                              getMovementName(
                                                tooth,
                                                key,
                                                movement[tooth]?.[innerKey]
                                                  ?.amount || 0,
                                              ),
                                            )}

                                            <span
                                              style={{ fontWeight: "700" }}
                                            >{` ${Math.abs(
                                              movement[tooth]?.[innerKey]
                                                ?.amount || 0,
                                            )} ${
                                              movements_mapper?.[key]?.scale ||
                                              ""
                                            }`}</span>
                                          </span>
                                        )
                                      }
                                      disabled={!canModify}
                                    />
                                  )}
                                </div>
                              ),
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              ))}

              {Object.keys(ipr).length > 0 && (
                <div className="timeline-block ">
                  <div className="marker"></div>
                  <div className="timeline-content">
                    <div className="tooth_title">
                      IPR and Spaces Final Values
                    </div>
                    <div>
                      {Object.keys(ipr)?.map(tooth => (
                        <CustomCheckBox
                          key={tooth}
                          onChange={() => handleIPRCheckboxChange(tooth)}
                          checked={ipr[tooth]?.applied === true}
                          id={`${tooth}_ipr}`}
                          title={
                            <span>
                              {`${actions[ipr?.[tooth]?.action] || ""} ${
                                ipr?.[tooth]?.type === 1
                                  ? "IPR"
                                  : ipr?.[tooth]?.type === -1
                                    ? "Spacing"
                                    : ""
                              }  Between Tooth ${
                                numberingUniversal?.[ipr[tooth]?.from || 0]?.[
                                  dentalNotation || "universal"
                                ]
                              }, Tooth ${
                                numberingUniversal?.[ipr[tooth]?.to || 0]?.[
                                  dentalNotation || "universal"
                                ]
                              } - `}
                              <span style={{ fontWeight: "700" }}>{` (${
                                ipr[tooth]?.amount
                                  ? Number(ipr[tooth]?.amount)?.toFixed(2)
                                  : 0
                              })`}</span>
                            </span>
                          }
                          disabled={!canModify}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            "--"
          )}
        </div>
        {/*end Doctor modifications */}
        {showModifications && canModify && (
          <div className="actions_container">
            <button className="save_btn" onClick={SaveModification}>
              {getContent("actions", "save")}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Modifications;
