import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Rnd } from "react-rnd";
import { apiResolver } from "@/helpers/apiResolver";
import { toothMovementTypes } from "../teethControls/TeethPositionHelper";
import { attachmentTypes } from "../teethControls/AttachmentSelector";
import { numberingUniversal } from "@/helpers/teethNumbering";
import { getParams } from "@/helpers/getParams";
import Divider from "@mui/material/Divider";
import moment from "moment";
import { useGlobalStore } from "@/store";
import { EditIPR, EditSpacing } from "../Icons";
import { useCustomTranslation } from "@/hooks/useTranslation";

function RevisedTreatment() {
  const { data } = useQuery({
    queryKey: ["treatmentSetupInfo"],
    queryFn: apiResolver,
    staleTime: 600000, // Cache data for 60 seconds (1 minute)
  });
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [grabbing, setGrabbing] = useState(false);
  const { content } = useCustomTranslation();

  const getContent = (group, key) => {
    return content(`controls.${group}.${key}`);
  };
  const { zIndexOverlay, showMovement } = useGlobalStore(state => ({
    zIndexOverlay: state.zIndexOverlay,
    showMovement: state.toggle.showMovement,
  }));
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const { dentalNotation } = getParams();
  function getMovementName(toothKey, movementKey, value) {
    const toothQuarter = getToothQuarter(Number(toothKey.match(/[0-9]+/)));

    if (toothQuarter === 5) {
      return "incorrect_tooth_key";
    }
    switch (movementKey) {
      case "Extrusion/Intrusion":
        if (value > 0) {
          if (toothQuarter <= 2) {
            return "intrusion";
          } else if (toothQuarter <= 4) {
            return "extrusion";
          }
        } else {
          if (toothQuarter <= 2) {
            return "extrusion";
          } else if (toothQuarter <= 4) {
            return "intrusion";
          }
        }
      case "Buccal/Lingual":
        if (value > 0) {
          return "buccal_translation";
        } else {
          return "lingual_translation";
        }
      case "Mesial/Distal":
        if (value > 0) {
          return "distal_translation";
        } else {
          return "mesial_translation";
        }
      case "Rotation":
        if (value > 0) {
          if (toothQuarter === 1 || toothQuarter === 4) {
            return "mesial_in_rotation";
          } else if (toothQuarter === 2 || toothQuarter === 3) {
            return "distal_in_rotation";
          }
        } else {
          if (toothQuarter === 1 || toothQuarter === 4) {
            return "distal_in_rotation";
          } else if (toothQuarter === 2 || toothQuarter === 3) {
            return "mesial_in_rotation";
          }
        }
      case "Inclination":
        if (value > 0) {
          if (toothQuarter <= 2) {
            return "buccal_inclination";
          } else if (toothQuarter <= 4) {
            return "lingual_inclination";
          }
        } else {
          if (toothQuarter <= 2) {
            return "lingual_inclination";
          } else if (toothQuarter <= 4) {
            return "buccal_inclination";
          }
        }
      case "Angulation":
        if (value > 0) {
          if (toothQuarter === 2 || toothQuarter === 4) {
            return "distal_crown_angulation";
          } else if (toothQuarter === 1 || toothQuarter === 3) {
            return "mesial_crown_angulation";
          }
        } else {
          if (toothQuarter === 2 || toothQuarter === 4) {
            return "mesial_crown_angulation";
          } else if (toothQuarter === 1 || toothQuarter === 3) {
            return "distal_crown_angulation";
          }
        }
      case "LeftHinge":
        if (value > 0) {
          if (toothQuarter === 2 || toothQuarter === 3) {
            return "distal_in_hinge";
          } else if (toothQuarter === 1 || toothQuarter === 4) {
            return "distal_out_hinge";
          }
        } else {
          if (toothQuarter === 2 || toothQuarter === 3) {
            return "distal_out_hinge";
          } else if (toothQuarter === 1 || toothQuarter === 4) {
            return "distal_in_hinge";
          }
        }
      case "RightHinge":
        if (value > 0) {
          if (toothQuarter === 2 || toothQuarter === 3) {
            return "mesial_out_hinge";
          } else if (toothQuarter === 1 || toothQuarter === 4) {
            return "mesial_in_hinge";
          }
        } else {
          if (toothQuarter === 2 || toothQuarter === 3) {
            return "mesial_in_hinge";
          } else if (toothQuarter === 1 || toothQuarter === 4) {
            return "mesial_out_hinge";
          }
        }
      default:
        "movement_key_is_not_found";
    }
  }
  function getToothQuarter(toothNumber) {
    if (toothNumber < 9) {
      return 1;
    } else if (toothNumber < 17) {
      return 2;
    } else if (toothNumber < 25) {
      return 3;
    } else if (toothNumber < 33) {
      return 4;
    } else {
      return 5;
    }
  }
  // rejection Reason if tp is rejected and if it has linked tp
  const rejectionReason = data?.rejectionReason;
  const doctor_modification = data?.modificationsRequest;

  const toothLogs = Object.keys(doctor_modification).map(toothKey => {
    if (toothKey.includes("Movement")) {
      const toothData = doctor_modification[toothKey];

      // Check if all values in the toothData object are equal to 0 or an empty string
      const allValuesAreZeroOrEmpty = Object.values(toothData).every(
        value => value === 0 || value === "",
      );

      // If all values are zero or empty, skip rendering this tooth
      if (allValuesAreZeroOrEmpty) {
        return null;
      }
      // Create an array of JSX elements for each key-value pair in toothData
      const toothDataEntries = Object.entries(toothData)
        .map(([key, value], idx) => {
          const svg = toothMovementTypes.find(
            typeObj => typeObj.type === key,
          )?.svg;
          if (key === "attachment") {
            if (
              toothData[key]["action"] === "" ||
              toothData?.["attachment"]?.["action"] === "No action done"
            ) {
              return;
            }
            return (
              <>
                <div
                  key={key}
                  className="flex items-center gap-1 attachment_log"
                >
                  {attachmentTypes.map((elem, i) => {
                    if (elem.name === toothData[key]["type"]) {
                      return elem.svg;
                    }
                  })}
                  <div>
                    {getContent(
                      "labels",
                      `attachment_${toothData[key]["action"]}`,
                    )}
                  </div>
                </div>
                <Divider />
              </>
            );
          } else {
            return (
              value !== 0 &&
              value !== "" && (
                <>
                  <div key={key} className="flex gap-1">
                    {svg && <div>{svg}</div>}
                    <div className="capitalize">
                      {Math.abs(value)}
                      {idx !== Object.entries(toothData).length - 1 && ","}
                    </div>
                    <div className="capitalize">
                      {getContent(
                        "labels",
                        getMovementName(toothKey, key, value),
                      )}
                    </div>
                  </div>
                  <Divider />
                </>
              )
            );
          }
        })
        .filter(Boolean);

      if (
        toothData?.["attachment"]?.["action"] === "" ||
        toothData?.["attachment"]?.["action"] === "No action done"
      ) {
        return;
      } else {
        return (
          <div key={toothKey} className="flex flex-col gap-2">
            <div
              style={{ color: "#717171" }}
              className="text-[11px] font-bold text-[#7D7F8E]"
            >
              {`${getContent("labels", "tooth")} ${
                numberingUniversal[Number(toothKey.split("_")[0])][
                  dentalNotation
                ]
              } ${getContent("labels", "adjusted")}`}
            </div>
            <div className="font-sm flex flex-col gap-3 text-[13px] font-normal text-[#181818]">
              {toothDataEntries}
            </div>
          </div>
        );
      }
    }
  });
  const iprSpacingLogs = Object.keys(doctor_modification).map(toothKey => {
    if (!toothKey.includes("Movement")) {
      const toothData = doctor_modification[toothKey];

      const typeText = toothData["type"]
        ? toothData.type === 1
          ? "IPR"
          : "spacing"
        : "";
      if (Math.abs(Number(toothData.amount).toFixed(2) == 0)) {
        return;
      }
      const icon =
        Math.abs(Number(toothData.amount)).toFixed(2) > 0.01 ? (
          <EditIPR />
        ) : (
          <EditSpacing />
        );
      let text;
      if (toothData["type"]) {
        if (isNaN(toothData.amount)) return;
        if (toothData.action == "added" || toothData.action == "deleted") {
          text = (
            <div
              key={toothKey}
              className="text-[13px] font-normal text-[#181818]"
            >
              {`${
                Math.abs(Number(toothData.amount)).toFixed(2) +
                " " +
                `${getContent("labels", `${typeText}_${toothData.action}_between`)} ` +
                numberingUniversal[
                  toothData.from.includes("t")
                    ? Number(toothData.from.split("t")[1])
                    : Number(toothData.from)
                ][dentalNotation] +
                " " +
                `${getContent("labels", "and")}` +
                " " +
                numberingUniversal[
                  toothData.to.includes("t")
                    ? Number(toothData.to.split("t")[1])
                    : Number(toothData.to)
                ][dentalNotation]
              }`}
            </div>
          );
        } else if (toothData.action == "changed") {
          // not used as hamzeh and othman said
          text = (
            <div
              key={toothKey}
              className="text-[13px] font-normal text-[#181818]"
            >
              {`${
                typeText +
                " " +
                toothData.action +
                "from" +
                " " +
                "<s>{Math.abs(toothData.preAmount)}</s>" +
                " " +
                Math.abs(Number(toothData.amount)).toFixed(2) +
                " " +
                toothData.action +
                " " +
                "between" +
                " " +
                numberingUniversal[
                  toothData.from.includes("t")
                    ? Number(toothData.from.split("t")[1])
                    : Number(toothData.from)
                ][dentalNotation] +
                " " +
                "and" +
                " " +
                numberingUniversal[
                  toothData.to.includes("t")
                    ? Number(toothData.to.split("t")[1])
                    : Number(toothData.to)
                ][dentalNotation]
              }`}
            </div>
          );
        }
      }

      return (
        <div key={toothKey} className="flex flex-col gap-3 font-normal font-sm">
          <div
            className="grid font-normal font-sm"
            style={{ gridTemplateColumns: "auto 1fr", gap: "8px" }}
          >
            {icon}
            {text}
          </div>
        </div>
      );
    }
  });
  return (
    <div className="absolute right-[10px] top-[70px] z-10 w-[280px]">
      <Rnd
        style={{
          zIndex:
            zIndexOverlay.findIndex(key => key === "showRejectionReasons") + 10,
        }}
        default={{
          x: 0,
          y: 0,
          width: 280,
          height: isModalOpen ? "auto" : 0, // Set the height to 'auto' when modal is open, 0 otherwise
        }}
        minHeight={isModalOpen ? 100 : 0} // Set a minimum height when modal is open
        maxHeight={showMovement ? "calc(100vh-460px)" : "calc(100vh-160px)"} // Set a maximum height
        onDragStart={() => setGrabbing(true)}
        onDragStop={() => setGrabbing(false)}
      >
        <div
          className={`bounty-scrollbar modification_contanier mt-2 flex ${showMovement ? "max-h-[calc(100vh-460px)]" : "max-h-[calc(100vh-160px)]"} w-[280px] gap-2 p-[16px]  ${grabbing ? "cursor-grabbing" : "cursor-default"}`}
        >
          <div className="level_1_title header_grid">
            <div className="flex flex-col items-center">
              <div className="text-sm font-semibold text-[#363638]">
                {getContent("labels", "revised_treatment_setup")}
              </div>
              <div className="text-[10px] font-medium text-[#5B5B5B]">
                {`_${getContent("labels", "revision_date")} :` +
                  " " +
                  moment(data?.updatedAt).format("DD/MM/YYYY hh:mm A")}
              </div>
            </div>

            <div
              onClick={toggleModal}
              style={{ cursor: "pointer", zIndex: "99" }}
              className={isModalOpen ? "arrow arrow_active" : "arrow"}
            />
          </div>
          {isModalOpen && (
            <div>
              {/* Rejection reason */}
              {rejectionReason && (
                <div className="mb-[8px]">
                  <div className="level_2_title text-[11px] font-bold text-[#7D7F8E]">
                    {getContent("labels", "comments")}
                  </div>
                  <div className="movement_text text-[13px] font-normal text-[#393939]">
                    {rejectionReason || "--"}
                  </div>
                </div>
              )}
              {/*end Rejection reason */}
              <Divider />
              {/* Doctor modifications */}
              <div className="mt-[8px]">
                <div className="mb-[8px] flex flex-col gap-4">{toothLogs}</div>

                <div className="mt-[8px] flex flex-col gap-4">
                  <div className="flex flex-col gap-2">
                    {iprSpacingLogs[0] && (
                      <div
                        style={{ color: "#717171" }}
                        className="text-[11px] font-bold text-[#7D7F8E]"
                      >
                        {getContent("labels", "ipr_and_spacing")}
                      </div>
                    )}
                    {iprSpacingLogs}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Rnd>
    </div>
  );
}

export default RevisedTreatment;
