import nav from "./components/nav_fr.json";
import movement_table from "./components/movement_table_fr.json";
import staging_timeline from "./components/staging_timeline_fr.json";
import bolton from "./components/bolton_fr.json";
import overjet_overbite from "./components/overjet_overbite_fr.json";
import controls from "./components/controls_fr.json";
import others from "./components/others_fr.json";

export const fr = {
  nav: { ...nav },
  movement_table: { ...movement_table },
  staging_timeline: { ...staging_timeline },
  bolton: { ...bolton },
  overjet_overbite: { ...overjet_overbite },
  controls: { ...controls },
  other: { ...others },
};
