// movement dictionary with all possible value for each key
export const movement_dictionary = {
  mesial_distal: ["Mesial/Distal", "mesial/distal", "left/right", "Left/Right"],
  extrusion_intrusion: ["Extrusion/Intrusion"],
  buccal_lingual: ["Buccal/Lingual", "buccal/lingual"],
  rotation: ["Rotation", "Rotation2"],
  angulation: ["Angulation"],
  inclination: ["Inclination"],
  leftHinge: ["LeftHinge"],
  rightHinge: ["RightHinge"],
  attachment: ["attachment"],
};
//hide keys from ui
export const not_allowed_to_show = [];

// ui mapper
export const movements_mapper = {
  mesial_distal: { title: "Mesial/Distal", scale: "mm" },
  buccal_lingual: { title: "Buccal/Lingual", scale: "mm" },
  extrusion_intrusion: { title: "Extrusion/Intrusion", scale: "mm" },
  rotation: { title: "Rotation", scale: "°" },
  angulation: { title: "Angulation", scale: "°" },
  inclination: { title: "Inclination", scale: "°" },
  leftHinge: { title: "LeftHinge", scale: "°" },
  rightHinge: { title: "RightHinge", scale: "°" },
};

// attachment Types
export const attachment_types = {
  att_extrusion: "Extrusion Attachment",

  att_oblique: "Oblique Attachment",

  att_vbra: "Vertical Beveled Rectangular Attachment",

  att_hbra: "Horizontal Beveled Rectangular Attachment",

  att_rotational: "Rotational Attachment",

  att_root_control: "Root Control Attachment",

  att_biolane: "Biblane Rotational Attachment",
};
// ipr , spacing and attachment actions
export const actions = {
  added: "Add",
  changed: "Modify",
  deleted: "Delete",
  remove: "Delete",
  add: "Add",
  replace: "Modify",
  delete: "Delete",
};
export const actions_logs = {
  added: "Added",
  changed: "Modified",
  deleted: "Deleted",
  remove: "Deleted",
  add: "Added",
  replace: "Modified",
  delete: "Deleted",
};
export const generateDesignerModifications = data => {
  // Initialize movement and ipr objects
  const movement = {};
  const ipr = {};

  // Check if the value of the 'amount' key is not equal to 0 for all entries
  for (let tooth in data) {
    if (tooth.endsWith("_IPR")) {
      if (
        data[tooth].amount &&
        data[tooth].amount !== 0 &&
        data[tooth]?.amount?.toFixed(2) !== "-0.00" &&
        data[tooth]?.amount?.toFixed(2) !== "0.00"
      ) {
        ipr[tooth] = {
          ...data[tooth],
          amount: data[tooth]?.amount?.toFixed(2),
          applied: false,
        };
      }
    } else if (tooth.endsWith("_Movement")) {
      const filterd_movement = {};
      for (let movement in data[tooth]) {
        if (data[tooth][movement] !== 0) {
          if (movement === "attachment") {
            if (data[tooth][movement].action !== "") {
              filterd_movement[movement] = data[tooth][movement];
            }
          } else {
            filterd_movement[movement] =
              movement === "attachment"
                ? { ...data[tooth][movement], applied: false }
                : { ["amount"]: data[tooth][movement], applied: false };
          }
        }
      }

      if (Object.keys(filterd_movement).length > 0) {
        movement[tooth] = filterd_movement;
      }
    } else {
      return true; // Skip non-'_IPR' entries
    }
  }
  return { movement, ipr };
};

/**
 *
 * @param {string} toothKey movement tooth key
 * @param {string} movementKey Teeth Movement Types
 * @param {int} value the movement amount
 * @returns {string} the actual movement name
 */
export function getMovementName(toothKey, movementKey, value) {
  const toothQuarter = getToothQuarter(Number(toothKey.match(/[0-9]+/)));
  if (toothQuarter === 5) {
    return "incorrect_tooth_key";
  }
  switch (movementKey) {
    case "extrusion_intrusion":
      if (value > 0) {
        if (toothQuarter <= 2) {
          return "intrusion";
        } else if (toothQuarter <= 4) {
          return "extrusion";
        }
      } else {
        if (toothQuarter <= 2) {
          return "extrusion";
        } else if (toothQuarter <= 4) {
          return "intrusion";
        }
      }
    case "buccal_lingual":
      if (value > 0) {
        return "buccal_translation";
      } else {
        return "lingual_translation";
      }
    case "mesial_distal":
      if (value > 0) {
        return "distal_translation";
      } else {
        return "mesial_translation";
      }
    case "rotation":
      if (value > 0) {
        if (toothQuarter === 1 || toothQuarter === 4) {
          return "mesial_in_rotation";
        } else if (toothQuarter === 2 || toothQuarter === 3) {
          return "distal_in_rotation";
        }
      } else {
        if (toothQuarter === 1 || toothQuarter === 4) {
          return "distal_in_rotation";
        } else if (toothQuarter === 2 || toothQuarter === 3) {
          return "mesial_in_rotation";
        }
      }
    case "inclination":
      if (value > 0) {
        if (toothQuarter <= 2) {
          return "buccal_inclination";
        } else if (toothQuarter <= 4) {
          return "lingual_inclination";
        }
      } else {
        if (toothQuarter <= 2) {
          return "lingual_inclination";
        } else if (toothQuarter <= 4) {
          return "buccal_inclination";
        }
      }
    case "angulation":
      if (value > 0) {
        if (toothQuarter === 2 || toothQuarter === 4) {
          return "distal_crown_angulation";
        } else if (toothQuarter === 1 || toothQuarter === 3) {
          return "mesial_crown_angulation";
        }
      } else {
        if (toothQuarter === 2 || toothQuarter === 4) {
          return "mesial_crown_angulation";
        } else if (toothQuarter === 1 || toothQuarter === 3) {
          return "distal_crown_angulation";
        }
      }
    case "leftHinge":
      if (value > 0) {
        if (toothQuarter === 2 || toothQuarter === 3) {
          return "distal_in_hinge";
        } else if (toothQuarter === 1 || toothQuarter === 4) {
          return "distal_out_hinge";
        }
      } else {
        if (toothQuarter === 2 || toothQuarter === 3) {
          return "distal_out_hinge";
        } else if (toothQuarter === 1 || toothQuarter === 4) {
          return "distal_in_hinge";
        }
      }
    case "rightHinge":
      if (value > 0) {
        if (toothQuarter === 2 || toothQuarter === 3) {
          return "mesial_out_hinge";
        } else if (toothQuarter === 1 || toothQuarter === 4) {
          return "mesial_in_hinge";
        }
      } else {
        if (toothQuarter === 2 || toothQuarter === 3) {
          return "mesial_in_hinge";
        } else if (toothQuarter === 1 || toothQuarter === 4) {
          return "mesial_out_hinge";
        }
      }
    default:
      "movement_key_is_not_found";
  }
}
/**
 * @param {int} toothNumber the Universal tooth number
 * @returns {int} 1 => LU, 2 => RU, 3 => RL, 4 => LL, 5 => wrong tooth number
 */
function getToothQuarter(toothNumber) {
  if (toothNumber < 9) {
    return 1;
  } else if (toothNumber < 17) {
    return 2;
  } else if (toothNumber < 25) {
    return 3;
  } else if (toothNumber < 33) {
    return 4;
  } else {
    return 5;
  }
}
