import S3 from "aws-sdk/clients/s3";
import AWS from "aws-sdk/global";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3"; // ES Modules import

const BASE_URL = process.env.REACT_APP_S3_URL;

export const uploadFile = (file, filename) => {
  return new Promise((resolve, reject) => {
    // To get the temporary access key and secret key you need to give
    // the s3 region with the identity pool id in the CognitoIdentityCredentials
    const IdentityPoolId = process.env.REACT_APP_AWS_IDENTITYPOOLID;
    const region = process.env.REACT_APP_AWS_REGION;
    AWS.config.update({
      region: region,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId, // Use the Identity Pool ID directly
      }),
    });

    const S3Client = new S3();
    const date = new Date();

    const params = {
      Bucket: process.env.REACT_APP_AWS_S3_BUCKET,
      // Using the date.getTime() method for a unique name;
      // however, you may want to consider alternative methods to avoid reliance on this approach.
      Key: filename,
      Body: file,
      ContentType: file.type,
      ACL: "private",
    };

    S3Client.upload(params, function (err, data) {
      if (err) {
        console.error("Error uploading file:", err);
        return reject(err);
      }
      resolve(data);
    });
  });
};
export function keyToUrl(key) {
  return `${BASE_URL.replace("/public", "")}${key}`;
}
