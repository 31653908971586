export const getgroupsPerPage = page => {
  return groups[page];
};

const groups = {
  main: [
    "nav",
    "movement_table",
    "staging_timeline",
    "bolton",
    "overjet_overbite",
    "controls",
    "other",
  ],
};
