import { useCustomTranslation } from "@/hooks/useTranslation";
import React from "react";

function Loader(props) {
  const { tpLoaded } = props;
  const { content } = useCustomTranslation();

  const getContent = (group, key) => {
    return content(`other.${group}.${key}`);
  };
  return (
    <div
      className="bounty_spinner_container"
      style={
        !tpLoaded
          ? { display: "block", zIndex: 99 }
          : { display: "none", zIndex: -1 }
      }
    >
      <div
        className={"bounty_spinner"}
        style={
          !tpLoaded
            ? { display: "block", zIndex: 99 }
            : { display: "none", zIndex: -1 }
        }
      >
        <div className={"bounty_double_bounce1"}></div>
        <div className={"bounty_double_bounce2"}></div>
      </div>
      <div
        className={"bounty_loading_title"}
        style={
          !tpLoaded
            ? { display: "block", zIndex: 99 }
            : { display: "none", zIndex: -1 }
        }
      >
        {getContent("labels", "preparing_treatment_plan")}
      </div>
    </div>
  );
}

export default Loader;
