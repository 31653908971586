import * as THREE from "three";
import { useGlobalStore } from "../../../store";
import { TurnElastics } from "../Elastics";
const { DEG2RAD } = THREE.MathUtils;

export const controllerActionStrings = {
  left: "left",
  right: "right",
  front: "front",
  top: "top",
  bottom: "bottom",
  upper: "upper",
  lower: "lower",
  "elastics-view": "elastics-view",
  "attachments-view": "attachments-view",
  "superImpose-view": "superImpose-view",
  "ipr-view": "ipr-view",
  "numbers-view": "numbers-view",
  "pontics-view": "pontics-view",
  "occlus-view": "occlus-view",
  "split-view": "split-view",
  "grid-view": "grid-view",
  "staging-view": "staging-view",
  "movement-view": "movement-view",
  "bolton-view": "bolton-view",
};

/**
 *
 * @param {keyof controllerActionStrings} action
 * @param {*} view
 * @param {*} setView
 * @param {*} setToggle
 * @param {*} toggle
 * @param {*} boltonInfo
 * @returns
 */
export const controller = (
  action,
  controlsRef,
  controlsRef2,
  view,
  setView,
  setToggle,
  toggle,
  boltonInfo,
) => {
  switch (action) {
    case "left":
      if (view === "left") return;
      controlsRef.reset(true);
      controlsRef2.reset(true);
      controlsRef?.rotate(90 * DEG2RAD, 0, true);
      controlsRef2?.rotate(90 * DEG2RAD, 0, true);
      setView(prev => "left");
      setToggle("showUpper", false);
      setToggle("showLower", false);
      break;
    case "right":
      if (view === "right") return;
      controlsRef.reset(true);
      controlsRef2.reset(true);
      controlsRef?.rotate(-90 * DEG2RAD, 0, true);
      controlsRef2?.rotate(-90 * DEG2RAD, 0, true);
      setView(prev => "right");
      setToggle("showUpper", false);
      setToggle("showLower", false);
      break;
    case "front":
      controlsRef.reset(true);
      controlsRef2.reset(true);
      setView(prev => "front");
      setToggle("showUpper", false);
      setToggle("showLower", false);
      break;
    case "top":
      if (view === "top") return;
      controlsRef.reset(true);
      controlsRef2.reset(true);
      controlsRef?.rotate(0, 90 * DEG2RAD, true);
      controlsRef2?.rotate(0, 90 * DEG2RAD, true);
      setView(prev => "top");
      setToggle("showUpper", true);
      setToggle("showLower", false);
      break;
    case "bottom":
      if (view === "bottom") return;
      controlsRef.reset(true);
      controlsRef2.reset(true);
      controlsRef?.rotate(0, -90 * DEG2RAD, true);
      controlsRef2?.rotate(0, -90 * DEG2RAD, true);
      setView(prev => "bottom");
      setToggle("showUpper", false);
      setToggle("showLower", true);
      break;
    case "upper":
      setToggle("showUpper", true);
      setToggle("showLower", false);
      break;
    case "lower":
      setToggle("showUpper", false);
      setToggle("showLower", true);
      break;
    case "attachments-view":
      setToggle("attachment");
      break;
    case "elastics-view":
      if (toggle.occ || toggle.show3DControls || toggle.superImpose) return;
      setToggle("elastics");
      break;
    case "superImpose-view":
      if (
        toggle.split ||
        toggle.occ ||
        toggle.compareModifications ||
        toggle.elastics
      )
        return;
      setToggle("superImpose");
      break;
    case "ipr-view":
      setToggle("ipr");
      break;
    case "numbers-view":
      setToggle("numbering");
      break;
    case "pontics-view":
      setToggle("pontics");
      break;
    case "occlus-view":
      if (toggle.superImpose || toggle.elastics) return;
      setToggle("occ");
      break;
    case "split-view":
      if (toggle.superImpose || toggle.show3DControls) return;
      controlsRef.reset(true);
      controlsRef2.reset(true);
      setToggle("split");
      break;
    case "grid-view":
      if (toggle.show3DControls) return;
      setToggle("grid");
      break;
    case "staging-view":
      setToggle("showStaging");
      break;
    case "movement-view":
      setToggle("showMovement");
      break;
    case "bolton-view":
      if (boltonInfo === null) return;
      setToggle("showBolton");
      break;
    case "records-view":
      setToggle("showRecords");
      break;
    default:
      controlsRef.reset(true);
      controlsRef2.reset(true);
  }
};
