export function isMobileBrowser() {
  const userAgent = navigator.userAgent || window.opera;

  if (/Android/.test(userAgent)) {
    return isAndroidMobile() || isAndroidTablet();
  }

  return /(bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
    userAgent,
  );
}

function isAndroidMobile() {
  return /Android.*Mobile/.test(navigator.userAgent);
}

function isAndroidTablet() {
  return (
    /Android/.test(navigator.userAgent) &&
    !/Android.*Mobile/.test(navigator.userAgent)
  );
}

export function isAppleMobile() {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent);
}

export const isIpad = /ipad/i.test(navigator.userAgent.toLowerCase());