import React, { useState } from "react";
import {
  Rotational,
  Extrusion,
  VBRA,
  HBRA,
  RootControl,
  BiplaneRotational,
  Oblique,
  TrashCan,
} from "../Icons";
import * as THREE from "three";
import { useGlobalStore } from "../../../store";
import { ToolTipToothMovement } from "@/components/shared-components/ToolTip";
import { names, deleteAttachmentFromTooth } from "./addRemoveAttachment";
import { useEffect } from "react";
import {
  MovementList,
  undoMovementList,
  redoMovementList,
  clearRedoMovementList,
  MovementTypes,
} from "./TeethPositionHelper";
import { useCustomTranslation } from "@/hooks/useTranslation";

export const attachmentTypes = [
  { svg: <Extrusion />, name: "att_extrusion", text: "extrusion_attachment" },
  { svg: <Oblique />, name: "att_oblique", text: "oblique_attachment" },
  {
    svg: <VBRA />,
    name: "att_vbra",
    text: "vertical_beveled_rectangular_attachment",
  },
  {
    svg: <HBRA />,
    name: "att_hbra",
    text: "horizontal_beveled_rectangular_attachment",
  },
  {
    svg: <Rotational />,
    name: "att_rotational",
    text: "rotational_attachment",
  },
  {
    svg: <RootControl />,
    name: "att_root_control",
    text: "root_control_attachment",
  },
  {
    svg: <BiplaneRotational />,
    name: "att_biolane",
    text: "biblane_rotational_attachment",
  },
];

function AttachmentSelector({ setAutoSave }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { content } = useCustomTranslation();

  const getContent = (group, key) => {
    return content(`controls.${group}.${key}`);
  };
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const [activeIndex, setActiveIndex] = useState(null);

  const {
    selectedAttachmentType,
    selectedTooth,
    setControls3D,
    logs,
    setLogs,
    attachmentArray,
    groupRef,
  } = useGlobalStore(state => ({
    selectedAttachmentType: state.controls3D.selectedAttachmentType,
    selectedTooth: state.controls3D.selectedTooth,
    setControls3D: state.setControls3D,
    logs: state.logs,
    setLogs: state.setLogs,
    attachmentArray: state.controls3D.attachmentArray,
    groupRef: state.refs.groupRef,
  }));

  const handleDivClick = (index, name) => {
    //console.log(controls.selectedTooth.mesh);
    if (activeIndex === index) {
      // If the same div is clicked again, toggle it off
      setActiveIndex(null);
    } else {
      // Set the new active div and reset the previous one
      setControls3D("selectedAttachmentType", name);
      setActiveIndex(index);
    }
  };

  const deleteAttachment = () => {
    let selectedToothNum = selectedTooth.name.split("_")[2].substring(1, 4);
    let action;
    let attachmentType = selectedAttachmentType;
    if (attachmentArray[selectedToothNum]?.length > 0) {
      action = "remove";
      attachmentType = "";
    } else {
      action = "";
    }

    let key = selectedToothNum.toString() + "_Movement";
    if (logs[key]) {
      const obj = Object.assign({}, logs[key]);
      delete logs[key];
      obj["attachment"] = {
        action: action,
        type: attachmentType,
      };
      logs[key] = obj;
    } else {
      logs[key] = {};
      logs[key]["attachment"] = {
        action: action,
        type: attachmentType,
      };
      logs[key][MovementTypes.MESIALDISTAL] = 0;
      logs[key][MovementTypes.BUCCALLINGUAL] = 0;
      logs[key][MovementTypes.EXTRUSIONINTRUSION] = 0;
      logs[key][MovementTypes.ROTATION] = 0;
      logs[key][MovementTypes.ANGULATION] = 0;
      logs[key][MovementTypes.INCLINATION] = 0;
      logs[key][MovementTypes.LEFTHINGE] = 0;
      logs[key][MovementTypes.RIGHTHINGE] = 0;
    }
    setLogs(logs);
    deleteAttachmentFromTooth(selectedTooth, logs, setLogs);
    clearRedoMovementList();
    return;
  };

  return (
    <div className="bounty_tooth_position">
      <div className="bounty_tooth_position_div_1">
        <div id="attachments" className="text-sm font-medium">
          {getContent("labels", "attachments")}
        </div>
        <div
          onClick={toggleModal}
          style={{ cursor: "pointer", zIndex: "99" }}
          className={isModalOpen ? "arrow arrow_active" : "arrow"}
        />
      </div>
      {!isModalOpen && (
        <div>
          <div style={{ display: "grid", justifyItems: "center" }}>
            <div className="bounty_divider" />
          </div>
          <div>
            <div
              className={`bounty_tooth_position_div_2 ${
                selectedTooth?.userData["isLocked"] || !selectedTooth
                  ? "bounty_controls_disabled"
                  : ""
              }`}
            >
              <div className="bounty_tooth_position_div_2_2">
                {attachmentTypes.map((att, i) => {
                  return (
                    <div
                      className={`${
                        selectedAttachmentType === att.name
                          ? "selected_att"
                          : ""
                      }`}
                      style={{
                        cursor:
                          selectedTooth?.userData["isLocked"] || !selectedTooth
                            ? "not-allowed"
                            : "pointer",
                      }}
                      key={i}
                      onClick={() =>
                        selectedTooth?.userData["isLocked"] || !selectedTooth
                          ? null
                          : handleDivClick(i, att.name)
                      }
                    >
                      <ToolTipToothMovement
                        text={getContent("tooltips", att.text)}
                        svg={att.svg}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <div style={{ display: "grid", justifyItems: "center" }}>
              <div className="bounty_divider" />
            </div>
            {selectedTooth && selectedTooth.children.length > 0 && (
              <>
                <div className="my-6 font-normal">
                  {getContent("labels", "attachment_note")}
                </div>
                <div
                  onClick={() => {
                    deleteAttachment(), setAutoSave();
                  }}
                  className="bounty_delete_att flex items-center justify-center"
                >
                  <div className="px-2">
                    <TrashCan />
                  </div>
                  <button> {getContent("actions", "delete")}</button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default AttachmentSelector;
