import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, Transition } from "@headlessui/react";

import { de } from "../translation/de/index_de";
import { en } from "../translation/en/index_en";
import { fr } from "../translation/fr/index_fr";
import { useModal } from "./useModal";
import { Tab } from "@headlessui/react";
import { TranslateIcon } from "../components/viewer/Icons";
export const useCustomTranslation = () => {
  const { t, i18n } = useTranslation();
  const [storedData, setStoredData] = useState(
    JSON.parse(localStorage.getItem("translation")),
  );
  const changeLanguage = language => {
    i18n.changeLanguage(language);
  };
  const content = val => {
    const splited_key = val.split(".");
    const env = process.env?.NODE_ENV;
    return t(val) === "missing_translation" && env === "production"
      ? storedData["en"]?.[splited_key[0]]?.[splited_key[1]]?.[
          splited_key[2]
        ] || t(val)
      : t(val);
  };

  return {
    content,
    changeLanguage,
  };
};

export const TranslationComponent = ({ group, color }) => {
  const { t, i18n } = useTranslation();
  const { ModalContainer: TranslateModal, toggleModal: toggleTranslate } =
    useModal();

  const [activeTab, setActiveTab] = useState(1);

  const [modalOpen, setModalOpen] = useState(false);

  const [user_data, setUserData] = useState(
    localStorage.getItem("user_data")
      ? JSON.parse(localStorage.getItem("user_data"))
      : {},
  );
  const is_translator =
    process.env.REACT_APP_TRANSLATOR === "true" ? true : false || false;

  const translator = process.env.REACT_APP_TRANSLATORS
    ? JSON.parse(process.env.REACT_APP_TRANSLATORS)?.filter(
        elem => elem.email === user_data?.email,
      )?.[0]
    : {};
  // Step 1: Retrieve the entire object from localStorage

  const [storedData, setStoredData] = useState(
    translator && Object.keys(translator).length > 0
      ? localStorage.getItem("translation")
        ? JSON.parse(localStorage.getItem("translation"))
        : { en: en, fr: fr, de: de }
      : { en: en, fr: fr, de: de },
  );
  const [data, setData] = useState({
    en: storedData["en"][group],
    fr: storedData["fr"][group],
    de: storedData["de"][group],
  });
  const languages = Object.keys(data);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const toggleModal = () => {
    setModalOpen(!modalOpen);
    const initData =
      translator && Object.keys(translator).length > 0
        ? localStorage.getItem("translation")
          ? JSON.parse(localStorage.getItem("translation"))
          : { en: en, fr: fr, de: de }
        : { en: en, fr: fr, de: de };
    setData({
      en: initData["en"][group],
      fr: initData["fr"][group],
      de: initData["de"][group],
    });
    setStoredData(initData);
  };
  // on change data in session storage
  const onChangeData = ({ lang, group2, key, value }) => {
    // Step 2: Modify the inner object
    if (key) {
      storedData[lang][group][group2][key] = value;

      // Step 4: reset data
      setData({
        en: storedData["en"][group],
        fr: storedData["fr"][group],
        de: storedData["de"][group],
      });
      setStoredData(storedData);
    }
  };

  const SaveChanges = () => {
    // Step 3: Save the updated object back to localStorage
    localStorage.setItem("translation", JSON.stringify(storedData));

    //   toggleModal();
    window.location.reload(); // Reload the page
  };

  const translator_lang = translator?.language ? translator?.language : "fr";
  const [value, setValue] = useState(translator_lang || "en");

  if (!is_translator || !translator_lang) {
    return <></>;
  }
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <Fragment>
      {is_translator && (
        <span
          className="edit_icon_translation"
          onClick={() => setModalOpen(!modalOpen)}
        >
          <TranslateIcon
            fontSize="large"
            className={`${
              color === "secondary"
                ? "svg_icon_traslation_secondary2"
                : "svg_icon_traslation"
            } edit_translation`}
          />
        </span>
      )}

      <Transition appear show={modalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[1000]" onClose={toggleModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-[60%]  transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="mb-3 text-lg font-medium leading-6 text-gray-900"
                  >
                    {`Translate ${group}`}
                  </Dialog.Title>
                  <Tab.Group>
                    <Tab.List className="flex p-1 space-x-1 rounded-xl bg-blue-900/20">
                      {translator_lang === "all" ? (
                        <Tab
                          className={({ selected }) =>
                            classNames(
                              "w-full rounded-lg py-2.5 text-sm font-medium leading-5",
                              "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                              selected
                                ? "bg-white text-blue-700 shadow"
                                : "text-blue-100 hover:bg-white/[0.12] hover:text-white",
                            )
                          }
                          onClick={() => {
                            setActiveTab(1);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          All
                        </Tab>
                      ) : translator_lang ? (
                        <Tab
                          className={({ selected }) =>
                            classNames(
                              "w-full rounded-lg py-2.5 text-sm font-medium leading-5",
                              "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                              selected
                                ? "bg-white text-blue-700 shadow"
                                : "text-blue-100 hover:bg-white/[0.12] hover:text-white",
                            )
                          }
                          onClick={() => {
                            setActiveTab(1);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {translator_lang.toLocaleUpperCase()}
                        </Tab>
                      ) : (
                        languages.map((lang, index) => (
                          <Tab
                            key={lang}
                            className={({ selected }) =>
                              classNames(
                                "w-full rounded-lg py-2.5 text-sm font-medium leading-5",
                                "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                                selected
                                  ? "bg-white text-blue-700 shadow"
                                  : "text-blue-100 hover:bg-white/[0.12] hover:text-white",
                              )
                            }
                            onClick={() => {
                              setActiveTab(index);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            {lang.toLocaleUpperCase()}
                          </Tab>
                        ))
                      )}
                    </Tab.List>

                    <Tab.Panels className="mt-2 max-h-[65vh] w-auto overflow-auto">
                      {Object.values(languages).map((lang, idx) => (
                        <Tab.Panel
                          key={idx}
                          className={
                            "rounded-xl bg-white p-3 ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"
                          }
                        >
                          <div className="flex flex-col">
                            <div className="overflow-x-auto">
                              <div className="inline-block w-full p-1.5 align-middle">
                                <div className="overflow-hidden border rounded-lg">
                                  <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                      <tr>
                                        <th
                                          scope="col"
                                          className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                                        >
                                          Group
                                        </th>
                                        {value !== "all" && value !== "en" && (
                                          <th
                                            scope="col"
                                            className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                                          >
                                            En{" "}
                                          </th>
                                        )}
                                        {languages
                                          .filter(elem =>
                                            value === "all"
                                              ? elem
                                              : elem === value,
                                          )
                                          .map(lang => (
                                            <th
                                              key={lang}
                                              scope="col"
                                              className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                                            >
                                              {lang}
                                            </th>
                                          ))}
                                      </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                      {Object.keys(data.en)?.map(group => {
                                        return Object.keys(data.en[group])?.map(
                                          (key, index) => (
                                            <tr key={key}>
                                              {index === 0 && (
                                                <th
                                                  className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap"
                                                  rowSpan={
                                                    Object.keys(data.en[group])
                                                      .length
                                                  }
                                                >
                                                  {group}
                                                </th>
                                              )}

                                              {languages
                                                .filter(elem =>
                                                  value === "all"
                                                    ? elem
                                                    : value !== "en" ||
                                                        value !== "all"
                                                      ? elem === value ||
                                                        elem === "en"
                                                      : elem === value,
                                                )
                                                .map(lang => (
                                                  <td
                                                    key={lang}
                                                    className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap"
                                                  >
                                                    <textarea
                                                      id="translationValue"
                                                      type="textarea"
                                                      placeholder="with a placeholder"
                                                      max={4}
                                                      onChange={e => {
                                                        onChangeData({
                                                          lang,
                                                          group2: group,
                                                          key,
                                                          value: e.target.value,
                                                        });
                                                      }}
                                                      defaultValue={
                                                        data[lang][group][key]
                                                      }
                                                      className={`block w-full appearance-none p-[8px] focus:outline-none ${
                                                        data[lang][group][
                                                          key
                                                        ] ===
                                                        "missing_translation"
                                                          ? "missing_translation"
                                                          : ""
                                                      }`}
                                                      invalid={
                                                        (
                                                          data[lang][group][
                                                            key
                                                          ] ===
                                                          "missing_translation"
                                                        ).toString() || "false"
                                                      }
                                                      disabled={lang === "en"}
                                                    />
                                                  </td>
                                                ))}
                                            </tr>
                                          ),
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tab.Panel>
                      ))}
                    </Tab.Panels>
                  </Tab.Group>

                  <div className="flex justify-end gap-2 mt-4">
                    <button
                      className="rounded-[3px] border border-solid border-[#BCBCBC] px-5 py-3 text-center text-sm font-normal text-[#666666]"
                      onClick={() => {
                        toggleTranslate();
                        toggleModal();
                      }}
                    >
                      {"Cancel"}
                    </button>
                    <button
                      className="rounded-[3px] border border-solid border-[#009ACE] bg-[#009ACE] px-5 py-3 text-center text-sm font-bold text-[#FFF]"
                      onClick={SaveChanges}
                    >
                      {"Save"}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </Fragment>
  );
};

export const CheckGroupThatHasMissing = () => {
  //const { user_data } = useSelector(currentUser);
  const [user_data, setUserData] = useState(
    JSON.parse(localStorage.getItem("user_data")),
  );
  const [data, setData] = useState(
    JSON.parse(localStorage.getItem("translation")),
  );

  const translator = process.env.REACT_APP_TRANSLATORS
    ? JSON.parse(process.env.REACT_APP_TRANSLATORS)?.filter(
        elem => elem.email === user_data.email,
      )?.[0]
    : {};

  const translator_lang = translator?.language ? translator?.language : null;
  const missing_groups = [];
  function checkForMissingTranslation(obj, group, path = []) {
    for (const key in obj) {
      const newPath = [...path, key];

      if (typeof obj[key] === "object") {
        checkForMissingTranslation(obj[key], group, newPath);
      } else if (obj[key] === "missing_translation") {
        // console.log({ group });
        missing_groups.push(group);
      }
    }
  }

  const languages = translator_lang
    ? translator_lang === "all"
      ? Object.keys(data)
      : [translator_lang]
    : [];

  const checkMissing = () => {
    for (const language of languages) {
      const groups = Object.keys(data[language]);
      for (const group of groups) {
        const inner_groups = Object.keys(data[language][group]);
        for (const inner_group of inner_groups) {
          checkForMissingTranslation(data[language][group][inner_group], group);
        }
      }
    }
    return [...new Set(missing_groups)];
  };
  return {
    checkMissing,
  };
};

/*****************************/
