import React, { Fragment, useState } from "react";
import "./sidebar.scss";
import { TranslateIcon } from "../viewer/Icons";
import { getgroupsPerPage } from "@/translation/groups";
import { Dialog, Transition } from "@headlessui/react";
import {
  CheckGroupThatHasMissing,
  TranslationComponent,
} from "@/hooks/useTranslation";
import JSZip from "jszip";
import { keyToUrl, uploadFile } from "@/helpers/s3";
import { de } from "@/translation/de/index_de";
import { en } from "@/translation/en/index_en";
import { fr } from "@/translation/fr/index_fr";
import { LegendToggleRounded } from "@mui/icons-material";
const TranslationCustomizer = () => {
  const [translationFile, setTranslationFile] = useState("all");
  const [allMissingGroups, setAllMissingGroups] = useState([]);
  const [toggleModal, setToggleModal] = useState(false);

  const [generating, setGenerating] = useState(false);

  const [link, setLink] = useState("");
  // const content = useSelector(currentContent);
  const initData = JSON.parse(localStorage.getItem("translation"));

  const { checkMissing } = CheckGroupThatHasMissing();
  const [currentGroups, setCurrentGroups] = useState(getgroupsPerPage("main"));
  /*--------------------------------------------------------------------------------*/
  /* toggle customizer                                          */
  /*--------------------------------------------------------------------------------*/
  const toggle = () => {
    document
      .getElementById("customizer3")
      .classList.toggle("show-service-panel");
  };

  const UploadFile = async (file, id) => {
    return uploadFile({
      name: `translation/${id}/${new Date().valueOf()}.${`exported_${translationFile}.json`}`,
      contentType: file.type,
      file: file,
    });
  };
  const UploadAllFile = async (file, id) => {
    let filekey = await uploadFile(
      file,
      `public/translation/all/${new Date().valueOf()}.${`exported_${"translationFiles"}.zip`}`,
    );

    return filekey;
  };
  const Export = () => {
    const chosen_File =
      translationFile !== "all"
        ? translationFile.replace("_file", "")
        : translationFile;
    translationFile !== "all"
      ? Export_and_upload_one(chosen_File)
      : Export_and_upload_all();
  };
  /*--------------------------------------------------------------------------------*/
  /* Export_and_upload_one                                                 */
  /*--------------------------------------------------------------------------------*/
  const Export_and_upload_one = async chosen_File => {
    const jsonData = initData[chosen_File];
    const jsonString = JSON.stringify(jsonData, null, 2);
    const blob = new Blob([jsonString], {
      type: "application/json",
      name: `exported_${translationFile}.json`,
    });
    const url = URL.createObjectURL(blob);
    const { key } = await UploadFile(blob, translationFile);

    setLink(keyToUrl(key));

    const a = document.createElement("a");
    a.href = url;
    a.download = `exported_${translationFile}.json`;
    a.style.display = "none";

    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };
  /*--------------------------------------------------------------------------------*/
  /* Export_and_upload_all                                                */
  /*--------------------------------------------------------------------------------*/
  const Export_and_upload_all = async () => {
    setGenerating(true);
    const zip = new JSZip();

    // Add JSON files to the zip archive
    zip.file("en.json", JSON.stringify(initData["en"], null, 2));
    zip.file("fr.json", JSON.stringify(initData["fr"], null, 2));
    zip.file("de.json", JSON.stringify(initData["de"], null, 2));

    // Generate a blob from the zip archive
    const blob = await zip.generateAsync({ type: "blob" });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);
    const { key } = await UploadAllFile(blob, "translationFiles");
    setLink(keyToUrl(key));
    CopyLink(keyToUrl(key));
    // Create a link element for downloading the zip file
    const a = document.createElement("a");
    a.href = url;
    a.download = "exported-data.zip";
    a.style.display = "none";

    document.body.appendChild(a);
    // a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };
  const CopyLink = link => {
    navigator.clipboard.writeText(link);
    alert("link is copied");
    setGenerating(false);
  };

  const ExportandCopy = async () => {
    await Export_and_upload_all();

    //await CopyLink();
  };
  const FindMissing = () => {
    const missings = checkMissing();

    setAllMissingGroups(missings);
    ToggleTranslationModal();
  };
  const ToggleTranslationModal = () => {
    setToggleModal(!toggleModal);
  };

  const checkKeys = () => {
    const mergeObjects = (obj1, obj2) => {
      let merged = {};

      for (const lang in obj1) {
        if (obj1.hasOwnProperty(lang) && obj2.hasOwnProperty(lang)) {
          merged[lang] = {};
          //languages groups
          for (const group in obj1[lang]) {
            if (obj2[lang].hasOwnProperty(group)) {
              merged[lang][group] = obj2[lang][group];

              // actions , labels ...etc groups
              for (const innerGroup in obj1[lang][group]) {
                if (obj2[lang][group].hasOwnProperty(innerGroup)) {
                  merged[lang][group][innerGroup] =
                    obj2[lang][group][innerGroup];

                  // keys inside each group
                  for (const key in obj1[lang][group][innerGroup]) {
                    if (obj2[lang][group][innerGroup].hasOwnProperty(key)) {
                      console.log(
                        "change inner group key ",
                        obj2[lang][group][innerGroup][key],
                      );
                      merged[lang][group][innerGroup][key] =
                        obj2[lang][group][innerGroup][key];
                    } else {
                      console.log(
                        "add new key ",
                        obj1[lang][group][innerGroup][key],
                      );

                      // add new groups keys
                      merged[lang][group][innerGroup][key] =
                        obj1[lang][group][innerGroup][key];
                    }
                  }
                } else {
                  // add new inner groups
                  console.log(
                    "add new inner group ",
                    obj1[lang][group][innerGroup],
                  );

                  merged[lang][group][innerGroup] =
                    obj1[lang][group][innerGroup];
                }
              }
            } else {
              // add new groups
              console.log("add new group ", obj1[lang][group]);

              merged[lang][group] = obj1[lang][group];
            }
          }
        }
      }

      return merged;
    };

    const obj1 = { en, fr, de };
    const obj2 = JSON.parse(localStorage.getItem("translation")) || {};

    const result = mergeObjects(obj1, obj2);
    console.log(result);
    localStorage.setItem("translation", JSON.stringify(result));
    window.location.reload();
  };
  return (
    <aside className="customizer" id="customizer3">
      <span
        className="text-white service-panel-toggle translation_toggle"
        onClick={toggle}
      >
        <TranslateIcon className="svg_icon_traslation_secondary translate_icon" />
      </span>
      <div className="customizer-body">
        <div className="px-3 py-3 mt-3 border-bottom">
          <h5 className="m-0 font-medium">Export Translation File</h5>

          <button
            onClick={ExportandCopy}
            variant="outlined"
            className="w-100 mb-3 mt-3 rounded-lg border border-solid border-[#444444] bg-[#444444] px-5 py-3 text-center text-sm  text-[#FFF]"
            style={{
              background: "#1c64f2",
              outline: "none",
              border: "none",
              padding: "6px 12px",
            }}
            disabled={generating}
          >
            {generating
              ? "Exporting File ....."
              : "Export File & Copy Link to Share"}
          </button>
          <hr></hr>
          {/* <h5 className="mt-3 font-medium">
              Find Missing keys Groups in the Portal
            </h5> */}

          <button
            onClick={FindMissing}
            variant="outlined"
            color="success"
            style={{
              outline: "none",
              border: "none",
              padding: "6px 12px",
            }}
            className="w-100 mb-3  mt-2 rounded-lg border border-solid border-[#198754] bg-[#198754] px-5 py-3 text-center text-sm font-normal text-[#FFF]"
          >
            Find Missing translations
          </button>

          <hr></hr>
          <button
            onClick={checkKeys}
            variant="outlined"
            className="w-100 mb-3 mt-3 rounded-lg border border-solid border-[#444444] bg-[#444444] px-5 py-3 text-center text-sm  text-[#FFF]"
            style={{
              background: "#1c64f2",
              outline: "none",
              border: "none",
              padding: "6px 12px",
            }}>
            Refresh Keys
          </button>

          <hr></hr>
          <h5 className="m-0 mt-3 mb-2 font-medium">All Groups </h5>

          {currentGroups && (
            <ul
              className="max-w-md divide-y divide-gray-200 dark:divide-gray-700 "
              style={{
                overflow: "auto",
                height: "65vh",
                border: "1px solid lightgray",
                borderRadius: "4px",
              }}
            >
              {" "}
              {currentGroups.map((group, index) => {
                return (
                  <li className="relative p-2 translation-groups" key={index}>
                    <div className="flex items-center space-x-4 rtl:space-x-reverse">
                      <div>
                        {group}
                        <TranslationComponent group={group} />
                      </div>
                    </div>
                  </li>
                );
              })}{" "}
            </ul>
          )}
        </div>
      </div>
      <Transition appear show={toggleModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[150] "
          onClose={ToggleTranslationModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="mb-3 text-lg font-medium leading-6 text-gray-900"
                  >
                    sections that have missing translation
                  </Dialog.Title>
                  <ul
                    className="max-w-md divide-y divide-gray-200 dark:divide-gray-700 "
                    style={{
                      overflow: "auto",
                      height: "80vh",
                      border: "1px solid lightgray",
                      borderRadius: "4px",
                    }}
                  >
                    {allMissingGroups.map((group, index) => {
                      return (
                        <li
                          className="relative p-2 translation-groups"
                          key={index}
                        >
                          <div className="flex items-center space-x-4 rtl:space-x-reverse">
                            <div>
                              {group}
                              <TranslationComponent group={group} />
                            </div>
                          </div>
                        </li>
                      );
                    })}{" "}
                  </ul>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </aside>
  );
};

export default TranslationCustomizer;
